import { useState } from "react";
import { useOutletContext } from "react-router-dom";

import DataFetcher from "../../../components/DataFetcher";
import { SearchBox } from "../../../components/Input";
import BatchDetailsModal from "./views/BatchDetailsModal";
import BatchTableList from "./views/BatchTableList";
import BatchCreationModal from "./views/BatchCreationModal";
import PlusBox from "../../../assets/icons/plusbox";
import { getPermissions } from "../../../config/swr";

const BATCH_CREATE_KEY = "create-batch-accounts";

const BatchAccountPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Batch Create Account");

  return (
    <DataFetcher
      url={`/accounts/batches`}
      buildUI={(data, mutate) => (
        <BatchAccountDisplay batches={data} mutate={mutate} />
      )}
    />
  );
};

const BatchAccountDisplay = ({ batches }) => {
  const permissions = getPermissions();

  const canCreateBatch = permissions[BATCH_CREATE_KEY];
  const [createBatchModal, setCreateBatchModal] = useState(false);

  const openCreateBatchModal = () => setCreateBatchModal(true);
  const closeCreateBatchModal = () => setCreateBatchModal(false);
  const [selectedBatchIndex, setSelectedBatchIndex] = useState(-1);
  const hideBatchDetailsView = () => setSelectedBatchIndex(-1);
  const selectedBatch =
    selectedBatchIndex < 0 ? null : batches[selectedBatchIndex];

  return (
    <div className="customers">
      <div className="flex between-center mb-32" style={{ padding: "0 24px" }}>
        <SearchBox placeholder={"Search File"} />

        {canCreateBatch && (
          <button
            className="flex center gap-8 btn primary fw-600"
            onClick={openCreateBatchModal}
          >
            <PlusBox />
            Upload Batch File
          </button>
        )}
      </div>

      <BatchTableList
        batches={batches}
        displayBatchDetails={setSelectedBatchIndex}
      />

      {selectedBatch && (
        <BatchDetailsModal
          batch={selectedBatch}
          dismissModal={hideBatchDetailsView}
        />
      )}

      {createBatchModal && (
        <BatchCreationModal dismissModal={closeCreateBatchModal} />
      )}
    </div>
  );
};

export default BatchAccountPage;
