import React from "react";

const ChatIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.048 0.5C37.68 0.5 48 11.6768 48 24.4639C48 39.2942 35.904 48.5 24 48.5C20.064 48.5 15.696 47.4424 12.192 45.3753C10.968 44.6302 9.936 44.0774 8.616 44.51L3.768 45.9522C2.544 46.3368 1.44 45.3753 1.8 44.0774L3.408 38.6933C3.672 37.9482 3.624 37.155 3.24 36.53C1.176 32.7323 0 28.5741 0 24.5361C0 11.8931 10.104 0.5 24.048 0.5ZM35.016 21.4835C33.312 21.4835 31.944 22.8535 31.944 24.5601C31.944 26.2426 33.312 27.6367 35.016 27.6367C36.72 27.6367 38.088 26.2426 38.088 24.5601C38.088 22.8535 36.72 21.4835 35.016 21.4835ZM23.952 21.4835C22.272 21.4594 20.88 22.8535 20.88 24.5361C20.88 26.2426 22.248 27.6127 23.952 27.6367C25.656 27.6367 27.024 26.2426 27.024 24.5601C27.024 22.8535 25.656 21.4835 23.952 21.4835ZM12.888 21.4835C11.184 21.4835 9.816 22.8535 9.816 24.5601C9.816 26.2426 11.208 27.6367 12.888 27.6367C14.592 27.6127 15.96 26.2426 15.96 24.5601C15.96 22.8535 14.592 21.4835 12.888 21.4835Z"
        fill="#D7D7D8"
      />
    </svg>
  );
};

export default ChatIcon;
