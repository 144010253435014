import { useState } from "react";
import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";
import Select from "react-select";

import Checkbox from "../../../../../components/Checkbox";
import { sendPatchRequest, sendPostRequest } from "../../../../../config/swr";
import useForm from "../../../../../hooks/useForm.hook";
import useMessageModal from "../../../../../hooks/useMessageModal.hook";
import { findFieldsWithDropdown } from "../../../../../utils/helpers";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    border: "1px solid #9fa4a7",
    borderRadius: "12px",
    padding: "10px 8px",
    width: "100%",
    "&:hover": {
      border: "1px solid #9fa4a7",
    },
  }),
};

export default function AdminDetailsForm({
  details,
  id,
  partners,
  secureAccessToken,
  onSuccess,
}) {
  const partner = partners?.find((item) => item.id === id);
  const [selectedField, setSelectedField] = useState();

  const form = useForm({
    name: details?.name,
    email: details?.email,
  });
  const showMessageModal = useMessageModal();

  const { trigger: postTrigger } = useSWRMutation(
    `/loanpartner_${id}/admin/add`,
    sendPostRequest
  );

  const { trigger: patchTrigger } = useSWRMutation(
    `/loanpartner_${id}/admin/${details?.id}/info`,
    sendPatchRequest
  );

  const selectOptions = findFieldsWithDropdown(partner?.applicationForm);

  const onChangeField = (selectedOption) => {
    setSelectedField(selectedOption);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { name, email, ...rest } = form.data;
    const checkedItems = Object.entries(rest)
      .filter((item) => item[1])
      .map((item) => item[0]);

    const obj = {
      name,
      email,
      secureAccessToken,
      ...(checkedItems.length > 0 && {
        restrictionFormFieldID: selectedField.value,
        restrictedValues: checkedItems,
      }),
    };

    const data = details
      ? {
          payload: obj,
        }
      : obj;

    const trigger = details ? patchTrigger : postTrigger;

    form.submitStarted();
    try {
      await trigger(data);
      onSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <label>Full Name</label>
      <input className="mb-20" type="text" {...form.fieldProps("name")} />

      <label>Email Address</label>
      <input className="mb-32" type="email" {...form.fieldProps("email")} />

      <div className="mb-16">
        <label htmlFor="permissions">Loan Review Restrictions</label>
        <Select
          closeMenuOnSelect={false}
          inputId={"permissions"}
          options={selectOptions}
          styles={colourStyles}
          onChange={onChangeField}
          value={selectedField}
          placeholder="Select field to use for restriction"
        />
      </div>

      {selectedField && (
        <div className="admin-roles mb-28">
          {Object.entries(selectedField?.restrictions ?? {}).map(([label]) => (
            <Checkbox
              key={label}
              text={label}
              color="#627178"
              {...form.fieldProps(label, true)}
            />
          ))}
        </div>
      )}

      <button
        disabled={!form.valid() || form.state.submitting}
        className="fullwidth btn primary"
      >
        {form.state.submitting ? (
          <FiLoader />
        ) : details ? (
          "Save Changes"
        ) : (
          "Add Admin"
        )}
      </button>
    </form>
  );
}
