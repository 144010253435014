const StatementIcon = () => {
  return (
    <svg
      width="88"
      height="100"
      viewBox="0 0 88 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.72548 53.8568C-0.945034 40.9635 -0.118058 24.356 0.884717 7.17064L39.1006 0.453453C42.8771 -0.210254 45.2878 -0.114191 48.8839 0.538505L86.7334 7.40985C87.8417 24.7053 89.6623 42.0301 84.7464 55.963C77.7934 75.6646 64.2968 90.0095 44.7569 100C23.9181 89.7 8.93235 75.6528 2.72548 53.8572V53.8568Z"
        fill="url(#paint0_linear_7479_67090)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.41527 53.489C5.23114 42.3059 5.94877 27.104 6.81827 12.1964L39.5351 6.44214C42.8111 5.86614 45.6056 6.08181 48.7275 6.63465L81.2841 12.4041C82.2455 27.4066 83.825 43.2308 79.5602 55.3157C73.5299 72.405 61.822 84.848 44.8728 93.5138C26.7971 84.5796 13.7971 72.3948 8.41451 53.489H8.41527Z"
        fill="url(#paint1_linear_7479_67090)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3496 52.9236C9.53451 43.0375 10.1686 29.5982 10.9379 16.4197L39.8611 11.3326C42.757 10.823 45.2273 11.0136 47.9873 11.5031L76.769 16.6031C77.6191 29.8662 79.0153 43.855 75.2449 54.5388C74.443 56.8121 73.526 58.9919 72.4978 61.0817H61.3883V24.0975H54.3882V61.0817H47.9653V36.0568H40.9653V61.0817H36.4048V45.3977H29.4047V61.0817H15.4133C14.1983 58.5298 13.1731 55.8157 12.3499 52.924L12.3496 52.9236Z"
        fill="url(#paint2_linear_7479_67090)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.4059 64.9531C64.5419 74.8571 55.8784 82.533 44.5798 88.3097C32.8092 82.492 23.4741 75.1149 17.4707 64.9531H70.4059Z"
        fill="url(#paint3_linear_7479_67090)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7479_67090"
          x1="-5.05675"
          y1="-0.561471"
          x2="102.313"
          y2="106.387"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4B4B4D" />
          <stop offset="0.25098" stopColor="#96989A" />
          <stop offset="0.458824" stopColor="#606062" />
          <stop offset="0.760784" stopColor="#96989A" />
          <stop offset="1" stopColor="#4B4B4D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7479_67090"
          x1="44.877"
          y1="93.5153"
          x2="45.7211"
          y2="11.172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEFEFE" />
          <stop offset="1" stopColor="#E6E7E8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7479_67090"
          x1="47.9657"
          y1="58.2454"
          x2="47.7007"
          y2="-14.6549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A859" />
          <stop offset="0.529412" stopColor="#137546" />
          <stop offset="1" stopColor="#274132" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7479_67090"
          x1="45.5275"
          y1="111.863"
          x2="46.8656"
          y2="64.9527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#373435" />
          <stop offset="1" stopColor="#727376" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StatementIcon;
