import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const LoanTableList = ({ loans, activeStatus, displayLoanDetails }) => {
  const headers = [
    "Account Name",
    "Account No.",
    "Principal",
    "Interest",
    "Request Date",
    activeStatus === "Rejected"
      ? "Rejected Date"
      : activeStatus === "Completed"
      ? "Completion Date"
      : "Maturity Date",
  ];

  const formatDate = (timestamp) =>
    timestamp && timestamp !== 0
      ? dayjs(timestamp * 1000)
          .utc()
          .format("Do MMM YYYY")
      : "-";

  return (
    <>
      {!loans || loans.length === 0 ? (
        <p className="box">No loans {activeStatus}</p>
      ) : (
        <table className="box fs-20">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {loans.map((loan, index) => (
              <tr
                key={index}
                className="clickable fw-600"
                onClick={() => displayLoanDetails(index)}
              >
                <td>{loan.customerName}</td>
                <td>{loan.accounts[0]?.accountNumber}</td>
                <td>&#8358; {loan.loanAmount?.toLocaleString()}</td>
                <td>{loan.interestRate ?? 0}%</td>
                <td>{formatDate(loan.requestedTimestamp)}</td>
                <td>
                  {activeStatus === "Rejected"
                    ? formatDate(loan.dateRejectedTimestamp)
                    : activeStatus === "Completed"
                    ? formatDate(loan.completedTimestamp)
                    : formatDate(loan.dueDateTimestamp)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default LoanTableList;
