import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import BulkMessages from "./bulk-messages";
import ChatAndSupport from "./chat-and-support";
import useConversationSelector from "./useConversationSelector.hook";
import { getPermissions } from "../../config/swr";

const SEND_MESSAGE_KEY = "send-bulk-message";
const CHAT_KEY = "chat";

const ConversationTypeBulkMessages = "Bulk Messages";

const ConversationTypeChat = "Chat Support";

export default function ConversationsPage() {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Messaging");
  const permissions = getPermissions();

  const canSendMessage = permissions[SEND_MESSAGE_KEY];
  const canChat = permissions[CHAT_KEY];

  const conversationTypes = {
    [ConversationTypeBulkMessages]: {
      title: "BULK NOTIFICATION HISTORY",
      formatter: (conversation, index) => {
        const { customers } = conversation;
        return { id: index, ...conversation, target: customers };
      },
    },
    ...(canChat && {
      [ConversationTypeChat]: {
        title: "CHAT / SUPPORT",
        formatter: (conversation) => {
          const { id, createdBy, messages } = conversation;
          const lastMessage = messages.slice().pop();
          return {
            id,
            target: `${createdBy.firstName} ${createdBy.lastName}`,
            message: lastMessage.text,
            lastUpdated: lastMessage.createdAt / 1000,
          };
        },
      },
    }),
  };

  useEffect(() => {
    document.querySelector(".page-header-content").classList.add("fullscreen");
    return () => {
      document
        .querySelector(".page-header-content")
        ?.classList.remove("fullscreen");
    };
  });

  const [
    ConversationSelector,
    setConversationList,
    getConversationList,
    addConversationToList,
    selectedConversationType,
    selectedConversation,
    unselectConversation,
  ] = useConversationSelector({
    conversationTypes,
    defaultType: ConversationTypeBulkMessages,
  });

  const [sendNewBulkMessage, setSendNewBulkMessage] = useState(false);

  const handleSend = () => {
    if (!canSendMessage) return;
    setSendNewBulkMessage(true);
    unselectConversation();
  };

  const displayBulkMessages = (messages) => {
    // display action button after bulk messages are fetched.
    const actionButton = canSendMessage && {
      text: "Send Bulk Message",
      onClick: canSendMessage && handleSend,
    };
    setConversationList(ConversationTypeBulkMessages, messages, actionButton);
  };

  const displayChatRooms = (chatRooms) => {
    setSendNewBulkMessage(false);
    setConversationList(ConversationTypeChat, chatRooms);
  };

  const displayNewChatRoom = (chatRoom) => {
    addConversationToList(ConversationTypeChat, chatRoom);
  };

  const addMessageToChatRoom = (msg) => {
    const chatRooms = getConversationList(ConversationTypeChat);
    const roomIndex = chatRooms.findIndex(
      (chatRoom) => chatRoom.id === msg.roomId
    );
    if (roomIndex >= 0) {
      chatRooms[roomIndex].messages.push(msg);
      setConversationList(ConversationTypeChat, chatRooms);
    }
  };

  return (
    <div className="chat">
      <ConversationSelector />

      {selectedConversationType === ConversationTypeBulkMessages ? (
        <BulkMessages
          displayMessageList={displayBulkMessages}
          selectedMessage={selectedConversation}
          unselectedMessage={unselectConversation}
          sendNewMessage={sendNewBulkMessage}
          cancelSendNewMessage={() => setSendNewBulkMessage(false)}
        />
      ) : selectedConversationType === ConversationTypeChat ? (
        canChat ? (
          <ChatAndSupport
            displayChatRooms={displayChatRooms}
            displayNewChatRoom={displayNewChatRoom}
            displayNewMessage={addMessageToChatRoom}
            selectedChatRoom={selectedConversation}
          />
        ) : (
          <h3 className="center-text">
            You are not authorized to view this page
          </h3>
        )
      ) : null}
    </div>
  );
}
