import { useState } from "react";

import DataFetcher from "../../../components/DataFetcher";
import ChatIcon from "../../../assets/icons/chat";

import ResendBulkMessage from "./ResendBulkMessage";
import NewBulkMessage from "./NewBulkMessage";

const BulkMessages = (props) => {
  const [inited, setInited] = useState(false);

  return (
    <DataFetcher
      url={`/bulk-messages`}
      buildUI={(data, mutate) => (
        <BulkMessagesDisplay
          messages={data}
          mutate={mutate}
          inited={inited}
          setInited={setInited}
          {...props}
        />
      )}
    />
  );
};

const BulkMessagesDisplay = ({
  displayMessageList,
  selectedMessage,
  unselectedMessage,
  sendNewMessage,
  cancelSendNewMessage,
  messages,
  mutate,
  inited,
  setInited,
}) => {
  if (!inited) {
    setTimeout(() => {
      displayMessageList(messages);
      setInited(true);
    }, 500);
  }

  return selectedMessage ? (
    <ResendBulkMessage
      {...selectedMessage}
      cancel={unselectedMessage}
      mutate={mutate}
      setInited={setInited}
      key={selectedMessage.id}
    />
  ) : sendNewMessage ? (
    <NewBulkMessage
      cancel={cancelSendNewMessage}
      mutate={mutate}
      setInited={setInited}
    />
  ) : (
    <div className="chat__conversation flex center">
      <div>
        <ChatIcon />
        <p className="fs-20">
          Select a previous bulk message to view or resend, or send a new bulk
          message.
        </p>
      </div>
    </div>
  );
};

export default BulkMessages;
