import React, { useState } from "react";
import { FiLoader } from "react-icons/fi";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import CheckboxIcon from "../../../assets/icons/checkbox";
import { FALLBACK_IMAGE_URL } from "../../../config/swr";

dayjs.extend(advancedFormat);

class MessageListView extends React.Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    // save a snapshot of the current scroll position IF new messages are being
    // added to the list, so that the current scroll position is maintained.
    if (prevProps.messages.length < this.props.messages.length) {
      const list = this.listRef.current;
      return list.scrollHeight - list.scrollTop;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // always scroll to bottom or to the previous scroll height.
    const list = this.listRef.current;
    list.scrollTop = list.scrollHeight - (snapshot ?? 0);
  }
  render() {
    return (
      <div ref={this.listRef} className="chat-msgs">
        {this.props.messages.map((msg) => (
          <React.Fragment key={msg.id}>
            <ChatMessage {...msg} />
          </React.Fragment>
        ))}
      </div>
    );
  }
}

function ChatMessage({ isCustomerMessage, text, createdAt, imageUrl, status }) {
  const [imgSrc, setImgSrc] = useState(imageUrl ?? FALLBACK_IMAGE_URL);

  return (
    <div className={`chat-msg ${isCustomerMessage ? "left" : "right"}`}>
      {imageUrl && (
        <img
          className="profile-img"
          src={imgSrc}
          onError={() => {
            setImgSrc(FALLBACK_IMAGE_URL);
          }}
          alt="profile"
        />
      )}
      <div className="fs-16">
        <p>{text}</p>
        <span>
          {dayjs(createdAt).format("DD-MM-YY")}
          &nbsp;&bull;&nbsp;
          {dayjs(createdAt).format("hh:mm a")}
          {!isCustomerMessage &&
            (status === "sending" ? (
              <FiLoader />
            ) : (
              <CheckboxIcon
                color={status === "delivered" ? "#009b54" : "#879297"}
              />
            ))}
        </span>
      </div>
    </div>
  );
}

export default MessageListView;
