import useSWRMutation from "swr/mutation";

import { sendPostRequest, sendPatchRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";
import { FiLoader } from "react-icons/fi";
import Modal from "../../../components/Modal";
import ArrowLeftIcon from "../../../assets/icons/arrowleft";

const ReferralPromoModal = ({ promo, dismissModal, mutate }) => {
  const form = useForm(promo);

  const { trigger: triggerCreatePromo } = useSWRMutation(
    "/referral-promo",
    sendPostRequest
  );
  const { trigger: triggerUpdatePromo } = useSWRMutation(
    `/referral-promo/${promo?.id}`,
    sendPatchRequest
  );

  const showMessageModal = useMessageModal();

  const dateStringToTimestamp = (date, isStartOfDay) => {
    if (!date) return 0;
    const dt = new Date(date);
    if (isStartOfDay) {
      dt.setHours(0, 0, 0, 0);
    } else {
      dt.setHours(23, 59, 59, 999);
    }
    return parseInt(dt.getTime() / 1000);
  };

  const savePromoDetails = async () => {
    form.submitStarted();
    const data = {
      ...form.data,
      startTimestamp: dateStringToTimestamp(form.data.startTimestamp, true),
      endTimestamp: dateStringToTimestamp(form.data.endTimestamp, false),
    };

    try {
      const trigger = promo ? triggerUpdatePromo : triggerCreatePromo;
      const res = await trigger(promo ? { payload: data } : data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      mutate();
      dismissModal();
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <Modal fullScreen handleClose={dismissModal}>
      <div className="successful-batch">
        <header className="box-shadow flex between-center">
          <div className="flex gap-12 center start">
            <span onClick={dismissModal} style={{ cursor: "pointer" }}>
              <ArrowLeftIcon width={14} style={{ verticalAlign: "middle" }} />
            </span>
            <h3 className="fs-20">
              {promo ? "Edit" : "Create"} Referral Promo
            </h3>
          </div>
        </header>

        <div
          className="successful-batch__content"
          style={{ padding: "1.5rem" }}
        >
          <div className="settings">
            <div className="flex start gap equal-widths mb-32">
              <div>
                <h2 className="fs-20 mb-16">Promo Details</h2>
                <div className="box">
                  <label htmlFor="name">Name</label>
                  <input
                    id="name"
                    type="text"
                    className="mb-16"
                    placeholder="Enter a name for this promo"
                    {...form.fieldProps("name")}
                  />

                  <label htmlFor="description">Description</label>
                  <textarea
                    id="description"
                    type="text"
                    className="mb-16"
                    placeholder="Enter a description for this promo"
                    {...form.fieldProps("description")}
                  ></textarea>

                  <label htmlFor="startTimestamp">Start Date</label>
                  <input
                    id="startTimestamp"
                    type="date"
                    className="mb-16"
                    {...form.timestampFieldProps("startTimestamp")}
                  />

                  <label htmlFor="endTimestamp">End Date</label>
                  <input
                    id="endTimestamp"
                    type="date"
                    className="mb-16"
                    {...form.timestampFieldProps("endTimestamp")}
                  />
                </div>
              </div>

              <div>
                <h2 className="fs-20 mb-16">Referral Bonus</h2>
                <div className="box">
                  <label htmlFor="referralTxAmountThreshold">
                    New Customer Min. Total Transaction Amount
                  </label>
                  <input
                    id="referralTxAmountThreshold"
                    type="number"
                    min="1"
                    className="mb-16"
                    placeholder="Referred customer min. transaction total"
                    {...form.fieldProps("referralTxAmountThreshold")}
                  />

                  <label htmlFor="referrerBonusAmount">
                    Bonus Amount to Referrer
                  </label>
                  <input
                    id="referrerBonusAmount"
                    type="number"
                    min="1"
                    className="mb-16"
                    placeholder="Enter promo bonus amount"
                    {...form.fieldProps("referrerBonusAmount")}
                  />

                  <label htmlFor="bonusDebitLedgerCode">
                    Debit Ledger Code
                  </label>
                  <input
                    id="bonusDebitLedgerCode"
                    type="text"
                    className="mb-16"
                    placeholder="Enter ledger code to debit for promo bonuses"
                    {...form.fieldProps("bonusDebitLedgerCode")}
                  />

                  <label htmlFor="bonusPaymentCap">
                    Bonus Payment Cap
                  </label>
                  <input
                    id="bonusPaymentCap"
                    type="number"
                    min="1"
                    className="mb-16"
                    placeholder="Enter max. number of promo bonus payments"
                    {...form.fieldProps("bonusPaymentCap")}
                  />
                </div>
              </div>
            </div>

            <button
              className="fullwidth dark primary btn"
              onClick={savePromoDetails}
              disabled={form.state.submitting}
            >
              {form.state.submitting ? <FiLoader /> : promo ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReferralPromoModal;
