import useMessageModal from "../../hooks/useMessageModal.hook";

export default function useShowRequestApprovalMessage() {
  const showMessageModal = useMessageModal();

  return ({ requestType, customerName, error }) => {
    showMessageModal({
      title: error
        ? `${requestType} Request Approval Failed`
        : `${requestType} Request Approved`,
      message: error ?? (
        <>
          {requestType} request has been successfully approved for
          <span className="fw-600"> {customerName}</span>
        </>
      ),
      isError: !!error,
      closeButtonText: "Close",
    });
  };
}
