import HeaderNavs from "../../../components/page-wrapper/HeaderNavs";
import ArrowLeftIcon from "../../../assets/icons/arrowleft";
import StatementIcon from "../../../assets/icons/statementicon";

const amountElement = (amount) => <>&#8358;{amount?.toLocaleString()}</>;

const formatClientIdentification = (arr) => {
  if (!arr) return [];
  return arr.flatMap((item) => {
    let objKeys = Object.keys(item);
    return objKeys.map((key) => {
      let title = "Identifier Value";
      if (key === "identifierName") title = "Identifier Name";
      return {
        title,
        value: item[key],
      };
    });
  });
};

const formatBreakdown = (arr) => {
  if (!arr) return [];
  return arr.flatMap((item) => {
    let objKeys = Object.keys(item);
    return objKeys.map((key) => {
      let title = "Min";
      if (key === "max") title = "Max";
      if (key === "percentageOfTransactionsInBucket")
        title = "Percentage Of Transactions In Bucket";
      return {
        title,
        value: item[key],
      };
    });
  });
};

const formatFlow = (arr) => {
  if (!arr) return [];
  return arr.flatMap((item) => {
    let objKeys = Object.keys(item);
    return objKeys.map((key) => {
      let title = "Year";
      if (key === "month_name") title = "Month Name";
      if (key === "amount") title = "Amount";
      return {
        title,
        value: item[key],
      };
    });
  });
};

const StatementAnalysis = ({ handleClose, loanStatement, loan }) => {
  const statementAnalysis = loanStatement.data["statementAnalysis "];
  const affordabilityAnalysis = loanStatement.data["affordabilityAnalysis "];

  const {
    clientIdentification,
    spendAnalysis,
    transactionPatternAnalysis,
    behavioralAnalysis,
    cashFlowAnalysis,
    incomeAnalysis,
  } = statementAnalysis;

  const loanInfo = [
    { title: "ID", value: loan.loanID },
    { title: "type", value: loan.type },
    { title: "Loan Amount", value: amountElement(loan.loanAmount) },
    { title: "Duration In Days", value: loan.durationInDays },
  ];

  if (!!loan.salary) {
    loanInfo.push(
      {
        title: "Salary Amount",
        value: amountElement(loan.salary.amount),
      },
      { title: "Salary Payment Date", value: loan.salary.paymentDate }
    );
  }

  const statementInfo = [
    { title: "Statement Key", value: statementAnalysis.key },
    { title: "Statement Name", value: statementAnalysis.name },
    { title: "Client Full Name", value: statementAnalysis.clientFullName },
    {
      title: "Client Phone Number",
      value: statementAnalysis.clientPhoneNumber,
    },
    { title: "Account Type", value: statementAnalysis.accountType },
    { title: "Account Balance", value: statementAnalysis.accountBalance },
    { title: "Account Name", value: statementAnalysis.accountName },
    { title: "Bank Name", value: statementAnalysis.bankName },
    { title: "Statement Type", value: statementAnalysis.statementType },
    { title: "Start Date", value: statementAnalysis.startDate },
    { title: "End Date", value: statementAnalysis.endDate },
    { title: "Date Created", value: statementAnalysis.createdDate },
    { title: "Processing Status", value: statementAnalysis.processingStatus },
  ];

  const identificationInfo = [
    ...formatClientIdentification(clientIdentification),
  ];

  const spendInfo = [
    {
      title: "Average Monthly Spend On Transfer",
      value: spendAnalysis.averageMonthlySpendOnTransfer,
    },
    {
      title: "Average Monthly Spend On Atm And P O S",
      value: spendAnalysis.averageMonthlySpendOnAtmAndPOS,
    },
    {
      title: "Average Monthly Spend On U S S D",
      value: spendAnalysis.averageMonthlySpendOnUSSD,
    },
    {
      title: "Average Monthly Spend On Gambling",
      value: spendAnalysis.averageMonthlySpendOnGambling,
    },
    {
      title: "Average Monthly Spend On Airtime And Data",
      value: spendAnalysis.averageMonthlySpendOnAirtimeAndData,
    },
    {
      title: "Average Monthly Spend On Utilities",
      value: spendAnalysis.averageMonthlySpendOnUtilities,
    },
    {
      title: "Average Monthly Spend On Online And Web",
      value: spendAnalysis.averageMonthlySpendOnOnlineAndWeb,
    },
    {
      title: "Average Monthly Spend On Transportation",
      value: spendAnalysis.averageMonthlySpendOnTransportation,
    },
    {
      title: "Average Monthly Spend On Health",
      value: spendAnalysis.averageMonthlySpendOnHealth,
    },
    {
      title: "Average Monthly Spend On Travel",
      value: spendAnalysis.averageMonthlySpendOnTravel,
    },
    {
      title: "Average Monthly Spend On Entertainment",
      value: spendAnalysis.averageMonthlySpendOnEntertainment,
    },
    {
      title: "Average Monthly Spend On Hospitality And Food",
      value: spendAnalysis.averageMonthlySpendOnHospitalityAndFood,
    },
    {
      title: "Average Monthly Spend On Rent",
      value: spendAnalysis.averageMonthlySpendOnRent,
    },
    {
      title: "Average Monthly Spend On Insurance",
      value: spendAnalysis.averageMonthlySpendOnInsurance,
    },
    {
      title: "Average Monthly Spend On Charges And Stamp Duty",
      value: spendAnalysis.averageMonthlySpendOnChargesAndStampDuty,
    },
    {
      title: "Average Monthly Spend On International Transactions",
      value: spendAnalysis.averageMonthlySpendOnInternationalTransactions,
    },
    {
      title: "Average Monthly Total Expenses",
      value: spendAnalysis.averageMonthlyTotalExpenses,
    },
    {
      title: "Average Monthly Recurring Expenses",
      value: spendAnalysis.averageMonthlyRecurringExpense,
    },
    {
      title: "Average Monthly Spend On Savings And Investments",
      value: spendAnalysis.averageMonthlySpendOnSavingsAndInvestments,
    },
    {
      title: "Average Monthly Spend On Crypto",
      value: spendAnalysis.averageMonthlySpendOnCrypto,
    },
    {
      title: "Average Monthly Spend On Others",
      value: spendAnalysis.averageMonthlySpendOnOthers,
    },
    {
      title: "Most Recurring Expense",
      value: spendAnalysis.mostRecurringExpense,
    },
    {
      title: "Total Spend On Transfer",
      value: spendAnalysis.totalSpendOnTransfer,
    },
    {
      title: "Total Spend On Atm And POS",
      value: spendAnalysis.totalSpendOnAtmAndPOS,
    },
    { title: "Total Spend On U S S D", value: spendAnalysis.totalSpendOnUSSD },
    {
      title: "Total Spend On Gambling",
      value: spendAnalysis.totalSpendOnGambling,
    },
    {
      title: "Total Spend On Airtime And Data",
      value: spendAnalysis.totalSpendOnAirtimeAndData,
    },
    {
      title: "Total Spend On Utilities",
      value: spendAnalysis.totalSpendOnUtilities,
    },
    {
      title: "Total Spend On Online And Web",
      value: spendAnalysis.totalSpendOnOnlineAndWeb,
    },
    {
      title: "Total Spend On Transportation",
      value: spendAnalysis.totalSpendOnTransportation,
    },
    { title: "Total Spend On Health", value: spendAnalysis.totalSpendOnHealth },
    { title: "Total Spend On Travel", value: spendAnalysis.totalSpendOnTravel },
    {
      title: "Total Spend On Entertainment",
      value: spendAnalysis.totalSpendOnEntertainment,
    },
    {
      title: "Total Spend On Hospitality And Food",
      value: spendAnalysis.totalSpendOnHospitalityAndFood,
    },
    { title: "Total Spend On Rent", value: spendAnalysis.totalSpendOnRent },
    {
      title: "Total Spend On Insurance",
      value: spendAnalysis.totalSpendOnInsurance,
    },
    {
      title: "Total Spend On Charges And Stamp Duty",
      value: spendAnalysis.totalSpendOnChargesAndStampDuty,
    },
    {
      title: "Total Spend On InternationalTransactions",
      value: spendAnalysis.totalSpendOnInternationalTransactions,
    },
    {
      title: "Total Monthly Expenses",
      value: spendAnalysis.totalMonthlyExpenses,
    },
    {
      title: "Total Recurring Expense",
      value: spendAnalysis.totalRecurringExpense,
    },
    {
      title: "Total Expenses",
      value: spendAnalysis.totalExpenses,
    },
    {
      title: "Total Monthly Spend On Savings And Investments",
      value: spendAnalysis.totalMonthlySpendOnSavingsAndInvestments,
    },
    {
      title: "Total Spend On Crypto",
      value: spendAnalysis.totalSpendOnCrypto,
    },
    {
      title: "Total Spend On Others",
      value: spendAnalysis.totalSpendOnOthers,
    },
  ];

  const locationsInfo = [
    {
      title: "Most Frequent Spend Category",
      value: spendAnalysis.mostFrequentSpendCategory,
    },
    {
      title: "Month With Highest Spend",
      value: spendAnalysis.monthWithHighestSpend,
    },
    {
      title: "Highest Spend",
      value: spendAnalysis.highestSpend,
    },
    {
      title: "Most Frequent Expense",
      value: spendAnalysis.mostFrequentExpense,
    },
    {
      title: "Most Frequent Expense Amount",
      value: spendAnalysis.mostFrequentExpenseAmount,
    },
  ];

  const transactionPatternInfo = [
    {
      title: "Last Day Of Credit",
      value: transactionPatternAnalysis.lastDayOfCredit,
    },
    {
      title: "Last Day Of Debit",
      value: transactionPatternAnalysis.lastDayOfDebit,
    },
    {
      title: "Percent Debit Transactions",
      value: transactionPatternAnalysis.percentDebitTransactions,
    },
    {
      title: "Percent Credit Transactions",
      value: transactionPatternAnalysis.percentCreditTransactions,
    },
    {
      title: "Total Number Of Transactions",
      value: transactionPatternAnalysis.totalNumberOfTransactions,
    },
    {
      title: "Percent Of Transactions Less Than 10 Thousand Naira",
      value:
        transactionPatternAnalysis.percentOfTransactionsLessThan10ThousandNaira,
    },
    {
      title:
        "Percent Of Transactions Between 10 Thousand To 100  Thousand Naira",
      value:
        transactionPatternAnalysis.percentOfTransactionsBetween10ThousandTo100ThousandNaira,
    },
    {
      title:
        "Percent Of Transactions Between 100 Thousand To 500  Thousand Naira",
      value:
        transactionPatternAnalysis.percentOfTransactionsBetween100ThousandTo500ThousandNaira,
    },
    {
      title:
        "Percent Of Transactions Between 500 Thousand To One Million Naira",
      value:
        transactionPatternAnalysis.percentOfTransactionsBetween500ThousandToOneMillionNaira,
    },
    {
      title: "Percent Of Transactions Greater Than One Million Naira",
      value:
        transactionPatternAnalysis.percentOfTransactionsGreaterThanOneMillionNaira,
    },
    {
      title:
        "Percent Number Of Days Transactions Was Less Than 10 Thousand Naira",
      value:
        transactionPatternAnalysis.percentNumberOfDaysTransactionsWasLessThan10ThousandNaira,
    },
    {
      title: "Percent Of Balances Less Than 10 Thousand Naira",
      value:
        transactionPatternAnalysis.percentOfBalancesLessThan10ThousandNaira,
    },
    {
      title: "Percent Of Balances Between 10 Thousand To 100 Thousand Naira",
      value:
        transactionPatternAnalysis.percentOfBalancesBetween10ThousandTo100ThousandNaira,
    },
    {
      title: "Percent Of Balances Between 100 Thousand To 500 Thousand Naira",
      value:
        transactionPatternAnalysis.percentOfBalancesBetween100ThousandTo500ThousandNaira,
    },
    {
      title: "Percent Of Balances Between 500 Thousand To One Million Naira",
      value:
        transactionPatternAnalysis.percentOfBalancesBetween500ThousandToOneMillionNaira,
    },
    {
      title: "Percent Of Balances Greater Than One Million Naira",
      value:
        transactionPatternAnalysis.percentOfBalancesGreaterThanOneMillionNaira,
    },
    {
      title: "Percent Number Of Days Balance Was Less Than 10 Thousand Naira",
      value:
        transactionPatternAnalysis.percentNumberOfDaysBalanceWasLessThan10ThousandNaira,
    },
    {
      title: "Most Frequent Balance Range",
      value: transactionPatternAnalysis.mostFrequentBalanceRange,
    },
    {
      title: "Most Frequent Transaction Range",
      value: transactionPatternAnalysis.mostFrequentTransactionRange,
    },
    {
      title: "Number Of Card Requests",
      value: transactionPatternAnalysis.numberOfCardRequests,
    },
    {
      title: "Most Frequent Credit Transfer",
      value: transactionPatternAnalysis.mostFrequentCreditTransfer,
    },
    {
      title: "Most Frequent Debit Transfer",
      value: transactionPatternAnalysis.mostFrequentDebitTransfer,
    },
    {
      title: "Return Cheque",
      value: transactionPatternAnalysis.returnCheque,
    },
    {
      title: "Missing Transactions",
      value: transactionPatternAnalysis.missingTransactions,
    },
    {
      title: "Percent Number Of Days Transactions In Smallest Bucket",
      value:
        transactionPatternAnalysis.percentNumberOfDaysTransactionsInSmallestBucket,
    },
    {
      title: "Percent Number Of Days Balances In Smallest Bucket",
      value:
        transactionPatternAnalysis.percentNumberOfDaysBalancesInSmallestBucket,
    },
    {
      title: "Number Of Self Transfer Inflows",
      value: transactionPatternAnalysis.noOfSelfTransferInflows,
    },
    {
      title: "Number Of Self Transfer Outflows",
      value: transactionPatternAnalysis.noOfSelfTransferOutflows,
    },
    {
      title: "Self Transfer Inflow Amount",
      value: transactionPatternAnalysis.selfTransferInflowAmount,
    },
    {
      title: "Self Transfer Outflow Amount",
      value: transactionPatternAnalysis.selfTransferOutflowAmount,
    },
  ];

  const balanceBreakdownInfo = [
    ...formatBreakdown(transactionPatternAnalysis.balanceBreakdown),
  ];

  const transactionBreakdownInfo = [
    ...formatBreakdown(transactionPatternAnalysis.transactionBreakdown),
  ];

  const selfTransferInflowInfo = [];

  const selfTransferOutflowInfo = [];

  const behaviouralAnalysisInfo = [
    {
      title: "Month To Month Inflow To Outflow Rate",
      value: behavioralAnalysis.monthToMonthInflowToOutflowRate,
    },
    {
      title: "Overall Inflow To Outflow Rate",
      value: behavioralAnalysis.overallInflowToOutflowRate,
    },
    {
      title: "Total Loan Amount",
      value: behavioralAnalysis.totalLoanAmount,
    },
    {
      title: "Total Loan Repayment Amount",
      value: behavioralAnalysis.totalLoanRepaymentAmount,
    },
    {
      title: "Loan To Inflow Rate",
      value: behavioralAnalysis.loanToInflowRate,
    },
    {
      title: "Loan Repayment To Inflow Rate",
      value: behavioralAnalysis.loanRepaymentToInflowRate,
    },
    {
      title: "Number Loan Transactions",
      value: behavioralAnalysis.numberLoanTransactions,
    },
    {
      title: "Number Repayment Transactions",
      value: behavioralAnalysis.numberRepaymentTransactions,
    },
    {
      title: "Gambling Status",
      value: behavioralAnalysis.gamblingStatus,
    },
    {
      title: "Gambling Rate",
      value: behavioralAnalysis.gamblingRate,
    },
    {
      title: "Account Activity",
      value: behavioralAnalysis.accountActivity,
    },
    {
      title: "Percent Of Inflow Irregularity",
      value: behavioralAnalysis.percentOfInflowIrregularity,
    },
    {
      title: "Average Monthly Loan Amount",
      value: behavioralAnalysis.averageMonthlyLoanAmount,
    },
    {
      title: "Average Monthly Loan Repayment Amount",
      value: behavioralAnalysis.averageMonthlyLoanRepaymentAmount,
    },
    {
      title: "Account Sweep",
      value: behavioralAnalysis.accountSweep,
    },
    {
      title: "Number Of Betting Transactions",
      value: behavioralAnalysis.numberOfBettingTransactions,
    },
  ];

  const loanTransactionsInfo = [];

  const loanRepaymentTransactionsInfo = [];

  const cashFlowAnalysisInfo = [
    {
      title: "Total Credit Turnover",
      value: cashFlowAnalysis.totalCreditTurnover,
    },
    {
      title: "Total Debit Turnover",
      value: cashFlowAnalysis.totalDebitTurnover,
    },
    {
      title: "Average Monthly Credits",
      value: cashFlowAnalysis.averageMonthlyCredits,
    },
    {
      title: "Average Monthly Debits",
      value: cashFlowAnalysis.averageMonthlyDebits,
    },
    {
      title: "Average Weekly Credits",
      value: cashFlowAnalysis.averageWeeklyCredits,
    },
    {
      title: "Average Weekly Debits",
      value: cashFlowAnalysis.averageWeeklyDebits,
    },
    {
      title: "Average Monthly Balance",
      value: cashFlowAnalysis.averageMonthlyBalance,
    },
    {
      title: "Average Weekly Balance",
      value: cashFlowAnalysis.averageWeeklyBalance,
    },
    {
      title: "Number Of Transacting Months",
      value: cashFlowAnalysis.numberOfTransactingMonths,
    },
    {
      title: "Valid Credit",
      value: cashFlowAnalysis.validCredit,
    },
    {
      title: "Period In Statement",
      value: cashFlowAnalysis.periodInStatement,
    },
    {
      title: "Year In Statement",
      value: cashFlowAnalysis.yearInStatement,
    },
    {
      title: "First Date In Statement",
      value: cashFlowAnalysis.firstDateInStatement,
    },
    {
      title: "Last Date In Statement",
      value: cashFlowAnalysis.lastDateInStatement,
    },
    {
      title: "Closing Balance",
      value: cashFlowAnalysis.closingBalance,
    },
  ];

  const monthlyOutflowInfo = [...formatFlow(cashFlowAnalysis.monthlyOutflow)];

  const monthlyInflowInfo = [...formatFlow(cashFlowAnalysis.monthlyInflow)];

  const incomeAnalysisInfo = [
    {
      title: "Average Predicted Salary",
      value: incomeAnalysis.averagePredictedSalary,
    },
    {
      title: "Is Salary Earner",
      value: incomeAnalysis.isSalaryEarner,
    },
    {
      title: "Expected Salary Payment Day",
      value: incomeAnalysis.expectedSalaryPaymentDay,
    },
    {
      title: "Frequency Of Salary Payments",
      value: incomeAnalysis.frequencyOfSalaryPayments,
    },
    {
      title: "Last Date Of Salary Payment",
      value: incomeAnalysis.lastDateOfSalaryPayment,
    },
    {
      title: "Number Of Salary Payments",
      value: incomeAnalysis.numberOfSalaryPayments,
    },
    {
      title: "Has Other Income",
      value: incomeAnalysis.hasOtherIncome,
    },
    {
      title: "Average Other Income",
      value: incomeAnalysis.averageOtherIncome,
    },
    {
      title: "Number Of Other Income Payments",
      value: incomeAnalysis.numberOfOtherIncomePayments,
    },
    {
      title: "Lowest Salary",
      value: incomeAnalysis.lowestSalary,
    },
    {
      title: "Most Recent Salary",
      value: incomeAnalysis.mostRecentSalary,
    },
  ];

  const salaryTransactionsInfo = [];

  const otherTransactionsInfo = [];

  const creditAnalysisInfo = [];

  const affordabilityAnalysisInfo = [
    {
      title: "Dti",
      value: affordabilityAnalysis.dti,
    },
    {
      title: "Loan Tenure",
      value: affordabilityAnalysis.loanTenure,
    },
    {
      title: "Monthly Disposable Income/Monthly Affordability Amount",
      value:
        affordabilityAnalysis[
          "monthly_disposable_income/monthly_affordability_amount"
        ],
    },
    {
      title: "Affordability Amount",
      value: affordabilityAnalysis.affordability_amount,
    },
    {
      title: "Average Monthly Total Expenses",
      value: affordabilityAnalysis.average_monthly_total_expenses,
    },
    {
      title: "Average Monthly Loan Repayment Amount",
      value: affordabilityAnalysis.average_monthly_loan_repayment_amount,
    },
  ];

  return (
    <>
      <div className="statement-analysis">
        <header className="box-shadow flex between-center">
          <div className="flex gap-12 center start">
            <span onClick={handleClose} style={{ cursor: "pointer" }}>
              <ArrowLeftIcon width={14} style={{ verticalAlign: "middle" }} />
            </span>
            <h3 className="fs-20">Statement Analysis</h3>
          </div>
          <div className="flex gap">
            <HeaderNavs />
          </div>
        </header>

        <div className="statement-analysis__content">
          <div className="flex col gap">
            <div className="flex space-between">
              <div>
                <h4 className="mb-12">Loan Information</h4>
                <div className="card__grid">
                  {loanInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <StatementIcon />
              </div>
            </div>

            <div>
              <h4 className="mb-12">Statement Analysis</h4>
              <div className="card__grid striped charcoal-80">
                {statementInfo.map((info, index) => (
                  <div key={index} className="flex gap-8 ">
                    <h3>{info.title}:</h3>
                    <span>{info.value}</span>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h4 className="mb-12">Identification Information</h4>
              <div className="card__grid striped charcoal-80">
                {identificationInfo.map((info, index) => (
                  <div key={index} className="flex gap-8 ">
                    <h3>{info.title}:</h3>
                    <span>{info.value}</span>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h4 className="mb-12">Spend Analysis</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {spendInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-12">ATM Locations</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {locationsInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-12">Transaction Pattern Analysis</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {transactionPatternInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-12">Balance Breakdown</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {balanceBreakdownInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-12">Transaction Breakdown</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {transactionBreakdownInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-12">Self Transfer Inflow Transactions</h4>
              <div className="flex equal-widths gap-20 start">
                {selfTransferInflowInfo.length < 1 ? (
                  <div className="flex center" style={{ minHeight: "80px" }}>
                    No information generated
                  </div>
                ) : (
                  selfTransferInflowInfo.map((info, index) => (
                    <div className="card__grid striped charcoal-80">
                      <div key={index} className="flex gap-8 ">
                        <h3>{info.title}:</h3>
                        <span>{info.value}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div>
              <h4 className="mb-12">Self Transfer Outflow Transactions</h4>
              <div className="flex equal-widths gap-20 start">
                {selfTransferOutflowInfo.length < 1 ? (
                  <div className="flex center" style={{ minHeight: "80px" }}>
                    No information generated
                  </div>
                ) : (
                  selfTransferOutflowInfo.map((info, index) => (
                    <div className="card__grid striped charcoal-80">
                      <div key={index} className="flex gap-8 ">
                        <h3>{info.title}:</h3>
                        <span>{info.value}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div>
              <h4 className="mb-12">Behavioural Analysis</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {behaviouralAnalysisInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-12">Loan Transactions</h4>
              <div className="flex equal-widths gap-20 start">
                {loanTransactionsInfo.length < 1 ? (
                  <div className="flex center" style={{ minHeight: "80px" }}>
                    No information generated
                  </div>
                ) : (
                  loanTransactionsInfo.map((info, index) => (
                    <div className="card__grid striped charcoal-80">
                      <div key={index} className="flex gap-8 ">
                        <h3>{info.title}:</h3>
                        <span>{info.value}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div>
              <h4 className="mb-12">Loan Repayment Transactions</h4>
              <div className="flex equal-widths gap-20 start">
                {loanRepaymentTransactionsInfo.length < 1 ? (
                  <div className="flex center" style={{ minHeight: "80px" }}>
                    No information generated
                  </div>
                ) : (
                  loanRepaymentTransactionsInfo.map((info, index) => (
                    <div className="card__grid striped charcoal-80">
                      <div key={index} className="flex gap-8 ">
                        <h3>{info.title}:</h3>
                        <span>{info.value}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div>
              <h4 className="mb-12">Cash Flow Analysis</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {cashFlowAnalysisInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-12">Monthly Outflow</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {monthlyOutflowInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-12">Monthly Inflow</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {monthlyInflowInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-12">Income Analysis</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {incomeAnalysisInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-12">Salary Transactions</h4>
              <div className="flex equal-widths gap-20 start">
                {salaryTransactionsInfo.length < 1 ? (
                  <div className="flex center" style={{ minHeight: "80px" }}>
                    No information generated
                  </div>
                ) : (
                  salaryTransactionsInfo.map((info, index) => (
                    <div className="card__grid striped charcoal-80">
                      <div key={index} className="flex gap-8 ">
                        <h3>{info.title}:</h3>
                        <span>{info.value}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div>
              <h4 className="mb-12">Other Income Transactions</h4>
              <div className="flex equal-widths gap-20 start">
                {otherTransactionsInfo.length < 1 ? (
                  <div className="flex center" style={{ minHeight: "80px" }}>
                    No information generated
                  </div>
                ) : (
                  otherTransactionsInfo.map((info, index) => (
                    <div className="card__grid striped charcoal-80">
                      <div key={index} className="flex gap-8 ">
                        <h3>{info.title}:</h3>
                        <span>{info.value}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div>
              <h4 className="mb-12">Credit Score Analysis</h4>
              <div className="flex equal-widths gap-20 start">
                {creditAnalysisInfo.length < 1 ? (
                  <div className="flex center" style={{ minHeight: "80px" }}>
                    No information generated
                  </div>
                ) : (
                  creditAnalysisInfo.map((info, index) => (
                    <div className="card__grid striped charcoal-80">
                      <div key={index} className="flex gap-8 ">
                        <h3>{info.title}:</h3>
                        <span>{info.value}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div>
              <h4 className="mb-12">Affordability Analysis</h4>
              <div className="flex equal-widths gap-20 start">
                <div className="card__grid striped charcoal-80">
                  {affordabilityAnalysisInfo.map((info, index) => (
                    <div key={index} className="flex gap-8 ">
                      <h3>{info.title}:</h3>
                      <span>{info.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatementAnalysis;
