import { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import DataFetcher from "../../components/DataFetcher";
import PendingLoanList from "./views/PendingLoanList";
import LoanTableList from "./views/LoanTableList";
import LoanDetailsModal from "./views/LoanDetailsModal";
import { SearchFilterContainer } from "../../components/SearchFilterTable";
import { getPermissions } from "../../config/swr";

const EXPORT_LOANS_KEY = "export-loans";
const DISBURSE_LOANS_KEY = "disburse-loan";

export const LoanTypePayday = "payday-loan",
  LoanTypeRetail = "retail-loan",
  LoanTypeAssetFinancing = "asset-financing-loan";

const loanTypeTitles = {
  [LoanTypePayday]: "Payday Loans",
  [LoanTypeRetail]: "Retail Loans",
  [LoanTypeAssetFinancing]: "Asset Financing",
};

const LoansPage = ({ loanType }) => {
  const [setPageTitle] = useOutletContext();
  setPageTitle(loanTypeTitles[loanType] ?? "Loans");
  const { id } = useParams();

  return (
    <DataFetcher
      url={`/loans/summary?type=${loanType ?? ""}&partnerID=${id ?? ""}`}
      buildUI={(data, mutate) => (
        <LoansSummaryAndTable
          key={loanType}
          loanSummaries={data}
          mutateSummary={mutate}
          loanType={loanType}
          partnerID={id}
        />
      )}
    />
  );
};

const LoansSummaryAndTable = ({
  loanSummaries,
  mutateSummary,
  loanType,
  partnerID,
}) => {
  const [activeStatus, setActiveStatus] = useState("Pending Approval");
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className="loans">
      <nav className="tab-nav mb-16">
        {loanSummaries.map(({ status, count, totalAmount }, index) => (
          <button
            key={index}
            onClick={() => setActiveStatus(status)}
            className={
              "box loan fs-20" + (activeStatus === status ? " active" : "")
            }
          >
            <span className="fs-16">
              {status === "Pending Approval"
                ? "Pending Loan Requests"
                : status === "Completed"
                ? "Paid"
                : status}
              &nbsp; ({count?.toLocaleString()})
            </span>
            &#8358;{totalAmount?.toLocaleString()}
          </button>
        ))}
      </nav>

      <DataFetcher
        key={activeStatus}
        url={`/loans?status=${activeStatus}&type=${
          loanType ?? ""
        }&search=${searchQuery}&partnerID=${partnerID ?? ""}`}
        buildUI={(data, mutateLoans) => {
          const mutate = () => {
            mutateSummary();
            mutateLoans();
          };

          return (
            <LoansTable
              data={data}
              updateSearchParam={setSearchQuery}
              mutate={mutate}
              loanType={loanType}
              partnerID={partnerID}
              activeStatus={activeStatus}
            />
          );
        }}
      />
    </div>
  );
};

const LoansTable = ({
  data,
  updateSearchParam,
  mutate,
  activeStatus,
  loanType,
  partnerID,
}) => {
  const permissions = getPermissions();

  const canExportLoans = permissions[EXPORT_LOANS_KEY];
  const canDisburseLoans = permissions[DISBURSE_LOANS_KEY];

  const filters = Object.keys(data).sort((f1, f2) => f1.localeCompare(f2));

  const isPendingLoans =
    filters.length > 0 &&
    filters.every((filter) => filter.startsWith("Pending"));

  const allowedFilters =
    activeStatus === "Pending Approval"
      ? canDisburseLoans
        ? filters
        : ["Pending Approval"]
      : filters;

  const [activeFilter, setActiveFilter] = useState(
    allowedFilters.length > 0 ? allowedFilters[0] : ""
  );

  const loans =
    allowedFilters.length > 0 && activeFilter ? data[activeFilter] : [];

  const [selectedLoanIndex, setSelectedLoanIndex] = useState(-1);
  const hideLoanDetailsView = () => setSelectedLoanIndex(-1);
  const selectedLoan = selectedLoanIndex < 0 ? null : loans[selectedLoanIndex];

  return (
    <>
      {filters.length > 0 && (
        <SearchFilterContainer
          searchHint="Search"
          csvUrl={
            canExportLoans &&
            `loans/export?loanType=${loanType ?? ""}&partnerID=${
              partnerID ?? ""
            }`
          }
          filterOptions={allowedFilters}
          reloadTable={({ filter, search }) => {
            setActiveFilter(filter);
            updateSearchParam(search);
          }}
        />
      )}

      {isPendingLoans && activeFilter === "Pending Disbursement" ? (
        <>
          {canDisburseLoans && (
            <PendingLoanList
              loans={loans}
              activeStatus={activeStatus}
              displayLoanDetails={setSelectedLoanIndex}
            />
          )}
        </>
      ) : (
        <LoanTableList
          loans={loans}
          activeStatus={activeStatus}
          displayLoanDetails={setSelectedLoanIndex}
        />
      )}

      {selectedLoan && (
        <LoanDetailsModal
          loan={selectedLoan}
          dismissModal={hideLoanDetailsView}
          setConfirmation={hideLoanDetailsView}
          mutate={mutate}
          partnerID={partnerID}
          activeFilter={activeFilter}
          activeStatus={activeStatus}
          loanType={loanType}
        />
      )}
    </>
  );
};

export default LoansPage;
