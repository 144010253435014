import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="chart__tooltip flex gap-8 col">
        <p>{`${payload[0].name}`}</p>
        <p>Amount -&nbsp; &#8358;{`${payload[0].value?.toLocaleString()}`}</p>
      </div>
    );
  }

  return null;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (!percent) return null;

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      className="pie-label"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChartComponent = ({ chartData }) => {
  if (chartData?.length === 0) return null;

  return (
    <ResponsiveContainer width="99%" height={200} minWidth={200}>
      <PieChart>
        <Pie
          data={chartData}
          cx="50%"
          minAngle={10}
          innerRadius={40}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {chartData.map(({ color }) => (
            <Cell key={color} fill={color} stroke={"none"} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
