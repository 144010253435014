import { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiCaretDown } from "react-icons/bi";

import { RenderSideBarLinks } from "./page-wrapper/SidebarLinks";
import logo from "../assets/logo_sidebar.png";

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = {
      error: null,
      errorInfo: null,
      showErrorDetails: false,
    };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    console.log(error);
    return { error };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    this.setState({ error, errorInfo });
    console.log({ error, errorInfo });
  }
  goToHome() {
    this.setState({ error: null });
    this.props.navigate("/overview");
  }
  toggleErrorDetailsDisplay() {
    this.setState((prevState) => ({
      showErrorDetails: !prevState.showErrorDetails,
    }));
  }

  render() {
    const { error, errorInfo, showErrorDetails } = this.state;

    // Check if the error is thrown
    if (error) {
      // You can render any custom fallback UI
      return (
        <div>
          <div className="sidebar">
            <div className="sidebar__logo">
              <Link to={"/overview"}>
                <img src={logo} alt="Logo" />
              </Link>
            </div>

            <div className="fs-16 flex col gap" style={{ minHeight: "75vh" }}>
              <div>{RenderSideBarLinks()}</div>
            </div>
          </div>

          <div className="page-header-content">
            <header className="box-shadow">
              <h1 className="fs-24">&nbsp;</h1>
              <div className="header-nav">
                <input
                  className="search fs-18"
                  type="text"
                  placeholder="Search"
                />
              </div>
            </header>

            <div className="page-content">
              <div>
                <h3 className="mb-16">
                  Oops, there is a client side error! Let&apos;s go back
                </h3>
                <button
                  onClick={() => this.goToHome()}
                  className="btn primary mb-16"
                >
                  Dashboard
                </button>
              </div>
              <div className="card__box" style={{ width: "40%", minWidth: "40%" }}>
                <div
                  className="flex gap between-center"
                  onClick={() => this.toggleErrorDetailsDisplay()}
                >
                  <span className="fs-18 fw-600">
                    Error Details (
                    {showErrorDetails ? "click to hide" : "click to view"})
                  </span>
                  <span>
                    <BiCaretDown
                      size={18}
                      className={
                        showErrorDetails
                          ? "dropdown-icon rotate-180"
                          : "dropdown-icon"
                      }
                    />
                  </span>
                </div>
                {showErrorDetails && (
                  <div className="flex col gap-12 mt-12">
                    <p>{error?.message}</p>
                    <p>{errorInfo?.componentStack}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default withNavigation(ErrorBoundary);
