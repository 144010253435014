import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import { OutlineButton } from "../../../components/Button";
import { sendPatchRequest, sendPostRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";

function ActionForm({
  url,
  loan,
  title,
  cancel,
  mutate,
  activeFilter,
  hasPending = false,
  isDecline = false,
  dismissModal,
}) {
  const [formData, setFormData] = useState({
    reason: "",
    ...(isDecline && { fraudulent: false }),
  });
  const isPendingDisbursement = activeFilter === "Pending Disbursement";

  const { trigger: postTrigger } = useSWRMutation(url, sendPostRequest);

  const { trigger: patchTrigger } = useSWRMutation(url, sendPatchRequest);

  const { state, submitStarted, submitError, submitSuccess } = useForm();
  const showMessageModal = useMessageModal();

  const onFormDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    submitStarted();

    const data =
      hasPending || isDecline
        ? {
            payload: {
              loanID: loan.loanID,
              customerID: loan.customerID,
              ...(isDecline && {
                reject: true,
                message: formData.reason,
                ...formData,
              }),
              ...(isDecline &&
                isPendingDisbursement && { IgnorePreviousApproval: true }),
              ...(hasPending && { approve: false }),
            },
          }
        : {
            loanID: loan.loanID,
            customerID: loan.customerID,
            ...(!hasPending && { ...formData }),
          };

    const trigger = hasPending || isDecline ? patchTrigger : postTrigger;

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message || "Successfully submitted",
        isError: false,
        closeButtonText: "Close",
      });
      submitSuccess();
      mutate();
      cancel();
      dismissModal();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      submitError();
    }
  };

  return (
    <form className="loan-repayment" onSubmit={submitForm}>
      <section className="flex col gap-8 fs-16 mb-32">
        <label className="flex col gap-4">
          <div>Reason</div>
          <input
            className="mini"
            type="text"
            placeholder="Enter Reason"
            name="reason"
            value={formData.reason}
            onChange={onFormDataChange}
          />
        </label>
        {isDecline && (
          <label className="flex start center gap-4 loan-checkbox" id="fraudulent">
            <input
              type="checkbox"
              id="fraudulent"
              name="fraudulent"
              onChange={onFormDataChange}
              checked={formData.fraudulent}
            />
            <div className="fs-16">Flag as fraudulent</div>
          </label>
        )}
      </section>
      <section className="flex col">
        <OutlineButton disabled={state.submitting} className="danger">
          {state.submitting ? <FiLoader /> : <>{title}</>}
        </OutlineButton>
        <button className="transparent btn" type="button" onClick={cancel}>
          Cancel
        </button>
      </section>
    </form>
  );
}

export default ActionForm;
