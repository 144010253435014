import { useState } from "react";
import { FiEye, FiEyeOff, FiLoader } from "react-icons/fi";

import useForm from "../../../hooks/useForm.hook";
import useToggle from "../../../hooks/useToggle.hook";

export default function AdminPasswordForm({
  buttonText,
  buttonClass,
  processPassword,
}) {
  const [password, setPassword] = useState("");
  const [pwdVisible, togglePasswordVisibility] = useToggle(false);

  const form = useForm();
  const onSubmit = (e) => {
    e.preventDefault();

    if (!form.state.submitting) {
      form.submitStarted();
      processPassword(password, form);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-field has-icon">
        <label htmlFor="pwd">Enter Password</label>
        <input
          type={pwdVisible ? "visible" : "password"}
          name="pwd"
          placeholder="••••••••"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={form.state.submitting}
        />
        <span id="form-field-icon" onClick={togglePasswordVisibility}>
          {pwdVisible ? (
            <FiEyeOff size={16} color={"#ABB3B7"} />
          ) : (
            <FiEye size={16} color={"#ABB3B7"} />
          )}
        </span>
      </div>

      <div className="color-orange mb-28">{form.state.error}</div>

      <button
        disabled={!password || form.state.submitting}
        className={buttonClass ?? "fullwidth btn primary"}
      >
        {form.state.submitting ? <FiLoader /> : buttonText ?? "Continue"}
      </button>
    </form>
  );
}
