import { useState } from "react";
import { OutlineButton } from "./Button";

export const ImagePickerWidgets = (acceptExtensions) => {
  const [image, setImage] = useState(null);

  const picker = ({
    text = "Upload Image",
    btnText = "Select Image",
    ...props
  }) => {
    return (
      <div id="image-picker" className={props.className ?? ""}>
        <span>{!image ? text : image.name}</span>
        <OutlineButton mini>{btnText}</OutlineButton>

        <input
          type="file"
          accept={acceptExtensions}
          onChange={(e) => {
            e.preventDefault();
            setImage(e.target.files[0]);
          }}
        />
      </div>
    );
  };

  const preview = (props) => {
    const className = props.className
      ? `img-preview ${props.className}`
      : "img-preview";
    if (!image) {
      return (
        <div className={className}>
          <span>Preview Image</span>
        </div>
      );
    }

    const imageUrl = (window.URL || window.webkitURL).createObjectURL(image);
    const bg = `center / cover no-repeat url(${imageUrl})`;
    return <div className={className} style={{ background: bg }}></div>;
  };

  return [picker, preview, image];
};
