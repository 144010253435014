import React from "react";

const CheckboxIcon = ({ color }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.56502 5.35432L6.79821 1.12114C6.92975 0.989598 7.09716 0.923828 7.30045 0.923828C7.50374 0.923828 7.67115 0.989598 7.80269 1.12114C7.93423 1.25268 8 1.42009 8 1.62338C8 1.82667 7.93423 1.99408 7.80269 2.12562L3.06726 6.86105C2.92377 7.00455 2.75635 7.07629 2.56502 7.07629C2.37369 7.07629 2.20628 7.00455 2.06278 6.86105L0.197309 4.99558C0.0657695 4.86404 0 4.69662 0 4.49333C0 4.29005 0.0657695 4.12263 0.197309 3.99109C0.328849 3.85955 0.496263 3.79378 0.699551 3.79378C0.90284 3.79378 1.07025 3.85955 1.20179 3.99109L2.56502 5.35432Z"
        fill={color ?? "#009B54"}
      />
    </svg>
  );
};

export default CheckboxIcon;
