import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";
import { useOutletContext } from "react-router-dom";

import { ImagePickerWidgets } from "../../../components/ImagePickerWidgets";
import DataFetcher from "../../../components/DataFetcher";
import { InputField } from "../../../components/Input";
import { DelayedActionButton } from "../../../components/Button";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";
import { getPermissions, sendPostRequest } from "../../../config/swr";

const UPDATE_CARD_KEY = "update-card-requests-settings";

const CardsSettings = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Cards Settings");

  return (
    <DataFetcher
      url={`/settings/card-requests`}
      buildUI={(data) => <CardsDisplay data={data} />}
    />
  );
};

const CardsDisplay = ({ data }) => {
  const permissions = getPermissions();

  const canUpdateCards = permissions[UPDATE_CARD_KEY];
  const [formData, setFormData] = useState(data);

  const [editTypeID, setEditTypeID] = useState(-1);
  const [type, setType] = useState("");

  const [editReasonID, setEditReasonID] = useState(-1);
  const [reason, setReason] = useState("");

  const showMessageModal = useMessageModal();

  const { trigger } = useSWRMutation(
    "/settings/card-requests",
    sendPostRequest
  );

  const [CardTemplateImagePicker, CardTemplateImagePreview] =
    ImagePickerWidgets();

  const form = useForm();

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "cardCharge") {
      setFormData({ ...formData, [name]: +value });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const onTypeEdit = (index) => {
    setEditTypeID(index);
    setType(formData.cardTypes[index]);
  };

  const onTypeRemoved = (index) => {
    setEditTypeID(-1);
    setType("");
    setFormData({
      ...formData,
      cardTypes: [
        ...formData.cardTypes.slice(0, index),
        ...formData.cardTypes.slice(index + 1),
      ],
    });
  };

  const onTypeChange = (e) => {
    const { value } = e.target;
    setType(value);
  };

  const onTypeSubmit = () => {
    if (!type) return;

    if (editTypeID < 0) {
      setFormData({
        ...formData,
        cardTypes: [...formData.cardTypes, type],
      });
    } else {
      const newArr = formData.cardTypes.slice();
      newArr[editTypeID] = type;
      setFormData({
        ...formData,
        cardTypes: newArr,
      });
    }
    setType("");
    setEditTypeID(-1);
  };

  const onReasonEdit = (index) => {
    setEditReasonID(index);
    setReason(formData.cardRequestReasons[index]);
  };

  const onReasonRemoved = (index) => {
    setEditReasonID(-1);
    setReason("");
    setFormData({
      ...formData,
      cardRequestReasons: [
        ...formData.cardRequestReasons.slice(0, index),
        ...formData.cardRequestReasons.slice(index + 1),
      ],
    });
  };

  const onReasonChange = (e) => {
    const { value } = e.target;
    setReason(value);
  };

  const onReasonSubmit = () => {
    if (!reason) return;

    if (editReasonID < 0) {
      setFormData({
        ...formData,
        cardRequestReasons: [...formData.cardRequestReasons, reason],
      });
    } else {
      const newArr = formData.cardRequestReasons.slice();
      newArr[editReasonID] = reason;
      setFormData({
        ...formData,
        cardRequestReasons: newArr,
      });
    }
    setReason("");
    setEditReasonID(-1);
  };

  const handleSubmit = async () => {
    if (!canUpdateCards) return;
    form.submitStarted();
    const data = {
      ...formData,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div className="settings">
      <div className="flex start gap equal-widths mb-28">
        <div>
          <h2 className="fs-20 mb-16">Card Types</h2>
          <div className="box">
            {!formData.cardTypes || formData.cardTypes?.length === 0 ? (
              <>No Card Types added yet</>
            ) : (
              formData.cardTypes?.map((tenure, i) => (
                <div
                  key={tenure}
                  className="flex gap center info-row-with-actions"
                >
                  <div className="text">
                    <h3 className="fs-20" style={{ marginBottom: 0 }}>
                      {tenure}
                    </h3>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary light"
                      onClick={() => onTypeEdit(i)}
                    >
                      Edit
                    </button>
                    <DelayedActionButton
                      className="btn danger light"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      isBusy={form.state.submitting}
                      clickAction={() => onTypeRemoved(i)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">&nbsp;</h2>
          <div className="box">
            <InputField
              label="Card Type"
              type="text"
              placeholder="Enter Card Type"
              className="mb-32"
              value={type}
              onChange={onTypeChange}
            />
            <button
              className="btn primary light fullwidth"
              onClick={onTypeSubmit}
              disabled={!type}
            >
              {editTypeID < 0 ? "Add" : "Update"}
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <div className="mb-28 flex gap equal-widths">
          <div className="box">
            <label htmlFor="">Card Template</label>

            <CardTemplateImagePicker className="mb-16" />

            <label htmlFor="">Card Renewal Charges</label>
            <input
              className="mb-16"
              type="number"
              min={0}
              placeholder="N 700"
              name="cardCharge"
              value={formData.cardCharge}
              onChange={onChange}
            />

            <label htmlFor="">Card Ledger Code</label>
            <input
              name="cardTransactionLedgerCode"
              value={formData.cardTransactionLedgerCode}
              onChange={onChange}
            />
          </div>

          <div className="card-preview box">
            <CardTemplateImagePreview />
          </div>
        </div>
      </div>

      <hr />

      <div className="flex start gap equal-widths mb-28">
        <div>
          <h2 className="fs-20 mb-16">Card Request Reasons</h2>
          <div className="box">
            {!formData.cardRequestReasons ||
            formData.cardRequestReasons?.length === 0 ? (
              <>No Request Reasons added yet</>
            ) : (
              formData.cardRequestReasons?.map((reason, i) => (
                <div
                  key={reason}
                  className="flex gap center info-row-with-actions"
                >
                  <div className="text">
                    <h3 className="fs-20" style={{ marginBottom: 0 }}>
                      {reason}
                    </h3>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary light"
                      onClick={() => onReasonEdit(i)}
                    >
                      Edit
                    </button>
                    <DelayedActionButton
                      className="btn danger light"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      isBusy={form.state.submitting}
                      clickAction={() => onReasonRemoved(i)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">&nbsp;</h2>
          <div className="box">
            <InputField
              label="Card Request Reason"
              type="text"
              placeholder="Enter Card Request Reason"
              className="mb-32"
              value={reason}
              onChange={onReasonChange}
            />
            <button
              className="btn primary light fullwidth"
              onClick={onReasonSubmit}
              disabled={!reason}
            >
              {editReasonID < 0 ? "Add" : "Update"}
            </button>
          </div>
        </div>
      </div>

      {canUpdateCards && (
        <button
          className="fullwidth dark primary btn"
          onClick={handleSubmit}
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}
    </div>
  );
};

export default CardsSettings;
