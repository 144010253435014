import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import { OutlineButton } from "./Button";
import CheckBox from "./Checkbox";

import ArrowLeftIcon from "../assets/icons/arrowleft";

import useMessageModal from "../hooks/useMessageModal.hook";
import useForm from "../hooks/useForm.hook";
import { FALLBACK_IMAGE_URL, sendPostRequest } from "../config/swr";

const ChannelOptions = ["sms", "email", "push-notification"];

const checkedBoxes = (formData) => {
  let channelStr = "";
  ChannelOptions.forEach((item) => {
    if (formData[item]) channelStr = `${channelStr}${item},`;
  });
  return channelStr.replace(/,\s*$/, "");
};

const SendMessage = ({
  accountName,
  customerID,
  accountNumber,
  profileImageUrl,
  handleClose,
}) => {
  const [imgSrc, setImgSrc] = useState(profileImageUrl ?? FALLBACK_IMAGE_URL);
  const form = useForm({
    title: "",
    message: "",
    sms: false,
    email: false,
    "push-notification": false,
  });
  const showMessageModal = useMessageModal();

  const { title, message, sms, email } = form.data;

  const { trigger } = useSWRMutation("/bulk-message", sendPostRequest);

  const submitMessageForm = async (event) => {
    event.preventDefault();

    form.submitStarted();
    const channelStr = checkedBoxes(form.data);

    const data = new FormData();
    data.append("title", title);
    data.append("message", message);
    data.append("customers", customerID);
    data.append("msgTypes", channelStr);

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message || "Successfully submitted",
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
      handleClose();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div>
      <div className="flex gap start center mb-16">
        <span onClick={handleClose}>
          <ArrowLeftIcon width={16} />
        </span>
        <p className="fs-20 fw-600">Send Message</p>
      </div>
      <div className="flex col center">
        <img
          src={imgSrc}
          onError={() => {
            setImgSrc(FALLBACK_IMAGE_URL);
          }}
          alt="profile"
          className={`profile-img mb-20`}
        />

        <h3 className="fs-20 mb-6">{accountName}</h3>
        <p className={`charcoal-60 mb-20 center-text`}>{accountNumber}</p>
      </div>
      <form className="flex col gap-8 mb-6" onSubmit={submitMessageForm}>
        <input
          type="text"
          className="mb-16"
          placeholder="Message Title"
          {...form.fieldProps("title")}
        />

        <textarea
          className="mb-16"
          placeholder="Message Body"
          {...form.fieldProps("message")}
        ></textarea>

        <div className="flex start center gap">
          <CheckBox text="Email" {...form.fieldProps("email", true)} />
          <CheckBox text="SMS" {...form.fieldProps("sms", true)} />
          <CheckBox
            text="Push Notification"
            {...form.fieldProps("push-notification", true)}
          />
        </div>

        <div className="flex equal-widths">
          <OutlineButton
            disabled={
              form.state.submitting ||
              !title ||
              !message ||
              !(email || sms || form.data["push-notification"])
            }
          >
            {form.state.submitting ? <FiLoader /> : "Send"}
          </OutlineButton>
        </div>
      </form>
    </div>
  );
};

export default SendMessage;
