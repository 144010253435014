import { useEffect, useState } from "react";
import { FiSend } from "react-icons/fi";

import { DelayedActionButton } from "../../../components/Button";
import AdminInfo from "./AdminInfo";
import MessageListView from "./MessageListView";

export default function MessageWindow({
  connected,
  chatRoom,
  joinChatRoom,
  closeChatRoom,
  sendMessage,
  tempMessages,
}) {
  const [joiningChatRoom, setJoiningChatRoom] = useState(false);
  const joinChatRoomClick = () => {
    setJoiningChatRoom(true);
    joinChatRoom();
  };

  const [closingChatRoom, setClosingChatRoom] = useState(false);
  const closeChatRoomClick = () => {
    setClosingChatRoom(true);
    closeChatRoom();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setJoiningChatRoom(false);
    setClosingChatRoom(false);
  }, [chatRoom.id]); // reset buttons if the admin navigates to a different chat room

  const [newChatMsg, setNewChatMsg] = useState("");
  const sendNewChatMsg = (e) => {
    e?.preventDefault();
    // TODO: Handle shift+enter.
    sendMessage(newChatMsg);
    setNewChatMsg("");
  };

  const customer = chatRoom.createdBy;
  const msgs = chatRoom.messages.map((msg) => {
    const isCustomerMessage = msg.author.id === customer.id;
    return {
      ...msg,
      isCustomerMessage,
      imageUrl: isCustomerMessage ? customer.imageUrl : null,
    };
  });

  const allMsgs = msgs.concat(Object.values(tempMessages));

  return (
    <div>
      <AdminInfo
        key={customer.firstName}
        customer={customer}
        chatRoom={chatRoom}
        connected={connected}
        closingChatRoom={closingChatRoom}
        closeChatRoomClick={closeChatRoomClick}
      />
      <hr />

      <MessageListView messages={allMsgs} />

      {chatRoom.waitingForAdmin ? (
        <DelayedActionButton
          className="fullwidth btn primary"
          disabled={!connected}
          isBusy={joiningChatRoom}
          clickAction={joinChatRoomClick}
          text="JOIN CHAT ROOM"
        />
      ) : (
        <form className="form-field has-icon fs-20" onSubmit={sendNewChatMsg}>
          <input
            type="text"
            placeholder="Enter message here"
            value={newChatMsg}
            onChange={(e) => setNewChatMsg(e.target.value)}
            disabled={!connected}
            style={{ padding: "13px 30px 13px 11px" }}
          />
          <span id="form-field-icon" onClick={sendNewChatMsg}>
            <FiSend size={20} color={"#9FA4A7"} />
          </span>
        </form>
      )}
    </div>
  );
}
