import React from "react";

const UploadIcon = () => {
  return (
    <svg
      width="32"
      height="26"
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9011 7.50001H25.0136C25.8986 7.50016 26.7749 7.67493 27.5923 8.0143C28.4096 8.35368 29.152 8.851 29.7768 9.47776C31.0406 10.7444 31.7504 12.4607 31.7504 14.25C31.7504 16.0393 31.0406 17.7556 29.7768 19.0223C29.152 19.649 28.4096 20.1463 27.5923 20.4857C26.7749 20.8251 25.8986 20.9999 25.0136 21H20.5248V18.75H25.0136C26.1723 18.6982 27.2663 18.2014 28.0679 17.3632C28.8694 16.525 29.3168 15.4098 29.3168 14.25C29.3168 13.0902 28.8694 11.9751 28.0679 11.1368C27.2663 10.2986 26.1723 9.80184 25.0136 9.75001H22.9571L22.6803 7.82176C22.5115 6.61368 21.953 5.49372 21.0896 4.63198C20.2263 3.77025 19.1052 3.21384 17.8968 3.04726C16.6877 2.88209 15.4578 3.11818 14.3958 3.7193C13.3338 4.32041 12.4983 5.25336 12.0176 6.37501L11.2683 8.08951L9.4481 7.66426C9.03202 7.56106 8.60548 7.50595 8.17685 7.50001C6.68735 7.50001 5.2586 8.09176 4.20785 9.14701C3.42478 9.93439 2.89196 10.9357 2.67639 12.0251C2.46082 13.1144 2.57212 14.2432 2.99629 15.2695C3.42046 16.2957 4.13857 17.1737 5.06033 17.793C5.9821 18.4123 7.06638 18.7452 8.17685 18.75H13.7883V21H8.17685C7.06239 21.01 5.9586 20.7824 4.93901 20.3324C3.91941 19.8823 3.00744 19.2202 2.26385 18.39C1.13954 17.1379 0.445604 15.5588 0.283654 13.8838C0.121703 12.2089 0.500243 10.526 1.36385 9.08176C1.93583 8.12489 2.70462 7.30049 3.61928 6.66317C4.53394 6.02586 5.57359 5.59018 6.66935 5.38501C7.76285 5.18251 8.8901 5.21176 9.97235 5.47726C10.6543 3.91579 11.8262 2.61886 13.3107 1.7826C14.7953 0.946349 16.5118 0.61626 18.2006 0.842261C19.8901 1.07089 21.4588 1.84487 22.6682 3.04657C23.8777 4.24827 24.6617 5.81198 24.9011 7.50001ZM21.1593 16.1693L18.2028 13.215V25.446H15.9663V13.296L13.0908 16.1715L11.5001 14.5785L16.3286 9.75001H17.9216L22.7501 14.5785L21.1593 16.1693Z"
        fill="#009B54"
      />
    </svg>
  );
};

export default UploadIcon;
