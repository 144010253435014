import React from "react";
import useForm from "../hooks/useForm.hook";
import { DelayedActionButton } from "./Button";

export default function MutableListView({
  dataList,
  renderItem,
  editItem,
  deleteItem,
}) {
  const form = useForm();

  const handleDeleteClick = (i) => {
    form.submitStarted();
    deleteItem(i, form.submitSuccess, form.submitError);
  };

  return (
    <>
      {(dataList ?? []).map((item, i) => {
        const editButton = (
          <button
            disabled={form.state.submitting}
            className="btn primary light"
            onClick={() => editItem(i, item)}
          >
            Edit
          </button>
        );

        const deleteButton = (
          <DelayedActionButton
            className="btn danger light"
            text="Delete"
            confirmText="Confirm Delete"
            busyText="Deleting"
            isBusy={form.state.submitting}
            clickAction={() => handleDeleteClick(i)}
          />
        );

        return renderItem(item, editButton, deleteButton);
      })}

      {form.state.error && (
        <div className="form-error">Error: {form.state.error}</div>
      )}
    </>
  );
}
