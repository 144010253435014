import { useState } from "react";
import { useOutletContext } from "react-router-dom";

import PartnerTableList from "./views/PartnerTableList";
import PartnerDetailsModal from "./views/PartnerDetailsModal";
import DeletePartnerModal from "./views/DeletePartnerModal";
import DataFetcher from "../../../components/DataFetcher";
import { SearchBox } from "../../../components/Input";

import { getPermissions } from "../../../config/swr";

const CREATE_LOAN_PARTNER_KEY = "create-loan-partner";

export const CREATE_MODAL = "CREATE_MODAL",
  EDIT_MODAL = "EDIT_MODAL",
  DELETE_MODAL = "DELETE_MODAL";

const LoanPartnersPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Loan Partners");

  return (
    <DataFetcher
      url={`/loan-partners`}
      buildUI={(data, mutate) => (
        <LoanPartnersDisplay partners={data} mutate={mutate} />
      )}
    />
  );
};

const LoanPartnersDisplay = ({ partners, mutate }) => {
  const [modal, setModal] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const permissions = getPermissions();

  const canCreateLoanPartner = permissions[CREATE_LOAN_PARTNER_KEY];

  const openCreateModal = () => setModal(CREATE_MODAL);
  const openEditModal = () => setModal(EDIT_MODAL);
  const openDeleteModal = () => setModal(DELETE_MODAL);
  const closeModal = () => setModal(null);

  const selectedPartner = selectedId
    ? partners.find((partner) => partner.id === selectedId)
    : null;

  const handleOpen = (e, id) => {
    e.stopPropagation();
    setSelectedId(null);
    if (activeId === id) {
      setActiveId(null);
      return;
    }
    setActiveId(id);
  };

  const handleClose = () => setActiveId(null);

  const handleEdit = (e, id) => {
    e.stopPropagation();
    openEditModal();
    setSelectedId(id);
    setActiveId(null);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    openDeleteModal();
    setSelectedId(id);
    setActiveId(null);
  };

  return (
    <div className="customers">
      <div className="flex between-center mb-16">
        <SearchBox placeholder={"Search Loan Partners"} />

        {canCreateLoanPartner && (
          <button
            className="flex center gap-8 btn outline fw-600"
            onClick={openCreateModal}
          >
            Add a new Partner
          </button>
        )}
      </div>

      <PartnerTableList
        partners={partners}
        activeId={activeId}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />

      {modal === CREATE_MODAL && (
        <PartnerDetailsModal
          partner={null}
          mutate={mutate}
          dismissModal={closeModal}
        />
      )}

      {modal === EDIT_MODAL && (
        <PartnerDetailsModal
          partner={selectedPartner}
          mutate={mutate}
          dismissModal={closeModal}
        />
      )}

      {modal === DELETE_MODAL && (
        <DeletePartnerModal
          partner={selectedPartner}
          dismissModal={closeModal}
        />
      )}
    </div>
  );
};

export default LoanPartnersPage;
