import { useState } from "react";
import { useOutletContext } from "react-router";

import { TxInfoForm, TxResolutionForm } from "./forms";

const TxInfoFormID = 1,
  TxResolutionFormID = 2;

export default function TransactionsPage() {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Transactions");
  const [pageState, setPageState] = useState({ formID: TxInfoFormID });
  const [fetchingData, setFetchingData] = useState(false);
  const changeForm = (formID, data) => setPageState({ formID, data });
  const backToTxInfo = () => changeForm(TxInfoFormID);

  const data = pageState.data;

  const dataDisplayClass = fetchingData
    ? "data-display fetching"
    : data
    ? "data-display"
    : "data-display empty-blocks";

  const customerInfo = data
    ? [
        ...data.customerInfo?.accounts?.map(({ accountNumber, status }, i) => ({
          title: `Account Number ${i + 1}`,
          value: (
            <>
              {`${accountNumber} `}
              <span className="charcoal-60">({status})</span>
            </>
          ),
        })),
        { title: "Phone Number", value: data.customerInfo?.phoneNumber },
        {
          title: "Account Status",
          value: data.customerInfo?.accounts?.some(
            ({ status }) => status === "Active"
          )
            ? "Active"
            : "Inactive",
        },
      ]
    : Array(1).fill({});

  const accountElement = (account) => {
    return (
      <>
        {account?.AccountNumber}
        {account?.bankName && (
          <span className="charcoal-60"> ({account?.bankName})</span>
        )}
      </>
    );
  };

  const txInfo = data
    ? [
        { title: "Transaction Date", value: data.transactionInfo?.date },
        {
          title: "Amount",
          value: <>&#8358; {data.transactionInfo?.amount?.toLocaleString()}</>,
        },
        { title: "Narration", value: data.transactionInfo?.narration },
        {
          title: "Source Account",
          value: accountElement(data.transactionInfo?.from),
        },
        {
          title: "Source Account Name",
          value: data.transactionInfo?.from?.name,
        },
        {
          title: "Destination Account",
          value: accountElement(data.transactionInfo?.to),
        },
        {
          title: "Destination Account Name",
          value: data.transactionInfo?.to?.name,
        },
        {
          title: "Status",
          value: (
            <span className="color-orange">{data.transactionInfo?.status}</span>
          ),
        },
      ]
    : Array(3).fill({});

  return (
    <div className="tx-page-content">
      <div className="box form-container">
        {pageState.formID === TxInfoFormID && (
          <TxInfoForm
            setFetchingData={setFetchingData}
            onSuccess={(data) => changeForm(TxResolutionFormID, data)}
          />
        )}

        {pageState.formID === TxResolutionFormID && (
          <TxResolutionForm data={pageState.data} onClose={backToTxInfo} />
        )}
      </div>

      <div className={`box ${dataDisplayClass}`}>
        <div className="flex gap start center">
          <img
            className="profile-img mb-12"
            src={
              data?.customerInfo?.profileImageURL ??
              "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png"
            }
            alt="customer dp"
          />
          {data?.customerInfo && (
            <div>
              <h3 className="fs-20 mb-6">{data?.customerInfo?.fullName}</h3>
              <p className={`charcoal-60`}>
                DOB: {data?.customerInfo?.dateOfBirth}
              </p>
            </div>
          )}
        </div>

        <div className={`flex col ${data ? "gap-8" : "gap-4"} mb-20`}>
          {customerInfo.map((info, index) => (
            <div key={index} className="info-row flex mb-4">
              <div>{info?.title}</div>
              <div>{info?.value}</div>
            </div>
          ))}
        </div>

        <hr className="mb-20" />

        <div className="flex col">
          {txInfo.map((info, index) => (
            <div key={index} className="info-row flex mb-4">
              <div>{info?.title}</div>
              <div>{info?.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
