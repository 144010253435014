import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import Checkbox from "../../../../components/Checkbox";
import { InputField } from "../../../../components/Input";
import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";
import { getPermissions, sendPostRequest } from "../../../../config/swr";

const UPDATE_GENERAL_COLLECTION_SETTINGS_KEY = "update-general-collection-settings";

const FewchoreCollectionSettings = ({ generalCollectionSettings }) => {
  const permissions = getPermissions();

  const canUpdateSettings = permissions[UPDATE_GENERAL_COLLECTION_SETTINGS_KEY];
  const [formData, setFormData] = useState(generalCollectionSettings);

  const { trigger } = useSWRMutation(
    "/settings/collections/general",
    sendPostRequest
  );

  const form = useForm();
  const showMessageModal = useMessageModal();

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      if (name === "minimumCollectionAmount" || name === "interestRatePA") {
        setFormData({ ...formData, [name]: +value });
        return;
      }
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async () => {
    if (!canUpdateSettings) return;
    form.submitStarted();

    const data = {
      ...formData,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <>
      <div className="flex gap equal-widths box mb-28">
        <div>
          <InputField
            label="Collection Name"
            type="text"
            placeholder="Enter Collection Name"
            className="mb-16"
            name="name"
            value={formData.name}
            onChange={onChange}
          />
          <InputField
            label="Package Summary"
            multiline
            placeholder="Enter Package Summary here"
            className="mb-16"
            name="summary"
            value={formData.summary}
            onChange={onChange}
          />
          <InputField
            label="Min. collection Amount"
            type="number"
            min="0"
            placeholder="N 1000"
            name="minimumCollectionAmount"
            value={formData.minimumCollectionAmount}
            onChange={onChange}
          />
        </div>
        <div>
          <InputField
            label="Product Code"
            type="text"
            placeholder="Enter Product Code here"
            className="mb-16"
            name="productCode"
            value={formData.productCode}
            onChange={onChange}
          />
          <InputField
            label="Interest Rate (P/A)"
            type="number"
            min="1"
            placeholder="Enter Interest Rate here"
            name="interestRatePA"
            value={formData.interestRatePA}
            onChange={onChange}
          />
        </div>
      </div>

      <h2 className="fs-20 mb-16">Collection Method</h2>
      <div className="flex gap box mb-28">
        <Checkbox
          name="fundViaCard"
          text="Debit Card"
          checked={formData.fundViaCard}
          setChecked={onChange}
        />
        <Checkbox
          name="fundViaAccount"
          text="Fewchore Account"
          checked={formData.fundViaAccount}
          setChecked={onChange}
        />
      </div>

      {canUpdateSettings && (
        <button
          className="fullwidth dark primary btn"
          onClick={handleSubmit}
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}
    </>
  );
};

export default FewchoreCollectionSettings;
