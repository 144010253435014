import dayjs from "dayjs";

const amountElement = (amount) => <>&#8358; {amount?.toLocaleString()}</>;
const formatDate = (timestamp) =>
  timestamp && timestamp > 0
    ? dayjs(timestamp * 1000).format("DD/MM/YYYY HH:mm:ss")
    : "-";

const DisbursementModal = ({
  timestamp,
  beneficiaryAccountNumber,
  failureReason,
  receipt,
}) => {
  const { status, transactionReference, amount, narration } = receipt;
  const repaymentInfo = [
    { title: "Timestamp", value: formatDate(timestamp) },
    { title: "Beneficiary Account Number", value: beneficiaryAccountNumber },
    { title: "Failure Reason", value: failureReason },
    { title: "Transaction Reference", value: transactionReference },
    {
      title: "Status",
      value: (
        <>
          {status === "Successful" ? (
            <span className="color-primary">{status}</span>
          ) : (
            <span>{status}</span>
          )}
        </>
      ),
    },
    { title: "Amount", value: amountElement(amount) },
    { title: "Narration", value: narration },
  ];

  return (
    <div>
      <h3 className="mb-20">Disbursement Details</h3>
      <div className="card__box">
        <div className="card__grid">
          {repaymentInfo.map((info, index) => (
            <div key={index} className="flex gap-8 between-center">
              <span className="charcoal-60">{info.title}:</span>
              <h3>{info.value}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DisbursementModal;
