const SpreadsheetIcon = () => {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10119_75908)">
        <path
          d="M30.5003 46.6333H27.167V53.3H30.5003V46.6333ZM70.5003 53.3H73.8337V46.6333H70.5003V53.3ZM30.5003 70H27.167V73.3333H30.5003V70ZM70.5003 70V73.3333H73.8337V70H70.5003ZM30.5003 26.6467H27.167V33.3133H30.5003V26.6467ZM70.5003 33.3133H73.8337V26.6467H70.5003V33.3133ZM90.5003 23.3333H93.8337V21.9533L92.8603 20.9733L90.5003 23.3333ZM70.5003 3.33333L72.8603 0.973333L71.8803 0H70.5003V3.33333ZM30.5003 53.3H70.5003V46.6333H30.5003V53.3ZM30.5003 73.3333H70.5003V66.6667H30.5003V73.3333ZM30.5003 33.3133H70.5003V26.6467H30.5003V33.3133ZM83.8337 93.3333H17.167V100H83.8337V93.3333ZM13.8337 90V10H7.16699V90H13.8337ZM87.167 23.3333V90H93.8337V23.3333H87.167ZM17.167 6.66667H70.5003V0H17.167V6.66667ZM68.1403 5.69333L88.1403 25.6933L92.8603 20.9733L72.8603 0.973333L68.1403 5.69333ZM17.167 93.3333C16.2829 93.3333 15.4351 92.9821 14.81 92.357C14.1848 91.7319 13.8337 90.8841 13.8337 90H7.16699C7.16699 92.6522 8.22056 95.1957 10.0959 97.0711C11.9713 98.9464 14.5148 100 17.167 100V93.3333ZM83.8337 100C86.4858 100 89.0294 98.9464 90.9047 97.0711C92.7801 95.1957 93.8337 92.6522 93.8337 90H87.167C87.167 90.8841 86.8158 91.7319 86.1907 92.357C85.5656 92.9821 84.7177 93.3333 83.8337 93.3333V100ZM13.8337 10C13.8337 9.11594 14.1848 8.2681 14.81 7.64298C15.4351 7.01786 16.2829 6.66667 17.167 6.66667V0C14.5148 0 11.9713 1.05357 10.0959 2.92893C8.22056 4.8043 7.16699 7.34784 7.16699 10H13.8337ZM27.167 30V70H33.8337V30H27.167ZM47.167 30V70H53.8337V30H47.167ZM67.167 30V70H73.8337V30H67.167Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_10119_75908">
          <rect
            width="100"
            height="100"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SpreadsheetIcon;
