import { useState } from "react";
import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";
import Modal from "../../../components/Modal";
import { ImagePickerWidgets } from "../../../components/ImagePickerWidgets";
import { sendPostRequest } from "../../../config/swr";
import useMessageModal from "../../../hooks/useMessageModal.hook";

const ModeSingle = "Single Account",
  ModeBulk = "Bulk Account";

const max11digitsRegEx = new RegExp("^\\d{0,11}$");

const accountCreatedMessage = (accountNumber, accountName) =>
  `You have successfully created a  new virtual account for ${accountName.toUpperCase()}, account number: ${accountNumber}.`;

export default function CreateVirtualAccountModal({ closeModal }) {
  const [selectedMode, setSelectedMode] = useState(ModeSingle);
  const [singleAcctData, setSingleAcctData] = useState({ bvn: "", nin: "" });
  const [CSVFilePicker, , file] = ImagePickerWidgets(".csv");

  const handleSingleAcctFieldUpdate = (e) => {
    const { name, value } = e.target;
    if ((name === "bvn" || name === "nin") && !max11digitsRegEx.test(value)) {
      return;
    }
    setSingleAcctData((prev) => ({ ...prev, [name]: value }));
  };

  const { bvn, nin } = singleAcctData;
  const canProceed =
    selectedMode === ModeSingle
      ? bvn?.length === 11 && nin?.length === 11
      : !!file;

  const showMessageModal = useMessageModal();
  const { trigger, isMutating } = useSWRMutation(
    "/virtual-accounts",
    sendPostRequest
  );

  const submitForm = async (e) => {
    e.preventDefault();
    if (!canProceed || isMutating) return;

    const data = new FormData();
    if (selectedMode === ModeSingle) {
      data.append("accountDetails", JSON.stringify(singleAcctData));
    } else {
      data.append("csvFile", file);
    }

    try {
      const res = await trigger(data);
      const { message, accountNumber, accountName } = res.data;
      if (message || accountNumber) {
        // Created successfully. Show a success message and close this modal.
        showMessageModal({
          title: "Success",
          message: message ?? accountCreatedMessage(accountNumber, accountName),
        });
        closeModal();
      } else {
        // A successful response without an account number or a message is a
        // response returning user info for display.
        const { FirstName, LastName, OtherNames, Email, PhoneNumber } =
          res.data;
        setSingleAcctData((prev) => ({
          ...prev,
          name: `${FirstName} ${OtherNames} ${LastName}`,
          email: Email,
          phone: PhoneNumber,
          address: "",
        }));
      }
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
    }
  };

  const userInfoDisplay = singleAcctData.name
    ? {
        name: "Name",
        phone: "Phone Number",
        email: "Email",
        address: "Address",
      }
    : null;

  return (
    <div>
      <Modal
        handleClose={closeModal}
        className="modal-heading-subheading-buttons"
      >
        <h1 className="fs-24">Create Virtual Account</h1>
        <p className="mb-20">
          {userInfoDisplay
            ? "Confirm personal information associated with the BVN and NIN you provided. Or update the information for this virtual account."
            : "Kindly enter Bank Verification Number(BVN) and National Identification Number(NIN) to create virtual account."}
        </p>
        {!userInfoDisplay && (
          <div className="flex center mb-32">
            {[ModeSingle, ModeBulk].map((mode) => (
              <button
                className="transparent btn"
                onClick={() => setSelectedMode(mode)}
                style={
                  selectedMode === mode
                    ? { borderBottom: "1px solid #1F613E", color: "#1F613E" }
                    : { color: "#ABB3B7" }
                }
              >
                {mode}
              </button>
            ))}
          </div>
        )}

        <form onSubmit={submitForm}>
          {selectedMode === ModeBulk ? (
            <>
              <label className="fs-16">
                CSV File (
                <a href="/va-sample-csv.csv" download="va-sample-csv.csv">
                  Download sample CSV
                </a>
                )
              </label>
              <CSVFilePicker
                className="mb-32"
                text={file?.name ?? "Select CSV File"}
                btnText={file ? "Change File" : "Select File"}
              />
            </>
          ) : userInfoDisplay ? (
            <div className="mb-20">
              {Object.entries(userInfoDisplay).map(([key, label]) => (
                <div className="mb-12" key={key}>
                  <label className="fs-16 mb-6" htmlFor={key}>
                    {label}
                  </label>
                  <input
                    id={key}
                    type={key === "email" ? "email" : "text"}
                    className="fs-14"
                    name={key}
                    value={singleAcctData[key] ?? ""}
                    onChange={handleSingleAcctFieldUpdate}
                  />
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="mb-16">
                <label className="fs-16 mb-6" htmlFor="bvn">
                  Bank Verification Number
                </label>
                <input
                  id="bvn"
                  className="fs-14"
                  type="text"
                  placeholder="Enter customer BVN"
                  name="bvn"
                  value={bvn}
                  onChange={handleSingleAcctFieldUpdate}
                />
              </div>
              <div className="mb-32">
                <label className="fs-16" htmlFor="nin">
                  National Identification Number
                </label>
                <input
                  id="nin"
                  className="fs-14"
                  type="text"
                  placeholder="Enter customer NIN"
                  name="nin"
                  value={nin}
                  onChange={handleSingleAcctFieldUpdate}
                />
              </div>
            </>
          )}

          <div className="actions flex equal-widths">
            <button
              className="btn primary fs-22"
              type="submit"
              disabled={!canProceed || isMutating}
            >
              {isMutating ? <FiLoader /> : "Proceed"}
            </button>
            <button
              className="btn transparent outline fs-22"
              onClick={closeModal}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
