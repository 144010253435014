export const firebaseConfig = {
  apiKey: "AIzaSyDmbGcDwgG6TXhHgHJ0L-upRol6N--s74s",
  authDomain: "fewchoremobile.firebaseapp.com",
  projectId: "fewchoremobile",
  storageBucket: "fewchoremobile.appspot.com",
  messagingSenderId: "619188699388",
  appId: "1:619188699388:web:b2d53328eae17599b31779",
  measurementId: "G-LQXFLYQ77C",
};

export const fcmVapidKey =
  "BBN0bN2sBs7HXAL4gJrEUe8ucLnRZqJcqlFhxuVN_Flo9mqFu6I2AGlh7COZCy8p42SGr-0EFmykbhedwOvyl28";
