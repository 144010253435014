import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const BatchTableList = ({ batches, displayBatchDetails }) => {
  const headers = [
    "batch name",
    "date",
    "No. of Entries ",
    "No. of fails",
    "No. of success",
    "Status",
  ];

  return (
    <>
      {!batches || batches.length === 0 ? (
        <p className="box">No batches added yet</p>
      ) : (
        <table className="fs-20 box" style={{ boxShadow: "unset" }}>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {batches.map((batch, index) => (
              <tr
                key={index}
                className="clickable fw-600"
                onClick={() => displayBatchDetails(index)}
              >
                <td>{batch.id}</td>
                <td>{dayjs(batch.timestamp * 1000).utc().format("DD/MM/YYYY")}</td>
                <td>{batch.totalEntries}</td>
                <td>{batch.failedReports?.length ?? 0}</td>
                <td>{batch.successfulReports?.length ?? 0}</td>
                <td>
                  {batch.status === "Completed" ? (
                    <span>{batch.status}</span>
                  ) : (
                    <span style={{ color: "#FB5E5A" }}>{batch.status}</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default BatchTableList;
