import { useState } from "react";
import dayjs from "dayjs";

export default function useForm(initialData) {
  const [formData, setFormData] = useState(initialData ?? {});
  const [formState, setFormState] = useState({
    submitting: false,
    error: false,
    successMsg: "",
  });

  const onFormDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue =
      type === "number" && !!value
        ? +value
        : type === "checkbox"
        ? checked
        : value;

    let key = JSON.parse(name);
    const updatedData = { ...formData };
    if (key.map) {
      const lastKey = key.pop();
      let innerObj = updatedData;
      for (let k of key) {
        innerObj = innerObj[k];
      }
      innerObj[lastKey] = newValue;
    } else {
      updatedData[key] = newValue;
    }
    setFormData(updatedData);
  };

  const valueValid = (key) => {
    const value = formData[key];
    return value === 0 || value === false || !!value; // 0 and false are valid values too
  };

  return {
    data: formData,
    state: formState,

    setData: (data) => setFormData(data ?? {}),

    fieldProps: (key, checkbox) => {
      const props = {
        name: JSON.stringify(key),
      };

      const value = key.map
        ? key.reduce((v, k) => v[k], formData)
        : formData[key];

      if (checkbox) {
        props["checked"] = value ?? false;
        props["setChecked"] = onFormDataChange;
      } else {
        props["value"] = value ?? "";
        props["onChange"] = onFormDataChange;
      }
      return props;
    },

    timestampFieldProps: (key) => {
      if (typeof formData[key] !== "string") {
        const timestamp = formData[key] ?? Date.now() / 1000;
        formData[key] = dayjs(timestamp * 1000).format("YYYY-MM-DD");
      }
      return {
        name: JSON.stringify(key),
        value: formData[key],
        onChange: onFormDataChange,
      };
    },

    valid: (...keys) => {
      if (keys.length === 0) {
        keys = Object.keys(formData);
      }
      // only valid if every key has a value.
      return keys.every(valueValid);
    },

    anyValid: (...keys) => {
      if (keys.length === 0) {
        keys = Object.keys(formData);
      }
      // only valid if any key has a value.
      return keys.some(valueValid);
    },

    submitStarted: () => {
      setFormState({
        submitting: true,
        error: false,
        successMsg: "",
      });
    },

    submitError: (error) => {
      setFormState({
        submitting: false,
        error: error,
        successMsg: "",
      });
    },

    submitSuccess: (message) => {
      setFormState({
        submitting: false,
        error: false,
        successMsg: message,
      });
    },
  };
}
