import { BiCaretDown } from "react-icons/bi";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import useToggle from "../../../hooks/useToggle.hook";

dayjs.extend(advancedFormat);

const hideDataWithDot = (value) => value.replace(/.+(.{4})$/, "****$1");

const CardDropDown = ({
  bin,
  bankName,
  accountName,
  expiryDate,
  disabled,
  index,
  length,
}) => {
  const [dropdownOpened, _toggleDropdown] = useToggle(false);
  const toggleDropdown = (e) => {
    e.preventDefault();
    _toggleDropdown();
  };

  const leftInfoList = [
    {
      title: "Bank Name",
      value: <span>{bankName}</span>,
    },
    {
      title: "Expiry Date",
      value: <span>{dayjs(expiryDate, "DD-MM-YYYY").format("MM-YY")}</span>,
    },
  ];

  const rightInfoList = [
    {
      title: "Account",
      value: <span>{accountName}</span>,
    },
    {
      title: "Status",
      value: <span>{disabled ? "Disabled" : "Active"}</span>,
    },
  ];

  return (
    <div className="card__box mb-20">
      <div className="flex gap between-center" onClick={toggleDropdown}>
        <span> Card {length > 1 && index + 1}</span>
        <span>
          {hideDataWithDot(bin)}
          <BiCaretDown
            size={18}
            className={
              dropdownOpened ? "dropdown-icon rotate-180" : "dropdown-icon"
            }
          />
        </span>
      </div>
      <div
        className={
          dropdownOpened ? "batch__dropdown opened" : "batch__dropdown"
        }
      >
        <div className="flex gap equal-widths">
          <div className="card__grid">
            {leftInfoList.map((info, index) => (
              <div key={index} className="flex gap-8 equal-widths">
                <span className="charcoal-60 fs-14">{info.title}:</span>
                <h3 className="fs-14">{info.value}</h3>
              </div>
            ))}
          </div>
          <div className="card__grid">
            {rightInfoList.map((info, index) => (
              <div key={index} className="flex gap-8 equal-widths">
                <span className="charcoal-60 fs-14">{info.title}:</span>
                <h3 className="fs-14">{info.value}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDropDown;
