import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";
import Select from "react-select";

import { OutlineButton } from "../../../components/Button";
import { sendPostRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    border: "1px solid #9fa4a7",
    borderRadius: "12px",
    padding: "0 6px",
    width: "100%",
    "&:hover": {
      border: "1px solid #9fa4a7",
    },
  }),
};

/**
 *
 * @param {array of bank accounts or bank cards} arr
 * @param {key in account or card that would be sent to endpoint and should serve as unique key} key
 * @param {boolean signifying if a card or an account} isCard
 * @returns {object of value and label to be used by React-Select}
 */
const formatArr = (arr, key, isCard) => {
  if (!arr || arr.length === 0) return [];
  return arr.map((item) => ({
    value: item[key],
    label: (
      <div className="flex between-center">
        <div>
          <p className="fs-12 ">{item.accountName}</p>
          <p>{isCard ? item.bankName : item.accountNumber}</p>
        </div>
        <div>&#8358; {item.balance?.toLocaleString()}</div>
      </div>
    ),
    isCard,
  }));
};

const InstantDebit = ({
  cancel,
  loan,
  mutate,
  amountDue,
  dismissModal,
  canAddRepayment,
}) => {
  const [formData, setFormData] = useState({});

  const { trigger } = useSWRMutation("/loan/repay-loan", sendPostRequest);

  const { state, submitStarted, submitError, submitSuccess } = useForm();
  const showMessageModal = useMessageModal();

  const buttonDisabled =
    !formData.account || !formData.amount || !formData.timestamp;

  const bankCards = formatArr(loan.bankCards, "id", true);

  const bankAccts = formatArr(loan.accounts, "accountNumber", false);

  const selectOptions = [...bankCards, ...bankAccts];

  const onChangeAccounts = (selectedOption) => {
    setFormData({
      ...formData,
      account: selectedOption,
    });
  };

  const handleMax = () => {
    setFormData({ ...formData, amount: amountDue });
  };

  const onFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (buttonDisabled || !canAddRepayment) return;

    if (+formData.amount < 50)
      return showMessageModal({
        title: "Error",
        message: "Amount cannot be less than 50",
        isError: true,
        closeButtonText: "Close",
      });

    submitStarted();

    const data = {
      loanID: loan.loanID,
      customerID: loan.customerID,
      amount: +formData.amount,
      ...(formData.account?.isCard && { bankCardID: formData.account.value }),
      ...(!formData.account?.isCard && {
        sourceAccountNumber: formData.account.value,
      }),
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message || "Successfully submitted",
        isError: false,
        closeButtonText: "Close",
      });
      submitSuccess();
      mutate();
      cancel();
      dismissModal();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      submitError();
    }
  };

  return (
    <form onSubmit={submitForm}>
      <section className="flex col gap-8 fs-16 mb-32">
        <label className="flex col gap-4">
          <div>Account</div>
          <Select
            isSearchable={false}
            closeMenuOnSelect={false}
            options={selectOptions}
            styles={colourStyles}
            onChange={onChangeAccounts}
            value={formData.account}
            placeholder="Select Account to Debit "
          />
        </label>
        <label className="flex col gap-4">
          <div>Date of Payment</div>
          <input
            className="mini"
            type="date"
            placeholder="Select Date of Payment"
            name="timestamp"
            value={formData.timestamp}
            onChange={onFormDataChange}
          />
        </label>

        <label className="flex col gap-4 repayment-amount-input">
          <div>Amount (&#8358;)</div>
          <input
            className="mini"
            type="number"
            step="0.01"
            placeholder="Enter Amount"
            name="amount"
            value={formData.amount}
            onChange={onFormDataChange}
          />
          <span className="repayment-amount-input-button" onClick={handleMax}>
            max
          </span>
        </label>
      </section>
      <section className="flex col">
        <OutlineButton disabled={state.submitting || buttonDisabled}>
          {state.submitting ? <FiLoader /> : "Add Loan Repayment"}
        </OutlineButton>
        <button className="transparent btn" type="button" onClick={cancel}>
          Cancel
        </button>
      </section>
    </form>
  );
};

export default InstantDebit;
