import React, { useState } from "react";
import { FiEye, FiEyeOff, FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";

import useForm from "../../../hooks/useForm.hook";
import useToggle from "../../../hooks/useToggle.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";
import { sendPostRequest } from "../../../config/swr";

const LoginForm = ({ fcmToken, loginSuccess, onForgotPasswordClick }) => {
  const { trigger } = useSWRMutation("/login", sendPostRequest);
  const [pwdVisible, togglePasswordVisibility] = useToggle(false);

  const [formData, setFormData] = useState({
    email: "",
    pwd: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const form = useForm();
  const showMessageModal = useMessageModal();

  const buttonDisabled =
    !formData.email || !formData.pwd || form.state.submitting;

  const doLogin = async () => {
    if (buttonDisabled) return;

    form.submitStarted();

    const notificationToken = fcmToken.value();

    // TODO: Use a unique device identifier that can properly distingush this
    // device from other devices. FCM ntfn token does that though.
    const deviceID = notificationToken;

    const data = {
      email: formData.email,
      password: formData.pwd,
      deviceID,
      notificationToken,
    };

    try {
      await trigger(data);
      form.submitSuccess();
      loginSuccess({
        email: formData.email,
        deviceID,
        notificationToken,
      });
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <form className="fullwidth">
      <h1 className="fs-24">Admin Login</h1>

      <div className="login-form-field mb-20">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          name="email"
          placeholder="admin@email.com"
          value={formData.email}
          onChange={onChange}
          disabled={form.state.submitting}
        />
      </div>

      <div className="form-field has-icon">
        <label htmlFor="pwd">Password</label>
        <input
          type={pwdVisible ? "visible" : "password"}
          name="pwd"
          placeholder="••••••••"
          value={formData.pwd}
          onChange={onChange}
          disabled={form.state.submitting}
        />
        <span id="form-field-icon" onClick={togglePasswordVisibility}>
          {pwdVisible ? (
            <FiEyeOff size={16} color={"#ABB3B7"} />
          ) : (
            <FiEye size={16} color={"#ABB3B7"} />
          )}
        </span>
      </div>

      <button
        className="forgot-pwd-btn fs-16 mb-32"
        type="button"
        onClick={onForgotPasswordClick}
      >
        Forgot password?
      </button>

      <button
        className="btn primary fullwidth"
        type="submit"
        onClick={doLogin}
        disabled={buttonDisabled}
      >
        {form.state.submitting ? <FiLoader /> : <>Log In</>}
      </button>
    </form>
  );
};

export default LoginForm;
