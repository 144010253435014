import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import { InputField } from "../../../../components/Input";
import { DelayedActionButton } from "../../../../components/Button";
import { getPermissions, sendPostRequest } from "../../../../config/swr";
import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";

const UPDATE_STOCK_KEY = "update-stock-investments-settings";

const StockInvestmentSettings = ({ stock }) => {
  const permissions = getPermissions();

  const canUpdateStock = permissions[UPDATE_STOCK_KEY];
  const [formData, setFormData] = useState(stock);

  const [brokerData, setBrokerData] = useState({
    name: "",
    url: "",
  });
  const [editBrokerID, setEditBrokerID] = useState(-1);

  const { trigger } = useSWRMutation(
    "/settings/investments/stock",
    sendPostRequest
  );

  const form = useForm();
  const showMessageModal = useMessageModal();

  const resetBrokerData = () => {
    setBrokerData({
      name: "",
      url: "",
    });
    setEditBrokerID(-1);
  };

  const onFormFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onBrokerEdit = (index) => {
    setEditBrokerID(index);
    setBrokerData({
      ...formData.stockBrokers[index],
    });
  };

  const onBrokerRemoved = (index) => {
    resetBrokerData();
    setFormData({
      ...formData,
      stockBrokers: [
        ...formData.stockBrokers.slice(0, index),
        ...formData.stockBrokers.slice(index + 1),
      ],
    });
  };

  const onBrokerDataChange = (e) => {
    const { name, value } = e.target;
    setBrokerData({ ...brokerData, [name]: value });
  };

  const onBrokerDataSubmit = () => {
    if (!brokerData.name || !brokerData.url) return;
    if (editBrokerID < 0) {
      setFormData({
        ...formData,
        stockBrokers: [...formData.stockBrokers, { ...brokerData }],
      });
    } else {
      const newArr = formData.stockBrokers.slice();
      newArr[editBrokerID] = {
        ...brokerData,
      };
      setFormData({
        ...formData,
        stockBrokers: newArr,
      });
    }
    resetBrokerData();
  };

  const handleSubmit = async () => {
    if (!canUpdateStock) return;
    form.submitStarted();

    const data = {
      ...formData,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <>
      <div className="flex gap equal-widths box mb-28">
        <div>
          <InputField
            label="Investment Name"
            type="text"
            placeholder="Stock Investment"
            name="name"
            value={formData.name}
            onChange={onFormFieldChange}
          />
        </div>
        <div>
          <InputField
            label="Package Summary"
            multiline
            placeholder="Enter Package Summary here"
            name="summary"
            value={formData.summary}
            onChange={onFormFieldChange}
          />
        </div>
      </div>

      <hr />

      <div className="flex start gap equal-widths mb-28">
        <div>
          <h2 className="fs-20 mb-16">stock brokers</h2>
          <div className="box">
            {!formData.stockBrokers || formData.stockBrokers?.length === 0 ? (
              <>No Stock Brokers added yet</>
            ) : (
              formData.stockBrokers?.map((broker, i) => (
                <div key={i} className="flex gap center info-row-with-actions">
                  <div className="text">
                    <h3 className="fs-20">{broker.name}</h3>
                    <p className="charcoal-60">{broker.url}</p>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary light"
                      onClick={() => onBrokerEdit(i)}
                    >
                      Edit
                    </button>
                    <DelayedActionButton
                      className="btn danger light"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      isBusy={form.state.submitting}
                      clickAction={() => onBrokerRemoved(i)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">
            {editBrokerID < 0
              ? "Add Stock Broker"
              : "Edit Stock Broker Details"}
          </h2>
          <div className="box">
            <InputField
              label="Stock Broker Name"
              type="text"
              className="mb-16"
              name="name"
              value={brokerData.name}
              onChange={onBrokerDataChange}
            />
            <InputField
              label="Stock Broker URL"
              type="text"
              className="mb-32"
              name="url"
              value={brokerData.url}
              onChange={onBrokerDataChange}
            />
            <button
              className="btn primary light fullwidth"
              onClick={onBrokerDataSubmit}
              disabled={!brokerData.name || !brokerData.url}
            >
              {editBrokerID < 0 ? "Add" : "Update"}
            </button>
          </div>
        </div>
      </div>

      {canUpdateStock && (
        <button
          className="fullwidth dark primary btn"
          onClick={handleSubmit}
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}
    </>
  );
};

export default StockInvestmentSettings;
