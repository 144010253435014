import React, { useState } from "react";
import useSWRMutation from "swr/mutation";

import AccountHolderBox from "../../../components/AccountHolderBox";
import { BackButton, DelayedActionButton } from "../../../components/Button";
import Modal from "../../../components/Modal";
import {
  UploadedDocumentPreview,
  UploadedDocumentSummary,
} from "../../../components/uploaded-document-widgets";
import { getPermissions, sendPatchRequest } from "../../../config/swr";
import DeclineRequestForm from "../DeclineRequestForm";
import useShowRequestApprovalMessage from "../useShowRequestApprovalMessage";
import SendMessage from "../../../components/SendMessage";

const ACCOUNT_UPGRADE_KEY = "update-account-upgrade-request";

const reqBodyFromState = (arr, key) => {
  const obj = arr.find((item) => item.key === key);
  return {
    approve: obj.status === "accepted",
    reject: obj.status === "rejected",
    message: obj.rejectReason,
  };
};

export default function AccountUpgradeDetailsModal({
  req,
  dismissModal,
  mutate,
}) {
  const permissions = getPermissions();

  const canUpgradeAccount = permissions[ACCOUNT_UPGRADE_KEY];
  const [documents, setDocuments] = useState(
    Object.entries(req.uploadedFiles).map(([key, value]) => ({
      ...value,
      key,
    }))
  );
  const { trigger } = useSWRMutation(`/account-upgrade`, sendPatchRequest);
  const [sendMessageModal, setSendMessageModal] = useState(false);

  const hideMessageModal = () => {
    setSendMessageModal(false);
  };

  const [selectedDocIndex, setSelectedDocIndex] = useState(-1);
  const closeDocumentPreview = ({ accepted, rejected, rejectReason }) => {
    if (!accepted && !rejected) {
      setSelectedDocIndex(-1);
      return;
    }

    const docsCopy = documents.slice();
    if (accepted) {
      docsCopy[selectedDocIndex].status = "accepted";
    } else if (rejected) {
      docsCopy[selectedDocIndex].status = "rejected";
      docsCopy[selectedDocIndex].rejectReason = rejectReason;
    }
    setDocuments(docsCopy);
    setSelectedDocIndex(-1);
  };

  const [approving, setApproving] = useState(false);
  const showRequestApprovalMessage = useShowRequestApprovalMessage();
  const approveRequest = async () => {
    if (!canUpgradeAccount) return;
    setApproving(true);

    const payload = {
      customerID: req.customerID,
      uploadedFilesFeedback: {
        "utility-bill": reqBodyFromState(documents, "utility-bill"),
        passport: reqBodyFromState(documents, "passport"),
        "id-card": reqBodyFromState(documents, "id-card"),
      },
      approve: true,
      reject: false,
      message: "",
      dontNotifyCustomer: false,
    };

    try {
      await trigger({
        payload,
      });
      showRequestApprovalMessage({
        requestType: "Account Upgrade",
        customerName: req.customerName,
        error: null,
      });
      setDocuments(null);
      mutate();
      dismissModal();
    } catch (error) {
      showRequestApprovalMessage({
        requestType: "Account Upgrade",
        customerName: req.customerName,
        error:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
      });
    }
    setApproving(false);
  };

  const [declining, setDeclining] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const declineRequest = async (message) => {
    if (!canUpgradeAccount) return;
    setDeclining(true);

    const payload = {
      customerID: req.customerID,
      uploadedFilesFeedback: {
        "utility-bill": reqBodyFromState(documents, "utility-bill"),
        passport: reqBodyFromState(documents, "passport"),
        "id-card": reqBodyFromState(documents, "id-card"),
      },
      approve: false,
      reject: true,
      message,
      dontNotifyCustomer: false,
    };

    try {
      await trigger({
        payload,
      });
      setDocuments(null);
      mutate();
      dismissModal();
      return { error: null, customerName: req.customerName };
    } catch (error) {
      return {
        error:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
      };
    } finally {
      setDeclining(false);
    }
  };

  if (sendMessageModal)
    return (
      <Modal
        rightSheet
        hideCancelButton
        className="account-holder-with-info-modal"
        handleClose={hideMessageModal}
      >
        <SendMessage
          accountName={req.customerName}
          accountNumber={
            req.accountNumbers &&
            (req.accountNumbers.length > 1
              ? req.accountNumbers.join(", ")
              : req.accountNumbers[0])
          }
          customerID={req.customerID}
          profileImageUrl={req.customerProfileImageUrl}
          handleClose={hideMessageModal}
        />
      </Modal>
    );

  return (
    <Modal rightSheet hideCancelButton handleClose={dismissModal}>
      <div className="modal-header-with-back-btn flex start center gap-8 mb-16">
        {selectedDocIndex < 0 ? (
          <BackButton onClick={dismissModal}>
            <h3 className="fs-20">Account Upgrade</h3>
          </BackButton>
        ) : (
          <BackButton onClick={closeDocumentPreview}>
            <h3 className="fs-20">Uploaded Document</h3>
          </BackButton>
        )}
      </div>

      {selectedDocIndex < 0 ? (
        <>
          <AccountHolderBox
            accountName={req.customerName}
            accountNumber={
              req.accountNumbers &&
              (req.accountNumbers.length > 1
                ? req.accountNumbers.join(", ")
                : req.accountNumbers[0])
            }
            profileImageUrl={req.customerProfileImageUrl}
            setSendMessageModal={setSendMessageModal}
          />

          {documents.map((document, index) => (
            <UploadedDocumentSummary
              key={index}
              document={document}
              timestamp={document.uploadTimestamp}
              onClick={() => setSelectedDocIndex(index)}
            />
          ))}

          <div
            className="flex col space-between gap-12"
            style={{ padding: "1.5rem 1rem" }}
          >
            <div className="flex gap-8 equal-widths">
              <span className="charcoal-60 fs-18">NIN</span>
              <h3 className="fs-18" style={{ textAlign: "right" }}>
                {req.customerNIN}
              </h3>
            </div>

            <div className="flex gap-8 equal-widths">
              <span className="charcoal-60 fs-18">Address</span>
              <h3 className="fs-18" style={{ textAlign: "right" }}>
                {req.customerAddress}
              </h3>
            </div>

            <div className="flex gap-8 equal-widths">
              <span className="charcoal-60 fs-18">Next of Kin</span>
              <h3 className="fs-18" style={{ textAlign: "right" }}>
                {req.nextOfKinName}
              </h3>
            </div>

            <div className="flex gap-8 equal-widths">
              <span className="charcoal-60 fs-18">Next of Kin Phone</span>
              <h3 className="fs-18" style={{ textAlign: "right" }}>
                {req.nextOfKinPhoneNumber}
              </h3>
            </div>

            <div className="flex gap-8 equal-widths">
              <span className="charcoal-60 fs-18">Next of Kin Address</span>
              <h3 className="fs-18" style={{ textAlign: "right" }}>
                {req.nextOfKinAddress}
              </h3>
            </div>

            <div className="flex gap-8 equal-widths">
              <span className="charcoal-60 fs-18">
                Next of Kin Relationship{" "}
              </span>
              <h3 className="fs-18" style={{ textAlign: "right" }}>
                {req.nextOfKinRelationship}
              </h3>
            </div>
          </div>

          {canUpgradeAccount && (
            <div
              style={{ padding: "3rem 1rem" }}
              className="flex gap equal-widths fs-16"
            >
              <button
                onClick={() => setShowDeclineModal(true)}
                disabled={showDeclineModal || approving}
                className="btn outline danger"
              >
                Decline
              </button>

              <DelayedActionButton
                className="btn outline"
                text="Approve"
                confirmText="Confirm Approval"
                disabled={
                  showDeclineModal ||
                  approving ||
                  documents.some((doc) => doc.status !== "accepted")
                }
                isBusy={approving}
                clickAction={approveRequest}
              />
            </div>
          )}
        </>
      ) : (
        <UploadedDocumentPreview
          document={documents[selectedDocIndex]}
          closePreview={closeDocumentPreview}
          hasPermissions={canUpgradeAccount}
        />
      )}

      {showDeclineModal && (
        <Modal
          className="modal-heading-subheading-buttons"
          handleClose={declining ? null : () => setShowDeclineModal(false)}
        >
          <DeclineRequestForm
            requestType="Account Upgrade"
            declineRequest={declineRequest}
            done={() => setShowDeclineModal(false)}
          />
        </Modal>
      )}
    </Modal>
  );
}
