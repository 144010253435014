const NotificationIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="100" rx="50" fill="#C4E3D5" />
      <path
        d="M30.5528 64.064L39.9368 54.68L46.7264 61.4672L57.6968 50.4992L62 54.8V42.8H50L54.3032 47.1032L46.7264 54.68L39.9392 47.8904L28.0688 59.7608C26.7006 56.6888 25.9957 53.3629 26 50C26 36.7448 36.7448 26 50 26C63.2552 26 74 36.7448 74 50C74 63.2552 63.2552 74 50 74C46.2032 74.0025 42.4601 73.103 39.0791 71.3755C35.698 69.648 32.7757 67.1421 30.5528 64.064Z"
        fill="#1F613E"
      />
    </svg>
  );
};

export default NotificationIcon;
