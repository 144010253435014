import React from "react";
import { useOutletContext, useParams, } from "react-router";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import DataFetcher from "../../components/DataFetcher";
import { SearchFilterContainer } from "../../components/SearchFilterTable";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const AdminLogsPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Activity Log", "/admin-users");
  const { id } = useParams();

  return (
    <DataFetcher
      url={`/activity-logs?adminID=${id}`}
      buildUI={(data) => <AdminLogsDisplay logs={data} />}
    />
  );
};

const AdminLogsDisplay = ({ logs }) => {
  const refetchLogs = ({ sort, search }) => {
    // TODO
  };

  return (
    <div className="admin-logs">
      {!logs || logs.length === 0 ? (
        <div className="flex center box fs-20">
          There is currently no logs for this admin
        </div>
      ) : (
        <SearchFilterContainer
          searchHint="Search logs"
          sortOptions={["Action", "Date"]}
          reloadTable={refetchLogs}
        >
          {logs.map((log, i) => (
            <React.Fragment key={i}>
              <div className="log-card mb-12">
                <div>
                  <h3>{log.title}</h3>
                  <p>{log.description}</p>
                </div>
                <span className="fs-14">
                  {dayjs(log.timestamp * 1000).utc().format("dddd, MMM DD, YYYY")}
                  &nbsp; &nbsp;{dayjs(log.timestamp * 1000).utc().format("hh:mm a")}
                </span>
              </div>
              {i < logs.length - 1 && <hr className="mb-12" />}
            </React.Fragment>
          ))}
        </SearchFilterContainer>
      )}
    </div>
  );
};

export default AdminLogsPage;
