import { useState } from "react";
import Select from "react-select";

import { DelayedActionButton } from "../../../../components/Button";
import Modal from "../../../../components/Modal";

import useMessageModal from "../../../../hooks/useMessageModal.hook";

import { splitDropdownStr } from "../../../../utils/helpers";

import {
  ADD_DROPDOWN,
  ADD_FIELD,
  EDIT_DROPDOWN,
  EDIT_FIELD,
} from "./partnerReducer";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    border: "1px solid #9fa4a7",
    borderRadius: "12px",
    padding: "10px 8px",
    width: "100%",
    "&:hover": {
      border: "1px solid #9fa4a7",
    },
  }),
};

const options = [
  {
    value: "string",
    label: "String",
  },
  {
    value: "date",
    label: "Date",
  },
  {
    value: "number",
    label: "Number",
  },
  {
    value: "email",
    label: "Email",
  },
  {
    value: "amount",
    label: "Amount",
  },
  {
    value: "telephone",
    label: "Telephone",
  },
  {
    value: "gender",
    label: "Gender",
  },
  {
    value: "loanamount",
    label: "Loan Amount",
  },
  {
    value: "dropdown",
    label: "Dropdown",
  },
  {
    value: "file(png,jpeg,jpg)",
    label: "File (png,jpeg,jpg)",
  },
];

const setSelectedType = (type) => {
  if (!type) return "";
  if (type.includes("dropdown"))
    return options.find((item) => item.value === "dropdown");

  return options.find((item) => item.value === type);
};

const getFieldValues = (str, sectionName, fieldName, restrictionsArr) => {
  const values = splitDropdownStr(str);

  const obj = restrictionsArr.find(
    (item) => item.restrictionFormFieldID === `${sectionName}.${fieldName}`
  );

  return values.map((item) => ({
    title: item,
    value: obj ? obj?.restrictions?.[item] : "",
  }));
};

const hasValues = (str, sectionName, fieldName, restrictionsArr) => {
  const valuesArr = getFieldValues(
    str,
    sectionName,
    fieldName,
    restrictionsArr
  );

  return valuesArr.some((item) => item.value);
};

const setFieldValues = (arr) => {
  if (!arr) return "";
  const newArr = arr.map((item) => item.title);
  const commaValues = newArr.join(", ");
  const dropdownStr = `dropdown(${commaValues})`;
  return dropdownStr;
};

const createRestrictionsObj = (arr) =>
  arr.reduce((o, { title, value }) => ({ ...o, [title]: value }), {});

const setLoanRestrictions = (arr, sectionName, fieldName) => {
  if (!arr) return "";
  return {
    restrictionFormFieldID: `${sectionName}.${fieldName}`,
    restrictions: createRestrictionsObj(arr),
  };
};

const FieldCreationModal = ({
  dismissModal,
  fieldData,
  dispatch,
  section,
  dropdownArr,
}) => {
  const showMessageModal = useMessageModal();
  const [formData, setFormData] = useState({
    ...fieldData,
    type: setSelectedType(fieldData.type),
    fieldValues: getFieldValues(
      fieldData.type,
      section.name,
      fieldData.name,
      dropdownArr
    ),
    restrictedField: hasValues(
      fieldData.type,
      section.name,
      fieldData.name,
      dropdownArr
    ),
  });
  const [dropdownFieldData, setDropdownFieldData] = useState({
    title: "",
    value: "",
  });
  const [editDropdownFieldID, setEditDropdownFieldID] = useState(-1);

  const btnDisabled = !formData.type?.value || !formData.name;
  const addBtnDisabled = !dropdownFieldData.title;

  const dropdownFieldDataFromForm = () => {
    return {
      title: dropdownFieldData.title?.trim(),
      ...(formData.restrictedField && { value: +dropdownFieldData.value }),
    };
  };

  const resetDropdownFieldData = () => {
    setEditDropdownFieldID(-1);
    setDropdownFieldData({
      title: "",
      value: "",
    });
  };

  const onDropdownFieldFormChange = (e) => {
    const { name, value } = e.target;
    setDropdownFieldData({ ...dropdownFieldData, [name]: value });
  };

  const onDropdownFieldEdit = (index) => {
    setEditDropdownFieldID(index);
    setDropdownFieldData(formData.fieldValues[index]);
  };

  const onDropdownFieldRemoved = (index) => {
    resetDropdownFieldData();
    setFormData({
      ...formData,
      fieldValues: [
        ...formData.fieldValues.slice(0, index),
        ...formData.fieldValues.slice(index + 1),
      ],
    });
  };

  const onDropdownFieldDataSubmit = () => {
    if (formData.restrictedField && !dropdownFieldData.value)
      return showMessageModal({
        title: "Error",
        message: "You must enter a title and value",
        isError: true,
        closeButtonText: "Close",
      });

    if (editDropdownFieldID < 0) {
      setFormData({
        ...formData,
        fieldValues: [...formData.fieldValues, dropdownFieldDataFromForm()],
      });
    } else {
      const newArr = formData.fieldValues.slice();
      newArr[editDropdownFieldID] = dropdownFieldDataFromForm();
      setFormData({
        ...formData,
        fieldValues: newArr,
      });
    }
    resetDropdownFieldData();
  };

  const handleFieldChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const selectOptions = options?.map((item) => ({
    value: item.value,
    label: item.label,
  }));

  const onChangeField = (selectedOption) => {
    setFormData({ ...formData, type: setSelectedType(selectedOption.value) });
  };

  const fieldDataFromForm = () => {
    const { editing, fieldValues, type, ...rest } = formData;
    if (type.value === "dropdown") {
      return {
        ...rest,
        type: setFieldValues(fieldValues),
        restrictions: createRestrictionsObj(fieldValues),
      };
    }
    return { ...rest, type: type.value };
  };

  const handleSubmit = () => {
    if (btnDisabled) return;

    const nameExists =
      !formData.editing &&
      section.fields?.find(
        (item) =>
          item.name?.trim()?.toLowerCase() ===
          formData.name?.trim()?.toLowerCase()
      );

    if (nameExists) {
      return showMessageModal({
        title: "Error",
        message: "Field already exists",
        isError: true,
        closeButtonText: "Close",
      });
    }

    if (
      formData.type?.value?.includes("dropdown") &&
      formData.fieldValues?.length === 0
    )
      return showMessageModal({
        title: "Error",
        message: "Dropdown type must have at least one field",
        isError: true,
        closeButtonText: "Close",
      });

    dispatch({
      type: formData.editing ? EDIT_FIELD : ADD_FIELD,
      payload: {
        sectionName: section.name,
        fieldName: formData.editing ? fieldData.name : null,
        item: fieldDataFromForm(),
      },
    });
    if (formData.type?.value?.includes("dropdown"))
      dispatch({
        type: formData.editing ? EDIT_DROPDOWN : ADD_DROPDOWN,
        payload: {
          item: setLoanRestrictions(
            formData.fieldValues,
            section.name,
            formData.name
          ),
          name: formData.editing ? `${section.name}.${fieldData.name}` : null,
        },
      });
    dismissModal();
  };

  return (
    <Modal
      handleClose={dismissModal}
      className="modal-heading-subheading-buttons"
    >
      <h3 className="fs-24 mb-32 center-text">
        {formData.editing ? "Edit an existing field" : "Add a new Field"}
      </h3>
      <div className="box">
        <label htmlFor="name">Field Name</label>
        <input
          id="name"
          name="name"
          type="text"
          className="mb-16"
          placeholder="Enter Title"
          onChange={handleFieldChange}
          value={formData.name}
        />
        <div className="mb-16">
          <label htmlFor="type">Type</label>
          <Select
            inputId={"type"}
            placeholder="Select"
            options={selectOptions}
            styles={colourStyles}
            onChange={onChangeField}
            value={formData.type}
          />
        </div>

        {formData.type?.value?.includes("dropdown") && (
          <div className="mb-16">
            <div className="flex equal-widths">
              <label>DropDown Fields</label>
              <div className="flex gap-4 center end loan-checkbox">
                <label htmlFor="restrictedField">Restricted Field</label>
                &nbsp;
                <input
                  className="mb-8"
                  type="checkbox"
                  id="restrictedField"
                  name="restrictedField"
                  onChange={handleFieldChange}
                  checked={formData.restrictedField}
                />
              </div>
            </div>

            <div className="flex col gap-8 mb-8">
              <div className="flex gap-8 center equal-widths">
                <div>
                  <input
                    className="field-modal-input"
                    id="title"
                    name="title"
                    type="text"
                    placeholder="Enter Field Title"
                    value={dropdownFieldData.title}
                    onChange={onDropdownFieldFormChange}
                  />
                </div>
                {formData.restrictedField && (
                  <div>
                    <input
                      className="field-modal-input"
                      id="value"
                      name="value"
                      type="text"
                      placeholder="Enter Field Value"
                      value={dropdownFieldData.value}
                      onChange={onDropdownFieldFormChange}
                    />
                  </div>
                )}
              </div>
              <div className="flex center">
                <button
                  className="btn primary light"
                  disabled={addBtnDisabled}
                  onClick={onDropdownFieldDataSubmit}
                >
                  {editDropdownFieldID < 0 ? "Add" : "Update"} Field
                </button>
              </div>
            </div>

            <div className="flex col gap-8">
              {formData.fieldValues?.map((item, i) => (
                <div key={i} className="flex gap between-center equal-widths">
                  <div className="text flex gap between-center">
                    <h3 className="fs-20">
                      {item?.title}&nbsp;
                      {item?.value && (
                        <>-&nbsp;{item?.value?.toLocaleString()}</>
                      )}
                    </h3>
                  </div>

                  <div className="flex gap end">
                    <button onClick={() => onDropdownFieldEdit(i)}>Edit</button>
                    <DelayedActionButton
                      className="tart-orange"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      clickAction={() => onDropdownFieldRemoved(i)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <label
          className="flex center gap-4 loan-checkbox mb-32"
          id="optional"
          style={{ justifyContent: "space-between" }}
        >
          <div className="fs-18">Optional</div>
          <input
            type="checkbox"
            id="optional"
            name="optional"
            onChange={handleFieldChange}
            checked={formData.optional}
          />
        </label>

        <button
          className="btn primary light fullwidth"
          onClick={handleSubmit}
          disabled={btnDisabled}
        >
          {formData.editing ? "Update" : "Add New"}
        </button>
      </div>
    </Modal>
  );
};

export default FieldCreationModal;
