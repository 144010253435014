export default function SuccessIcon() {
  return (
    <svg
      width="240"
      height="241"
      viewBox="0 0 240 241"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="217.088"
        cy="37.5366"
        rx="13.2664"
        ry="13.439"
        fill="url(#paint0_linear_5698_54426)"
      />
      <ellipse
        cx="148.561"
        cy="17.2258"
        rx="6.03016"
        ry="6.10863"
        fill="url(#paint1_linear_5698_54426)"
      />
      <ellipse
        cx="44.6247"
        cy="15.1607"
        rx="14.4724"
        ry="14.6607"
        fill="url(#paint2_linear_5698_54426)"
      />
      <ellipse
        cx="220.705"
        cy="201.246"
        rx="9.64826"
        ry="9.77381"
        fill="url(#paint3_linear_5698_54426)"
      />
      <ellipse
        cx="6.03016"
        cy="134.062"
        rx="6.03016"
        ry="6.10863"
        fill="url(#paint4_linear_5698_54426)"
      />
      <ellipse
        cx="233.97"
        cy="114.511"
        rx="6.03016"
        ry="6.10863"
        fill="url(#paint5_linear_5698_54426)"
      />
      <ellipse
        cx="55.7"
        cy="230.727"
        rx="9.64826"
        ry="9.77381"
        fill="url(#paint6_linear_5698_54426)"
      />
      <ellipse
        cx="126.029"
        cy="128.561"
        rx="96.8039"
        ry="97.1272"
        fill="url(#paint7_linear_5698_54426)"
      />
      <path
        d="M113.955 141.213L98.5149 125.721L97.4562 124.659L96.3938 125.717L90.7743 131.316L89.7115 132.375L90.7706 133.437L112.892 155.633L113.955 156.699L115.017 155.633L162.506 107.986L163.561 106.927L162.506 105.868L156.926 100.269L155.864 99.2038L154.802 100.269L113.955 141.213Z"
        fill="white"
        stroke="white"
        strokeWidth="3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5698_54426"
          x1="217.088"
          y1="24.0977"
          x2="217.088"
          y2="50.9756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009B54" />
          <stop offset="1" stopColor="#19CF7C" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5698_54426"
          x1="148.561"
          y1="11.1172"
          x2="148.561"
          y2="23.3344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009B54" />
          <stop offset="1" stopColor="#19CF7C" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5698_54426"
          x1="44.6247"
          y1="0.5"
          x2="44.6247"
          y2="29.8214"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009B54" />
          <stop offset="1" stopColor="#19CF7C" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5698_54426"
          x1="220.705"
          y1="191.473"
          x2="220.705"
          y2="211.02"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009B54" />
          <stop offset="1" stopColor="#19CF7C" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5698_54426"
          x1="6.03016"
          y1="127.953"
          x2="6.03016"
          y2="140.17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009B54" />
          <stop offset="1" stopColor="#19CF7C" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5698_54426"
          x1="233.97"
          y1="108.402"
          x2="233.97"
          y2="120.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009B54" />
          <stop offset="1" stopColor="#19CF7C" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5698_54426"
          x1="55.7"
          y1="220.953"
          x2="55.7"
          y2="240.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009B54" />
          <stop offset="1" stopColor="#19CF7C" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5698_54426"
          x1="98.0233"
          y1="44.8726"
          x2="126.212"
          y2="225.659"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F613E" />
          <stop offset="1" stopColor="#1FD373" />
        </linearGradient>
      </defs>
    </svg>
  );
}
