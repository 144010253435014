import { useState } from "react";
import { FiLoader } from "react-icons/fi";
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from "react-icons/hi";
import useSWRMutation from "swr/mutation";

import { DelayedActionButton } from "../../../../components/Button";
import { InputField } from "../../../../components/Input";

import { getPermissions, sendPostRequest } from "../../../../config/swr";
import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";

const UPDATE_GENERAL_KEY = "update-general-loan-settings";

const IntegerState = (initialValue, minValue, maxValue) => {
  minValue = minValue ?? 0;
  const [value, setValue] = useState(initialValue);
  const setValueSafely = (v) => {
    if (v < minValue) return;
    if (maxValue && v > maxValue) return;
    setValue(v);
  };

  return {
    value,
    increment: () => setValueSafely(value + 1),
    decrement: () => setValueSafely(value - 1),
  };
};

const IntegerField = ({ state }) => {
  return (
    <div className="integer-field">
      <button onClick={state.decrement}>
        <HiOutlineMinusCircle size={24} color="#3D5059" />
      </button>
      <span className="fs-32">{state.value}</span>
      <button onClick={state.increment}>
        <HiOutlinePlusCircle size={24} color="#3D5059" />
      </button>
    </div>
  );
};

const GeneralLoanTab = ({ generalLoanSettings, mutate }) => {
  const { trigger } = useSWRMutation("/settings/loan/general", sendPostRequest);
  const form = useForm();
  const [formData, setFormData] = useState(generalLoanSettings);
  const [editCompanyID, setEditCompanyID] = useState(-1);
  const [companyData, setCompanyData] = useState({
    name: "",
    domain: "",
    dailyInterestRate: "",
    reqNumberOfCards: "",
    skipStatement: false,
  });
  const [emailBlacklistURL, setEmailBlacklistURL] = useState("");
  const [editEmailBlacklistID, setEditEmailBlacklistID] = useState(-1);

  const companyBtnDisabled =
    !companyData.name ||
    !companyData.domain ||
    companyData.dailyInterestRate === "" ||
    companyData.reqNumberOfCards === "";

  const permissions = getPermissions();
  const showMessageModal = useMessageModal();

  const canUpdateGeneral = permissions[UPDATE_GENERAL_KEY];
  const nCards = IntegerState(generalLoanSettings?.numberOfCards);
  const statementMonths = IntegerState(
    generalLoanSettings?.bankStatementMonths
  );
  const cardExp = IntegerState(generalLoanSettings?.minimumMonthsToCardExpiry);
  const salaryPercent = IntegerState(
    generalLoanSettings?.maximumSalaryPercent,
    0,
    100
  );
  const minLoans = IntegerState(generalLoanSettings?.completedLoansThreshold);

  const resetCompanyData = () => {
    setCompanyData({
      name: "",
      domain: "",
      dailyInterestRate: "",
      reqNumberOfCards: "",
      skipStatement: false,
    });
    setEditCompanyID(-1);
  };

  const formatCompanyData = () => {
    return {
      ...companyData,
      dailyInterestRate: +companyData.dailyInterestRate,
      reqNumberOfCards: +companyData.reqNumberOfCards,
    };
  };

  const onCompanyEdit = (index) => {
    setEditCompanyID(index);
    setCompanyData({
      ...formData.exemptedLoanEmailDomains[index],
    });
  };

  const onCompanyRemove = (index) => {
    resetCompanyData();
    setFormData({
      ...formData,
      exemptedLoanEmailDomains: [
        ...formData.exemptedLoanEmailDomains.slice(0, index),
        ...formData.exemptedLoanEmailDomains.slice(index + 1),
      ],
    });
  };

  const onCompanyChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      return setCompanyData({ ...companyData, [name]: checked });
    }
    setCompanyData({ ...companyData, [name]: value });
  };

  const onCompanySubmit = () => {
    if (companyBtnDisabled) return;
    if (editCompanyID < 0) {
      setFormData({
        ...formData,
        exemptedLoanEmailDomains:
          formData.exemptedLoanEmailDomains?.length > 0
            ? [...formData.exemptedLoanEmailDomains, formatCompanyData()]
            : [formatCompanyData()],
      });
    } else {
      const newArr = formData.exemptedLoanEmailDomains?.slice() ?? [];
      newArr[editCompanyID] = formatCompanyData();
      setFormData({
        ...formData,
        exemptedLoanEmailDomains: newArr,
      });
    }
    resetCompanyData();
  };

  const resetEmailBlacklistData = () => {
    setEmailBlacklistURL("");
    setEditEmailBlacklistID(-1);
  };

  const onEmailBlacklistEdit = (index) => {
    setEditEmailBlacklistID(index);
    setEmailBlacklistURL(formData.emailDomainBlacklist[index]);
  };

  const onEmailBlacklistChange = (e) => {
    setEmailBlacklistURL(e.target.value);
  };

  const onEmailBlacklistRemoved = (index) => {
    resetEmailBlacklistData();
    setFormData({
      ...formData,
      emailDomainBlacklist: [
        ...formData.emailDomainBlacklist.slice(0, index),
        ...formData.emailDomainBlacklist.slice(index + 1),
      ],
    });
  };

  const onEmailBlacklistSubmit = () => {
    if (!emailBlacklistURL) return;
    if (editEmailBlacklistID < 0) {
      setFormData({
        ...formData,
        emailDomainBlacklist: [
          ...(formData.emailDomainBlacklist || []),
          emailBlacklistURL,
        ],
      });
    } else {
      const newArr = [...formData.emailDomainBlacklist];
      newArr[editEmailBlacklistID] = emailBlacklistURL;
      setFormData({
        ...formData,
        emailDomainBlacklist: newArr,
      });
    }
    resetEmailBlacklistData();
  };

  const handleSubmit = async () => {
    if (!canUpdateGeneral) return;
    form.submitStarted();

    const data = {
      numberOfCards: nCards.value,
      bankStatementMonths: statementMonths.value,
      minimumMonthsToCardExpiry: cardExp.value,
      maximumSalaryPercent: salaryPercent.value,
      completedLoansThreshold: minLoans.value,
      exemptedLoanEmailDomains: formData.exemptedLoanEmailDomains,
      emailDomainBlacklist: formData.emailDomainBlacklist,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
      mutate();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          error?.message ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div>
      <div className="flex start center box gls">
        <div className="flex col center item">
          <label htmlFor="">Required Number of Cards</label>
          <IntegerField state={nCards} />
        </div>

        <div className="flex col center item">
          <label htmlFor="">Bank Statement Duration (months)</label>
          <IntegerField state={statementMonths} />
        </div>

        <div className="flex col center item">
          <label htmlFor="">Min. Months to Card Expiry </label>
          <IntegerField state={cardExp} />
        </div>

        <div className="flex col center item">
          <label htmlFor="">Max. Loan Salary Percentage (%)</label>
          <IntegerField state={salaryPercent} />
        </div>

        <div className="flex col center item">
          <label htmlFor="">Min. Completed Loans Limit</label>
          <IntegerField state={minLoans} />
        </div>
      </div>

      <hr />

      <div className="flex start gap-20 equal-widths mb-28">
        <div className="group-item">
          <h2 className="fs-20 mb-16">Exempted Companies</h2>

          <div className="box group-item mb-16">
            {!formData.exemptedLoanEmailDomains ||
            formData.exemptedLoanEmailDomains?.length === 0 ? (
              <>No Exemptions added yet</>
            ) : (
              formData.exemptedLoanEmailDomains?.map((company, i) => (
                <div
                  key={company.name}
                  className="flex gap-20 center info-row-with-actions"
                >
                  <div className="text">
                    <h3 className="fs-20">
                      {company.name} ({company.dailyInterestRate}%)
                    </h3>
                    <p className="charcoal-60">{company.domain}</p>
                    <p className="charcoal-60">
                      No of cards: {company.reqNumberOfCards}
                    </p>
                    <p className="charcoal-60">
                      Skip statement: {`${company.skipStatement}`}
                    </p>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary light"
                      onClick={() => onCompanyEdit(i)}
                    >
                      Edit
                    </button>
                    <DelayedActionButton
                      className="btn danger light"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      isBusy={form.state.submitting}
                      clickAction={() => onCompanyRemove(i)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">
            {editCompanyID < 0 ? "Add" : "Edit"} Company
          </h2>
          <div className="box">
            <InputField
              label="Company Name"
              placeholder="Enter Company Name"
              className="mb-16"
              name="name"
              value={companyData.name}
              onChange={onCompanyChange}
            />
            <InputField
              label="Company Domain"
              placeholder="Enter Company Domain"
              className="mb-16"
              name="domain"
              value={companyData.domain}
              onChange={onCompanyChange}
            />
            <InputField
              type="number"
              label="Daily Interest Rate"
              placeholder="Enter Daily Interest Rate"
              className="mb-16"
              name="dailyInterestRate"
              value={companyData.dailyInterestRate}
              onChange={onCompanyChange}
            />
            <InputField
              type="number"
              label="Number of Cards"
              placeholder="Enter Number of Cards"
              className="mb-16"
              name="reqNumberOfCards"
              value={companyData.reqNumberOfCards}
              onChange={onCompanyChange}
            />
            <label
              className="flex start center gap-4 loan-checkbox mb-32"
              id="skipStatement"
            >
              <input
                type="checkbox"
                id="skipStatement"
                name="skipStatement"
                onChange={onCompanyChange}
                checked={companyData.skipStatement}
              />
              <div className="fs-18">Skip Statement</div>
            </label>
            <button
              className="btn primary light fullwidth"
              onClick={onCompanySubmit}
              disabled={companyBtnDisabled}
            >
              {editCompanyID < 0 ? "Add" : "Update"}
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div className="flex start gap-20 equal-widths mb-28">
        <div>
          <h2 className="fs-20 mb-16">email blacklist</h2>
          <div className="box group-item">
            {!formData.emailDomainBlacklist ||
            formData.emailDomainBlacklist.length === 0 ? (
              <>No Email Blacklists added yet</>
            ) : (
              formData.emailDomainBlacklist.map((url, index) => (
                <div key={index} className="mb-16">
                  <div className="flex gap center info-row-with-actions">
                    <div className="text">
                      <p className="charcoal-60">{url}</p>
                    </div>

                    <div className="actions">
                      <button
                        className="btn primary light"
                        onClick={() => onEmailBlacklistEdit(index)}
                      >
                        Edit
                      </button>
                      <DelayedActionButton
                        className="btn danger light"
                        text="Remove"
                        confirmText="Confirm"
                        busyText="Remove"
                        isBusy={form.state.submitting}
                        clickAction={() => onEmailBlacklistRemoved(index)}
                      />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">
            {editEmailBlacklistID < 0
              ? "Add Email Blacklist URL"
              : "Edit Email Blacklist URL"}
          </h2>
          <div className="box group-item">
            <div>
              <InputField
                label="Email Blacklist URL"
                type="text"
                className="mb-16"
                name="url"
                value={emailBlacklistURL}
                onChange={onEmailBlacklistChange}
              />
              <button
                className="btn primary light fullwidth"
                onClick={onEmailBlacklistSubmit}
                disabled={!emailBlacklistURL}
              >
                {editEmailBlacklistID < 0 ? "Add" : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {canUpdateGeneral && (
        <button
          className="fullwidth dark primary btn"
          onClick={handleSubmit}
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}
      {form.state.submitting && <div className="blockade"></div>}
    </div>
  );
};

export default GeneralLoanTab;
