import React, { useState } from "react";
import { OutsideClickAlerter } from "./OutsideClickAlerter";

export const InputField = ({
  label,
  className,
  extraChildren,
  multiline,
  ...props
}) => {
  return (
    <div className={className ?? "fs-16"}>
      {label ? <label htmlFor={props.id}>{label}</label> : null}
      {extraChildren ? (
        <div style={{ marginBottom: "8px" }}>{extraChildren}</div>
      ) : null}
      {multiline ? <textarea {...props}></textarea> : <input {...props} />}
    </div>
  );
};

export function SearchBox({
  searchDelay,
  handleSearch,
  handleSearchResultClick,
  ...props
}) {
  const [searchQuery, setSearchQuery] = useState({
    value: "",
    callbackTimeout: undefined,
  });
  const [seachResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const handleChange = (e) => {
    clearTimeout(searchQuery.callbackTimeout);

    const callbackTimeout = setTimeout(
      async (query) => {
        const results = (await handleSearch(query)) ?? [];
        setSearchResults(results);
        setShowResults(results.length > 0);
      },
      searchDelay ?? 1000,
      e.target.value
    );

    setSearchQuery({
      value: e.target.value,
      callbackTimeout: callbackTimeout,
    });
  };

  const handleResultClick = (item) => {
    setShowResults(false);
    setSearchQuery(item);
    if (handleSearchResultClick) {
      handleSearchResultClick();
    }
  };

  return (
    <OutsideClickAlerter
      className="searchbox__container fs-20"
      handleOnClickOutside={() => setShowResults(false)}
    >
      <input
        type="text"
        {...props}
        value={searchQuery.value}
        onChange={handleChange}
      />

      {showResults ? (
        <div className="in-place-popup box-shadow">
          {seachResults.map((result, index) => (
            <p
              key={index}
              className="clickable"
              onClick={() => handleResultClick(result, index)}
            >
              {result}
            </p>
          ))}
        </div>
      ) : null}
    </OutsideClickAlerter>
  );
}
