import Modal from "../../../../components/Modal";

const DeletePartnerModal = ({ dismissModal, partner }) => {
  return (
    <Modal
      handleClose={dismissModal}
      className="modal-heading-subheading-buttons"
    >
      <h1 className="fs-24">Confirm Delete</h1>
      <p className="mb-32">Are you sure you want to delete <span className="fw-600">{partner.name}</span>?</p>
      <div className="actions">
        <button className="btn primary fs-22" onClick={dismissModal}>
          No
        </button>
        <button className="btn danger outline fs-22">Yes, Delete</button>
      </div>
    </Modal>
  );
};

export default DeletePartnerModal;
