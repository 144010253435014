import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

function PendingLoanList({ loans, activeStatus, displayLoanDetails }) {
  return (
    <section>
      <ul className="box loan">
        {!loans || loans.length === 0 ? (
          <p>No loans {activeStatus}</p>
        ) : (
          loans.map((loan, index) => (
            <li
              key={index}
              className="flex center gap info-row-with-actions loan"
              style={{ borderColor: "#D7D7D8" }}
            >
              <div className="text">
                <h3 className="fs-20">
                  {loan.customerName}
                  <span className="btn primary light fs-14">
                    {`${loan.durationInDays} days`}
                  </span>
                </h3>
                <p>
                  <span className="charcoal-60">
                    {loan.accounts[0]?.accountNumber}
                  </span>
                  <span className="fw-600">
                    &#8226; &#8358; {loan.loanAmount?.toLocaleString()}
                  </span>
                </p>
                <p className="charcoal-60">
                  {dayjs(loan.requestedTimestamp * 1000)
                    .utc()
                    .format("D - MMM - YYYY")}
                  &nbsp;&bull;&nbsp;
                  {dayjs(loan.requestedTimestamp * 1000)
                    .utc()
                    .format("hh:mm")}
                </p>
              </div>

              <div className="flex gap-8 ">
                {loan.approvedBy && (
                  <button className="approved-button">
                    Approved by:&nbsp;
                    <span className="fw-600">{loan.approvedBy}</span>
                  </button>
                )}
                <button
                  className="btn primary light"
                  onClick={() => displayLoanDetails(index)}
                >
                  View details
                </button>
              </div>
            </li>
          ))
        )}
      </ul>
    </section>
  );
}

export default PendingLoanList;
