import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import { sendGetRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";
import { PrimaryButton } from "../../../components/Button";

export default function ValidateCustomerInfoForm({ onSuccess, setFetchingData }) {
  const { trigger } = useSWRMutation(`/customer-info?`, sendGetRequest);

  const form = useForm({
    accountNumber: "",
  });

  const showMessageModal = useMessageModal();

  const submitValidateInfoForm = async (e) => {
    e.preventDefault();
    const { accountNumber } = form.data;
    setFetchingData(true);
    form.submitStarted();

    try {
      const res = await trigger(`loginID=${accountNumber}`);
      form.submitSuccess();
      onSuccess({ customerInfo: res.data, ...form.data });
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }

    setFetchingData(false);
  };

  return (
    <>
      <b className="fs-24 mb-20">Enter Customer Info</b>
      <form onSubmit={submitValidateInfoForm}>
        <div className="mb-12">
          <label>Account Number/Phone No/BVN</label>
          <input
            placeholder="002xxxxxxxxxxxx"
            {...form.fieldProps("accountNumber")}
          />
        </div>

        <PrimaryButton
          className="fullwidth"
          disabled={!form.data.accountNumber || form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Validate"}
        </PrimaryButton>
      </form>
    </>
  );
}
