import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";

import Checkbox from "../../../components/Checkbox";
import { sendPatchRequest, sendPostRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";

export default function AdminDetailsForm({
  details,
  adminRoles,
  secureAccessToken,
  onSuccess,
}) {
  const form = useForm({
    name: details?.name,
    email: details?.email,
    roles: adminRoles.reduce(
      (rolesObj, role) => ({
        ...rolesObj,
        [role.id]: details?.roles?.indexOf(role.id) >= 0,
      }),
      {}
    ),
  });
  const showMessageModal = useMessageModal();

  const { trigger: postTrigger } = useSWRMutation(
    "/admin/add",
    sendPostRequest
  );

  const { trigger: patchTrigger } = useSWRMutation(
    `/admin/${details?.id}/info`,
    sendPatchRequest
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    const selectedRoles = Object.keys(form.data.roles).filter(
      (roleID) => !!form.data.roles[roleID]
    );

    if (selectedRoles.length === 0) {
      showMessageModal({
        title: "Error",
        message: "admin role(s) is needed and cannot be empty",
        isError: true,
        closeButtonText: "Close",
      });
      return;
    }

    const data = details
      ? {
          payload: {
            ...form.data,
            secureAccessToken,
            roles: selectedRoles,
          },
        }
      : {
          ...form.data,
          secureAccessToken,
          roles: selectedRoles,
        };

    const trigger = details ? patchTrigger : postTrigger;

    form.submitStarted();
    try {
      await trigger(data);
      onSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <label>Full Name</label>
      <input className="mb-20" type="text" {...form.fieldProps("name")} />

      <label>Email Address</label>
      <input className="mb-32" type="email" {...form.fieldProps("email")} />

      <label className="fs-20 mb-20">Assign Security Roles</label>
      <div className="admin-roles mb-28">
        {adminRoles.map((role) => (
          <Checkbox
            key={role.id}
            text={role.name}
            color="#627178"
            {...form.fieldProps(["roles", role.id], true)}
          />
        ))}
      </div>

      <button
        disabled={!form.valid() || form.state.submitting}
        className="fullwidth btn primary"
      >
        {form.state.submitting ? (
          <FiLoader />
        ) : details ? (
          "Save Changes"
        ) : (
          "Add Admin"
        )}
      </button>
    </form>
  );
}
