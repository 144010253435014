import { Fragment, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import dayjs from "dayjs";

import DataFetcher from "../../../components/DataFetcher";
import SearchFilterTable, {
  FilterArray,
} from "../../../components/SearchFilterTable";
import ReferralPromoModal from "./ReferralPromoModal";

import { getPermissions } from "../../../config/swr";

const ReferralPromosPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Referral Promos");

  return (
    <DataFetcher
      url={`/referral-promos`}
      buildUI={(data, mutate) => {
        const promos = (data ?? []).map((promo) => {
          const promoCapExceeded =
            promo.status === "active" &&
            promo.referrerBonusAmount > 0 &&
            promo.payoutCount >= promo.bonusPaymentCap;
          promo.filterStatus = promoCapExceeded
            ? "reached max payout"
            : promo.status;
          return promo;
        });

        return (
          <ReferralPromosListView
            allPromos={promos.reverse()}
            mutate={mutate}
          />
        );
      }}
    />
  );
};

const promoSortOptions = {
  Name: "name",
  "Start Date": "startTimestamp",
  "End Date": "endTimestamp",
};

const ReferralPromosListView = ({ allPromos, mutate }) => {
  const permissions = getPermissions();
  const canCreateReferralPromo = permissions["create-referral-promo"];
  const canViewPromoUsers = permissions["view-referral-promo-users"];
  const canUpdateReferralPromo = permissions["update-referral-promo"];

  const [showAddPromoModal, setShowAddPromoModal] = useState(false);
  const addPromoButton = canCreateReferralPromo ? (
    <button
      className="mini btn outline fw-600"
      onClick={() => setShowAddPromoModal(true)}
    >
      Create Referral Promo
    </button>
  ) : undefined;

  const [filter, setFilter] = useState({});
  const promosToDisplay = FilterArray(allPromos, filter, promoSortOptions);
  const promoStatuses = [
    "reached max payout",
    "active",
    "upcoming",
    "ended",
  ].filter(
    (status) =>
      status === "active" ||
      promosToDisplay.some((promo) => promo.filterStatus === status)
  );

  const [editPromoID, setEditPromoID] = useState(null);
  const promoToEdit = editPromoID
    ? allPromos.find((promo) => promo.id === editPromoID)
    : null;

  return (
    <div className="customers">
      {!allPromos || allPromos.length === 0 ? (
        <div className="flex col gap-8 center fs-20">
          <p>No referral promos yet.</p>
          {addPromoButton}
        </div>
      ) : (
        <SearchFilterTable
          mini
          appendWidget={addPromoButton}
          searchHint="Search for a promo with the name"
          searchDelay={0}
          headers={["Name", "Start Date", "End Date", "No. of Users"]}
          sortOptions={Object.keys(promoSortOptions)}
          reloadTable={setFilter}
        >
          {promoStatuses.map((status) => (
            <Fragment key={status}>
              <tr>
                <td
                  colSpan="5"
                  style={{
                    padding: "6px",
                    textAlign: "start",
                    borderTop: "none",
                  }}
                >
                  <h3>{status.toUpperCase()}</h3>
                </td>
              </tr>
              {promosToDisplay
                .filter((promo) => promo.filterStatus === "ended")
                .map((promo) => (
                  <tr key={promo.id}>
                    <td style={{ wordBreak: "break-all" }}>{promo.name}</td>
                    <td>
                      {dayjs(promo.startTimestamp * 1000).format(
                        "DD MMM, YYYY"
                      )}
                    </td>
                    <td>
                      {dayjs(promo.endTimestamp * 1000).format("DD MMM, YYYY")}
                    </td>
                    <td>
                      {promo.newUsersCount === 0 ? (
                        "No new users yet"
                      ) : canViewPromoUsers ? (
                        <span>
                          {promo.newUsersCount}&nbsp;
                          <Link to={`/promos/referral-promo/${promo.id}/users`}>
                            (view)
                          </Link>
                        </span>
                      ) : (
                        promo.newUsersCount
                      )}
                    </td>
                    <td>
                      {canUpdateReferralPromo && (
                        <span
                          className="clickable color-primary"
                          onClick={() => setEditPromoID(promo.id)}
                        >
                          Edit
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
            </Fragment>
          ))}
        </SearchFilterTable>
      )}

      {showAddPromoModal && (
        <ReferralPromoModal
          promo={null}
          mutate={mutate}
          dismissModal={() => setShowAddPromoModal(false)}
        />
      )}

      {promoToEdit && (
        <ReferralPromoModal
          promo={{ ...promoToEdit }}
          mutate={mutate}
          dismissModal={() => setEditPromoID(null)}
        />
      )}
    </div>
  );
};

export default ReferralPromosPage;
