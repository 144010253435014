export default function AccountUpgradeIcon() {
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width="56"
        height="56"
        rx="16"
        fill="#F57E43"
        fillOpacity="0.08"
      />
      <path
        d="M36.4257 31.9286C36.2483 31.4271 35.9197 30.9929 35.4853 30.6859C35.0509 30.3789 34.532 30.2141 34 30.2143H22C21.318 30.2143 20.664 30.4852 20.1817 30.9675C19.6995 31.4497 19.4286 32.1037 19.4286 32.7857V33.3986C19.4286 36.5854 23.0372 38.7857 28 38.7857C30.6246 38.7857 32.8703 38.1369 34.4037 37.0714H28V36.2143H35.4006C35.7666 35.8192 36.0546 35.388 36.2534 34.9286H28V34.0714H36.5114C36.5513 33.8494 36.5713 33.6242 36.5714 33.3986V32.7857H28V31.9286H36.4257ZM33.1429 23.3572C33.1428 23.1423 33.1296 22.9276 33.1034 22.7143H28V21.8572H32.92C32.7817 21.403 32.5809 20.9703 32.3234 20.5714H28V19.7143H31.63C30.9101 18.9968 29.9937 18.5088 28.9965 18.3118C27.9994 18.1148 26.9663 18.2177 26.0276 18.6075C25.0889 18.9973 24.2867 19.6564 23.7224 20.5018C23.1581 21.3471 22.8569 22.3408 22.8569 23.3572C22.8569 24.3736 23.1581 25.3672 23.7224 26.2125C24.2867 27.0579 25.0889 27.717 26.0276 28.1068C26.9663 28.4966 27.9994 28.5995 28.9965 28.4025C29.9937 28.2055 30.9101 27.7175 31.63 27H28V26.1429H32.3234C32.5789 25.7486 32.7812 25.3166 32.9209 24.8572H28V24H33.1034C33.1296 23.7867 33.1428 23.572 33.1429 23.3572Z"
        fill="#F57E43"
      />
    </svg>
  );
}
