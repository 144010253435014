import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useState } from "react";

import { firebaseConfig, fcmVapidKey } from "../config/fcm";

export default function useFCMToken() {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  const [fcmToken, setFcmToken] = useState(null);

  if (!fcmToken) {
    setFcmToken({ fetching: true });

    getToken(messaging, { vapidKey: fcmVapidKey })
      .then((token) => {
        setFcmToken({
          value: token,
          declined: !token,
        });
        console.log(token);
      })
      .catch((error) => {
        setFcmToken({ error });
        console.log(error);
      });
  }

  return {
    value: () => fcmToken?.value,
    ready: () => fcmToken && !fcmToken.fetching,
    notReady: () => !fcmToken || fcmToken.fetching,
  };
}
