import React, { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import UploadIcon from "../../../assets/icons/upload";
import { PrimaryButton, OutlineButton } from "../../../components/Button";
import CheckBox from "../../../components/Checkbox";
import { InputField } from "../../../components/Input";
import useForm from "../../../hooks/useForm.hook";
import { sendPostRequest } from "../../../config/swr";
import Radio from "../../../components/Radio";

const ChannelOptions = ["sms", "email", "push-notification"];

const checkedBoxes = (formData) => {
  let channelStr = "";
  ChannelOptions.forEach((item) => {
    if (formData[item]) channelStr = `${channelStr}${item},`;
  });
  return channelStr.replace(/,\s*$/, "");
};

export default function NewBulkMessage({ cancel, mutate, setInited }) {
  const form = useForm({
    customer: "",
    title: "",
    message: "",
    sms: false,
    email: false,
    "push-notification": false,
  });
  const [radioOption, setRadioOption] = useState("");
  const [imageData, setImageData] = useState({
    image: "",
    imageUrl: "",
  });

  const { title, message, customer } = form.data;
  const { image, imageUrl } = imageData;
  const { trigger } = useSWRMutation("/bulk-message", sendPostRequest);

  const onChange = ({ target }) => {
    setRadioOption(target.value);
  };

  const onImageChange = (e) => {
    const { name, files } = e.target;

    setImageData({
      ...imageData,
      [name]: files[0],
      imageUrl: files[0] && URL.createObjectURL(files[0]),
    });
  };

  const submitMessageForm = async (event) => {
    event.preventDefault();

    form.submitStarted();
    const channelStr = checkedBoxes(form.data);

    const customers =
      customer && customer.trim().length > 1 ? [customer] : null;

    const data = new FormData();
    data.append("title", title);
    data.append("message", message);
    data.append("image", image);
    data.append("customers", customers ?? radioOption);
    data.append("msgTypes", channelStr);

    try {
      const res = await trigger(data);
      form.submitSuccess(res.data?.message || "Successfully submitted");
      cancel();
      mutate();
      setInited(false);
    } catch (error) {
      form.submitError(
        error?.response?.data?.errorMessage ||
          "An error occurred. Please try again"
      );
    }
  };

  return (
    <form className="chat__sendbulk" onSubmit={submitMessageForm}>
      <p className="fs-20">Send Bulk Message</p>

      <div className="chat__sendbulk-receipient fs-16">
        <div className="chat__sendbulk-receipient-inputs">
          <InputField
            label="Recipient"
            {...form.fieldProps("customer")}
            extraChildren={
              <div className="chat__sendbulk-receipient-options">
                <Radio
                  text={"All Customers"}
                  name="customer"
                  value="all"
                  onChange={onChange}
                  checked={radioOption === "all"}
                />
                <Radio
                  text={"Active Customers"}
                  name="customer"
                  value="active"
                  onChange={onChange}
                  checked={radioOption === "active"}
                />
                <Radio
                  text={"Inactive Customers"}
                  name="customer"
                  value="inactive"
                  onChange={onChange}
                  checked={radioOption === "inactive"}
                />
                <Radio
                  text={"Suspended Customers"}
                  name="customer"
                  value="suspended"
                  onChange={onChange}
                  checked={radioOption === "suspended"}
                />
              </div>
            }
            placeholder="Enter Customer Name"
          />
          <InputField
            label="Title"
            placeholder="Title"
            {...form.fieldProps("title")}
          />
          <InputField
            multiline
            rows="4"
            label="Your Message"
            {...form.fieldProps("message")}
            extraChildren={
              <div className="chat__sendbulk-receipient-options2">
                <CheckBox text={"SMS"} {...form.fieldProps("sms", true)} />
                <CheckBox text={"Email"} {...form.fieldProps("email", true)} />
                <CheckBox
                  text={"In App"}
                  {...form.fieldProps("push-notification", true)}
                />
              </div>
            }
            placeholder="Hello, {{first name}}"
          />
          <label
            className="chat__sendbulk-receipient-inputs-selectimage"
            htmlFor="select-image"
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                className="chat-previewpane-image"
                alt="message-img"
              />
            ) : (
              <>
                <p className="fs-20">Select Image</p>
                <p className="fs-16">Min. Res.: 360px * 150px</p>
                <p className="fs-18">(.jpg, .png, .jpeg)</p>
                <UploadIcon />
              </>
            )}
            <input
              type="file"
              hidden
              id="select-image"
              name="image"
              onChange={onImageChange}
            />
          </label>
        </div>
        <div className="chat__sendbulk-receipient-buttons">
          <OutlineButton big onClick={cancel} disabled={form.state.submitting}>
            Cancel
          </OutlineButton>
          <PrimaryButton big disabled={form.state.submitting}>
            {form.state.submitting ? <FiLoader /> : "Send"}
          </PrimaryButton>
        </div>
      </div>
      {form.state.error && (
        <div className="form-error">Error: {form.state.error}</div>
      )}
    </form>
  );
}
