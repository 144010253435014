export const PayrollPermissions = {
  VIEW_PAYROLL_STAFF_PERM: "view-payroll-staff",
  VIEW_PAYROLL_HISTORY_PERM: "view-payroll-history",
  VIEW_PAYROLL_SETTINGS_PERM: "view-payroll-settings",
  ADD_PAYROLL_STAFF_PERM: "add-payroll-staff",
  EDIT_PAYROLL_STAFF_PERM: "update-payroll-staff",
  UPLOAD_PAYROLL_STAFF_CSV_PERM: "upload-payroll-staff-csv",
  TOGGLE_PAYROLL_STAFF_STATUS_PERM: "toggle-payroll-staff-status",
  REVIEW_PAYROLL_STAFF_UPDATE_PERM: "review-payroll-staff",
  EDIT_PAYROLL_SETTINGS_PERM: "update-payroll-settings",
  RETRY_FAILED_PAYMENT_PERM: "retry-payroll-staff-payment",
  UPLOAD_PAYROLL_PAYSLIP_STAMP_PERM: "save-payroll-payslip-stamp",
  VIEW_PAYROLL_UPCOMING_PAYMENT_PERM: "view-payroll-upcoming-payment",
  REVIEW_PAYROLL_UPCOMING_PAYMENT_PERM: "review-payroll-upcoming-payment",
};
