import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";

import { sendPostRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";

const ForgotPasswordForm = ({ fcmToken, onSuccess, onCanceled }) => {
  const { trigger } = useSWRMutation("/reset-password", sendPostRequest);

  const showMessageModal = useMessageModal();
  const form = useForm({
    email: "",
  });

  const canSubmitForm =
    form.valid() && fcmToken.ready() && !form.state.submitting;

  const resetPassword = async () => {
    const { email } = form.data;

    const notificationToken = fcmToken.value();

    // TODO: Use a unique device identifier that can properly distingush this
    // device from other devices. FCM ntfn token does that though.
    const deviceID = notificationToken;

    const data = {
      email,
      deviceID,
      notificationToken,
    };
    form.submitStarted();

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
      onSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div className="login-form">
      <h1 className="fs-24">Password Reset</h1>

      <div className="login-form-field mb-32">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          name="email"
          disabled={form.state.submitting}
          {...form.fieldProps("email")}
          placeholder="admin@email.com"
        />
      </div>

      <button
        className="btn primary fullwidth"
        disabled={!canSubmitForm}
        onClick={resetPassword}
      >
        {form.state.submitting ? <FiLoader /> : "Continue"}
      </button>

      <button className="btn transparent fullwidth fs-16" onClick={onCanceled}>
        Cancel
      </button>
    </div>
  );
};

export default ForgotPasswordForm;
