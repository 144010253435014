function PageNotFound() {
  return (
    <div style={{ padding: "1rem" }}>
      <h3 className="mb-6">Page not found</h3>
      <a href="/">Go to home page</a>
    </div>
  );
}

export default PageNotFound;
