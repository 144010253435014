import { useState } from "react";
import { BiCaretDown } from "react-icons/bi";

import { DelayedActionButton } from "../../../components/Button";
import { InputField } from "../../../components/Input";

import useToggle from "../../../hooks/useToggle.hook";

const LoanOffers = ({ loanFormData, setData }) => {
  const [dropdownOpened, _toggleDropdown] = useToggle(false);
  const toggleDropdown = (e) => {
    e.preventDefault();
    _toggleDropdown();
  };
  const { selectedLoanOffer, loanOffers } = loanFormData;
  const [offerData, setOfferData] = useState({
    maxLoanAmount: "",
    interestRate: "",
    tenureInDays: "",
  });
  const [editOfferID, setEditOfferID] = useState(-1);
  const offerBtnDisabled =
    !offerData.maxLoanAmount ||
    !offerData.interestRate ||
    !offerData.tenureInDays;

  const offerDataFromForm = () => {
    return {
      ...offerData,
      maxLoanAmount: +offerData.maxLoanAmount,
      interestRate: +offerData.interestRate,
      tenureInDays: +offerData.tenureInDays,
    };
  };

  const resetOfferData = () => {
    setOfferData({
      maxLoanAmount: "",
      interestRate: "",
      tenureInDays: "",
    });
    setEditOfferID(-1);
  };

  const onOfferEdit = (index) => {
    setEditOfferID(index);
    setOfferData({
      ...loanOffers[index],
    });
  };

  const onOfferRemove = (index) => {
    resetOfferData();
    setData({
      ...loanFormData,
      loanOffers: [
        ...loanOffers.slice(0, index),
        ...loanOffers.slice(index + 1),
      ],
    });
  };

  const onOfferChange = (e) => {
    const { name, value } = e.target;
    setOfferData({ ...offerData, [name]: value });
  };

  const onOfferSubmit = (e) => {
    e.preventDefault();
    if (offerBtnDisabled) return;
    if (editOfferID < 0) {
      const newArr = loanOffers?.slice() ?? [];
      setData({
        ...loanFormData,
        loanOffers: [...newArr, offerDataFromForm()],
      });
    } else {
      const newArr = loanOffers?.slice() ?? [];
      newArr[editOfferID] = offerDataFromForm();
      setData({
        ...loanFormData,
        loanOffers: newArr,
      });
    }
    resetOfferData();
  };

  return (
    <div className="card__box mb-20">
      <div className="flex gap between-center" onClick={toggleDropdown}>
        <span>Loan Offers</span>
        <span>
          <BiCaretDown
            size={18}
            className={
              dropdownOpened ? "dropdown-icon rotate-180" : "dropdown-icon"
            }
          />
        </span>
      </div>
      <div
        className={
          dropdownOpened ? "batch__dropdown opened" : "batch__dropdown"
        }
      >
        <div className="flex col gap-8" style={{ padding: "4px" }}>
          <div className="group-item">
            <div className="card__box">
              {!loanOffers || loanOffers?.length === 0 ? (
                <div>No Offers added yet</div>
              ) : (
                loanOffers?.map((offer, i) => (
                  <div
                    key={offer.id}
                    className={`flex gap center info-row-with-actions ${
                      selectedLoanOffer && selectedLoanOffer.id === offer.id
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className="text">
                      <h3 className="fs-20">
                        {offer.tenureInDays} Days
                        <span className="fw-600">({offer.interestRate}%)</span>
                      </h3>
                      <p className="charcoal-60">
                        &#8358; {offer.maxLoanAmount?.toLocaleString()}
                      </p>
                    </div>

                    {!selectedLoanOffer && (
                      <div className="actions">
                        <button onClick={() => onOfferEdit(i)}>Edit</button>
                        <DelayedActionButton
                          className="tart-orange"
                          text="Remove"
                          confirmText="Confirm"
                          busyText="Remove"
                          clickAction={() => onOfferRemove(i)}
                        />
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>

          {!selectedLoanOffer && (
            <div>
              <h2 className="fs-18 mb-16">
                {editOfferID < 0 ? "Add" : "Edit"} Loan Offer
              </h2>
              <form className="card__box" onSubmit={onOfferSubmit}>
                <InputField
                  label="Loan Amount"
                  type="number"
                  placeholder="Enter Loan Amount"
                  className="mb-16"
                  name="maxLoanAmount"
                  value={offerData.maxLoanAmount}
                  onChange={onOfferChange}
                />
                <InputField
                  label="Duration (days)"
                  type="number"
                  placeholder="Enter Loan Duration"
                  className="mb-16"
                  name="tenureInDays"
                  value={offerData.tenureInDays}
                  onChange={onOfferChange}
                />
                <InputField
                  label="Interest Rate (%)"
                  type="number"
                  placeholder="10"
                  className="mb-32"
                  name="interestRate"
                  value={offerData.interestRate}
                  onChange={onOfferChange}
                />
                <button
                  className="btn primary light fullwidth"
                  disabled={offerBtnDisabled}
                >
                  {editOfferID < 0 ? "Add" : "Update"}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoanOffers;
