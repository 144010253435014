import { Fragment } from "react";
import { BiCaretDown } from "react-icons/bi";

import useToggle from "../../../hooks/useToggle.hook";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const amountElement = (amount) => <>&#8358; {amount?.toLocaleString()}</>;
const formatDate = (timestamp) =>
  timestamp && timestamp > 0
    ? dayjs(timestamp * 1000)
        .utc()
        .format("Do MMM YYYY")
    : "-";

const LoanRepaymentScheduleDisplay = ({ amountPaid, repaymentSchedule }) => {
  const [dropdownOpened, toggleDropdown] = useToggle(false);

  let totalDue = 0;
  const nowUnixSecs = Date.now() / 1000;
  const secsInADay = 24 * 3600;
  for (const payment of repaymentSchedule) {
    totalDue += payment.amountDue;
    const unpaidBalance = totalDue - amountPaid;
    if (unpaidBalance <= 0) {
      continue; // paid
    }

    payment.balance =
      unpaidBalance > payment.amountDue ? payment.amountDue : unpaidBalance;
    const daysToPayment = (payment.dueDateTimestamp - nowUnixSecs) / secsInADay;
    if (daysToPayment < 0) {
      payment.overdueDays = Math.abs(Math.floor(daysToPayment));
    } else if (daysToPayment <= 3) {
      payment.dueDays = Math.ceil(daysToPayment);
    }
  }

  return (
    <div className="card__box mb-20">
      <div className="flex gap between-center" onClick={toggleDropdown}>
        <span>Loan Repayment Schedule</span>
        <span>
          <BiCaretDown
            size={18}
            className={
              dropdownOpened ? "dropdown-icon rotate-180" : "dropdown-icon"
            }
          />
        </span>
      </div>
      <div
        className={
          dropdownOpened ? "batch__dropdown opened" : "batch__dropdown"
        }
      >
        <div className="card__grid flex col gap-8">
          <div className="flex between-center fw-600">
            <p>Repayment Date</p>
            <p>Amount Due</p>
          </div>
          {repaymentSchedule?.map((schedule, index) => (
            <div key={index}>
              <div className="flex between-center">
                <span>{formatDate(schedule.dueDateTimestamp)}</span>
                <span>{amountElement(schedule.amountDue)}</span>
              </div>
              {schedule.overdueDays && (
                <p className="fs-14 fw-600 tart-orange mt-6 right-text">
                  {amountElement(schedule.balance)} was due&nbsp;
                  {schedule.overdueDays.toLocaleString()} days ago.
                </p>
              )}
              {schedule.dueDays && (
                <p className="fs-14 fw-600 color-orange mt-6 right-text">
                  {amountElement(schedule.balance)} is due in&nbsp;
                  {schedule.dueDays.toLocaleString()} days.
                </p>
              )}
            </div>
          ))}
          <hr />
          <div className="flex between-center fw-600 mt-6">
            <p>Total</p>
            <p>{amountElement(totalDue)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanRepaymentScheduleDisplay;
