import useSWR from "swr";
import { BsChatDots, BsChatDotsFill } from "react-icons/bs";
import { IoNotificationsOutline, IoNotificationsSharp } from "react-icons/io5";
import { NavLink } from "react-router-dom";

import { getPermissions } from "../../config/swr";

const VIEW_CONVERSATIONS_KEY = "view-bulk-messages";
const VIEW_SUMMARY_KEY = "dashboard-summary";

const HeaderNavs = () => {
  const permissions = getPermissions();

  const { data } = useSWR(permissions[VIEW_SUMMARY_KEY] ? `/summary` : null);

  const canViewMessages = permissions[VIEW_CONVERSATIONS_KEY];
  const canViewSummary = permissions[VIEW_SUMMARY_KEY];
  
  return (
    <>
      {canViewMessages && (
        <NavLink to="/conversations">
          {({ isActive }) =>
            isActive ? (
              <BsChatDotsFill size={24} color="#4A7F63" />
            ) : (
              <BsChatDots size={24} color="#4A7F63" />
            )
          }
        </NavLink>
      )}
      {canViewSummary && (
        <NavLink to="/notifications">
          {({ isActive }) =>
            isActive ? (
              <div className="sidebar__notifications">
                <IoNotificationsSharp size={24} color="#4A7F63" />
                {data && data.unreadNotificationsCount > 0 && (
                  <span className="fw-600 flex center">
                    {data.unreadNotificationsCount}
                  </span>
                )}
              </div>
            ) : (
              <div className="sidebar__notifications">
                <IoNotificationsOutline size={24} color="#4A7F63" />
                {data && data.unreadNotificationsCount > 0 && (
                  <span className="fw-600 flex center">
                    {data.unreadNotificationsCount}
                  </span>
                )}
              </div>
            )
          }
        </NavLink>
      )}
    </>
  );
};

export default HeaderNavs;
