import { useState } from "react";
import { FiLoader } from "react-icons/fi";
import { Link, useOutletContext } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import useSWR from "swr";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import DataFetcher from "../../components/DataFetcher";
import { PrimaryButton } from "../../components/Button";
import Modal from "../../components/Modal";
import SearchFilterTable from "../../components/SearchFilterTable";
import AdminDetailsForm from "./forms/AdminDetailsForm";
import AdminPasswordForm from "./forms/AdminPasswordForm";
import {
  getPermissions,
  sendPatchRequest,
  sendPostRequest,
} from "../../config/swr";
import useMessageModal from "../../hooks/useMessageModal.hook";
import useForm from "../../hooks/useForm.hook";
import useFCMToken from "../../hooks/useFCMToken.hook";

dayjs.extend(utc);
const ADD_ADMIN_KEY = "add-admin";
const UPDATE_INFO_KEY = "update-admin-info";
const UPDATE_STATUS_KEY = "update-admin-status";
const EXPORT_ADMIN_KEY = "export-admins";
const VIEW_LOG_KEY = "view-activity-logs";

const AdminUsersPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Admin Users");
  return (
    <DataFetcher
      url={`/admin/list`}
      buildUI={(data, mutate) => (
        <AdminUsersDisplay data={data} mutate={mutate} />
      )}
    />
  );
};

const AdminUsersDisplay = ({ data, mutate }) => {
  const [activeStatus, setActiveStatus] = useState("active");
  const [activeId, setActiveId] = useState(null);
  const permissions = getPermissions();
  const showMessageModal = useMessageModal();
  const form = useForm();

  const canAddAdmin = permissions[ADD_ADMIN_KEY];
  const canUpdateInfo = permissions[UPDATE_INFO_KEY];
  const canUpdateStatus = permissions[UPDATE_STATUS_KEY];
  const canExportAdmin = permissions[EXPORT_ADMIN_KEY];
  const canViewLogs = permissions[VIEW_LOG_KEY];

  const adminUsers = data[activeStatus];
  const { data: rolesData, error: rolesError } = useSWR(
    "/settings/security-roles"
  );
  const { trigger } = useSWRMutation(
    "/admin/resend-adminpassword-token",
    sendPostRequest
  );

  const reloadAdminUsers = ({ sort, search }) => {
    // TODO
  };

  const [addOrEditAdmin, setAddOrEditAdmin] = useState(null);
  const addAdmin = () => {
    if (!canAddAdmin) return;
    setAddOrEditAdmin({});
  };
  const editAdmin = (i) => {
    if (!canUpdateInfo) return;
    setAddOrEditAdmin({ index: i });
  };

  const addOrEditAdminDone = () => {
    setAddOrEditAdmin(null);
    mutate();
  };

  const [toggleAdminStatusIndex, setToggleAdminStatusIndex] = useState(-1);
  const toggleAdminStatusDone = () => {
    setToggleAdminStatusIndex(-1);
    mutate();
  };

  const sendPassEmail = async (email, index) => {
    form.submitStarted();
    setActiveId(index);
    try {
      const res = await trigger({
        email,
      });
      showMessageModal({
        title: "Success",
        message: res.data?.message || "Successfully sent",
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
    setActiveId(null);
  };

  return (
    <div className="admin-users">
      {rolesError ? (
        <>An error occurred. Please try again</>
      ) : (
        <>
          <div className="flex center gap mb-32">
            <div className="tab-nav">
              {Object.entries(data).map(([status, admins], index) => (
                <button
                  key={index}
                  onClick={() => setActiveStatus(status)}
                  className={
                    "box fs-20" + (activeStatus === status ? " active" : "")
                  }
                >
                  <span
                    className="fs-16"
                    style={{ textTransform: "capitalize" }}
                  >
                    {status}
                  </span>
                  {admins?.length ?? 0}
                </button>
              ))}
            </div>

            {canAddAdmin && (
              <PrimaryButton onClick={addAdmin} disabled={!canAddAdmin}>
                Add New Admin
              </PrimaryButton>
            )}
          </div>

          {!adminUsers || adminUsers.length === 0 ? (
            <div className="flex center box fs-20">
              There is currently no {activeStatus} admins
            </div>
          ) : (
            <SearchFilterTable
              searchHint="Search admin"
              headers={["Full name", "Email Address", "Actions", "Created"]}
              sortOptions={["Name", "Email", "Created At"]}
              reloadTable={reloadAdminUsers}
              csvUrl={canExportAdmin && "admin/export"}
            >
              {adminUsers.map((admin, index) => (
                <tr key={index}>
                  <td style={{ wordBreak: "break-all" }}>{admin.name}</td>
                  <td style={{ wordBreak: "break-all" }}>
                    <span className="flex col center equal-widths">
                      <span>{admin.email}</span>
                      {activeStatus === "active" && !admin.isPasswordSet && (
                        <span>
                          <button
                            className="color-primary fs-14"
                            onClick={() => sendPassEmail(admin.email, index)}
                          >
                            {activeId === index && form.state.submitting ? (
                              <FiLoader />
                            ) : (
                              "Send Password Email"
                            )}
                          </button>
                        </span>
                      )}
                    </span>
                  </td>
                  <td>
                    {canUpdateInfo && (
                      <button
                        className="btn color-primary"
                        onClick={() => editAdmin(index)}
                      >
                        Edit
                      </button>
                    )}

                    {canUpdateStatus && (
                      <button
                        className="btn color-orange"
                        onClick={() => setToggleAdminStatusIndex(index)}
                      >
                        {admin.busy ? (
                          <FiLoader />
                        ) : activeStatus === "active" ? (
                          "Disable"
                        ) : (
                          "Enable"
                        )}
                      </button>
                    )}

                    {canViewLogs && (
                      <Link
                        to={`/admin-logs/${admin.id}`}
                        className="btn charcoal-60"
                      >
                        View Logs
                      </Link>
                    )}

                    <button className="btn">&nbsp;</button>
                  </td>
                  <td>
                    {dayjs(admin.createdAt * 1000).utc().format("DD-MM-YYYY")}
                    &nbsp; &bull; &nbsp;
                    {dayjs(admin.createdAt * 1000).utc().format("hh:mm a")}
                  </td>
                </tr>
              ))}
            </SearchFilterTable>
          )}

          {addOrEditAdmin && (
            <AdminDetailsFormModal
              adminInfo={
                addOrEditAdmin.index >= 0
                  ? adminUsers[addOrEditAdmin.index]
                  : null
              }
              adminRoles={rolesData.roles}
              done={addOrEditAdminDone}
            />
          )}

          {toggleAdminStatusIndex >= 0 && (
            <ToggleAdminStatusModal
              adminInfo={adminUsers[toggleAdminStatusIndex]}
              activeStatus={activeStatus}
              done={toggleAdminStatusDone}
              canUpdateStatus={canUpdateStatus}
            />
          )}
        </>
      )}
    </div>
  );
};

function AdminDetailsFormModal({ adminInfo, adminRoles, done }) {
  const fcmToken = useFCMToken();
  const [secureAccessToken, setSecureAccessToken] = useState("");
  const { trigger } = useSWRMutation(
    `/admin/request-secure-access`,
    sendPostRequest
  );
  const showMessageModal = useMessageModal();

  const requestSecureAccessToken = async (password, form) => {
    const notificationToken = fcmToken.value();

    const deviceID = notificationToken;
    try {
      const res = await trigger({
        password,
        deviceID,
      });
      setSecureAccessToken(res.data.secureAccessToken);
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <Modal
      handleClose={() => done()}
      className="modal-heading-subheading-buttons"
    >
      <div className="mb-32">
        <h1 className="fs-20">
          {adminInfo ? "Edit Admin Details" : "Add New Admin"}
        </h1>
        {!secureAccessToken && <p>Enter your password to continue</p>}
      </div>

      <div className="mb-6">
        {!secureAccessToken ? (
          <AdminPasswordForm processPassword={requestSecureAccessToken} />
        ) : (
          <AdminDetailsForm
            details={adminInfo}
            adminRoles={adminRoles}
            secureAccessToken={secureAccessToken}
            onSuccess={done}
          />
        )}
      </div>

      <button className="fullwidth btn" onClick={() => done()}>
        Cancel
      </button>
    </Modal>
  );
}

function ToggleAdminStatusModal({
  adminInfo,
  done,
  activeStatus,
  canUpdateStatus,
}) {
  const showMessageModal = useMessageModal();
  const { trigger: patchTrigger } = useSWRMutation(
    `/admin/${adminInfo?.id}/status`,
    sendPatchRequest
  );

  const { trigger: postTrigger } = useSWRMutation(
    `/admin/request-secure-access`,
    sendPostRequest
  );

  const toggleAdminStatus = async (password, form) => {
    if (!canUpdateStatus) return;

    try {
      const res = await postTrigger({
        password,
        deviceID: "abc",
      });

      await patchTrigger({
        payload: {
          ...adminInfo,
          secureAccessToken: res.data.secureAccessToken,
          status: activeStatus === "active" ? "Disabled" : "Active",
        },
      });
      done();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
    }
  };

  return (
    <Modal
      handleClose={() => done()}
      className="modal-heading-subheading-buttons"
    >
      <div className="mb-32">
        <h1 className="fs-20">Disable Admin</h1>
        <p>
          Enter your password to&nbsp;
          {activeStatus === "active" ? "Disable" : "Enable"}
          &nbsp;
          {adminInfo.name}
        </p>
      </div>

      <div className="mb-6">
        {activeStatus === "active" ? (
          <AdminPasswordForm
            buttonText="Disable"
            buttonClass="fullwidth btn danger"
            processPassword={toggleAdminStatus}
          />
        ) : (
          <AdminPasswordForm
            buttonText="Enable"
            buttonClass="fullwidth btn primary"
            processPassword={toggleAdminStatus}
          />
        )}
      </div>

      <button className="fullwidth btn" onClick={() => done()}>
        Cancel
      </button>
    </Modal>
  );
}

export default AdminUsersPage;
