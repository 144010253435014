import { useState } from "react";
import { useOutletContext } from "react-router-dom";

import DataFetcher from "../../../components/DataFetcher";
import FewchoreCollectionSettings from "./tabs/FewchoreCollection";
import TargetCollectionSettings from "./tabs/TargetCollection";
import { getPermissions } from "../../../config/swr";

const VIEW_GENERAL_KEY = "view-general-collection-settings";
const VIEW_TARGET_KEY = "view-target-collection-settings";

const CollectionsSettings = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Collections Settings");

  return (
    <DataFetcher
      url={`/settings/collections`}
      buildUI={(data) => <CollectionsDisplay data={data} />}
    />
  );
};

const CollectionsDisplay = ({ data }) => {
  const { generalCollectionSettings, targetCollectionSettings } = data;
  const [activeTab, setActiveTab] = useState(1);
  const permissions = getPermissions();

  const canViewGeneral = permissions[VIEW_GENERAL_KEY];
  const canViewTarget = permissions[VIEW_TARGET_KEY];

  if (!canViewGeneral && !canViewTarget)
    return (
      <div className="flex center fs-20">
        You are not authorised to see this page
      </div>
    );

  return (
    <div className="settings">
      <div className="tab-nav mb-32">
        <button
          className={`btn box${activeTab === 1 ? " active" : ""}`}
          onClick={() => setActiveTab(1)}
          disabled={!canViewGeneral}
        >
          Fewchore Collection
        </button>
        <button
          className={`btn box${activeTab === 2 ? " active" : ""}`}
          onClick={() => setActiveTab(2)}
          disabled={!canViewTarget}
        >
          Target Collection
        </button>
      </div>

      <div className="tabs mb-32">
        <h2 className="fs-20 mb-16">Collection Details</h2>
        {activeTab === 1 && canViewGeneral && (
          <FewchoreCollectionSettings
            generalCollectionSettings={generalCollectionSettings}
          />
        )}
        {activeTab === 2 && canViewTarget && (
          <TargetCollectionSettings
            targetCollectionSettings={targetCollectionSettings}
          />
        )}
      </div>
    </div>
  );
};

export default CollectionsSettings;
