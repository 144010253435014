import { Navigate } from "react-router-dom";

import { getPermissions, getToken } from "../config/swr";

export const CheckAllowed = (perms, key, keys = []) => {
  if (!perms) return false;

  // First check `key` if specified.
  if (!!key && !perms[key]) return false;

  // Then (also) check `keys` if specified.
  if (keys.length > 0 && !keys.some((key) => perms[key])) {
    return false;
  }

  // Either there was no `key` and no `keys` or the checks passed.
  return true;
};

const AllowedRoute = ({ permissionsKey, permissionsKeys, children }) => {
  const token = getToken();

  const permissions = getPermissions();

  const isAllowed = CheckAllowed(permissions, permissionsKey, permissionsKeys);

  if (!token || !permissions) return <Navigate to="/login" />;

  if (!isAllowed) return <Navigate to="/" replace />;

  return <>{children}</>;
};

export default AllowedRoute;
