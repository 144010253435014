import Checkbox from "../../../components/Checkbox";
import useForm from "../../../hooks/useForm.hook";

export default function AccountProductForm({ productData, onSubmit }) {
  const form = useForm(productData);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!form.valid()) return;

    const productData = {
      ...form.data,
    };
    onSubmit(productData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="displayName">Display Name</label>
      <input
        id="displayName"
        type="text"
        className="mb-20"
        placeholder="Enter Display Name"
        {...form.fieldProps("displayName")}
      />

      <label htmlFor="code">Product Code</label>
      <input
        id="code"
        type="text"
        className="mb-20"
        placeholder="Enter Product Code"
        {...form.fieldProps("code")}
      />

      <Checkbox
        text="Display on app"
        color="#3d5059"
        additionalStyles={{
          background: "none",
        }}
        {...form.fieldProps("showInApp", true)}
      />

      <button
        className="fullwidth btn primary light"
        disabled={!form.data.code || !form.data.displayName}
      >
        {productData ? "Update" : "Add"}
      </button>
    </form>
  );
}
