import { useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export function UploadedDocumentSummary({ document, onClick, timestamp }) {
  return (
    <div className="uploaded-doc-details">
      <div onClick={onClick} className="clickable">
        <p>
          {document.name}
          {document.description ? ` (${document.description})` : null}
        </p>
        <p>{dayjs(timestamp * 1000).utc().format("dddd, MMMM DD YYYY hh:mm")}</p>
      </div>
      <button className={document.status}>
        {document.status ?? "Pending"}
      </button>
    </div>
  );
}

export function UploadedDocumentPreview({
  ownerName,
  document,
  closePreview,
  children,
  hasPermissions,
}) {
  const [showRejectDocConfirmation, setShowRejectDocConfirmation] =
    useState(false);
  const showRejectDocForm = () => setShowRejectDocConfirmation(true);
  const cancelRejectDoc = () => setShowRejectDocConfirmation(false);

  const [rejectReason, setRejectReason] = useState(document.rejectReason ?? "");
  const rejectDoc = () => {
    closePreview({ rejected: true, rejectReason: rejectReason });
  };

  const acceptDoc = () => {
    closePreview({ accepted: true });
  };

  const docExtension = new URL(document.url).pathname.split(".").pop();
  const fileName = `${ownerName}_${document.name}_${docExtension}`;

  return (
    <div className="uploaded-doc-preview">
      <div>
        <div className="mb-20">
          <h3 className="fs-20">{document.name}</h3>
          {children}
        </div>

        {!!children && <h3 className="fs-16 mb-16">Document</h3>}

        <div className="uploaded-doc-preview-img mb-28">
          <img src={document.url} alt={document.name} />
        </div>

        {!showRejectDocConfirmation && (
          <div className="uploaded-doc-action-buttons">
            <a
              href={document.url}
              download={fileName}
              target="_blank"
              rel="noreferrer"
              className="btn outline mini"
            >
              Download
            </a>
            {hasPermissions && (
              <>
                <button
                  className="btn danger outline mini"
                  onClick={showRejectDocForm}
                >
                  Reject
                </button>
                <button className="btn outline mini" onClick={acceptDoc}>
                  Accept
                </button>
              </>
            )}
          </div>
        )}
      </div>

      {showRejectDocConfirmation && (
        <div className="reject-uploaded-doc">
          <textarea
            placeholder="Enter reason for rejecting"
            rows="2"
            className="fs-16 mb-16"
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          ></textarea>

          <button
            className="fullwidth btn primary mini mb-12"
            disabled={!rejectReason}
            onClick={rejectDoc}
          >
            Reject
          </button>

          <button
            className="fullwidth btn outline danger mini"
            onClick={cancelRejectDoc}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}
