import React, { useRef, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({
  rightSheet,
  width,
  fullScreen,
  notification,
  hideCancelButton,
  children,
  className,
  handleClose,
}) => {
  const body = useRef(document.body);
  useEffect(() => {
    let cleanup = body.current;
    cleanup.classList.add("no-scroll");
    return () => {
      cleanup.classList.remove("no-scroll");
    };
  });

  const modalContent = (contentClass) => {
    if (className) contentClass = `${contentClass} ${className}`;
    const style = width ? { width, minWidth: "unset" } : {};
    if (rightSheet && fullScreen) {
      style.width = "100%";
      style.maxWidth = "unset";
    }
    return (
      <div
        className={contentClass}
        style={style}
        onClick={(e) => e.stopPropagation()}
      >
        {(rightSheet || notification) && !hideCancelButton && (
          <div className="mb-20">
            <button
              type="button"
              onClick={handleClose}
              aria-label="close modal"
            >
              <AiOutlineClose size="18px" strokeWidth="18px" />
            </button>
          </div>
        )}
        {children}
      </div>
    );
  };

  if (rightSheet) {
    return (
      <div className="modal" onClick={handleClose}>
        {modalContent("box-shadow modal__content__rightsheet")}
      </div>
    );
  }

  if (notification) {
    return (
      <div className="modal" onClick={handleClose}>
        <div className="modal__wrapper">
          {modalContent("box-shadow modal__content__notification")}
        </div>
      </div>
    );
  }

  if (fullScreen) {
    return (
      <div className="modal" onClick={handleClose}>
        {modalContent("box-shadow modal__content__fullscreen")}
      </div>
    );
  }

  return (
    <div className="modal" onClick={handleClose}>
      <div className="modal__wrapper">
        {modalContent("box-shadow modal__content")}
      </div>
    </div>
  );
};

export default Modal;
