import React from "react";
import { Routes, Route } from "react-router-dom";

import PageWrapper from "./components/page-wrapper";
import LoginPage, { SetPasswordFormID } from "./pages/login";

import OverviewPage from "./pages/overview";
import MobileAdsPage from "./pages/mobile-ads";
import CollectionsPage from "./pages/collections";
import TransactionsPage from "./pages/transactions";
import AdminUsersPage from "./pages/admin-users";
import AdminLogsPage from "./pages/admin-logs";

import ReferralPromosPage from "./pages/promos/referral-promos";
import ReferralPromoUsersPage from "./pages/promos/referral-promos/ReferralPromoUsersPage";

import LoansPage, {
  LoanTypeAssetFinancing,
  LoanTypePayday,
  LoanTypeRetail,
} from "./pages/loans";
import LoanPartnersPage from "./pages/loans/partners";
import LoanPartnersAdminUsersPage from "./pages/loans/partners/admin-users";
import PartnerLoansAdminLogsPage from "./pages/loans/partners/admin-logs";

import { CustomerRoutes, PayrollRoutes, SettingsRoutes } from "./Routes";

import ConversationsPage from "./pages/conversations";
import NotificationsPage from "./pages/notifications";
import PageNotFound from "./pages/404";

import AllowedRoute from "./components/AllowedRoute";
import ErrorBoundary from "./components/ErrorBoundary";

import { getToken } from "./config/swr";

const App = () => {
  const token = getToken();

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="login" element={<LoginPage />}></Route>

        <Route
          path="set-password"
          element={<LoginPage initialFormID={SetPasswordFormID} />}
        ></Route>

        <Route
          path="/"
          element={
            <AllowedRoute permissionsKey="">
              <PageWrapper key={token} />
            </AllowedRoute>
          }
        >
          <Route
            path="/"
            element={
              <AllowedRoute permissionsKey="dashboard-summary">
                <OverviewPage />
              </AllowedRoute>
            }
          ></Route>
          <Route
            path="overview"
            element={
              <AllowedRoute permissionsKey="dashboard-summary">
                <OverviewPage />
              </AllowedRoute>
            }
          ></Route>

          <Route
            path="transactions"
            element={
              <AllowedRoute permissionsKey="fix-payment-issues">
                <TransactionsPage />
              </AllowedRoute>
            }
          ></Route>

          <Route
            path="mobile-ads"
            element={
              <AllowedRoute permissionsKey="view-mobile-ads">
                <MobileAdsPage />
              </AllowedRoute>
            }
          ></Route>

          <Route path="promos">
            <Route
              path="referral-promos"
              element={
                <AllowedRoute permissionsKey="view-referral-promos">
                  <ReferralPromosPage />
                </AllowedRoute>
              }
            />

            <Route
              path="referral-promo/:id/users"
              element={
                <AllowedRoute permissionsKey="view-referral-promo-users">
                  <ReferralPromoUsersPage />
                </AllowedRoute>
              }
            />
          </Route>

          <Route path="loans">
            <Route
              path=""
              element={
                <AllowedRoute permissionsKey="view-loans">
                  <LoansPage loanType={""} />
                </AllowedRoute>
              }
            />

            <Route
              path="payday-loans"
              element={
                <AllowedRoute permissionsKey="view-loans">
                  <LoansPage loanType={LoanTypePayday} />
                </AllowedRoute>
              }
            />

            <Route
              path="retail-loans"
              element={
                <AllowedRoute permissionsKey="view-loans">
                  <LoansPage loanType={LoanTypeRetail} />
                </AllowedRoute>
              }
            />

            <Route
              path="asset-financing"
              element={
                <AllowedRoute permissionsKey="view-loans">
                  <LoansPage loanType={LoanTypeAssetFinancing} />
                </AllowedRoute>
              }
            />

            <Route
              path="loan-partners"
              element={
                <AllowedRoute permissionsKey="view-loan-partners">
                  <LoanPartnersPage />
                </AllowedRoute>
              }
            />

            <Route
              path="loan-partners/:id"
              element={
                <AllowedRoute permissionsKey="view-loans">
                  <LoansPage loanType={""} />
                </AllowedRoute>
              }
            />

            <Route
              path="loan-partners/:id/admin"
              element={
                <AllowedRoute permissionsKey="view-loan-partner-admins">
                  <LoanPartnersAdminUsersPage />
                </AllowedRoute>
              }
            ></Route>
          </Route>

          <Route
            path="loanpartner/:partnerId/admin-logs/:id"
            element={
              <AllowedRoute permissionsKey="view-activity-logs">
                <PartnerLoansAdminLogsPage />
              </AllowedRoute>
            }
          ></Route>

          <Route
            path="collections"
            element={
              <AllowedRoute permissionsKey="view-collections">
                <CollectionsPage />
              </AllowedRoute>
            }
          ></Route>

          <Route path="customers">
            {CustomerRoutes.map((r) => (
              <Route
                key={r.path}
                path={r.path}
                element={
                  <AllowedRoute permissionsKey={r.permissionsKey}>
                    <r.component />
                  </AllowedRoute>
                }
              ></Route>
            ))}
          </Route>

          <Route path="payroll">
            {PayrollRoutes.map((r) => (
              <Route
                key={r.path}
                path={r.path}
                element={
                  <AllowedRoute permissionsKey={r.permissionsKey}>
                    <r.component />
                  </AllowedRoute>
                }
              ></Route>
            ))}
          </Route>

          <Route
            path="admin-users"
            element={
              <AllowedRoute permissionsKey="view-admins">
                <AdminUsersPage />
              </AllowedRoute>
            }
          ></Route>
          <Route
            path="admin-logs/:id"
            element={
              <AllowedRoute permissionsKey="view-activity-logs">
                <AdminLogsPage />
              </AllowedRoute>
            }
          ></Route>

          <Route path="settings">
            {SettingsRoutes.map((r) => (
              <Route
                key={r.path}
                path={r.path}
                element={
                  <AllowedRoute
                    permissionsKey={r.permissionsKey}
                    permissionsKeys={r.permissionsKeys}
                  >
                    <r.component />
                  </AllowedRoute>
                }
              ></Route>
            ))}
          </Route>

          <Route
            path="conversations"
            element={
              <AllowedRoute permissionsKey="view-bulk-messages">
                <ConversationsPage />
              </AllowedRoute>
            }
          ></Route>

          <Route
            path="notifications"
            element={
              <AllowedRoute permissionsKey="">
                <NotificationsPage />
              </AllowedRoute>
            }
          ></Route>
        </Route>

        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default App;
