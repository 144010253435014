import { useState } from "react";
import { BiCaretDown } from "react-icons/bi";

import { DelayedActionButton } from "../../../../components/Button";
import FieldCreationModal from "./FieldCreationModal";

import useToggle from "../../../../hooks/useToggle.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";

import { splitDropdownStr } from "../../../../utils/helpers";

import {
  EDIT_SECTION_TITLE,
  REMOVE_FIELD,
  REMOVE_SECTION,
} from "./partnerReducer";

const headers = ["Name", "Type", "Optional", "Field Values", ""];

const SectionDropDown = ({
  section,
  dispatch,
  applicationForm,
  dropdownArr,
}) => {
  const showMessageModal = useMessageModal();
  const [dropdownOpened, _toggleDropdown] = useToggle(false);
  const [editTitle, setEditTitle] = useState(false);
  const toggleDropdown = () => {
    if (dropdownOpened) {
      setEditTitle(false);
    }
    _toggleDropdown();
  };
  const [fieldData, setFieldData] = useState(null);
  const [sectionName, setSectionName] = useState(section.name ?? "");

  const openFieldCreationModal = (field) => {
    setFieldData({
      name: field?.name ?? "",
      type: field?.type ?? "",
      fieldValues: field?.fieldValues ?? [],
      optional: field?.optional ?? false,
      editing: !!field?.name ?? false,
    });
  };
  const closeFieldCreationModal = () => setFieldData(null);

  const handleEditTitle = (e) => {
    e.stopPropagation();
    if (!dropdownOpened) {
      toggleDropdown();
      setEditTitle((prev) => !prev);
      return;
    }
    setEditTitle((prev) => !prev);
  };

  const handleRemoveSection = (name) => {
    dispatch({
      type: REMOVE_SECTION,
      payload: { name },
    });
  };

  const handleNameChange = ({ target }) => {
    const { value } = target;

    if (!value.trim())
      return showMessageModal({
        title: "Error",
        message: "Section name cannot be empty",
        isError: true,
        closeButtonText: "Close",
      });

    const nameExists =
      applicationForm &&
      applicationForm.find(
        (item) => item.name?.toLowerCase() === value.toLowerCase()
      );

    if (nameExists) {
      return showMessageModal({
        title: "Error",
        message: "Section already exists",
        isError: true,
        closeButtonText: "Close",
      });
    }
    setSectionName(value);
    dispatch({
      type: EDIT_SECTION_TITLE,
      payload: { name: value, oldName: section.name },
    });
  };

  const handleDeleteField = (fieldName) => {
    dispatch({
      type: REMOVE_FIELD,
      payload: { sectionName: section.name, fieldName },
    });
  };

  return (
    <div className="section__dropdown">
      <div
        className="flex gap between-center dropdown__header"
        onClick={toggleDropdown}
      >
        <p className="fw-600 flex gap-8 start center">
          <span>
            {section.name}&nbsp;
            <span className="gray-60">
              {section.fields?.length <= 1 ? (
                <>({section.fields?.length} Field)</>
              ) : (
                <>({section.fields?.length} Fields)</>
              )}
            </span>
          </span>
          <span
            className="fs-14"
            onClick={(e) => handleEditTitle(e)}
            style={{ color: "#549dc6" }}
          >
            {editTitle ? "Close edit title" : "Edit title"}
          </span>
        </p>
        <p className="flex gap-8 center">
          <DelayedActionButton
            className="btn danger light"
            text="Remove"
            confirmText="Confirm Remove"
            busyText="Removing"
            clickAction={() => handleRemoveSection(section.name)}
          />
          <BiCaretDown
            size={18}
            className={
              dropdownOpened ? "dropdown-icon rotate-180" : "dropdown-icon"
            }
          />
        </p>
      </div>
      <div
        className={
          dropdownOpened ? "field__dropdown opened" : "field__dropdown"
        }
      >
        <div>
          {editTitle && (
            <>
              <label htmlFor="sectionName">Section Title</label>
              <input
                id="sectionName"
                type="text"
                className="mb-16"
                placeholder=""
                value={sectionName}
                onChange={handleNameChange}
              />
            </>
          )}
          <div>
            <div className="flex between-center mb-16 fw-600">
              <p className="">Fields</p>
              <button
                className="btn color-primary"
                onClick={() => openFieldCreationModal()}
              >
                Add Field
              </button>
            </div>
            <div className="mb-16">
              {!section?.fields || section?.fields?.length === 0 ? (
                <p className="box">No fields added yet</p>
              ) : (
                <table className="fs-16 box statement">
                  <thead>
                    <tr>
                      {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {section?.fields?.map((field, index) => {
                      const fieldValuesLen = splitDropdownStr(
                        field.type
                      )?.length;
                      return (
                        <tr key={index} className="fw-600">
                          <td>{field.name}</td>
                          <td>{field.type}</td>
                          <td>{`${field.optional}`}</td>
                          <td>
                            {fieldValuesLen > 0 ? fieldValuesLen : "None"}
                          </td>
                          <td className="flex end">
                            <button
                              className="btn color-primary"
                              onClick={() => openFieldCreationModal(field)}
                            >
                              Edit
                            </button>
                            <DelayedActionButton
                              className="btn tart-orange"
                              text="Delete"
                              confirmText="Confirm Delete"
                              busyText="Deleting"
                              clickAction={() => handleDeleteField(field.name)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {!!fieldData && (
        <FieldCreationModal
          section={section}
          fieldData={fieldData}
          dispatch={dispatch}
          dropdownArr={dropdownArr}
          dismissModal={closeFieldCreationModal}
        />
      )}
    </div>
  );
};

export default SectionDropDown;
