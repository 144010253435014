/**
 * Formats array of account numbers to a string.
 * @param arr - array containing account numbers eg [
  "1100026420",
  "1300026426",
  "1400026429",
  "1500026422",
  "1600026425",
  "1700026428",
  "1200026423",
].
 * @returns A string eg `1100026420, 1300026426 + 5 more`.
 */
export const formatAccts = (arr) => {
  if (!arr) return "";
  if (arr.length === 1) return arr[0];
  if (arr.length > 1 && arr.length < 2) return arr.join(", ");
  return `${arr[0]}, ${arr[1]} + ${arr.length - 2} more`;
};

const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const type = isSafari() ? "application/csv" : "text/csv";

/**
 * Creates a csv file from a string and downloads it.
 * @param str - string to be encoded to csv.
 * @param name - name used to save downloaded csv file.
 * @returns void`.
 */
export const createCSV = (str, name) => {
  const csvContent = `data:${type};charset=utf-8,"\uFEFF",${str}`;
  const encodedURI = encodeURI(csvContent);

  const link = document.createElement("a");
  if (link.download !== undefined) {
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", `${name}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Creates an array of values from a string containing comma separated values within parenthesis.
 * @param str - string containing comma separated values eg "dropdown(Junior, Senior, Manager)".
 * @returns array of values`.
 */
const getFieldValues = (str) => {
  if (!str || typeof str !== "string") return [];
  const init = str.indexOf("(");
  const fin = str.indexOf(")");
  const commaValues = str.slice(init + 1, fin);
  const values = commaValues.split(",").map((item) => item.trim());
  return values;
};

/**
 * Creates an array of objects to be used by React-select for displaying loan restrictions.
 * @param data - array of sections with a name key and a fields key which is an array of objects with a name field and type field.
 * @param restrictionsArr - array of dropdown fields with title and value.
 * @returns array of objects with value, label and restrictions as keys. This object can then be used by React-select`.
 */
export const findFieldsWithDropdown = (data = [], restrictionsArr = []) => {
  if (!data) return [];
  const newArr = [];
  data.forEach((item) => {
    if (item.fields && item.fields.length > 0) {
      item.fields.forEach((field) => {
        if (field.type.includes("dropdown")) {
          const valuesArr = getFieldValues(field.type);
          const restrictionsObj = restrictionsArr.find(
            (obj) => obj.restrictionFormFieldID === `${item.name}.${field.name}`
          );

          newArr.push({
            value: `${item.name}.${field.name}`,
            label: `${capitalizeFirstLetter(
              item.name
            )} - ${capitalizeFirstLetter(field.name)}`,
            restrictions: restrictionsObj
              ? restrictionsObj.restrictions
              : valuesArr.reduce((o, key) => ({ ...o, [key]: "" }), {}),
          });
        }
      });
    }
  });
  return newArr;
};

/**
 * Creates an array of dropdown options from the  dropdown string.
 * @param str - dropdown string eg "dropdown(Silver, Gold, Diamond)".
 * @returns array of dropdown options eg ["Silver", "Gold", "Diamond"].
 */
export const splitDropdownStr = (str) => {
  if (!str || typeof str !== "string" || !str.includes("dropdown")) return [];
  const init = str.indexOf("(");
  const fin = str.indexOf(")");
  const commaValues = str.slice(init + 1, fin);
  return commaValues.split(",").map((item) => item.trim());
};
