import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import { InputField } from "../../../../components/Input";
import { DelayedActionButton } from "../../../../components/Button";
import { sendPostRequest, getPermissions } from "../../../../config/swr";
import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";

const UPDATE_FLEXI_KEY = "update-flexi-investments-settings";

const FlexiInvestmentSettings = ({ flexi }) => {
  const permissions = getPermissions();

  const canUpdateFlexi = permissions[UPDATE_FLEXI_KEY];
  const [formData, setFormData] = useState(flexi);

  const [editTenureID, setEditTenureID] = useState(-1);
  const [tenureData, setTenureData] = useState("");

  const [editWithdrawalID, setEditWithdrawalID] = useState(-1);
  const [withdrawalData, setWithdrawalData] = useState({
    withdrawalType: "",
    rate: "",
    description: "",
  });

  const { trigger } = useSWRMutation(
    "/settings/investments/flexi",
    sendPostRequest
  );

  const form = useForm();
  const showMessageModal = useMessageModal();

  const withdrawalDataFromForm = () => {
    return {
      ...withdrawalData,
      rate: +withdrawalData.rate,
    };
  };

  const resetWithdrawalData = () => {
    setWithdrawalData({
      withdrawalType: "",
      rate: "",
      description: "",
    });
    setEditWithdrawalID(-1);
  };

  const onFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "minimumInvestmentAmount") {
      setFormData({ ...formData, [name]: +value });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const onTenureEdit = (index) => {
    setEditTenureID(index);
    setTenureData(formData.tenures[index]);
  };

  const onTenureRemoved = (index) => {
    setEditTenureID(-1);
    setTenureData("");
    setFormData({
      ...formData,
      tenures: [
        ...formData.tenures.slice(0, index),
        ...formData.tenures.slice(index + 1),
      ],
    });
  };

  const onTenureDataChange = (e) => {
    const { value } = e.target;
    setTenureData(value);
  };

  const onTenureDataSubmit = () => {
    if (!tenureData) return;

    if (editTenureID < 0) {
      setFormData({
        ...formData,
        tenures: [...formData.tenures, +tenureData],
      });
    } else {
      const newArr = formData.tenures.slice();
      newArr[editTenureID] = tenureData;
      setFormData({
        ...formData,
        tenures: newArr,
      });
    }
    setTenureData("");
    setEditTenureID(-1);
  };

  const onWithdrawalEdit = (index) => {
    setEditWithdrawalID(index);
    setWithdrawalData({
      ...formData.flexiInterestRate[index],
    });
  };

  const onWithdrawalDisabled = (index) => {
    const newArr = formData.flexiInterestRate?.slice();
    const wdObj = newArr[index];
    newArr[index] = {
      ...wdObj,
      isDisabled: !wdObj?.isDisabled,
    };
    setFormData({
      ...formData,
      flexiInterestRate: newArr,
    });
  };

  const onWithdrawalDataChange = (e) => {
    const { name, value } = e.target;
    setWithdrawalData({ ...withdrawalData, [name]: value });
  };

  const onWithdrawalDataSubmit = () => {
    if (!withdrawalData.withdrawalType || !withdrawalData.rate) return;

    if (editWithdrawalID < 0) {
      setFormData({
        ...formData,
        flexiInterestRate: [
          ...formData.flexiInterestRate,
          withdrawalDataFromForm(),
        ],
      });
    } else {
      const newArr = formData.flexiInterestRate?.slice();
      newArr[editWithdrawalID] = withdrawalDataFromForm();
      setFormData({
        ...formData,
        flexiInterestRate: newArr,
      });
    }
    resetWithdrawalData();
  };

  const handleSubmit = async () => {
    if (!canUpdateFlexi) return;

    form.submitStarted();

    const data = {
      ...formData,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <>
      <div className="flex gap equal-widths box mb-28">
        <div>
          <InputField
            label="Investment Name"
            type="text"
            placeholder="Flexi Investment"
            className="mb-16"
            name="name"
            value={formData.name}
            onChange={onFormFieldChange}
          />
          <InputField
            label="Package Summary"
            multiline
            placeholder="Enter Package Summary here"
            className="mb-16"
            name="summary"
            value={formData.summary}
            onChange={onFormFieldChange}
          />
        </div>
        <div>
          <InputField
            label="Product Code"
            type="text"
            placeholder="Enter Product Code here"
            className="mb-16"
            name="productCode"
            value={formData.productCode}
            onChange={onFormFieldChange}
          />
          <InputField
            label="Product ID"
            type="text"
            name="productID"
            className="mb-16"
            value={formData.productID}
            onChange={onFormFieldChange}
          />
          <InputField
            label="Min. Investment Amount"
            type="number"
            min="0"
            placeholder="N 1000"
            name="minimumInvestmentAmount"
            value={formData.minimumInvestmentAmount}
            onChange={onFormFieldChange}
          />
        </div>
      </div>

      <hr />

      <div className="flex start gap equal-widths mb-28">
        <div>
          <h2 className="fs-20 mb-16">Investment Tenures</h2>
          <div className="box">
            {!formData.tenures || formData.tenures?.length === 0 ? (
              <>No Tenures added yet</>
            ) : (
              formData.tenures?.map((tenure, i) => (
                <div
                  key={tenure}
                  className="flex gap center info-row-with-actions"
                >
                  <div className="text">
                    <h3 className="fs-20" style={{ marginBottom: 0 }}>
                      {tenure} Days
                    </h3>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary light"
                      onClick={() => onTenureEdit(i)}
                    >
                      Edit
                    </button>
                    <DelayedActionButton
                      className="btn danger light"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      isBusy={form.state.submitting}
                      clickAction={() => onTenureRemoved(i)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">
            {editTenureID < 0 ? "Add" : "Edit"} Investment Tenure
          </h2>
          <div className="box">
            <InputField
              label="Number of Days"
              type="number"
              placeholder="Should be multiples of 30"
              className="mb-32"
              value={tenureData}
              onChange={onTenureDataChange}
            />
            <button
              className="btn primary light fullwidth"
              onClick={onTenureDataSubmit}
              disabled={!tenureData}
            >
              {editTenureID < 0 ? "Add" : "Update"}
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div className="flex start gap equal-widths mb-28">
        <div>
          <h2 className="fs-20 mb-16">Withdrawal methods</h2>
          <div className="box">
            {!formData.flexiInterestRate ||
            formData.flexiInterestRate?.length === 0 ? (
              <>No Interest Rates added yet</>
            ) : (
              formData.flexiInterestRate?.map((wm, i) => (
                <div
                  key={wm.withdrawalType}
                  className="flex gap center info-row-with-actions"
                >
                  <div className="text">
                    <h3 className="fs-20" style={{ marginBottom: 0 }}>
                      {wm.withdrawalType}
                      <span className="btn primary light fs-14">
                        {wm.isDisabled ? "Disabled" : `${wm.rate}% ROI`}
                      </span>
                    </h3>
                    <p className="charcoal-60">{wm?.description}</p>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary light"
                      onClick={() => onWithdrawalEdit(i)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn danger light"
                      onClick={() => onWithdrawalDisabled(i)}
                    >
                      {wm?.isDisabled ? "Enable" : "Disable"}
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">Edit withdrawal method</h2>
          <div className="box">
            <InputField
              label="Name of Withdrawal Method"
              type="text"
              className="mb-16"
              name="withdrawalType"
              value={withdrawalData.withdrawalType}
              onChange={onWithdrawalDataChange}
            />
            <InputField
              label="Description (100 words)"
              type="text"
              className="mb-16"
              name="description"
              value={withdrawalData.description}
              onChange={onWithdrawalDataChange}
            />
            <InputField
              label="Expected ROI (%)"
              type="number"
              placeholder="1.2"
              className="mb-32"
              name="rate"
              value={withdrawalData.rate}
              onChange={onWithdrawalDataChange}
            />
            <button
              className="btn primary light fullwidth"
              onClick={onWithdrawalDataSubmit}
            >
              Update
            </button>
          </div>
        </div>
      </div>

      {canUpdateFlexi && (
        <button
          className="fullwidth dark primary btn"
          onClick={handleSubmit}
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}
    </>
  );
};

export default FlexiInvestmentSettings;
