import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import { OutlineButton } from "../../../components/Button";
import { sendPostRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";

const DirectDeposit = ({
  cancel,
  loan,
  mutate,
  amountDue,
  dismissModal,
  canAddRepayment,
}) => {
  const [formData, setFormData] = useState({});

  const { trigger } = useSWRMutation("/loan/add-repayment", sendPostRequest);

  const { state, submitStarted, submitError, submitSuccess } = useForm();
  const showMessageModal = useMessageModal();
  const buttonDisabled =
    !formData.transactionReference ||
    !formData.amount ||
    !formData.timestamp ||
    !formData.description;

  const handleMax = () => {
    setFormData({ ...formData, amount: amountDue });
  };

  const onFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitForm = async (e) => {
    if (!canAddRepayment || buttonDisabled) return;
    e.preventDefault();
    submitStarted();

    const data = {
      loanID: loan.loanID,
      customerID: loan.customerID,
      repayment: {
        ...formData,
        amount: +formData.amount,
        timestamp: new Date(formData.timestamp).getTime() / 1000,
      },
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message || "Successfully submitted",
        isError: false,
        closeButtonText: "Close",
      });
      submitSuccess();
      mutate();
      cancel();
      dismissModal();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      submitError();
    }
  };

  return (
    <form onSubmit={submitForm}>
      <section className="flex col gap-8 fs-16 mb-32">
        <label className="flex col gap-4">
          <div>Date of Payment</div>
          <input
            className="mini"
            type="date"
            placeholder="Select Date of Payment"
            name="timestamp"
            value={formData.timestamp}
            onChange={onFormDataChange}
          />
        </label>
        <label className="flex col gap-4">
          <div>Transaction Reference ID</div>
          <input
            className="mini"
            type="text"
            placeholder="Enter Transaction Reference ID"
            name="transactionReference"
            value={formData.transactionReference}
            onChange={onFormDataChange}
          />
        </label>
        <label className="flex col gap-4 repayment-amount-input">
          <div>Amount (&#8358;)</div>
          <input
            className="mini"
            type="number"
            placeholder="Enter Amount"
            name="amount"
            value={formData.amount}
            onChange={onFormDataChange}
          />
          <span className="repayment-amount-input-button" onClick={handleMax}>
            max
          </span>
        </label>
        <label className="flex col gap-4">
          <div>Description</div>
          <input
            className="mini"
            type="text"
            placeholder="Enter Description"
            name="description"
            value={formData.description}
            onChange={onFormDataChange}
          />
        </label>
      </section>
      <section className="flex col">
        <OutlineButton disabled={state.submitting || buttonDisabled}>
          {state.submitting ? <FiLoader /> : "Add Loan Repayment"}
        </OutlineButton>
        <button className="transparent btn" type="button" onClick={cancel}>
          Cancel
        </button>
      </section>
    </form>
  );
};

export default DirectDeposit;
