import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import { InputField } from "../../../../components/Input";
import { DelayedActionButton } from "../../../../components/Button";
import { sendPostRequest, getPermissions } from "../../../../config/swr";
import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";

const UPDATE_STANDARD_KEY = "update-standard-investments-settings";

const StandardInvestmentSettings = ({ standard }) => {
  const permissions = getPermissions();

  const canUpdateStandard = permissions[UPDATE_STANDARD_KEY];
  const [formData, setFormData] = useState(standard);

  const [tenureData, setTenureData] = useState({
    tenure: "",
    rate: "",
  });
  const [editTenureID, setEditTenureID] = useState(-1);

  const { trigger } = useSWRMutation(
    "/settings/investments/standard",
    sendPostRequest
  );

  const form = useForm();
  const showMessageModal = useMessageModal();

  const tenureDataFromForm = () => {
    return {
      tenure: +tenureData.tenure,
      rate: +tenureData.rate,
    };
  };

  const resetTenureData = () => {
    setTenureData({
      tenure: "",
      rate: "",
    });
    setEditTenureID(-1);
  };

  const onFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "minimumInvestmentAmount") {
      setFormData({ ...formData, [name]: +value });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const onTenureEdit = (index) => {
    setEditTenureID(index);
    setTenureData({
      ...formData.investmentTenures[index],
    });
  };

  const onTenureRemoved = (index) => {
    resetTenureData();
    setFormData({
      ...formData,
      investmentTenures: [
        ...formData.investmentTenures.slice(0, index),
        ...formData.investmentTenures.slice(index + 1),
      ],
    });
  };

  const onTenureDataChange = (e) => {
    const { name, value } = e.target;
    setTenureData({ ...tenureData, [name]: value });
  };

  const onTenureDataSubmit = () => {
    if (!tenureData.tenure || !tenureData.rate) return;
    if (editTenureID < 0) {
      setFormData({
        ...formData,
        investmentTenures: [
          ...formData.investmentTenures,
          tenureDataFromForm(),
        ],
      });
    } else {
      const newArr = formData.investmentTenures.slice();
      newArr[editTenureID] = tenureDataFromForm();
      setFormData({
        ...formData,
        investmentTenures: newArr,
      });
    }
    resetTenureData();
  };

  const handleSubmit = async () => {
    if (!canUpdateStandard) return;
    form.submitStarted();

    const data = {
      ...formData,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <>
      <div className="flex gap equal-widths box mb-28">
        <div>
          <InputField
            label="Investment Name"
            type="text"
            placeholder="Standard Investment"
            className="mb-16"
            name="name"
            value={formData.name}
            onChange={onFormFieldChange}
          />
          <InputField
            label="Package Summary"
            multiline
            placeholder="Enter Package Summary here"
            className="mb-16"
            name="summary"
            value={formData.summary}
            onChange={onFormFieldChange}
          />
        </div>
        <div>
          <InputField
            label="Product Code"
            type="text"
            placeholder="Enter Product Code here"
            className="mb-16"
            name="productCode"
            value={formData.productCode}
            onChange={onFormFieldChange}
          />
          <InputField
            label="Product ID"
            type="text"
            name="productID"
            className="mb-16"
            value={formData.productID}
            onChange={onFormFieldChange}
          />
          <InputField
            label="Min. Investment Amount"
            type="number"
            min="0"
            placeholder="N 1000"
            name="minimumInvestmentAmount"
            value={formData.minimumInvestmentAmount}
            onChange={onFormFieldChange}
          />
        </div>
      </div>

      <hr />

      <div className="flex start gap equal-widths mb-28">
        <div>
          <h2 className="fs-20 mb-16">Investment Tenures</h2>
          <div className="box">
            {!formData.investmentTenures ||
            formData.investmentTenures?.length === 0 ? (
              <>No Investment Tenures added yet</>
            ) : (
              formData.investmentTenures?.map((tn, i) => (
                <div
                  key={tn.tenure}
                  className="flex gap center info-row-with-actions"
                >
                  <div className="text">
                    <h3 className="fs-20">{tn.tenure} Days</h3>
                    <p className="charcoal-60">{tn.rate}%</p>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary light"
                      onClick={() => onTenureEdit(i)}
                    >
                      Edit
                    </button>
                    <DelayedActionButton
                      className="btn danger light"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      isBusy={form.state.submitting}
                      clickAction={() => onTenureRemoved(i)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">
            {editTenureID < 0 ? "Add" : "Edit"} Investment Tenure
          </h2>
          <div className="box">
            <InputField
              label="Number of Days"
              type="text"
              placeholder="Should be multiples of 30"
              className="mb-16"
              name="tenure"
              value={tenureData.tenure}
              onChange={onTenureDataChange}
            />
            <InputField
              label="Expected ROI (%)"
              type="text"
              placeholder="1-100"
              className="mb-32"
              name="rate"
              value={tenureData.rate}
              onChange={onTenureDataChange}
            />
            <button
              className="btn primary light fullwidth"
              onClick={onTenureDataSubmit}
              disabled={!tenureData.tenure || !tenureData.rate}
            >
              {editTenureID < 0 ? "Add" : "Update"}
            </button>
          </div>
        </div>
      </div>

      {canUpdateStandard && (
        <button
          className="fullwidth dark primary btn"
          onClick={handleSubmit}
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}
    </>
  );
};

export default StandardInvestmentSettings;
