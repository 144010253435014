import { useReducer, useState } from "react";
import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";

import Modal from "../../../../components/Modal";
import AddSectionModal from "./AddSectionModal";
import BasicPartnerInfo from "./BasicPartnerInfo";
import CustomerApplicationForm from "./CustomerApplicationForm";
import PartnerLoanTenure from "./PartnerLoanTenure";

import ArrowLeftIcon from "../../../../assets/icons/arrowleft";

import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";
import { findFieldsWithDropdown } from "../../../../utils/helpers";
import { sendPatchRequest, sendPostRequest } from "../../../../config/swr";

import { partnerReducer } from "./partnerReducer";

const PartnerDetailsModal = ({ partner, dismissModal, mutate }) => {
  const { state, submitStarted, submitError, submitSuccess } = useForm();

  const [formState, dispatch] = useReducer(partnerReducer, {
    ...partner,
    dropdownArr: partner?.loanRestrictions
      ? [{ ...partner.loanRestrictions }]
      : [],
  });

  const { trigger: postTrigger } = useSWRMutation(
    "/loan-partner",
    sendPostRequest
  );

  const { trigger: patchTrigger } = useSWRMutation(
    `/loan-partner?partnerID=`,
    sendPatchRequest
  );
  const showMessageModal = useMessageModal();

  const [addSectionModal, setAddSectionModal] = useState(false);
  const openAddSectionModal = () => setAddSectionModal(true);
  const closeAddSectionModal = () => setAddSectionModal(false);

  const {
    name,
    description,
    eligibleAccountProductCode,
    loanDisburser,
    disbursementSource,
    repaymentDestination,
    autoDebitCustomers,
    applicationForm,
    loanPackages,
    loanRestrictions,
    fieldEdited,
    dropdownArr,
  } = { ...formState };

  const selectOptions = findFieldsWithDropdown(applicationForm, dropdownArr);

  const handleSubmit = async () => {
    submitStarted();
    const { id, fieldEdited, dropdownArr, loanSummaries, ...rest } = formState;

    const data = partner
      ? {
          payload: { ...rest },
          subUrl: id,
        }
      : { ...rest };

    const trigger = partner ? patchTrigger : postTrigger;

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message || "Successfully submitted",
        isError: false,
        closeButtonText: "Close",
      });
      submitSuccess();
      mutate();
      dismissModal();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      submitError();
    }
  };

  return (
    <Modal fullScreen handleClose={dismissModal}>
      <div className="successful-batch">
        <header className="box-shadow flex between-center">
          <div className="flex gap-12 center start">
            <span onClick={dismissModal} style={{ cursor: "pointer" }}>
              <ArrowLeftIcon width={14} style={{ verticalAlign: "middle" }} />
            </span>
            <h3 className="fs-20">Loan Partners</h3>
          </div>
        </header>

        <div
          className="successful-batch__content"
          style={{ padding: "1.5rem" }}
        >
          <BasicPartnerInfo
            name={name}
            description={description}
            loanDisburser={loanDisburser}
            disbursementSource={disbursementSource}
            repaymentDestination={repaymentDestination}
            autoDebitCustomers={autoDebitCustomers}
            eligibleAccountProductCode={eligibleAccountProductCode}
            dispatch={dispatch}
          />

          <hr className="mb-32" />

          <div className="mb-32">
            <div className="flex between-center mb-16">
              <h2 className="fs-20">customer application form</h2>
              <button
                className="btn primary light"
                onClick={openAddSectionModal}
              >
                Add Section
              </button>
            </div>
            <div className="box" style={{ padding: "6px 24px" }}>
              <CustomerApplicationForm
                key={applicationForm?.length}
                applicationForm={applicationForm}
                dispatch={dispatch}
                fieldEdited={fieldEdited}
                loanRestrictions={loanRestrictions}
                dropdownArr={dropdownArr}
                selectOptions={selectOptions}
              />
            </div>
          </div>

          <PartnerLoanTenure loanPackages={loanPackages} dispatch={dispatch} />

          <button
            className="fullwidth dark primary btn"
            disabled={state.submitting}
            onClick={handleSubmit}
          >
            {state.submitting ? <FiLoader /> : "Save"}
          </button>
        </div>
      </div>

      {addSectionModal && (
        <AddSectionModal
          applicationForm={applicationForm}
          dispatch={dispatch}
          dismissModal={closeAddSectionModal}
        />
      )}
    </Modal>
  );
};

export default PartnerDetailsModal;
