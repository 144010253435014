import { useRef, useState } from "react";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import utc from "dayjs/plugin/utc";

import { SearchBox } from "../../components/Input";
import { OutlineButton } from "../../components/Button";

dayjs.extend(calendar);
dayjs.extend(utc);

export default function useConversationSelector({
  conversationTypes,
  defaultType,
}) {
  const [allConversations, setAllConversations] = useState({});
  const actionButtonRef = useRef(null);

  const setConversationList = (type, conversations, actionButton) => {
    actionButtonRef.current = actionButton;
    setAllConversations({
      ...allConversations,
      [type]: conversations ?? [],
    });
  };

  const getConversationList = (type) => {
    return allConversations[type].slice(); // return a copy.
  };

  const addConversationToList = (type, conversation) => {
    const currentList = getConversationList(type);
    currentList.splice(0, 0, conversation);
    setConversationList(type, currentList);
  };

  const [selectedConversationType, setSelectedConversationType] =
    useState(defaultType);

  const [selectedConversationID, setSelectedConversationID] = useState(-1);
  const unselectConversation = () => setSelectedConversationID(-1);

  const changeSelectedConversationType = (type) => {
    actionButtonRef.current = null;
    unselectConversation();
    setSelectedConversationType(type);
  };

  const selectedConversationList =
    allConversations[selectedConversationType] ?? [];

  const selectedConversation = selectedConversationID
    ? selectedConversationList.find((c) => c.id === selectedConversationID)
    : null;

  const {
    title: selectedConversationTitle,
    formatter: selectedConversationFormatter,
  } = conversationTypes[selectedConversationType];

  const ConversationSelector = () => {
    return (
      <div className="chat__history">
        <div className="tab-nav fs-16 mini gap-12 mb-20">
          {Object.keys(conversationTypes).map((item) => (
            <button
              className={`btn mini box${
                selectedConversationType === item ? " active" : ""
              }`}
              onClick={() => changeSelectedConversationType(item)}
            >
              {item}
            </button>
          ))}
        </div>

        <div className="searchbox-holder">
          <SearchBox
            className="searchconversations"
            placeholder="Search conversations"
          />

          {actionButtonRef.current && (
            <OutlineButton mini onClick={actionButtonRef.current.onClick}>
              {actionButtonRef.current.text}
            </OutlineButton>
          )}
        </div>

        <div className="chat__history-toptext flex between-center">
          <p className="fs-18">{selectedConversationTitle}</p>
        </div>

        <div className="conversations-list">
          {selectedConversationList
            .map(selectedConversationFormatter)
            .map((c) => (
              <div
                key={c.id}
                className="chat__history-notification flex center"
                onClick={() => setSelectedConversationID(c.id)}
              >
                <div className="chat__history-notification-left">
                  <h3 className="fs-20">{c.target}</h3>
                  {c.title ? (
                    <>
                      <h3 className="fs-16">{c.title}</h3>
                      <p className="charcoal-60">{c.message}</p>
                    </>
                  ) : (
                    <p>{c.message}</p>
                  )}
                </div>
                <span>
                  {dayjs(c.lastUpdated * 1000).utc().format("DD-MM-YY")}
                  &nbsp;&bull;&nbsp;
                  {dayjs(c.lastUpdated * 1000).utc().format("hh:mm a")}
                </span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return [
    ConversationSelector,
    setConversationList,
    getConversationList,
    addConversationToList,
    selectedConversationType,
    selectedConversation,
    unselectConversation,
  ];
}
