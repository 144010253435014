import React, { useState } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import { BiCaretDown } from "react-icons/bi";
import { FiLoader } from "react-icons/fi";
import { useOutletContext } from "react-router";
import useSWRMutation from "swr/mutation";
import { mutate as globalMutate } from "swr";

import EllipseIcon from "../../assets/icons/ellipse";
import NotificationIcon from "../../assets/icons/notificationicon";
import DataFetcher from "../../components/DataFetcher";
import Dropdown from "../../components/Dropdown";
import Modal from "../../components/Modal";
import { sendPatchRequest } from "../../config/swr";
import useForm from "../../hooks/useForm.hook";
import useMessageModal from "../../hooks/useMessageModal.hook";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const selectUnread = (arr) => arr.filter((item) => !item.read);
const selectRead = (arr) => arr.filter((item) => item.read);

const NotificationsPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Notifications");

  return (
    <DataFetcher
      url={`/notifications`}
      buildUI={(data, mutate) => (
        <NotificationsDisplay notifications={data} mutate={mutate} />
      )}
    />
  );
};

const NotificationsDisplay = ({ notifications, mutate }) => {
  const [sortOption, setSortOption] = useState("Most Recent");
  const [notificationData, setNotificationData] = useState(null);

  const { trigger } = useSWRMutation(
    `/notifications/mark-as-read`,
    sendPatchRequest
  );
  const showMessageModal = useMessageModal();

  const form = useForm();

  const noUnreads = selectUnread(notifications.notifications).length === 0;

  const sortOptions = noUnreads
    ? ["Most Recent"]
    : ["Most Recent", "Unread First"];

  const handleSort = (option) => {
    setSortOption(option);
  };

  const openNotificationModal = async ({
    id,
    title,
    body,
    timestamp,
    read,
  }) => {
    setNotificationData({
      title,
      body,
      timestamp,
    });
    if (!read) {
      const data = {
        payload: {
          notificationIDs: [id],
        },
      };

      try {
        await trigger(data);
        mutate();
        globalMutate("/summary");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleAll = async () => {
    form.submitStarted();

    const unreads = selectUnread(notifications.notifications);
    const unreadIds = unreads.map((item) => item.id);
    const data = {
      payload: {
        notificationIDs: [...unreadIds],
      },
    };

    try {
      await trigger(data);
      mutate();
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  const showNotification = (notification) => {
    const { id, title, body, timestamp, read } = notification;
    return (
      <React.Fragment key={id}>
        <div
          className={`${read ? "" : "unread"} log-card mb-12 flex center`}
          onClick={() => openNotificationModal(notification)}
        >
          <EllipseIcon filled={!read} />
          <div>
            <h3 className="fs-18">{title}</h3>
            <p className="fs-16">{body}</p>
            <p className="fs-14">
              {dayjs(timestamp * 1000).utc().format("dddd, MMMM DD YYYY hh:mm a")}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="notifications">
      <div className="flex between-center mb-12">
        <Dropdown
          className="fs-20 flex center fw-600 flex gap-8"
          options={sortOptions}
          selectedOption={sortOption}
          onOptionSelected={handleSort}
          style={{ color: "#4A7F63" }}
        >
          {sortOption} <BiCaretDown size={18} />
        </Dropdown>

        <button
          disabled={noUnreads || form.state.submitting}
          className="btn primary light"
          onClick={handleAll}
        >
          {form.state.submitting ? <FiLoader /> : "Mark all as read"}
        </button>
      </div>
      <div className="notifications__log">
        {sortOption === "Unread First" ? (
          <>
            <h3 className="fs-20 mb-20">Unread</h3>
            <div className="mb-20">
              {selectUnread(notifications.notifications).map((notification) =>
                showNotification(notification)
              )}
            </div>

            <h3 className="fs-20 mb-20">Read</h3>
            <div className="mb-20">
              {selectRead(notifications.notifications).map((notification) =>
                showNotification(notification)
              )}
            </div>
          </>
        ) : (
          notifications.notifications?.map((notification) =>
            showNotification(notification)
          )
        )}

        {form.state.submitting && (
          <div className="notifications__log-blockade"></div>
        )}
      </div>

      {!!notificationData && (
        <Modal notification handleClose={() => setNotificationData(null)}>
          <div className="notifications__modalcontent flex col gap-20 center">
            <NotificationIcon />

            <div>
              <h3 className="fs-24">{notificationData?.title}</h3>
              <p className="fs-14">
                {dayjs(notificationData?.timestamp * 1000).utc().format(
                  "dddd, MMMM DD YYYY hh:mm a"
                )}
              </p>
            </div>
            <p className="fs-16">{notificationData?.body}</p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default NotificationsPage;
