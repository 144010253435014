import { useState } from "react";
import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";

import Dropdown from "../../../../components/Dropdown";
import { SearchBox } from "../../../../components/Input";
import ArrowLeftIcon from "../../../../assets/icons/arrowleft";
import { FilterDark } from "../../../../assets/icons/filter";
// import MessageIcon from "../../../../assets/icons/messageicon";
import ExportIcon from "../../../../assets/icons/export";

import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";

import { createCSV } from "../../../../utils/helpers";
import { sendGetRequest } from "../../../../config/swr";

const headers = [
  "Full name",
  "date of birth",
  "tier",
  "email",
  "Account Number",
];
const sortOptions = ["Ascending"];

const SuccessfulBatchModal = ({ batch, handleClose }) => {
  const { trigger } = useSWRMutation(`/accounts/batches`, sendGetRequest);
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const form = useForm();
  const showMessageModal = useMessageModal();

  const handleSort = (option) => {
    setSortOption(option);
  };

  const handleClick = async () => {
    let filename = `${batch.id}_successes.csv`;

    form.submitStarted();

    try {
      const res = await trigger(`/${filename}`);
      createCSV(res.data, filename);
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred while exporting csv. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <>
      <div className="successful-batch">
        <header className="box-shadow flex between-center">
          <div className="flex gap-12 center start">
            <span onClick={handleClose} style={{ cursor: "pointer" }}>
              <ArrowLeftIcon width={14} style={{ verticalAlign: "middle" }} />
            </span>
            <h3 className="fs-20">Batch Create Account</h3>
          </div>
        </header>

        <div className="successful-batch__content">
          <div>
            <div
              className="flex between-center mb-32"
              style={{ padding: "24px" }}
            >
              <SearchBox placeholder={"Search Customer"} />

              <div className="flex gap-20 center fw-600 fs-16">
              <button
              className="flex center"
              disabled={form.state.submitting}
              onClick={() => handleClick()}
            >
              {form.state.submitting ? (
                <FiLoader />
              ) : (
                <>
                  <ExportIcon />
                  Export as CSV
                </>
              )}
            </button>

                <div>Batch Code: {batch.id}</div>

                <Dropdown
                  alignEnd
                  className="fs-16 flex center"
                  options={sortOptions}
                  selectedOption={sortOption}
                  onOptionSelected={handleSort}
                  style={{ paddingRight: "10px" }}
                >
                  <FilterDark />
                  {sortOption ? "Sort by: " + sortOption : "Sort"}
                </Dropdown>

                {/* <button
                  className="flex gap-8 center btn primary light fs-14 mini no-hover"
                  style={{ cursor: "pointer" }}
                >
                  <MessageIcon />
                  <span>Message All</span>
                </button> */}
              </div>
            </div>

            {!batch.successfulReports ||
            batch.successfulReports?.length === 0 ? (
              <h3 className="center-text">No reports</h3>
            ) : (
              <table className="fs-20 box" style={{ boxShadow: "unset" }}>
                <thead>
                  <tr>
                    {headers.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {batch.successfulReports.map((report, index) => (
                    <tr key={index} className="clickable fw-600">
                      <td>{`${report["First Name"]} ${report["Last Name"]}`}</td>
                      <td>{report["Date of Birth"] ?? "N/A"}</td>
                      <td>1</td>
                      <td>{report["Email"] ?? "N/A"}</td>
                      <td>{report["Account Number"] ?? "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessfulBatchModal;
