import AccountUpgradeRequestsPage from "./pages/customers/account-upgrade";
import AccountsCSVPage from "./pages/customers/accounts-csv";
import AddressUpdateRequestsPage from "./pages/customers/address-update";
import BatchAccountPage from "./pages/customers/batch-account";
import PendingCardRequestsPage from "./pages/customers/card-requests";
import ResetPinPage from "./pages/customers/reset-pin";
import ValidateCustomerInfoPage from "./pages/customers/validate-info";
import { PayrollPermissions } from "./pages/payroll";
import PayrollStaffListPage from "./pages/payroll/PayrollStaffListPage";
import PayrollHistoryPage from "./pages/payroll/PayrollHistoryPage";
import PayrollSettingsPage from "./pages/payroll/PayrollSettingsPage";

import {
  AccountsSettings,
  LoanSettings,
  CollectionsSettings,
  InvestmentsSettings,
  CardsSettings,
  SecurityRoleSettings,
  SecuritySettings,
} from "./pages/settings";
import PayrollPaymentReviewPage from "./pages/payroll/PayrollPaymentReviewPage";
import VirtualAccountsPage from "./pages/customers/virtual-accounts";

export const CustomerRoutes = [
  {
    path: "virtual-accounts",
    component: VirtualAccountsPage,
    permissionsKey: "view-virtual-accounts",
  },
  {
    path: "account-upgrade",
    component: AccountUpgradeRequestsPage,
    permissionsKey: "view-account-upgrade-requests",
  },
  {
    path: "address-update",
    component: AddressUpdateRequestsPage,
    permissionsKey: "view-address-update-requests",
  },
  {
    path: "cards",
    component: PendingCardRequestsPage,
    permissionsKey: "view-card-requests",
  },
  {
    path: "batch-account",
    component: BatchAccountPage,
    permissionsKey: "view-batch-accounts",
  },
  {
    path: "reset-pin",
    component: ResetPinPage,
    permissionsKey: "set-temporary-transaction-pin",
  },
  {
    path: "accounts-csv",
    component: AccountsCSVPage,
    permissionsKey: "export-accounts",
  },
  {
    path: "validate-customer-info",
    component: ValidateCustomerInfoPage,
    permissionsKey: "",
  },
];

export const PayrollRoutes = [
  {
    path: "staff",
    component: PayrollStaffListPage,
    permissionsKey: PayrollPermissions.VIEW_PAYROLL_STAFF_PERM,
  },
  {
    path: "upcoming-payments",
    component: PayrollPaymentReviewPage,
    permissionsKey: PayrollPermissions.VIEW_PAYROLL_UPCOMING_PAYMENT_PERM,
  },
  {
    path: "history",
    component: PayrollHistoryPage,
    permissionsKey: PayrollPermissions.VIEW_PAYROLL_HISTORY_PERM,
  },
  {
    path: "settings",
    component: PayrollSettingsPage,
    permissionsKey: PayrollPermissions.VIEW_PAYROLL_SETTINGS_PERM,
  },
];

export const SettingsRoutes = [
  {
    path: "general",
    component: AccountsSettings,
    permissionsKey: "view-general-settings",
  },
  {
    path: "loans",
    component: LoanSettings,
    permissionsKey: "",
    permissionsKeys: [
      "view-general-loan-settings",
      "view-payday-loan-settings",
      "view-retail-loan-settings",
    ],
  },
  {
    path: "collections",
    component: CollectionsSettings,
    permissionsKey: "",
    permissionsKeys: [
      "view-general-collection-settings",
      "view-target-collection-settings",
    ],
  },
  {
    path: "investments",
    component: InvestmentsSettings,
    permissionsKey: "view-investments-settings",
  },
  {
    path: "cards",
    component: CardsSettings,
    permissionsKey: "view-card-requests-settings",
  },
  {
    path: "security-roles",
    component: SecurityRoleSettings,
    permissionsKey: "view-security-roles",
  },
  {
    path: "security",
    component: SecuritySettings,
    permissionsKey: "",
  },
];
