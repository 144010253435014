import { useState } from "react";
import { useOutletContext } from "react-router-dom";

import DataFetcher from "../../../components/DataFetcher";
import PaydayLoanTab from "./tabs/PaydayLoanTab";
import RetailLoanTab from "./tabs/RetailLoanTab";
import GeneralLoanTab from "./tabs/GeneralLoanTab";
import AssetFinancingTab from "./tabs/AssetFinancingTab";

import { getPermissions } from "../../../config/swr";

const VIEW_GENERAL_KEY = "view-general-loan-settings";
const VIEW_PAYDAY_KEY = "view-payday-loan-settings";
const VIEW_RETAIL_KEY = "view-retail-loan-settings";

const Loan = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Loan Settings");

  return (
    <DataFetcher
      url={`/settings/loan`}
      buildUI={(data, mutate) => <LoanDisplay data={data} mutate={mutate} />}
    />
  );
};

const LoanDisplay = ({ data, mutate }) => {
  const { generalLoanSettings, paydayLoanSettings, retailLoanSettings } = data;

  const permissions = getPermissions();

  const canViewGeneral = permissions[VIEW_GENERAL_KEY];
  const canViewPayday = permissions[VIEW_PAYDAY_KEY];
  const canViewRetail = permissions[VIEW_RETAIL_KEY];
  const [view, setview] = useState(1);

  if (!canViewGeneral && !canViewPayday && !canViewRetail)
    return (
      <div className="flex center fs-20">
        You are not authorised to see this page
      </div>
    );

  return (
    <div className="settings">
      <div className="tab-nav mb-32">
        <button
          className={`btn box${view === 1 ? " active" : ""}`}
          onClick={() => setview(1)}
          disabled={!canViewGeneral}
        >
          General Settings
        </button>
        <button
          className={`btn box${view === 2 ? " active" : ""}`}
          onClick={() => setview(2)}
          disabled={!canViewPayday}
        >
          Payday Loan
        </button>
        <button
          className={`btn box${view === 3 ? " active" : ""}`}
          onClick={() => setview(3)}
          disabled={!canViewRetail}
        >
          Retail Loan
        </button>
        <button
          className={`btn box${view === 4 ? " active" : ""}`}
          onClick={() => setview(4)}
        >
          Asset Financing
        </button>
      </div>

      <div className="tabs mb-32">
        <h2 className="fs-20 mb-16">Loan Details</h2>

        {view === 1 && canViewGeneral && (
          <GeneralLoanTab
            generalLoanSettings={generalLoanSettings}
            mutate={mutate}
          />
        )}

        {view === 2 && canViewPayday && (
          <PaydayLoanTab
            paydayLoanSettings={paydayLoanSettings}
            mutate={mutate}
          />
        )}

        {view === 3 && canViewRetail && (
          <RetailLoanTab
            retailLoanSettings={retailLoanSettings}
            mutate={mutate}
          />
        )}

        {view === 4 && <AssetFinancingTab />}
      </div>
    </div>
  );
};

export default Loan;
