import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import { InputField } from "../../../../components/Input";
import { DelayedActionButton } from "../../../../components/Button";

import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";
import { getPermissions, sendPostRequest } from "../../../../config/swr";

const UPDATE_RETAIL_LOAN_SETTINGS_KEY = "update-retail-loan-settings";

const RetailLoanTab = ({ retailLoanSettings, mutate }) => {
  const permissions = getPermissions();

  const canUpdateSettings = permissions[UPDATE_RETAIL_LOAN_SETTINGS_KEY];
  const [formData, setFormData] = useState(retailLoanSettings);
  const [tenureData, setTenureData] = useState({
    days: "",
    interestRate: "",
  });
  const { trigger } = useSWRMutation("/settings/loan/retail", sendPostRequest);
  const form = useForm();
  const showMessageModal = useMessageModal();
  const [editTenureID, setEditTenureID] = useState(-1);

  const tenureDataFromForm = () => {
    return { days: +tenureData.days, interestRate: +tenureData.interestRate };
  };

  const resetTenureData = () => {
    setTenureData({
      days: "",
      interestRate: "",
    });
    setEditTenureID(-1);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "maximumFirstTimeCustomerLoanAmount" ||
      name === "maxReturningCustomerLoanAmount"
    ) {
      setFormData({ ...formData, [name]: +value });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const onTenureEdit = (index) => {
    setEditTenureID(index);
    setTenureData({
      ...formData.packages[index],
    });
  };

  const onTenureRemove = (index) => {
    resetTenureData();
    setFormData({
      ...formData,
      packages: [
        ...formData.packages.slice(0, index),
        ...formData.packages.slice(index + 1),
      ],
    });
  };

  const onTenureChange = (e) => {
    const { name, value } = e.target;
    setTenureData({ ...tenureData, [name]: value });
  };

  const onTenureSubmit = () => {
    if (!tenureData.days || !tenureData.interestRate) return;
    if (editTenureID < 0) {
      setFormData({
        ...formData,
        packages: [...formData.packages, tenureDataFromForm()],
      });
    } else {
      const newArr = formData.packages.slice();
      newArr[editTenureID] = tenureDataFromForm();
      setFormData({
        ...formData,
        packages: newArr,
      });
    }
    resetTenureData();
  };

  const handleSubmit = async () => {
    if (!canUpdateSettings) return;

    form.submitStarted();
    const data = {
      ...formData,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
      mutate();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          error?.message ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div className="retail-loan">
      <div className="box flex start gap equal-widths mb-28">
        <div className="group-item">
          <label htmlFor="">Name</label>
          <input
            type="text"
            className="mb-16"
            name="name"
            value={formData.name}
            onChange={onChange}
          />

          <label htmlFor="">Summary</label>
          <textarea
            name="summary"
            value={formData.summary}
            onChange={onChange}
          ></textarea>
        </div>

        <div className="group-item">
          <label htmlFor="">Disbursement Ledger Code</label>
          <input
            type="text"
            className="mb-16"
            name="disbursementLedgerCode"
            value={formData.disbursementLedgerCode}
            onChange={onChange}
          />

          <label htmlFor="">Repayment Ledger Code</label>
          <input
            type="text"
            name="repaymentLedgerCode"
            className="mb-16"
            value={formData.repaymentLedgerCode}
            onChange={onChange}
          />

          <label htmlFor="">First time borrowers limit</label>
          <input
            type="text"
            name="maximumFirstTimeCustomerLoanAmount"
            className="mb-16"
            value={formData.maximumFirstTimeCustomerLoanAmount}
            onChange={onChange}
          />

          <label htmlFor="">Returning borrowers limit</label>
          <input
            type="text"
            name="maxReturningCustomerLoanAmount"
            value={formData.maxReturningCustomerLoanAmount}
            onChange={onChange}
          />
        </div>
      </div>

      <hr />

      <div className="flex start gap equal-widths mb-28">
        <div className="group-item">
          <h2 className="fs-20 mb-16">Loan Tenures</h2>

          <div className="box">
            {!formData.packages || formData.packages?.length === 0 ? (
              <>No Packages added yet</>
            ) : (
              formData.packages?.map((tenure, i) => (
                <div
                  key={tenure.days}
                  className="flex gap center info-row-with-actions"
                >
                  <div className="text">
                    <h3 className="fs-20">{tenure.days} Days</h3>
                    <p className="charcoal-60">{tenure.interestRate}%</p>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary light"
                      onClick={() => onTenureEdit(i)}
                    >
                      Edit
                    </button>
                    <DelayedActionButton
                      className="btn danger light"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      isBusy={form.state.submitting}
                      clickAction={() => onTenureRemove(i)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">
            {editTenureID < 0 ? "Add" : "Edit"} Loan Tenure
          </h2>
          <div className="box">
            <InputField
              label="Maximum Duration (days)"
              type="number"
              placeholder="Should be multiples of 30"
              className="mb-16"
              name="days"
              value={tenureData.days}
              onChange={onTenureChange}
            />
            <InputField
              label="Interest Rate (%) for Entire Duration"
              type="number"
              placeholder="10"
              className="mb-32"
              name="interestRate"
              value={tenureData.interestRate}
              onChange={onTenureChange}
            />
            <button
              className="btn primary light fullwidth"
              onClick={onTenureSubmit}
              disabled={!tenureData.days || !tenureData.interestRate}
            >
              {editTenureID < 0 ? "Add" : "Update"}
            </button>
          </div>
        </div>
      </div>

      {canUpdateSettings && (
        <button
          className="fullwidth dark primary btn"
          onClick={handleSubmit}
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}
    </div>
  );
};

export default RetailLoanTab;
