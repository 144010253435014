import { useState } from "react";
import useSWRMutation from "swr/mutation";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import AccountHolderBox from "../../../components/AccountHolderBox";
import { BackButton, DelayedActionButton } from "../../../components/Button";
import Modal from "../../../components/Modal";
import SendMessage from "../../../components/SendMessage";
import DeclineRequestForm from "../DeclineRequestForm";

import useShowRequestApprovalMessage from "../useShowRequestApprovalMessage";

import { getPermissions, sendPatchRequest } from "../../../config/swr";

dayjs.extend(utc);
const CARD_REQUEST_KEY = "update-card-request";

const CardRequestDetailsModal = ({ req, dismissModal, mutate }) => {
  const [sendMessageModal, setSendMessageModal] = useState(false);
  const permissions = getPermissions();

  const canUpdateCard = permissions[CARD_REQUEST_KEY];
  const { trigger } = useSWRMutation(`/card-request`, sendPatchRequest);

  const hideMessageModal = () => {
    setSendMessageModal(false);
  };

  const [approving, setApproving] = useState(false);
  const showRequestApprovalMessage = useShowRequestApprovalMessage();
  const approveRequest = async (i) => {
    if (!canUpdateCard) return;
    setApproving(true);

    const { customerName, customerID } = req;

    try {
      await trigger({
        subUrl: `/${customerID}`,
        payload: {
          message: "",
          approve: true,
          reject: false,
        },
      });
      mutate();
      showRequestApprovalMessage({
        requestType: "Card",
        customerName: customerName,
        error: null,
      });
      dismissModal();
    } catch (error) {
      showRequestApprovalMessage({
        requestType: "Card",
        customerName: customerName,
        error:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
      });
    }
    setApproving(false);
  };

  const [declining, setDeclining] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const declineRequest = async (message) => {
    if (!canUpdateCard) return;
    const { customerID, customerName } = req;
    setDeclining(true);

    try {
      await trigger({
        subUrl: `/${customerID}`,
        payload: {
          message,
          approve: false,
          reject: true,
        },
      });
      mutate();
      dismissModal();
      return { error: null, customerName };
    } catch (error) {
      return {
        error:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
      };
    } finally {
      setDeclining(false);
    }
  };

  const requestInfo = [
    {
      title: "Request Date",
      value: <>{dayjs(req.timestamp * 1000).utc().format("D - MMM - YYYY")}</>,
    },
    {
      title: "Card Type",
      value: req.cardType,
    },
    {
      title: "Account Tier",
      value: req.accountTier,
    },
    {
      title: "Card Account Number",
      value: req.cardAccountNumber,
    },
    {
      title: "Request Reason",
      value: req.requestReason,
    },
  ];

  if (sendMessageModal)
    return (
      <Modal
        rightSheet
        hideCancelButton
        className="account-holder-with-info-modal"
        handleClose={hideMessageModal}
      >
        <SendMessage
          accountName={req.customerName}
          accountNumber={req.cardAccountNumber}
          customerID={req.customerID}
          profileImageUrl={req.customerProfileImageUrl}
          handleClose={hideMessageModal}
        />
      </Modal>
    );

  return (
    <Modal rightSheet hideCancelButton handleClose={dismissModal}>
      <div className="modal-header-with-back-btn flex start center gap-8 mb-16">
        <BackButton onClick={dismissModal}>
          <h3 className="fs-20">Card Request</h3>
        </BackButton>
      </div>

      <>
        <AccountHolderBox
          accountName={req.customerName}
          accountNumber={req.cardAccountNumber}
          profileImageUrl={req.customerProfileImageUrl}
          setSendMessageModal={setSendMessageModal}
        />

        <div
          className="fs-16"
          style={{
            padding: "0 1rem 1rem",
            borderBottom: "0.5px solid #d7d7d8",
          }}
        >
          <div className="card__grid mb-32">
            {requestInfo.map((info, index) => (
              <div key={index} className="flex gap-8 between-center">
                <span className="charcoal-60">{info.title}:</span>
                <h3>{info.value}</h3>
              </div>
            ))}
          </div>

          {canUpdateCard && (
            <div className="flex gap equal-widths fs-16">
              <button
                onClick={() => setShowDeclineModal(true)}
                disabled={showDeclineModal || approving}
                className="btn outline danger"
              >
                Decline
              </button>

              <DelayedActionButton
                className="btn primary light"
                text="Approve"
                confirmText="Tap again to confirm"
                busyText="Approving"
                disabled={showDeclineModal || approving}
                isBusy={approving}
                clickAction={approveRequest}
              />
            </div>
          )}
        </div>
      </>

      {showDeclineModal && (
        <Modal
          className="modal-heading-subheading-buttons"
          handleClose={declining ? null : () => setShowDeclineModal(false)}
        >
          <DeclineRequestForm
            requestType="Card"
            declineRequest={declineRequest}
            done={() => setShowDeclineModal(false)}
          />
        </Modal>
      )}
    </Modal>
  );
};

export default CardRequestDetailsModal;
