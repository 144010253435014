import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import DataFetcher from "../../../../components/DataFetcher";
import { InputField } from "../../../../components/Input";
import { DelayedActionButton } from "../../../../components/Button";

import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";
import { getPermissions, sendPostRequest } from "../../../../config/swr";

const UPDATE_ASSET_LOAN_SETTINGS_KEY = "update-assetfi-loan-settings";

const AssetFinancingTab = () => {
  return (
    <DataFetcher
      url={`/settings/loan/assetfi`}
      buildUI={(data, mutate) => (
        <AssetFinancingTabDisplay data={data} mutate={mutate} />
      )}
    />
  );
};

const AssetFinancingTabDisplay = ({ data, mutate }) => {
  const permissions = getPermissions();

  const canUpdateSettings = permissions[UPDATE_ASSET_LOAN_SETTINGS_KEY];
  const [formData, setFormData] = useState(data);
  const [partnerData, setPartnerData] = useState({
    name: "",
    url: "",
  });
  const { trigger } = useSWRMutation("/settings/loan/assetfi", sendPostRequest);
  const form = useForm();
  const showMessageModal = useMessageModal();
  const [editPartnerID, setEditPartnerID] = useState(-1);

  const partnerDataFromForm = () => {
    return { name: partnerData.name, url: partnerData.url };
  };

  const resetPartnerData = () => {
    setPartnerData({
      name: "",
      url: "",
    });
    setEditPartnerID(-1);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onPartnerEdit = (index) => {
    setEditPartnerID(index);
    setPartnerData({
      ...formData.partners[index],
    });
  };

  const onPartnerRemove = (index) => {
    resetPartnerData();
    setFormData({
      ...formData,
      partners: [
        ...formData.partners.slice(0, index),
        ...formData.partners.slice(index + 1),
      ],
    });
  };

  const onPartnerChange = (e) => {
    const { name, value } = e.target;
    setPartnerData({ ...partnerData, [name]: value });
  };

  const onPartnerSubmit = () => {
    if (!partnerData.name || !partnerData.url) return;
    if (editPartnerID < 0) {
      setFormData({
        ...formData,
        partners: [...formData.partners, partnerDataFromForm()],
      });
    } else {
      const newArr = formData.partners.slice();
      newArr[editPartnerID] = partnerDataFromForm();
      setFormData({
        ...formData,
        partners: newArr,
      });
    }
    resetPartnerData();
  };

  const handleSubmit = async () => {
    if (!canUpdateSettings) return;

    form.submitStarted();
    const data = {
      ...formData,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
      mutate();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          error?.message ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div className="retail-loan">
      <div className="box flex start gap equal-widths mb-28">
        <div className="group-item">
          <label htmlFor="">Name</label>
          <input
            type="text"
            className="mb-16"
            name="name"
            value={formData.name}
            onChange={onChange}
          />

          <label htmlFor="">Summary</label>
          <textarea
            name="summary"
            value={formData.summary}
            onChange={onChange}
          ></textarea>
        </div>

        <div className="group-item">
          <label htmlFor="">Disbursement Ledger Code</label>
          <input
            type="text"
            className="mb-16"
            name="disbursementLedgerCode"
            value={formData.disbursementLedgerCode}
            onChange={onChange}
          />

          <label htmlFor="">Repayment Ledger Code</label>
          <input
            type="text"
            name="repaymentLedgerCode"
            value={formData.repaymentLedgerCode}
            onChange={onChange}
          />
        </div>
      </div>

      <hr />

      <div className="flex start gap equal-widths mb-28">
        <div className="group-item">
          <h2 className="fs-20 mb-16">Loan Partners</h2>

          <div className="box">
            {!formData.partners || formData.partners?.length === 0 ? (
              <>No partners added yet</>
            ) : (
              formData.partners?.map((partner, i) => (
                <div
                  key={partner.name}
                  className="flex gap center info-row-with-actions"
                >
                  <div className="text">
                    <h3 className="fs-20">{partner.name}</h3>
                    <p className="charcoal-60">{partner.url}</p>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary light"
                      onClick={() => onPartnerEdit(i)}
                    >
                      Edit
                    </button>
                    <DelayedActionButton
                      className="btn danger light"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      isBusy={form.state.submitting}
                      clickAction={() => onPartnerRemove(i)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">
            {editPartnerID < 0 ? "Add" : "Edit"} Loan partner
          </h2>
          <div className="box">
            <InputField
              label="Partner Name"
              placeholder="Enter partner name"
              className="mb-16"
              name="name"
              value={partnerData.name}
              onChange={onPartnerChange}
            />
            <InputField
              label="Partner url"
              placeholder="Enter partner URL"
              className="mb-32"
              name="url"
              value={partnerData.url}
              onChange={onPartnerChange}
            />
            <button
              className="btn primary light fullwidth"
              onClick={onPartnerSubmit}
              disabled={!partnerData.name || !partnerData.url}
            >
              {editPartnerID < 0 ? "Add" : "Update"}
            </button>
          </div>
        </div>
      </div>
      
      {canUpdateSettings && (
        <button
          className="fullwidth dark primary btn"
          onClick={handleSubmit}
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}
    </div>
  );
};

export default AssetFinancingTab;
