import { useState } from "react";

import { DelayedActionButton } from "../../../../components/Button";
import { InputField } from "../../../../components/Input";

import { ADD_TENURE, EDIT_TENURE, REMOVE_TENURE } from "./partnerReducer";

const PartnerLoanTenure = ({ loanPackages, dispatch }) => {
  const [tenureData, setTenureData] = useState({
    days: "",
    interestRate: "",
  });
  const [editTenureID, setEditTenureID] = useState(-1);

  const tenureDataFromForm = () => {
    return { days: +tenureData.days, interestRate: +tenureData.interestRate };
  };

  const resetTenureData = () => {
    setTenureData({
      days: "",
      interestRate: "",
    });
    setEditTenureID(-1);
  };

  const onTenureEdit = (index) => {
    setEditTenureID(index);
    setTenureData({
      ...loanPackages[index],
    });
  };

  const onTenureRemove = (index) => {
    resetTenureData();
    dispatch({
      type: REMOVE_TENURE,
      payload: { index },
    });
  };

  const onTenureChange = (e) => {
    const { name, value } = e.target;
    setTenureData({ ...tenureData, [name]: value });
  };

  const onTenureSubmit = () => {
    if (!tenureData.days || !tenureData.interestRate) return;
    if (editTenureID < 0) {
      dispatch({
        type: ADD_TENURE,
        payload: tenureDataFromForm(),
      });
    } else {
      dispatch({
        type: EDIT_TENURE,
        payload: { index: editTenureID, item: tenureDataFromForm() },
      });
    }
    resetTenureData();
  };

  return (
    <div className="flex start gap equal-widths mb-28">
      <div className="group-item">
        <h2 className="fs-20 mb-16">Loan Tenures</h2>

        <div className="box">
          {!loanPackages || loanPackages?.length === 0 ? (
            <div>No Packages added yet</div>
          ) : (
            loanPackages?.map((tenure, i) => (
              <div
                key={tenure.days}
                className="flex gap center info-row-with-actions"
              >
                <div className="text">
                  <h3 className="fs-20">{tenure.days} Days</h3>
                  <p className="charcoal-60">{tenure.interestRate}%</p>
                </div>

                <div className="actions">
                  <button
                    className="btn primary light"
                    onClick={() => onTenureEdit(i)}
                  >
                    Edit
                  </button>
                  <DelayedActionButton
                    className="btn danger light"
                    text="Remove"
                    confirmText="Confirm"
                    busyText="Remove"
                    clickAction={() => onTenureRemove(i)}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <div>
        <h2 className="fs-20 mb-16">
          {editTenureID < 0 ? "Add" : "Edit"} Loan Tenure
        </h2>
        <div className="box">
          <InputField
            label="Maximum Duration (days)"
            type="number"
            placeholder="Should be multiples of 30"
            className="mb-16"
            name="days"
            value={tenureData.days}
            onChange={onTenureChange}
          />
          <InputField
            label="Interest Rate for Duration (%)"
            type="number"
            placeholder="10"
            className="mb-32"
            name="interestRate"
            value={tenureData.interestRate}
            onChange={onTenureChange}
          />
          <button
            className="btn primary light fullwidth"
            onClick={onTenureSubmit}
            disabled={!tenureData.days || !tenureData.interestRate}
          >
            {editTenureID < 0 ? "Add" : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartnerLoanTenure;
