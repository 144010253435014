import React from "react";

const ArrowLeftIcon = ({ width, ...rest }) => {
  return (
    <svg
      width={width ?? 31}
      height="28"
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0001 13.9985C31.0001 14.4957 30.8025 14.9727 30.4509 15.3243C30.0992 15.6759 29.6223 15.8735 29.1251 15.8735H7.4013L15.4526 23.921C15.6269 24.0953 15.7652 24.3022 15.8595 24.53C15.9539 24.7578 16.0024 25.0019 16.0024 25.2485C16.0024 25.495 15.9539 25.7391 15.8595 25.9669C15.7652 26.1947 15.6269 26.4016 15.4526 26.576C15.2782 26.7503 15.0713 26.8886 14.8435 26.9829C14.6157 27.0773 14.3716 27.1258 14.1251 27.1258C13.8785 27.1258 13.6344 27.0773 13.4066 26.9829C13.1788 26.8886 12.9719 26.7503 12.7976 26.576L1.54756 15.326C1.37294 15.1518 1.23441 14.9449 1.13988 14.7171C1.04536 14.4893 0.996704 14.2451 0.996704 13.9985C0.996704 13.7518 1.04536 13.5076 1.13988 13.2798C1.23441 13.052 1.37294 12.8451 1.54756 12.671L12.7976 1.42096C13.1496 1.06889 13.6271 0.871094 14.1251 0.871094C14.623 0.871094 15.1005 1.06889 15.4526 1.42096C15.8046 1.77304 16.0024 2.25055 16.0024 2.74846C16.0024 3.24637 15.8046 3.72389 15.4526 4.07596L7.4013 12.1235H29.1251C29.6223 12.1235 30.0992 12.321 30.4509 12.6726C30.8025 13.0243 31.0001 13.5012 31.0001 13.9985Z"
        fill="black"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
