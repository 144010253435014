import { CHANGE_PARTNER } from "./partnerReducer";

const BasicPartnerInfo = ({
  name = "",
  description = "",
  loanDisburser = "",
  disbursementSource = "",
  repaymentDestination = "",
  autoDebitCustomers = false,
  eligibleAccountProductCode = "",
  dispatch,
}) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    dispatch({
      type: CHANGE_PARTNER,
      payload: { name: [name], value: type === "checkbox" ? checked : value },
    });
  };

  return (
    <div className="mb-32">
      <h2 className="fs-20 mb-16">Basic partner info</h2>
      <div className="box">
        <label htmlFor="name">Partner (Organization) Name</label>
        <input
          id="name"
          type="text"
          className="mb-16"
          placeholder="Enter Partner (Organization) Name"
          name="name"
          value={name}
          onChange={handleChange}
        />

        <label htmlFor="description">Description</label>
        <input
          id="description"
          type="text"
          className="mb-16"
          placeholder="Enter Description"
          name="description"
          value={description}
          onChange={handleChange}
        />

        <label htmlFor="eligibleAccountProductCode">
          Eligible Account Product Code
        </label>
        <input
          id="eligibleAccountProductCode"
          type="text"
          className="mb-16"
          placeholder="Enter Eligible Account Product Code here"
          name="eligibleAccountProductCode"
          value={eligibleAccountProductCode}
          onChange={handleChange}
        />

        <label htmlFor="loanDisburser">Loan Disburser</label>
        <select
          id="loanDisburser"
          className="mb-16"
          name="loanDisburser"
          value={loanDisburser}
          onChange={handleChange}
        >
          <option value="" disabled>
            Select Loan Disburser
          </option>
          <option value="partner">Partner Admins</option>
          <option value="fewchore">Fewchore Admins</option>
        </select>

        <label htmlFor="disbursementSource">Disbursement Source</label>
        <input
          id="disbursementSource"
          type="text"
          className="mb-16"
          disabled={loanDisburser === ""}
          placeholder={
            loanDisburser === ""
              ? "Select Loan Disburser"
              : loanDisburser === "partner"
              ? "Enter Partner Account Number"
              : "Enter Fewchore Ledger Code"
          }
          name="disbursementSource"
          value={disbursementSource}
          onChange={handleChange}
        />

        <label htmlFor="repaymentDestination">Repayment Destination</label>
        <input
          id="repaymentDestination"
          type="text"
          className="mb-16"
          disabled={loanDisburser === ""}
          placeholder={
            loanDisburser === ""
              ? "Select Loan Disburser"
              : loanDisburser === "partner"
              ? "Enter Partner Account Number"
              : "Enter Fewchore Ledger Code"
          }
          name="repaymentDestination"
          value={repaymentDestination}
          onChange={handleChange}
        />

        <label
          className="flex gap-4 loan-checkbox mb-16"
          id="autoDebitCustomers"
        >
          <div className="fs-18">Auto Debit Customers</div>
          &nbsp;
          <input
            type="checkbox"
            id="autoDebitCustomers"
            name="autoDebitCustomers"
            onChange={handleChange}
            checked={autoDebitCustomers}
          />
        </label>
      </div>
    </div>
  );
};

export default BasicPartnerInfo;
