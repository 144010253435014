import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import Checkbox from "../../../../components/Checkbox";
import { InputField } from "../../../../components/Input";
import { DelayedActionButton } from "../../../../components/Button";
import { getPermissions, sendPostRequest } from "../../../../config/swr";
import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";

const UPDATE_TARGET_COLLECTION_SETTINGS_KEY =
  "update-target-collection-settings";

const TargetCollectionSettings = ({ targetCollectionSettings }) => {
  const permissions = getPermissions();

  const canUpdateSettings = permissions[UPDATE_TARGET_COLLECTION_SETTINGS_KEY];
  const [formData, setFormData] = useState(targetCollectionSettings);

  const [interestData, setInterestData] = useState({
    frequency: "",
    rate: "",
  });
  const [editInterestID, setEditInterestID] = useState(-1);

  const { trigger } = useSWRMutation(
    "/settings/collections/target",
    sendPostRequest
  );
  const showMessageModal = useMessageModal();
  const form = useForm();

  const interestDataFromForm = () => {
    return { ...interestData, rate: +interestData.rate };
  };

  const resetInterestData = () => {
    setInterestData({
      Interest: "",
      rate: "",
    });
    setEditInterestID(-1);
  };

  const onFormFieldChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      if (
        name === "minimumCollectionAmount" ||
        name === "percentageInterestDeduction"
      ) {
        setFormData({ ...formData, [name]: +value });
        return;
      }
      setFormData({ ...formData, [name]: value });
    }
  };

  const onInterestEdit = (index) => {
    setEditInterestID(index);
    setInterestData({
      ...formData.interestRates[index],
    });
  };

  const onInterestRemoved = (index) => {
    resetInterestData();
    setFormData({
      ...formData,
      interestRates: [
        ...formData.interestRates.slice(0, index),
        ...formData.interestRates.slice(index + 1),
      ],
    });
  };

  const onInterestDataChange = (e) => {
    const { name, value } = e.target;
    setInterestData({ ...interestData, [name]: value });
  };

  const onInterestDataSubmit = () => {
    if (!interestData.frequency || !interestData.rate) return;
    if (editInterestID < 0) {
      setFormData({
        ...formData,
        interestRates: [...formData.interestRates, interestDataFromForm()],
      });
    } else {
      const newArr = formData.interestRates?.slice();
      newArr[editInterestID] = interestDataFromForm();
      setFormData({
        ...formData,
        interestRates: newArr,
      });
    }
    resetInterestData();
  };

  const handleSubmit = async () => {
    if (!canUpdateSettings) return;
    form.submitStarted();

    const data = {
      ...formData,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <>
      <div className="flex gap equal-widths box mb-28">
        <div>
          <InputField
            label="Collection Name"
            type="text"
            placeholder="Enter Collection Name"
            className="mb-16"
            name="name"
            value={formData.name}
            onChange={onFormFieldChange}
          />
          <InputField
            label="Package Summary"
            multiline
            placeholder="Enter Package Summary here"
            className="mb-16"
            name="summary"
            value={formData.summary}
            onChange={onFormFieldChange}
          />
          <InputField
            label="Min. collection Amount"
            type="number"
            min="0"
            placeholder="N 1000"
            name="minimumCollectionAmount"
            value={formData.minimumCollectionAmount}
            onChange={onFormFieldChange}
          />
        </div>
        <div>
          <InputField
            label="Credit Ledger Code"
            type="text"
            placeholder="Ledger Code for deposits"
            className="mb-16"
            name="creditLedgerCode"
            value={formData.creditLedgerCode}
            onChange={onFormFieldChange}
          />
          <InputField
            label="Debit Ledger Code"
            type="text"
            placeholder="Ledger Code for withdrawals"
            className="mb-16"
            name="debitLedgerCode"
            value={formData.debitLedgerCode}
            onChange={onFormFieldChange}
          />
          <InputField
            label="Interest Deduction (%)"
            type="number"
            min="1"
            max="100"
            placeholder="Percentage deduction on premature withdrawal"
            name="percentageInterestDeduction"
            value={formData.percentageInterestDeduction}
            onChange={onFormFieldChange}
          />
        </div>
      </div>

      <div className="flex start gap equal-widths mb-28">
        <div>
          <h2 className="fs-20 mb-16">INTEREST RATES</h2>
          <div className="box">
            {!formData.interestRates || formData.interestRates?.length === 0 ? (
              <>No Interest Rates added yet</>
            ) : (
              formData.interestRates?.map((ir, i) => (
                <div
                  key={ir.frequency}
                  className="flex gap center info-row-with-actions"
                >
                  <div className="text">
                    <h3 className="fs-20">{ir.frequency}</h3>
                    <p className="charcoal-60">{ir.rate}%</p>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary light"
                      onClick={() => onInterestEdit(i)}
                    >
                      Edit
                    </button>
                    <DelayedActionButton
                      className="btn danger light"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      isBusy={form.state.submitting}
                      clickAction={() => onInterestRemoved(i)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">
            {editInterestID < 0 ? "Add" : "Edit"} Interest Rate
          </h2>
          <div className="box">
            <InputField
              label="Frequency (days)"
              type="text"
              placeholder="Should be multiples of 30"
              className="mb-16"
              name="frequency"
              value={interestData.frequency}
              onChange={onInterestDataChange}
            />
            <InputField
              label="Rate (%)"
              type="number"
              placeholder="1-100"
              className="mb-32"
              name="rate"
              value={interestData.rate}
              onChange={onInterestDataChange}
            />
            <button
              className="btn primary light fullwidth"
              onClick={onInterestDataSubmit}
              disabled={!interestData.frequency || !interestData.rate}
            >
              {editInterestID < 0 ? "Add" : "Update"}
            </button>
          </div>
        </div>
      </div>

      <h2 className="fs-20 mb-16">Collection Method</h2>
      <div className="flex gap box mb-28">
        <Checkbox
          name={`fundViaCard`}
          text="Debit Card"
          checked={formData.fundViaCard}
          setChecked={onFormFieldChange}
        />
        <Checkbox
          name={`fundViaAccount`}
          text="Fewchore Account"
          checked={formData.fundViaAccount}
          setChecked={onFormFieldChange}
        />
      </div>

      {canUpdateSettings && (
        <button
          className="fullwidth dark primary btn"
          onClick={handleSubmit}
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}
    </>
  );
};

export default TargetCollectionSettings;
