import { FiLoader } from "react-icons/fi";

import Modal from "../../../../components/Modal";

import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";

import { ADD_SECTION } from "./partnerReducer";

const AddSectionModal = ({ dismissModal, applicationForm, dispatch }) => {
  const form = useForm({
    name: "",
  });
  const showMessageModal = useMessageModal();

  const { name } = form.data;

  const submitSectionForm = async (e) => {
    e.preventDefault();
    form.submitStarted();

    const nameExists =
      applicationForm &&
      applicationForm.find(
        (item) => item.name?.toLowerCase() === name.toLowerCase()
      );

    if (nameExists) {
      form.submitError();
      return showMessageModal({
        title: "Error",
        message: "Section already exists",
        isError: true,
        closeButtonText: "Close",
      });
    }
    dispatch({
      type: ADD_SECTION,
      payload: name,
    });
    form.submitSuccess();
    dismissModal();
  };

  return (
    <Modal
      handleClose={dismissModal}
      className="modal-heading-subheading-buttons"
    >
      <h3 className="fs-24 mb-32 center-text">Add a new Section</h3>
      <form onSubmit={submitSectionForm}>
        <label htmlFor="name">Section Name</label>
        <input
          id="name"
          type="text"
          className="mb-16"
          placeholder="Enter Name"
          {...form.fieldProps("name")}
        />

        <button
          className="btn primary light fullwidth"
          disabled={form.state.submitting || !name}
        >
          {form.state.submitting ? <FiLoader /> : "Add New"}
        </button>
      </form>
    </Modal>
  );
};

export default AddSectionModal;
