import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";

import { ImagePickerWidgets } from "../../../../components/ImagePickerWidgets";
import Modal from "../../../../components/Modal";

import { getPermissions, sendPostRequest } from "../../../../config/swr";
import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";

const BATCH_CREATE_KEY = "create-batch-accounts";

const BatchCreationModal = ({ dismissModal }) => {
  const permissions = getPermissions();

  const canCreateBatch = permissions[BATCH_CREATE_KEY];
  const { trigger } = useSWRMutation("/accounts/batches", sendPostRequest);
  const showMessageModal = useMessageModal();

  const form = useForm({
    productCode: "",
  });
  const { productCode } = form.data;

  const [BatchAccountFilePicker, , file] = ImagePickerWidgets();
  const buttonDisabled = !productCode || !file;

  const handleSubmit = async () => {
    if (buttonDisabled || !canCreateBatch) return;
    form.submitStarted();
    const data = new FormData();
    data.append("csvFile", file);
    data.append("productCode", productCode);

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
      dismissModal();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <Modal
      handleClose={form.state.submitting ? () => null : dismissModal}
      className="modal-heading-subheading-buttons"
    >
      <h1 className="fs-24">Batch Account Creation</h1>
      <p className="mb-32 center-text">
        Upload excel sheet and enter product code to create batch account
      </p>

      <div className="mb-32">
        <label htmlFor="">Excel Sheet</label>

        <BatchAccountFilePicker
          className="mb-16"
          text="Upload Doc/file"
          btnText="Select File"
        />

        <label htmlFor="">Product Code</label>
        <input
          className="mb-16"
          type="text"
          placeholder="Enter product code"
          {...form.fieldProps("productCode")}
        />
      </div>

      <div className="actions">
        <button
          className="btn primary fs-22"
          onClick={handleSubmit}
          disabled={form.state.submitting || buttonDisabled}
        >
          {form.state.submitting ? <FiLoader /> : "Create"}
        </button>
        <button
          className="btn transparent outline fs-22"
          onClick={dismissModal}
          disabled={form.state.submitting}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default BatchCreationModal;
