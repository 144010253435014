import CheckboxIcon from "../assets/icons/checkbox";

const CheckBox = ({
  text,
  name,
  checked,
  setChecked,
  disabled,
  color,
  additionalStyles,
}) => {
  const style = {
    ...(additionalStyles ?? {}),
  };
  if (color) style.color = color;
  return (
    <label className="checkbox clickable" htmlFor={name} style={style}>
      <input
        type="checkbox"
        name={name}
        id={name}
        onChange={setChecked}
        checked={checked}
        disabled={disabled}
        style={{ visibility: "hidden", display: "none" }}
      />
      <div
        className="checkbox__box flex center"
        style={color ? { borderColor: color } : {}}
      >
        {checked ? <CheckboxIcon color={color} /> : null}
      </div>
      {text}
    </label>
  );
};

export default CheckBox;
