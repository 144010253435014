import React, { useState } from "react";
import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";

import { SearchBox } from "./Input";
import Dropdown from "./Dropdown";
import ExportIcon from "../assets/icons/export";
import { FilterAlt, FilterDark } from "../assets/icons/filter";
import useForm from "../hooks/useForm.hook";
import useMessageModal from "../hooks/useMessageModal.hook";
import { sendGetRequest } from "../config/swr";
import { createCSV } from "../utils/helpers";

export const SORT_ASC = "asc",
  SORT_DESC = "desc";

export const FilterArray = (
  arr,
  { sort, sortDirection, search },
  sortFields
) => {
  if (arr.length === 0) {
    return arr;
  }

  if (!!search) {
    search = search.toLowerCase();
    const fields = Object.keys(arr[0]);
    arr = arr.filter((item) => {
      return fields.some(
        (field) => item[field]?.toString().toLowerCase().indexOf(search) >= 0
      );
    });
  }

  if (!!sort && !!sortFields) {
    const sortKey = sortFields[sort];
    const positiveSortValue = sortDirection === SORT_ASC ? 1 : -1;
    arr = arr.sort((item1, item2) => {
      if (item1[sortKey] > item2[sortKey]) {
        return positiveSortValue;
      }
      if (item1[sortKey] < item2[sortKey]) {
        return -positiveSortValue;
      }
      return 0;
    });
  }

  return arr;
};

export function SearchFilterContainer({
  prependWidget,
  appendWidget,
  searchHint,
  searchDelay,
  sortOptions,
  filterOptions,
  reloadTable,
  children,
  csvUrl,
}) {
  const { trigger } = useSWRMutation(`/`, sendGetRequest);
  const form = useForm();
  const showMessageModal = useMessageModal();
  const [options, setOptions] = useState({
    filter: filterOptions?.length > 0 ? filterOptions[0] : "",
    sortDirection: SORT_ASC,
  });

  const handleFilter = (option) => {
    setOptions({ ...options, filter: option });
    reloadTable(options);
  };

  const handleSort = (option) => {
    const sortDirection =
      options.sort === option && options.sortDirection === SORT_ASC
        ? SORT_DESC
        : SORT_ASC;
    const newOptions = { ...options, sort: option, sortDirection };
    setOptions(newOptions);
    reloadTable(newOptions);
  };

  const handleSearch = (query) => {
    const newOptions = { ...options, search: query };
    setOptions(newOptions);
    reloadTable(newOptions);
  };

  const handleClick = async (url) => {
    form.submitStarted();

    try {
      const res = await trigger(url);
      createCSV(res.data, csvUrl);
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred while exporting csv. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div className="search-filter-box">
      <div className="flex between-center">
        <SearchBox
          placeholder={searchHint ?? "Search Customers, Account Numbers, etc."}
          searchDelay={searchDelay}
          handleSearch={handleSearch}
        />

        <div className="flex center fs-16">
          {prependWidget}

          {csvUrl && (
            <button
              className="flex center"
              disabled={form.state.submitting || !csvUrl}
              onClick={() => handleClick(csvUrl)}
            >
              {form.state.submitting ? (
                <FiLoader />
              ) : (
                <>
                  <ExportIcon />
                  Export as CSV
                </>
              )}
            </button>
          )}

          {filterOptions && filterOptions.length > 0 && (
            <Dropdown
              alignEnd
              className="fs-16 flex center"
              options={filterOptions}
              selectedOption={options.filter}
              onOptionSelected={handleFilter}
              style={{ paddingRight: "10px" }}
            >
              <FilterAlt />
              {options.filter ?? "Filter"}
            </Dropdown>
          )}

          {sortOptions && sortOptions.length > 0 && (
            <Dropdown
              alignEnd
              className="fs-16 flex center"
              options={sortOptions}
              selectedOption={options.sort}
              onOptionSelected={handleSort}
              allowReselection
              style={{ paddingRight: "10px" }}
            >
              <FilterDark />
              {options.sort
                ? `Sort by: ${options.sort} (${options.sortDirection})`
                : "Sort"}
            </Dropdown>
          )}

          {appendWidget}
        </div>
      </div>

      {children}
    </div>
  );
}

function SearchFilterTable({
  headers,
  prependWidget,
  appendWidget,
  searchHint,
  searchDelay,
  sortOptions,
  filterOptions,
  reloadTable,
  tableClass,
  children,
  csvUrl,
}) {
  return (
    <SearchFilterContainer
      prependWidget={prependWidget}
      appendWidget={appendWidget}
      searchHint={searchHint}
      searchDelay={searchDelay}
      sortOptions={sortOptions}
      filterOptions={filterOptions}
      reloadTable={reloadTable}
      csvUrl={csvUrl}
    >
      <table className={"box fs-20 " + tableClass}>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>

        <tbody>{children}</tbody>
      </table>
    </SearchFilterContainer>
  );
}

export default SearchFilterTable;
