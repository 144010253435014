import useWebSocket from "react-use-websocket";

import { BASE_URL, getToken, useLogout } from "../../../config/swr";

export const chatMessageTypes = {
  // server sends these
  ready: "Ready",
  activeChatRooms: "ActiveChatRooms",
  reloadActiveChatRooms: "ReloadActiveChatRooms",
  newChatRoom: "NewChatRoom",
  newMessage: "NewMessage",
  tokenUpdated: "TokenUpdated",
  // TODO: Not expecting this to use chatRoomMessages since we don't send
  // FetchChatRoomMessages requests yet.
  // chatRoomMessages: "ChatRoomMessages",

  // we send these...
  fetchActiveChatRooms: "FetchActiveChatRooms",
  joinChatRoom: "JoinChatRoom",
  sendMessage: "SendMessage",
  closeChatRoom: "CloseChatRoom",
  // TODO: Not expecting this to use fetchChatRoomMessages since we don't really
  // need to yet.
  // fetchChatRoomMessages: "FetchChatRoomMessages",
};

export default function useChatWebSocket(incomingMessageHandlers) {
  const logout = useLogout();

  // TODO: Consider NOT using this library as it re-renders the page for
  // every message received from the server, including pings every 5 secs.
  const { sendMessage, sendJsonMessage, readyState, getWebSocket } =
    useWebSocket(
      `wss://${BASE_URL}/chat-and-support`,
      {
        queryParams: { token: getToken(), custompingpong: true },
        shouldReconnect: (e) => {
          if (e.code === 1006) {
            return true; // abnormal closure, try to reconnect
          }

          if (e.code === 3000) {
            logout(); // authorization error
          } else {
            console.error(
              `chat ws disconnected, code ${e.code}, reason: ${e.reason}`
            );
          }
          return false;
        },
        reconnectAttempts: 10,
      }
    );

  const wsSendMessage = (msgType, msgData) => {
    sendJsonMessage({ type: msgType, data: msgData });
  };

  const ws = getWebSocket();
  if (!ws) {
    return [readyState, wsSendMessage];
  }

  ws.onmessage = ({ data: rawMsg }) => {
    if (rawMsg === "ping") {
      sendMessage("pong");
      return;
    }

    let msg;
    try {
      msg = JSON.parse(rawMsg);
    } catch (error) {
      console.error(`invalid ws message: ${rawMsg}: ${error}`);
      return;
    }

    if (msg.errorMsg) {
      alert(msg.errorMsg);
      return;
    }

    const handleMessage = incomingMessageHandlers[msg.type];
    if (handleMessage) {
      handleMessage(msg.data);
    } else {
      console.log(`ws message of type ${msg.type} has no handler`);
    }
  };

  return [readyState, wsSendMessage];
}
