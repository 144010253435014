export default function ErrorIcon() {
  return (
    <svg
      width="144"
      height="144"
      viewBox="0 0 144 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="130.252"
        cy="22.2199"
        rx="7.95985"
        ry="8.06342"
        fill="url(#paint0_linear_1992_39368)"
      />
      <ellipse
        cx="89.1368"
        cy="10.0351"
        rx="3.61811"
        ry="3.66519"
        fill="url(#paint1_linear_1992_39368)"
      />
      <ellipse
        cx="26.7749"
        cy="8.79646"
        rx="8.68347"
        ry="8.79646"
        fill="url(#paint2_linear_1992_39368)"
      />
      <ellipse
        cx="132.424"
        cy="120.449"
        rx="5.78898"
        ry="5.86431"
        fill="url(#paint3_linear_1992_39368)"
      />
      <ellipse
        cx="3.61826"
        cy="80.1375"
        rx="3.61811"
        ry="3.66519"
        fill="url(#paint4_linear_1992_39368)"
      />
      <ellipse
        cx="140.382"
        cy="68.4021"
        rx="3.61811"
        ry="3.66519"
        fill="url(#paint5_linear_1992_39368)"
      />
      <ellipse
        cx="33.4185"
        cy="138.136"
        rx="5.78898"
        ry="5.86431"
        fill="url(#paint6_linear_1992_39368)"
      />
      <ellipse
        cx="75.6169"
        cy="76.8356"
        rx="58.0826"
        ry="58.2766"
        fill="url(#paint7_linear_1992_39368)"
      />
      <path
        d="M95.0415 61.8946L91.1053 57.9584L75.4999 73.5638L59.8944 57.9584L55.9582 61.8946L71.5636 77.5L55.9582 93.1055L59.8944 97.0417L75.4999 81.4363L91.1053 97.0417L95.0415 93.1055L79.4361 77.5L95.0415 61.8946Z"
        fill="black"
        stroke="white"
        strokeWidth="6"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1992_39368"
          x1="130.252"
          y1="14.1565"
          x2="130.252"
          y2="30.2833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B0000" />
          <stop offset="1" stopColor="#CF1919" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1992_39368"
          x1="89.1368"
          y1="6.36987"
          x2="89.1368"
          y2="13.7003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B0000" />
          <stop offset="1" stopColor="#CF1919" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1992_39368"
          x1="26.7749"
          y1="0"
          x2="26.7749"
          y2="17.5929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B0000" />
          <stop offset="1" stopColor="#CF1919" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1992_39368"
          x1="132.424"
          y1="114.585"
          x2="132.424"
          y2="126.313"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B0000" />
          <stop offset="1" stopColor="#CF1919" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1992_39368"
          x1="3.61826"
          y1="76.4723"
          x2="3.61826"
          y2="83.8027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B0000" />
          <stop offset="1" stopColor="#CF1919" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1992_39368"
          x1="140.382"
          y1="64.7369"
          x2="140.382"
          y2="72.0673"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B0000" />
          <stop offset="1" stopColor="#CF1919" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1992_39368"
          x1="33.4185"
          y1="132.271"
          x2="33.4185"
          y2="144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B0000" />
          <stop offset="1" stopColor="#CF1919" stopOpacity="0.93" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1992_39368"
          x1="58.8131"
          y1="26.6225"
          x2="75.7267"
          y2="135.095"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#611F1F" />
          <stop offset="1" stopColor="#D31F1F" />
        </linearGradient>
      </defs>
    </svg>
  );
}
