import React, { useRef } from "react";
import ReactDOM from "react-dom/client";

import ErrorIcon from "../assets/icons/erroricon";
import SuccessIcon from "../assets/icons/successicon";
import { PrimaryButton } from "../components/Button";
import Modal from "../components/Modal";

function useMessageModal() {
  const body = useRef(document.body);

  return ({ title, message, isError, closeButtonText }) => {
    if (!body.current) return;

    const container = document.createElement("div");
    const closeModal = () => {
      if (!body.current) return;
      body.current.removeChild(container);
      body.current.classList.remove("no-scroll");
    };

    const modal = (
      <Modal className="message__modal" handleClose={closeModal}>
        <div>
          <h1 className="fs-20">{title}</h1>
          <p className="fs-16">{message}</p>
        </div>
        {isError ? <ErrorIcon /> : <SuccessIcon />}
        <PrimaryButton
          className="fullwidth"
          style={isError ? { backgroundColor: "#9B0000" } : {}}
          onClick={closeModal}
        >
          {closeButtonText ?? "Close"}
        </PrimaryButton>
      </Modal>
    );

    ReactDOM.createRoot(container).render(modal);
    body.current.appendChild(container);
  };
}

export default useMessageModal;
