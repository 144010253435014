import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";
import Modal from "../../components/Modal";
import useForm from "../../hooks/useForm.hook";
import useMessageModal from "../../hooks/useMessageModal.hook";
import MutableListView from "../../components/MutableListView";
import SalaryBreakdownFields, {
  CalculateNetSalary,
} from "./SalaryBreakdownFields";
import { PayrollPermissions } from ".";
import {
  getPermissions,
  sendPostRequest,
  sendPutRequest,
} from "../../config/swr";
import DataFetcher from "../../components/DataFetcher";
import UploadIcon from "../../assets/icons/upload";

const paymentDateTypes = [
  "Last day of month",
  "First day of new month",
  "Some days before end of month",
  "Some days into new month",
  "Fixed date",
];

const nextPaymentDate = (selectedPaymentType, paymentDate) => {
  const selectedIndex = paymentDateTypes.indexOf(selectedPaymentType);
  if (selectedIndex < 0 || (selectedIndex >= 2 && !paymentDate)) {
    // invalid selection
    return;
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  let day = today.getDay(),
    month = today.getMonth();

  switch (selectedIndex) {
    case 0:
      day = 0; // last day of previous month
      month++;
      break;
    case 1:
      day = 1; // first day of current month
      month++;
      break;
    case 2:
      day = -paymentDate; // `paymentDate` days before the current month
      month++;
      break;
    case 3:
      day = paymentDate; // `paymentDate` days into the current month
      month++;
      break;
    default:
      day = paymentDate; // exact date in same month
      break;
  }

  const nextPayDate = new Date(today.getFullYear(), month, day);
  if (today > nextPayDate) {
    return new Date(today.getFullYear(), month + 1, day);
  }
  return nextPayDate;
};

export default function PayrollSettingsPage() {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Payroll Settings");

  return (
    <DataFetcher
      url={`/settings/payroll`}
      buildUI={(data, mutate) => (
        <PayrollSettingsPageWithData data={data} mutate={mutate} />
      )}
    />
  );
}

const PayrollSettingsPageWithData = ({ data, mutate }) => {
  const permissions = getPermissions();
  const canEditPayrollSettings =
    permissions[PayrollPermissions.EDIT_PAYROLL_SETTINGS_PERM];
  const canUploadPayslipStamp =
    permissions[PayrollPermissions.UPLOAD_PAYROLL_PAYSLIP_STAMP_PERM];

  const form = useForm(data);

  // Require a specific date or number of days if the selected paymentDateType
  // is not one of the first 2 types displayed.
  const specifyPaymentDate =
    paymentDateTypes.indexOf(form.data.paymentDateType) >= 2;
  const specifyPaymentDateHint = !specifyPaymentDate
    ? ""
    : form.data.paymentDateType.startsWith("Some")
    ? form.data.paymentDateType.replace("Some", "Enter number of")
    : "Enter day e.g. 25";
  const nextPayDate = nextPaymentDate(
    form.data.paymentDateType,
    form.data.paymentDate
  );

  const checkCanMutateExistingStaffRole = () => {
    if (!data.nextApprovedPaymentDate) return true;

    showMessageModal({
      title: "Not Allowed",
      message: `Cannot edit/delete an existing staff role until after the next salary payments are made on ${data.nextApprovedPaymentDate}.`,
      isError: true,
      closeButtonText: "Close",
    });
  };

  const [addEditStaffRole, setAddEditStaffRole] = useState(null);
  const closeStaffRoleForm = () => setAddEditStaffRole(null);
  const editStaffRole = (index) => {
    if (!checkCanMutateExistingStaffRole()) return;
    setAddEditStaffRole({ editIndex: index });
  };
  const deleteStaffRole = (index, onSuccess) => {
    if (!checkCanMutateExistingStaffRole()) {
      onSuccess();
      return;
    }

    form.setData({
      ...form.data,
      staffRoles: [
        ...form.data.staffRoles.slice(0, index),
        ...form.data.staffRoles.slice(index + 1),
      ],
    });
    onSuccess();
  };
  const addOrUpdateStaffRole = (role) => {
    const roles = form.data.staffRoles?.slice() ?? [];
    if (addEditStaffRole.editIndex >= 0) {
      roles[addEditStaffRole.editIndex] = role;
    } else {
      roles.push(role);
    }

    form.setData({
      ...form.data,
      staffRoles: roles,
    });
    closeStaffRoleForm();
  };

  const specificPaymentDate = specifyPaymentDate && form.data.paymentDate;
  const formValid =
    !!form.data.paymentDateType &&
    specifyPaymentDate === !!specificPaymentDate &&
    !!form.data.paymentLedgerCode;

  const showMessageModal = useMessageModal();

  const { trigger: stampTrigger } = useSWRMutation(
    "/settings/payroll/payslip-stamp",
    sendPutRequest
  );

  const [stampData, setStampData] = useState(null);
  const closeStampModal = () => setStampData(null);
  const openStampModal = () => {
    setStampData({ imageUrl: data.payslipStampImageUrl });
  };

  const onStampFormDataChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) {
      setStampData({ imageUrl: data.payslipStampImageUrl });
    } else {
      setStampData({
        imageUrl: URL.createObjectURL(uploadedFile),
        changed: true,
      });
    }
  };

  const submitStampForm = async (event) => {
    event.preventDefault();
    if (!stampData.changed || !canUploadPayslipStamp) return;

    form.submitStarted();

    const data = new FormData(event.target);

    try {
      await stampTrigger({ payload: data });
      form.submitSuccess();
      closeStampModal();
      mutate();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  const { trigger } = useSWRMutation("/settings/payroll", sendPostRequest);

  const submitUpdate = async () => {
    if (!formValid) return;
    form.submitStarted();
    const data = {
      ...form.data,
    };
    if (!specifyPaymentDate) {
      data.paymentDate = 0;
    }

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div className="settings">
      <div className="flex start gap equal-widths mb-32">
        <div>
          <h2 className="fs-20 mb-16">Next Payment Date</h2>
          <div className="box mb-20">
            <label className="fs-18" htmlFor="paymentDateType">
              Salary Payment Date
            </label>
            <select
              id="paymentDateType"
              disabled={
                !canEditPayrollSettings || !!data.nextApprovedPaymentDate
              }
              {...form.fieldProps("paymentDateType")}
              className="mb-6"
            >
              <option value="" disabled hidden>
                Select Salary Payment Date
              </option>
              {paymentDateTypes.map((paymentDateType, index) => (
                <option key={index} value={paymentDateType}>
                  {paymentDateType}
                </option>
              ))}
            </select>
            {specifyPaymentDate && (
              <input
                id="paymentDate"
                type="number"
                className={nextPayDate ? "mb-6" : "mb-20"}
                placeholder={specifyPaymentDateHint}
                disabled={
                  !canEditPayrollSettings || !!data.nextApprovedPaymentDate
                }
                {...form.fieldProps("paymentDate")}
              />
            )}
            {data.nextApprovedPaymentDate ? (
              <div className="fs-16 tart-orange">
                <i>
                  Cannot modify payment date until after the next salary
                  payments are made on {data.nextApprovedPaymentDate}.
                </i>
              </div>
            ) : (
              nextPayDate && (
                <div
                  className="fs-14 color-orange"
                  style={{ letterSpacing: 0 }}
                >
                  Next payment date:&nbsp;
                  <b>{nextPayDate.toDateString("ddd, dd mmm, yyyy")}</b>
                </div>
              )
            )}
          </div>

          <h2 className="fs-20 mb-16">Payment Settings</h2>
          <div className="box mb-20">
            <label className="fs-18" htmlFor="paymentLedgerCode">
              Payment Ledger to Debit
            </label>
            <input
              id="paymentLedgerCode"
              type="text"
              className="mb-20"
              placeholder="Enter Ledger Code here"
              disabled={!canEditPayrollSettings}
              {...form.fieldProps("paymentLedgerCode")}
            />

            <label className="fs-18" htmlFor="payslipLogoURL">
              Payslip Logo URL
            </label>
            <input
              id="payslipLogoURL"
              type="text"
              className="mb-20"
              placeholder="Enter company logo URL"
              disabled={!canEditPayrollSettings}
              {...form.fieldProps("payslipLogoURL")}
            />

            <div className="flex gap between-center">
              <label>Payslip Stamp</label>
              <button className="btn mini outline" onClick={openStampModal}>
                {canUploadPayslipStamp ? "Update" : "View"}
              </button>
            </div>

            {stampData && (
              <Modal
                handleClose={closeStampModal}
                className="mobile-ads__modalcontent"
              >
                <p className="fs-24 center-text">Stamp Editor</p>
                <form onSubmit={submitStampForm}>
                  {canUploadPayslipStamp && (
                    <div className="mobile-ads__modalcontent-top flex center">
                      <label
                        className="mobile-ads__modalcontent-top-selectimage"
                        htmlFor="select-image"
                      >
                        <p className="fs-20">Select Image</p>
                        <p className="fs-16">Min. Res.: 360px * 150px</p>
                        <p className="fs-18">(.jpg, .png, .jpeg)</p>
                        <UploadIcon />
                        <input
                          type="file"
                          hidden
                          id="select-image"
                          name="stampImage"
                          onChange={onStampFormDataChange}
                        />
                      </label>
                    </div>
                  )}

                  <div className="mobile-ads__modalcontent-bottom">
                    <p className="fs-16">Preview</p>
                    <div className="mobile-ads__modalcontent-bottom-previewpane">
                      {stampData?.imageUrl && (
                        <img
                          src={stampData?.imageUrl}
                          className="mobile-ads__modalcontent-bottom-previewpane-image"
                          alt="payslip stamp"
                        />
                      )}
                    </div>

                    {canUploadPayslipStamp && (
                      <>
                        <button
                          className="mobile-ads__modalcontent-bottom-save fs-22"
                          disabled={!stampData.changed || form.state.submitting}
                        >
                          {form.state.submitting ? <FiLoader /> : " Save"}
                        </button>
                        <button
                          className="mobile-ads__modalcontent-bottom-cancel fs-18"
                          type="button"
                          onClick={closeStampModal}
                          disabled={form.state.submitting}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </form>
              </Modal>
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">Staff Roles</h2>
          <div className="box mb-20">
            <div className="mb-28">
              <MutableListView
                dataList={form.data.staffRoles}
                renderItem={(item, editButton, deleteButton) => (
                  <div
                    key={item.name}
                    className="flex gap center info-row-with-actions"
                  >
                    <div className="text">
                      <p className="fs-20">{item.name}</p>
                      <p className="charcoal-60">
                        &#8358; {CalculateNetSalary(item).toLocaleString()}
                      </p>
                    </div>

                    {canEditPayrollSettings && (
                      <div className="actions">
                        {editButton}
                        {deleteButton}
                      </div>
                    )}
                  </div>
                )}
                editItem={editStaffRole}
                deleteItem={deleteStaffRole}
              />
            </div>

            {canEditPayrollSettings && (
              <button
                onClick={() => setAddEditStaffRole({})}
                className="fullwidth btn primary light"
              >
                Add Staff Role
              </button>
            )}

            {addEditStaffRole && (
              <Modal handleClose={closeStaffRoleForm} className="box">
                <StaffRoleForm
                  role={
                    addEditStaffRole.editIndex >= 0
                      ? form.data.staffRoles[addEditStaffRole.editIndex]
                      : null
                  }
                  nextApprovedPaymentDate={data.nextApprovedPaymentDate}
                  onSubmit={addOrUpdateStaffRole}
                />
              </Modal>
            )}
          </div>
        </div>
      </div>

      {canEditPayrollSettings && (
        <button
          className="fullwidth dark primary btn"
          disabled={form.state.submitting || !formValid}
          onClick={submitUpdate}
        >
          {form.state.submitting ? <FiLoader /> : "Save Payroll Settings"}
        </button>
      )}
    </div>
  );
};

const StaffRoleForm = ({ role, nextApprovedPaymentDate, onSubmit }) => {
  const form = useForm(role);
  const formValid = form.data.name;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValid) onSubmit(form.data);
  };

  // Disable salary modification if the next payment date has been approved and
  // this is an existing role update.
  const disableSalaryMod = nextApprovedPaymentDate && role;

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-20">
        <h3 className="fs-18 center">
          {role ? `Edit "${role.name}" Staff Role` : "Add Staff Role"}
        </h3>
      </div>

      <label htmlFor="name">Name</label>
      <input
        id="name"
        type="text"
        className="mb-8"
        placeholder="Enter Name"
        {...form.fieldProps("name")}
      />

      <div className="fs-16 tart-orange mb-8">
        {disableSalaryMod && (
          <i>
            Cannot modify salary information until after the next salary
            payments are made on {nextApprovedPaymentDate}.
          </i>
        )}
      </div>

      <SalaryBreakdownFields form={form} readOnly={disableSalaryMod} />

      <button className="fullwidth btn primary" disabled={!formValid}>
        {role ? "Update Role" : "Add Role"}
      </button>
    </form>
  );
};
