import dayjs from "dayjs";

const amountElement = (amount) => <>&#8358; {amount?.toLocaleString()}</>;
const formatDate = (timestamp) =>
  timestamp && timestamp > 0
    ? dayjs(timestamp * 1000).format("DD/MM/YYYY HH:mm:ss")
    : "-";

const RepaymentModal = ({ repayments }) => {
  return (
    <div>
      <h3 className="mb-20">Repayment History</h3>
      {!repayments || repayments.length === 0 ? (
        <p>No repayments added yet</p>
      ) : (
        <div>
          {repayments.map((item, index) => (
            <RepaymentDisplay key={index} {...item} />
          ))}
        </div>
      )}
    </div>
  );
};

const RepaymentDisplay = ({
  timestamp,
  sourceAccountNumber,
  failureReason,
  transactionReference,
  status,
  amount,
  bankCardID,
  paymentMethod,
}) => {
  const repaymentInfo = [
    {
      title: "Status",
      value: (
        <>
          {status === "Successful" ? (
            <span className="color-primary">{status}</span>
          ) : (
            <span>{status}</span>
          )}
        </>
      ),
    },
    { title: "Transaction Reference", value: transactionReference },
    { title: "Timestamp", value: formatDate(timestamp) },
    { title: "Amount", value: amountElement(amount) },
    { title: "Payment Method", value: paymentMethod },
    { title: "Source Account Number", value: sourceAccountNumber },
    { title: "Bank  Card ID", value: bankCardID },
    { title: "Failure Reason", value: failureReason },
  ];

  return (
    <div className="card__box mb-20">
      <div className="card__grid">
        {repaymentInfo.map((info, index) => (
          <div key={index} className="flex gap-8 between-center">
            <span className="charcoal-60">{info.title}:</span>
            <h3>{info.value}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RepaymentModal;
