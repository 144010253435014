import { useState } from "react";
import { useOutletContext } from "react-router";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import { OutlineButton } from "../../../components/Button";
import DataFetcher from "../../../components/DataFetcher";
import CardRequestDetailsModal from "./CardRequestDetailsModal";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const PendingCardRequestsPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Cards");

  return (
    <DataFetcher
      url={`/card-requests`}
      buildUI={(data, mutate) => (
        <PendingCardRequestsDisplay requests={data} mutate={mutate} />
      )}
    />
  );
};

const PendingCardRequestsDisplay = ({ requests, mutate }) => {
  const [selectedReqIndex, setSelectedReqIndex] = useState(-1);

  return (
    <div>
      <h2 className="fs-20 mb-16">Pending Card requests</h2>

      <div className="box">
        {!requests || requests.length === 0 ? (
          <>There is currently no pending card requests</>
        ) : (
          requests.map((req, i) => (
            <div key={i} className="flex center gap info-row-with-actions">
              <div className="text">
                <h3 className="fs-20">
                  {req.customerName}
                  <span className="btn primary light fs-14 no-hover">
                    {`Tier ${req.accountTier}`}
                  </span>
                </h3>
                <p className="charcoal-60">
                  {req.cardType} &#8226; {req.cardAccountNumber}
                </p>
                <p className="charcoal-60">{req.requestReason}</p>
                <p className="charcoal-60">
                  {dayjs(req.timestamp * 1000).utc().format("D - MMM - YYYY")}
                  &nbsp;&bull;&nbsp;
                  {dayjs(req.timestamp * 1000).utc().format("hh:mm")}
                </p>
              </div>

              <div className="actions">
                <OutlineButton
                  mini
                  style={{ padding: "0.6rem 2rem" }}
                  onClick={() => setSelectedReqIndex(i)}
                >
                  View Request
                </OutlineButton>
              </div>
            </div>
          ))
        )}
      </div>

      {selectedReqIndex >= 0 && (
        <CardRequestDetailsModal
          mutate={mutate}
          req={requests[selectedReqIndex]}
          dismissModal={() => setSelectedReqIndex(-1)}
        />
      )}
    </div>
  );
};

export default PendingCardRequestsPage;
