import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";
import SearchFilterTable, {
  FilterArray,
} from "../../components/SearchFilterTable";
import DataFetcher from "../../components/DataFetcher";
import useForm from "../../hooks/useForm.hook";
import { CalculateNetSalary } from "./SalaryBreakdownFields";
import useMessageModal from "../../hooks/useMessageModal.hook";
import { sendPatchRequest, sendPostRequest } from "../../config/swr";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function PayrollHistoryPage() {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Payroll Payment History");

  const [pageState, setPageState] = useState({
    loadingData: true,
    queryParams: "",
  });

  const setDateQuery = (query) => {
    if (pageState.queryParams !== query) {
      setPageState({ loadingData: true, queryParams: query });
    }
  };

  const setLoadingComplete = () => {
    if (pageState.loadingData) {
      setPageState({ ...pageState, loadingData: false });
    }
  };

  const prepareHistoryArray = (data) => {
    return data
      .map((monthPayment) => {
        const monthKey = `${monthPayment.year}/${monthPayment.month}`;
        return monthPayment.payments.map((payment) => ({
          ...payment,
          id: `${monthKey}_${payment.staff.id}`,
          monthKey: monthKey,
          monthYear: { month: monthPayment.month, year: monthPayment.year },
          month: `${monthNames[monthPayment.month - 1]} ${monthPayment.year}`,
          staffName: payment.staff.name,
          netSalary: CalculateNetSalary(payment.salary),
          hasFailedPayment:
            payment.mainPayment.status !== "Successful" ||
            payment.secondaryPayment.status !== "Successful",
        }));
      })
      .flat()
      .sort((payment1, payment2) => {
        const sameMonth = payment1.monthKey === payment2.monthKey;
        if (sameMonth) {
          if (payment1.staffName > payment2.staffName) return 1;
          return -1;
        }

        if (payment1.monthKey > payment2.monthKey) return -1;
        return 1;
      });
  };

  return (
    <>
      <PayrollDateRangeSelector
        setDateQuery={setDateQuery}
        loadingData={pageState.loadingData}
      />

      <DataFetcher
        url={`/payroll/payment-history${pageState.queryParams}`}
        loadingUI={<></>}
        onLoadingComplete={setLoadingComplete}
        buildUI={(data) => (
          <PayrollHistoryDisplay
            data={prepareHistoryArray(data)}
            setDateQuery={setDateQuery}
          />
        )}
      />
    </>
  );
}

function PayrollHistoryDisplay({ data }) {
  const sortOptions = {
    Name: "staffName",
    Month: "monthKey",
    "Net Salary": "netSalary",
    "Other Allowances": "otherAllowances",
  };

  const [filter, setFilter] = useState({});
  const history = FilterArray(data, filter, sortOptions);

  const showMessageModal = useMessageModal();

  const { trigger: retryPaymentTrigger } = useSWRMutation(
    `/payroll/payment/retry`,
    sendPatchRequest
  );

  const { trigger: resendPayslipTrigger } = useSWRMutation(
    `/payroll/payment/resend-slip`,
    sendPostRequest
  );

  const [busyPayments, setBusyPayments] = useState({});

  const retryPayment = (payment) => {
    hitAPI(payment, retryPaymentTrigger, false); // patch, not post
  };

  const resendPayslip = (payment) => {
    hitAPI(payment, resendPayslipTrigger, true); // post
  };

  const hitAPI = async (payment, trigger, isPostReq) => {
    setBusyPayments({ ...busyPayments, [payment.id]: true });
    const data = {
      ...payment.monthYear,
      staffID: payment.staff.id,
    };
    try {
      const res = await trigger(isPostReq ? data : { payload: data });
      const ok = res.data?.ok;
      showMessageModal({
        title: ok ? "Success" : "Failed",
        message: res.data?.message,
        isError: !ok,
        closeButtonText: "Close",
      });
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
    }

    setBusyPayments({ ...busyPayments, [payment.id]: false });
  };

  return (
    <>
      <div style={{ marginBottom: "-10px" }}></div>

      <SearchFilterTable
        headers={["Name", "Month", "Net Salary", "Other Allowances", "Action"]}
        sortOptions={Object.keys(sortOptions)}
        searchHint="Search for a payment"
        searchDelay={0}
        reloadTable={setFilter}
        tableClass="mini"
      >
        {history.map((payment, index) => (
          <tr key={index}>
            <td>{payment.staff.name}</td>
            <td>{payment.month}</td>
            <td>
              <PaymentAmountAndRef
                amount={payment.netSalary}
                receipt={payment.mainPayment}
              />
            </td>
            <td>
              <PaymentAmountAndRef
                amount={payment.salary.otherAllowance}
                receipt={payment.secondaryPayment}
              />
            </td>
            <td>
              {payment.hasFailedPayment ? (
                <button
                  className="mini danger outline btn"
                  disabled={busyPayments[payment.id]}
                  onClick={() => retryPayment(payment)}
                >
                  {busyPayments[payment.id] ? (
                    <FiLoader />
                  ) : (
                    "Retry Failed Payment"
                  )}
                </button>
              ) : (
                <button
                  className="mini outline btn"
                  disabled={busyPayments[payment.id]}
                  onClick={() => resendPayslip(payment)}
                >
                  {busyPayments[payment.id] ? <FiLoader /> : "Resend Payslip"}
                </button>
              )}
            </td>
          </tr>
        ))}
      </SearchFilterTable>
    </>
  );
}

function PayrollDateRangeSelector({ setDateQuery, loadingData }) {
  const dateRangeForm = useForm({ startDate: "", endDate: "" });
  const applyDateRange = () => {
    const queryParams = ["start", "end"]
      .map((key) => {
        const dtString = dateRangeForm.data[`${key}Date`];
        if (!dtString) return "";
        const date = new Date(dtString);
        const month = date.getMonth() + 1,
          year = date.getFullYear();
        return `${key}Month=${month}&${key}Year=${year}`;
      })
      .filter((param) => !!param);
    setDateQuery("?" + queryParams.join("&"));
  };

  return (
    <div className="flex start center gap-8 fs-14">
      <input
        id="startDate"
        style={{ width: "inherit" }}
        type="date"
        max={new Date().toISOString().split("T")[0]}
        {...dateRangeForm.fieldProps("startDate")}
      />
      <span>-</span>
      <input
        id="endDate"
        style={{ width: "inherit" }}
        type="date"
        min={dateRangeForm.data.startDate}
        max={new Date().toISOString().split("T")[0]}
        {...dateRangeForm.fieldProps("endDate")}
      />
      <button
        className="primary btn fw-600 flex center gap-4"
        disabled={!dateRangeForm.anyValid() || loadingData}
        onClick={applyDateRange}
      >
        {loadingData ? <FiLoader /> : "Fetch History"}
      </button>
    </div>
  );
}

function PaymentAmountAndRef({ amount, receipt }) {
  if (receipt.transactionReference) {
    return (
      <div className="flex col">
        <span>{amount?.toLocaleString()}</span>
        <code className="fw-400 fs-14">{receipt.transactionReference}</code>
      </div>
    );
  }

  return (
    <div className="flex col color-orange tooltip">
      <span>{amount?.toLocaleString()}</span>
      <code className="fw-400 fs-14">payment failed</code>
      <span className="tooltiptext fs-14">
        {receipt.failureReason || "failure reason: unknown"}
      </span>
    </div>
  );
}
