import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import TableDotsIcon from "../../assets/icons/tabledots";
import UploadIcon from "../../assets/icons/upload";
import MutableListView from "../../components/MutableListView";
import DataFetcher from "../../components/DataFetcher";
import { InputField } from "../../components/Input";
import Modal from "../../components/Modal";
import {
  sendDeleteRequest,
  sendPatchRequest,
  sendPostRequest,
  getPermissions,
} from "../../config/swr";
import useForm from "../../hooks/useForm.hook";
import useMessageModal from "../../hooks/useMessageModal.hook";

const MAX_ADS = 5;
const DELETE_ADS_KEY = "delete-mobile-ads";
const UPDATE_ADS_KEY = "update-mobile-ads";

const MobileAdsPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Mobile Adverts");

  return (
    <DataFetcher
      url={`/mobile-ads`}
      buildUI={(data, mutate) => (
        <MobileAdsDisplay data={data} mutate={mutate} />
      )}
    />
  );
};

const MobileAdsDisplay = ({ data, mutate }) => {
  const [adData, setAdData] = useState(null);
  const permissions = getPermissions();

  const canDeleteAd = permissions[DELETE_ADS_KEY];
  const canUpdateAd = permissions[UPDATE_ADS_KEY];

  const { trigger: postTrigger } = useSWRMutation(
    "/mobile-ad",
    sendPostRequest
  );

  const { trigger: patchTrigger } = useSWRMutation(
    `/mobile-ad?id=${adData?.id}`,
    sendPatchRequest
  );

  const { trigger: deleteTrigger } = useSWRMutation(
    `/mobile-ad?id=`,
    sendDeleteRequest
  );

  const form = useForm();
  const showMessageModal = useMessageModal();

  const saveAdButtonDisabled = !adData?.imageUrl;
  const addBtnDisabled = form.state.submitting || data?.length === MAX_ADS;

  const closeAdFormModal = () => {
    setAdData(null);
    form.submitSuccess(); // clear error message
  };

  const openAdFormModal = (_, item) => {
    setAdData({
      id: item?.id,
      url: item?.url ?? "",
      imageUrl: item?.imageUrl,
    });
  };

  const deleteAd = async (index, onSuccess, onError) => {
    const { id } = data[index];
    if (!canDeleteAd) return;
    try {
      const res = await deleteTrigger(id);
      onSuccess(res.data?.message || "Successfully removed");
      mutate();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      onError();
    }
  };

  const onAdFormDataChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setAdData({
        ...adData,
        [name]: files[0],
        imageUrl: files[0] && URL.createObjectURL(files[0]),
      });
    } else {
      setAdData({
        ...adData,
        [name]: value,
      });
    }
  };

  const submitAdForm = async (event) => {
    event.preventDefault();
    if (saveAdButtonDisabled || !canUpdateAd) return;

    form.submitStarted();

    const data = adData?.id
      ? { payload: new FormData(event.target) }
      : new FormData(event.target);

    const saveAdTrigger = adData?.id ? patchTrigger : postTrigger;

    try {
      await saveAdTrigger(data);
      form.submitSuccess();
      closeAdFormModal();
      mutate();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div className="mobile-ads">
      <div className="mobile-ads__heading flex between-center fs-20">
        <p>MOBILE APP ADVERT SECTION</p>
        <span>5 Max</span>
      </div>
      <div className="mobile-ads__table">
        <table>
          <tbody>
            {
              <MutableListView
                dataList={data}
                renderItem={(item, editButton, deleteButton) => (
                  <tr key={item.id} className="flex center">
                    <td>
                      <TableDotsIcon />
                    </td>
                    <td>
                      <div>
                        <img
                          src={item.imageUrl}
                          className="mobile-ads__table-image"
                          alt="ad-img"
                        />
                      </div>
                    </td>
                    <td>
                      <p className="mobile-ads__table-title fs-20">
                        {item.url}
                      </p>
                    </td>
                    {canUpdateAd && <td>{editButton}</td>}
                    {canDeleteAd && <td>{deleteButton}</td>}
                  </tr>
                )}
                editItem={openAdFormModal}
                deleteItem={deleteAd}
              />
            }

            <tr>
              <td colSpan={5}>
                {canUpdateAd && (
                  <p className="mobile-ads__table-newad-title fs-18">
                    <button onClick={openAdFormModal} disabled={addBtnDisabled}>
                      Add New Advertisement Post
                    </button>
                  </p>
                )}
                <p className="mobile-ads__table-newad-subtitle fs-18">
                  Information will be automatically updated on mobile app
                  interface
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {!!adData && (
        <Modal
          handleClose={closeAdFormModal}
          className="mobile-ads__modalcontent"
        >
          <p className="fs-24">Ads Editor</p>
          <form onSubmit={submitAdForm}>
            <div className="mobile-ads__modalcontent-top">
              <label
                className="mobile-ads__modalcontent-top-selectimage"
                htmlFor="select-image"
              >
                <p className="fs-20">Select Image</p>
                <p className="fs-16">Min. Res.: 360px * 150px</p>
                <p className="fs-18">(.jpg, .png, .jpeg)</p>
                <UploadIcon />
                <input
                  type="file"
                  hidden
                  id="select-image"
                  name="image"
                  onChange={onAdFormDataChange}
                />
              </label>

              <div className="mobile-ads__modalcontent-top-inputs">
                <InputField
                  label="Link URL"
                  placeholder="Enter Link URL"
                  name="url"
                  value={adData?.url}
                  onChange={onAdFormDataChange}
                />
              </div>
            </div>

            <div className="mobile-ads__modalcontent-bottom">
              <p className="fs-16">Preview</p>
              <div className="mobile-ads__modalcontent-bottom-previewpane">
                {adData?.imageUrl && (
                  <img
                    src={adData?.imageUrl}
                    className="mobile-ads__modalcontent-bottom-previewpane-image"
                    alt="ad-img"
                  />
                )}
              </div>
              <button
                className="mobile-ads__modalcontent-bottom-save fs-22"
                disabled={saveAdButtonDisabled || form.state.submitting}
              >
                {form.state.submitting ? <FiLoader /> : " Save"}
              </button>
              <button
                className="mobile-ads__modalcontent-bottom-cancel fs-18"
                type="button"
                onClick={closeAdFormModal}
                disabled={form.state.submitting}
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default MobileAdsPage;
