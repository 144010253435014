import { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import dayjs from "dayjs";

import DataFetcher from "../../../components/DataFetcher";
import SearchFilterTable, {
  SORT_ASC,
} from "../../../components/SearchFilterTable";

const ReferralPromoUsersPage = () => {
  const { id } = useParams();

  const [setPageTitle] = useOutletContext();
  setPageTitle("Referral Promo Signups");

  return (
    <DataFetcher
      url={`/referral-promo/${id}/users`}
      buildUI={(data) => (
        <ReferralPromoUsersListView allUsers={data} promoID={id} />
      )}
    />
  );
};

const usersSortOptions = {
  Name: "name",
  "Signup Date": "createdAt",
  Referrer: "referrerBankerID",
  "Transaction Count": "transactionCount",
};

const filterUsers = (users, { sort, sortDirection, search, filter }) => {
  if (!!filter) {
    const filterFn =
      filter === "Pending Payout"
        ? (user) => user.bonusPaymentTxRef === ""
        : (user) => user.bonusPaymentTxRef !== "";
    users = users.filter(filterFn);
  }
  if (!!search) {
    users = users.filter(
      (user) =>
        user.name.toLowerCase().includes(search.toLowerCase()) ||
        user.phoneNumber.toLowerCase().includes(search.toLowerCase())
    );
  }
  if (!!sort) {
    const sortKey = usersSortOptions[sort];
    const positiveSortValue = sortDirection === SORT_ASC ? 1 : -1;
    users = users.sort((user1, user2) => {
      if (user1[sortKey] > user2[sortKey]) {
        return -positiveSortValue;
      }
      if (user1[sortKey] < user2[sortKey]) {
        return positiveSortValue;
      }
      return 0;
    });
  }
  return users;
};

const ReferralPromoUsersListView = ({ promoID, allUsers }) => {
  const [filter, setFilter] = useState({});
  const usersToDisplay = filterUsers(allUsers, filter);

  return (
    <div className="customers">
      {!allUsers || allUsers.length === 0 ? (
        <div className="flex col gap-8 center fs-20">No signups yet.</div>
      ) : (
        <SearchFilterTable
          mini
          searchHint="Search using name or phone number"
          searchDelay={0}
          headers={[
            "Name",
            "Phone Number",
            "Banker ID",
            "Signup Date",
            "Referrer Banker ID",
            "Txs",
            "Bonus Payment",
          ]}
          sortOptions={Object.keys(usersSortOptions)}
          filterOptions={["Pending Payout", "Completed Payout"]}
          reloadTable={setFilter}
          csvUrl={`/referral-promo/${promoID}/users?csv=true`}
        >
          {usersToDisplay.map((user) => (
            <tr key={user.bankerID}>
              <td style={{ wordBreak: "break-all" }}>{user.name}</td>
              <td>{user.phoneNumber}</td>
              <td>{user.bankerID}</td>
              <td>
                {dayjs(user.createdAt * 1000).format("DD/MM/YYYY hh:mm A")}
              </td>
              <td>{user.referrerBankerID}</td>
              <td>
                {user.transactionCount === 0
                  ? 0
                  : `${user.transactionCount} (N${user.totalAmountTransacted})`}
              </td>
              <td>
                {user.bonusPaymentTxRef
                  ? `${dayjs(user.bonusPaymentTimestamp * 1000).format(
                      "DD/MM/YYYY hh:mm A"
                    )}`
                  : "Pending"}
              </td>
            </tr>
          ))}
        </SearchFilterTable>
      )}
    </div>
  );
};

export default ReferralPromoUsersPage;
