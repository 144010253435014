import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FiLoader } from "react-icons/fi";

import DataFetcher from "../../../components/DataFetcher";
import { DelayedActionButton } from "../../../components/Button";
import { InputField } from "../../../components/Input";
import {
  getPermissions,
  sendDeleteRequest,
  sendPatchRequest,
  sendPostRequest,
} from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";

const DELETE_ROLES_KEY = "delete-security-role-setting";
const UPDATE_ROLES_KEY = "update-security-roles-settings";

const animatedComponents = makeAnimated();

const colourStyles = {
  control: (styles) => ({
    ...styles,
    border: "1px solid #9fa4a7",
    borderRadius: "12px",
    padding: "10px 8px",
    width: "100%",
    "&:hover": {
      border: "1px solid #9fa4a7",
    },
  }),
};

const SecurityRoleSettings = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Security Roles Settings");

  return (
    <DataFetcher
      url={`/settings/security-roles`}
      buildUI={(data, mutate) => (
        <SecurityRoleDisplay data={data} mutate={mutate} />
      )}
    />
  );
};

const SecurityRoleDisplay = ({ data, mutate }) => {
  const permissions = getPermissions();

  const canDeleteRoles = permissions[DELETE_ROLES_KEY];
  const canUpdateRoles = permissions[UPDATE_ROLES_KEY];
  const [editRoleID, setEditRoleID] = useState(null);
  const [roleData, setRoleData] = useState({
    name: "",
    description: "",
    permissions: [],
  });

  const { trigger: postTrigger } = useSWRMutation(
    "/settings/security-roles",
    sendPostRequest
  );

  const { trigger: patchTrigger } = useSWRMutation(
    `/settings/security-role/${roleData.id}`,
    sendPatchRequest
  );

  const { trigger: deleteTrigger } = useSWRMutation(
    `/settings/security-role`,
    sendDeleteRequest
  );

  const form = useForm();
  const delForm = useForm();
  const showMessageModal = useMessageModal();

  const buttonDisabled = !roleData.description || !roleData.name;

  const selectOptions = data.permissions?.map((item) => ({
    value: item.code,
    label: item.displayName,
  }));

  const selectedPermissions = roleData.permissions
    .map((selItem) => data.permissions?.find((item) => item.code === selItem))
    ?.filter((item) => !!item)
    ?.map((item) => ({
      value: item.code,
      label: item.displayName,
    }));

  const onChangePermissions = (selectedOption) => {
    setRoleData({
      ...roleData,
      permissions: [...selectedOption?.map((item) => item.value)],
    });
  };

  const resetRole = () => {
    setRoleData({
      name: "",
      description: "",
      permissions: [],
    });
    setEditRoleID(null);
  };

  const onRoleEdit = (id) => {
    delForm.submitSuccess(); // clear error message
    form.submitSuccess(); // clear error message
    setEditRoleID(id);
    const role = data.roles?.find((item) => item.id === id);
    setRoleData({
      ...role,
    });
  };

  const onRoleRemove = async (id) => {
    if (!canDeleteRoles) return;
    form.submitSuccess(); // clear error message
    delForm.submitStarted();

    try {
      const res = await deleteTrigger(`/${id}`);

      showMessageModal({
        title: "Success",
        message: res.data?.message || "Successfully removed",
        isError: false,
        closeButtonText: "Close",
      });
      delForm.submitSuccess();
      resetRole();
      mutate();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      delForm.submitError();
    }
  };

  const onRoleChange = (e) => {
    const { name, value } = e.target;
    setRoleData({ ...roleData, [name]: value });
  };

  const onRoleSubmit = async () => {
    if (!canUpdateRoles || buttonDisabled) return;

    delForm.submitSuccess(); // clear error message
    form.submitStarted();

    const data = editRoleID ? { payload: roleData } : roleData;

    const submitRoleData = editRoleID ? patchTrigger : postTrigger;

    try {
      const res = await submitRoleData(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message || "Successfully submitted",
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
      resetRole();
      mutate();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  const allBtnDisabled = form.state.submitting || delForm.state.submitting;

  return (
    <div className="settings flex start gap equal-widths mb-28">
      <div>
        <h2 className="fs-20 mb-16">SECURITY ROLES</h2>
        <div className="box">
          {!data.roles || data.roles?.length === 0 ? (
            <>No Roles added yet</>
          ) : (
            data.roles?.map((role) => (
              <div
                key={role.id}
                className="flex gap center info-row-with-actions"
              >
                <div className="text">
                  <h3 className="fs-20">{role.name}</h3>
                  <p className="charcoal-60">{role.description}</p>
                </div>

                <div className="actions">
                  {canUpdateRoles && (
                    <button
                      className="btn primary light"
                      onClick={() => onRoleEdit(role.id)}
                      disabled={allBtnDisabled}
                    >
                      Edit
                    </button>
                  )}
                  {canDeleteRoles && (
                    <DelayedActionButton
                      className="btn danger light"
                      text="Remove"
                      confirmText="Confirm"
                      busyText="Remove"
                      isBusy={allBtnDisabled}
                      clickAction={() => onRoleRemove(role.id)}
                    />
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <div>
        <h2 className="fs-20 mb-16">
          {editRoleID ? "EDIT" : "ADD NEW"} SECURITY ROLE
        </h2>
        <div className="box">
          <InputField
            label="Title"
            type="text"
            placeholder="Enter Role Title"
            className="mb-16"
            name="name"
            value={roleData.name}
            onChange={onRoleChange}
          />
          <InputField
            label="Description"
            type="text"
            placeholder="Enter Role Description"
            className="mb-16"
            name="description"
            value={roleData.description}
            onChange={onRoleChange}
          />
          <div className="mb-32">
            <label htmlFor="permissions">Permissions</label>
            <Select
              closeMenuOnSelect={false}
              isMulti
              inputId={"permissions"}
              components={animatedComponents}
              options={selectOptions}
              styles={colourStyles}
              onChange={onChangePermissions}
              value={selectedPermissions}
              placeholder="Select Role Responsibility"
            />
          </div>
          {canUpdateRoles && (
            <button
              className="btn primary light fullwidth"
              onClick={onRoleSubmit}
              disabled={buttonDisabled || allBtnDisabled}
            >
              {form.state.submitting ? (
                <FiLoader />
              ) : editRoleID ? (
                "Update"
              ) : (
                "Add"
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SecurityRoleSettings;
