import React, { useState } from "react";
import { BiCaretDown } from "react-icons/bi";
import { FiLoader } from "react-icons/fi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { OutlineButton } from "../../../components/Button";

dayjs.extend(utc);

const LoanTypeRetail = "retail-loan",
  LoanFilterPendingApproval = "Pending Approval",
  LoanFilterPendingDisbursement = "Pending Disbursement",
  LoanFilterPendingCloseRequest = "Pending Close Request",
  LoanFilterPendingStopInterestRequest = "Pending Stop Interest Request",
  LoanFilterActive = "Active",
  LoanFilterDue = "Due",
  LoanFilterOverdue = "Overdue",
  LoanFilterRejected = "Rejected",
  LoanFilterCompleted = "Completed";

const amountElement = (amount) => <>&#8358; {amount?.toLocaleString()}</>;
const formatDate = (timestamp) =>
  timestamp && timestamp > 0
    ? dayjs(timestamp * 1000)
        .utc()
        .format("Do MMM YYYY")
    : "-";

const LoanInfo = ({
  loan,
  loanType,
  salaryDate,
  activeFilter,
  activeStatus,
  canViewStatement,
  fetchingLoanStatement,
  fetchingLoanTransaction,
  onDateChange,
  showRepaymentModal,
  onLoanStatementClick,
  showDisbursementModal,
  onStatementTransactionsClick,
}) => {
  const loanTypeInfo = [
    {
      title: <span className="fs-18">Loan Type</span>,
      value: <span className="color-orange fs-18">{loan.type}</span>,
    },
  ];
  const adminInfo = [];
  const loanInfo = [];
  if (!!loan.salary) {
    loanInfo.push(
      { title: "Salary Amount", value: amountElement(loan.salary.amount) },
      {
        title: "Loan Amount (% Salary)",
        value: (
          <>
            <span className="charcoal-60">
              ({((loan.loanAmount * 100) / loan.salary.amount).toFixed(2)}%)
            </span>
            &nbsp;&nbsp;
            {amountElement(loan.loanAmount)}
          </>
        ),
      },
      { title: "Interest Rate", value: `${loan.interestRate}%` }
    );
  } else {
    loanInfo.push(
      {
        title: "Loan Amount",
        value: amountElement(loan.loanAmount),
      },
      { title: "Interest Rate", value: `${loan.interestRate}%` }
    );
  }

  const statementButtons = loan.canViewStatement
    ? [
        {
          title: "Bank Statement",
          value: (
            <OutlineButton
              mini
              onClick={onStatementTransactionsClick}
              disabled={fetchingLoanStatement || !canViewStatement}
            >
              {fetchingLoanTransaction ? <FiLoader /> : "View Statement"}
            </OutlineButton>
          ),
        },
        {
          title: "Bank Statement Analysis",
          value: (
            <OutlineButton
              mini
              onClick={onLoanStatementClick}
              disabled={fetchingLoanStatement || !canViewStatement}
            >
              {fetchingLoanStatement ? <FiLoader /> : "View Analysis"}
            </OutlineButton>
          ),
        },
      ]
    : [];

  //Pending Approval
  if (activeFilter === LoanFilterPendingApproval) {
    loanInfo.push({
      title: "Requested Date",
      value: formatDate(loan.requestedTimestamp),
    });

    if (!!loan.durationInDays) {
      loanInfo.push({
        title: "Duration",
        value: `${loan.durationInDays} days`,
      });
    }

    if (!!loan.salary) {
      loanInfo.push({
        title: "Pay Day",
        value: (
          <input
            className="mini fs-14"
            type="date"
            value={
              salaryDate ??
              dayjs(loan.salary.paymentDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            }
            onChange={loan.approvedBy ? null : onDateChange}
            readOnly={loan.approvedBy}
          />
        ),
      });
    }

    loanInfo.push(...statementButtons);
  }

  //Pending Disbursement
  if (activeFilter === LoanFilterPendingDisbursement) {
    loanInfo.push({
      title: "Requested Date",
      value: formatDate(loan.requestedTimestamp),
    });

    if (!!loan.durationInDays) {
      loanInfo.push({
        title: "Duration",
        value: `${loan.durationInDays} days`,
      });
    }

    if (!!loan.salary) {
      loanInfo.push({
        title: "Pay Day",
        value: (
          <input
            className="mini fs-14"
            type="date"
            value={
              salaryDate ??
              dayjs(loan.salary.paymentDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            }
            onChange={loan.approvedBy ? null : onDateChange}
            readOnly={loan.approvedBy}
          />
        ),
      });
    }

    loanInfo.push(...statementButtons);

    adminInfo.push(
      {
        title: "Approved By",
        value: <span className="color-primary">{loan.approvedBy}</span>,
      },
      {
        title: "Approval Date",
        value: formatDate(loan.dateApprovedTimestamp),
      }
    );
  }

  //Active
  if (
    activeFilter === LoanFilterActive ||
    (activeStatus === LoanFilterActive &&
      activeFilter === LoanFilterPendingCloseRequest)
  ) {
    loanInfo.push(
      {
        title: "Accrued Interest",
        value: amountElement(loan.accruedInterest),
      },
      {
        title: "Total Amount Due",
        value: amountElement(loan.accruedInterest + loan.loanAmount),
      },
      {
        title: "Total Amount Paid",
        value: (
          <div className="flex gap-8 center">
            {loan.repayments && (
              <OutlineButton mini onClick={showRepaymentModal}>
                View Details
              </OutlineButton>
            )}
            <span>{amountElement(loan.totalRepayment)}</span>
          </div>
        ),
      },
      {
        title: "Unpaid Balance",
        value: amountElement(
          loan.accruedInterest + loan.loanAmount - loan.totalRepayment
        ),
      },
      {
        title: "Requested Date",
        value: formatDate(loan.requestedTimestamp),
      },
      {
        title: "Effective Date",
        value: formatDate(loan.disbursementInfo?.timestamp),
      },
      {
        title: "Due Date",
        value: formatDate(loan.dueDateTimestamp),
      }
    );
    if (!!loan.salary) {
      loanInfo.push({
        title: "Pay Day",
        value: (
          <input
            className="mini fs-14"
            type="date"
            value={
              salaryDate ??
              dayjs(loan.salary.paymentDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            }
            onChange={loan.approvedBy ? null : onDateChange}
            readOnly={loan.approvedBy}
          />
        ),
      });
    }
    loanInfo.push(
      {
        title: "Days Left",
        value: `${dayjs(loan.dueDateTimestamp * 1000)
          .utc()
          .fromNow()}`,
      },
      ...statementButtons
    );
    if (loan.approvedBy) {
      adminInfo.push(
        {
          title: "Approved By",
          value: <span className="color-primary">{loan.approvedBy}</span>,
        },
        {
          title: "Approval Date",
          value: formatDate(loan.dateApprovedTimestamp),
        }
      );
    }
    if (loan.disburseBy) {
      adminInfo.push({
        title: "Disbursed By",
        value: (
          <div className="flex gap-8 center">
            {loan.disbursementInfo && (
              <OutlineButton mini onClick={showDisbursementModal}>
                View Details
              </OutlineButton>
            )}
            <span className="color-primary">{loan.disburseBy}</span>
          </div>
        ),
      });
    }

    if (loan.closeRequest) {
      adminInfo.push(
        {
          title: "Closed by",
          value: (
            <span className="color-primary" style={{ wordBreak: "break-all" }}>
              {loan.closedBy}
            </span>
          ),
        },
        {
          title: "Reason for Closing",
          value: <span>{loan.closeRequest?.reason}</span>,
        },
        {
          title: "Time of Request",
          value: (
            <span>
              {dayjs(loan.closeRequest?.requestTimestamp * 1000)
                .utc()
                .format("hh:mm")}
              &nbsp;&bull;&nbsp;
              {dayjs(loan.closeRequest?.requestTimestamp * 1000)
                .utc()
                .format("DD/MM/YYYY")}
            </span>
          ),
        }
      );
    }

    if (loan.stopInterestRequest) {
      adminInfo.push(
        {
          title: "Stop Interest Request Sent By",
          value: (
            <span className="color-primary" style={{ wordBreak: "break-all" }}>
              {loan.interestStoppedBy}
            </span>
          ),
        },
        {
          title: "Reason for Stopping Interest",
          value: <span>{loan.stopInterestRequest?.reason}</span>,
        },
        {
          title: "Time of Request",
          value: (
            <span>
              {dayjs(loan.stopInterestRequest?.requestTimestamp * 1000)
                .utc()
                .format("hh:mm")}
              &nbsp;&bull;&nbsp;
              {dayjs(loan.stopInterestRequest?.requestTimestamp * 1000)
                .utc()
                .format("DD/MM/YYYY")}
            </span>
          ),
        }
      );
    }
  }

  //Due
  if (activeFilter === LoanFilterDue) {
    loanInfo.push(
      {
        title: "Accrued Interest",
        value: amountElement(loan.accruedInterest),
      },
      {
        title: "Total Amount Due",
        value: amountElement(loan.accruedInterest + loan.loanAmount),
      },
      {
        title: "Total Amount Paid",
        value: (
          <div className="flex gap-8 center">
            {loan.repayments && (
              <OutlineButton mini onClick={showRepaymentModal}>
                View Details
              </OutlineButton>
            )}
            <span>{amountElement(loan.totalRepayment)}</span>
          </div>
        ),
      },
      {
        title: "Unpaid Balance",
        value: amountElement(
          loan.accruedInterest + loan.loanAmount - loan.totalRepayment
        ),
      },
      {
        title: "Requested Date",
        value: formatDate(loan.requestedTimestamp),
      },
      {
        title: "Effective Date",
        value: formatDate(loan.disbursementInfo?.timestamp),
      },
      {
        title: "Due Date",
        value: formatDate(loan.dueDateTimestamp),
      }
    );
    if (!!loan.salary) {
      loanInfo.push({
        title: "Pay Day",
        value: (
          <input
            className="mini fs-14"
            type="date"
            value={
              salaryDate ??
              dayjs(loan.salary.paymentDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            }
            onChange={loan.approvedBy ? null : onDateChange}
            readOnly={loan.approvedBy}
          />
        ),
      });
    }
    loanInfo.push(
      {
        title: "Days Left",
        value: 0,
      },
      ...statementButtons
    );
    if (loan.approvedBy) {
      adminInfo.push(
        {
          title: "Approved By",
          value: <span className="color-primary">{loan.approvedBy}</span>,
        },
        {
          title: "Approval Date",
          value: formatDate(loan.dateApprovedTimestamp),
        }
      );
    }
    if (loan.disburseBy) {
      adminInfo.push({
        title: "Disbursed By",
        value: (
          <div className="flex gap-8 center">
            {loan.disbursementInfo && (
              <OutlineButton mini onClick={showDisbursementModal}>
                View Details
              </OutlineButton>
            )}
            <span className="color-primary">{loan.disburseBy}</span>
          </div>
        ),
      });
    }
  }

  //OverDue
  if (
    activeFilter === LoanFilterOverdue ||
    activeFilter === LoanFilterPendingStopInterestRequest ||
    (activeStatus === LoanFilterOverdue &&
      activeFilter === LoanFilterPendingCloseRequest)
  ) {
    loanInfo.push(
      {
        title: "Accrued Interest",
        value: amountElement(loan.accruedInterest),
      },
      {
        title: "Total Amount Due",
        value: amountElement(loan.accruedInterest + loan.loanAmount),
      },
      {
        title: "Total Amount Paid",
        value: (
          <div className="flex gap-8 center">
            {loan.repayments && (
              <OutlineButton mini onClick={showRepaymentModal}>
                View Details
              </OutlineButton>
            )}
            <span>{amountElement(loan.totalRepayment)}</span>
          </div>
        ),
      },
      {
        title: "Unpaid Balance",
        value: amountElement(
          loan.accruedInterest + loan.loanAmount - loan.totalRepayment
        ),
      },
      {
        title: "Requested Date",
        value: formatDate(loan.requestedTimestamp),
      },
      {
        title: "Effective Date",
        value: formatDate(loan.disbursementInfo?.timestamp),
      },
      {
        title: "Due Date",
        value: formatDate(loan.dueDateTimestamp),
      }
    );
    if (!!loan.salary) {
      loanInfo.push({
        title: "Pay Day",
        value: (
          <input
            className="mini fs-14"
            type="date"
            value={
              salaryDate ??
              dayjs(loan.salary.paymentDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            }
            onChange={loan.approvedBy ? null : onDateChange}
            readOnly={loan.approvedBy}
          />
        ),
      });
    }
    loanInfo.push(
      {
        title: "Days Overdue",
        value: `${dayjs(loan.dueDateTimestamp * 1000)
          .utc()
          .fromNow()}`,
      },
      ...statementButtons
    );
    if (loan.approvedBy) {
      adminInfo.push(
        {
          title: "Approved By",
          value: <span className="color-primary">{loan.approvedBy}</span>,
        },
        {
          title: "Approval Date",
          value: formatDate(loan.dateApprovedTimestamp),
        }
      );
    }
    if (loan.disburseBy) {
      adminInfo.push({
        title: "Disbursed By",
        value: (
          <div className="flex gap-8 center">
            {loan.disbursementInfo && (
              <OutlineButton mini onClick={showDisbursementModal}>
                View Details
              </OutlineButton>
            )}
            <span className="color-primary">{loan.disburseBy}</span>
          </div>
        ),
      });
    }
    if (loan.closeRequest) {
      adminInfo.push(
        {
          title: "Closed by",
          value: (
            <span className="color-primary" style={{ wordBreak: "break-all" }}>
              {loan.closedBy}
            </span>
          ),
        },
        {
          title: "Reason for Closing",
          value: <span>{loan.closeRequest?.reason}</span>,
        },
        {
          title: "Time of Request",
          value: (
            <span>
              {dayjs(loan.closeRequest?.requestTimestamp * 1000)
                .utc()
                .format("hh:mm")}
              &nbsp;&bull;&nbsp;
              {dayjs(loan.closeRequest?.requestTimestamp * 1000)
                .utc()
                .format("DD/MM/YYYY")}
            </span>
          ),
        }
      );
    }

    if (loan.stopInterestRequest) {
      adminInfo.push(
        {
          title: "Stop Interest Request Sent By",
          value: (
            <span className="color-primary" style={{ wordBreak: "break-all" }}>
              {loan.interestStoppedBy}
            </span>
          ),
        },
        {
          title: "Reason for Stopping Interest",
          value: <span>{loan.stopInterestRequest?.reason}</span>,
        },
        {
          title: "Time of Request",
          value: (
            <span>
              {dayjs(loan.stopInterestRequest?.requestTimestamp * 1000)
                .utc()
                .format("hh:mm")}
              &nbsp;&bull;&nbsp;
              {dayjs(loan.stopInterestRequest?.requestTimestamp * 1000)
                .utc()
                .format("DD/MM/YYYY")}
            </span>
          ),
        }
      );
    }
  }

  //Rejected
  if (activeFilter === LoanFilterRejected) {
    loanInfo.push({
      title: "Requested Date",
      value: formatDate(loan.requestedTimestamp),
    });
    if (loanType === LoanTypeRetail) {
      loanInfo.push({
        title: "Duration",
        value: `${loan.durationInDays} days`,
      });
    }
    if (!!loan.salary) {
      loanInfo.push({
        title: "Pay Day",
        value: (
          <input
            className="mini fs-14"
            type="date"
            value={
              salaryDate ??
              dayjs(loan.salary.paymentDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            }
            onChange={loan.approvedBy ? null : onDateChange}
            readOnly={loan.approvedBy}
          />
        ),
      });
    }
    if (loan.approvedBy) {
      adminInfo.push(
        {
          title: "Approved By",
          value: <span className="color-primary">{loan.approvedBy}</span>,
        },
        {
          title: "Approval Date",
          value: formatDate(loan.dateApprovedTimestamp),
        }
      );
    }
    if (loan.disburseBy) {
      adminInfo.push({
        title: "Disbursed By",
        value: (
          <div className="flex gap-8 center">
            {loan.disbursementInfo && (
              <OutlineButton mini onClick={showDisbursementModal}>
                View Details
              </OutlineButton>
            )}
            <span className="color-primary">{loan.disburseBy}</span>
          </div>
        ),
      });
    }
    if (loan.rejectedBy) {
      adminInfo.push(
        {
          title: "Rejected By",
          value: <span className="color-primary">{loan.rejectedBy}</span>,
        },
        {
          title: "Rejection Date",
          value: formatDate(loan.dateRejectedTimestamp),
        }
      );
    }
  }

  //Completed
  if (activeFilter === LoanFilterCompleted) {
    loanInfo.push(
      {
        title: "Accrued Interest",
        value: amountElement(loan.accruedInterest),
      },
      {
        title: "Total Amount Due",
        value: amountElement(loan.accruedInterest + loan.loanAmount),
      },
      {
        title: "Total Amount Paid",
        value: (
          <div className="flex gap-8 center">
            {loan.repayments && (
              <OutlineButton mini onClick={showRepaymentModal}>
                View Details
              </OutlineButton>
            )}
            <span>{amountElement(loan.totalRepayment)}</span>
          </div>
        ),
      },
      {
        title: "Unpaid Balance",
        value: amountElement(
          loan.accruedInterest + loan.loanAmount - loan.totalRepayment
        ),
      },
      {
        title: "Requested Date",
        value: formatDate(loan.requestedTimestamp),
      },
      {
        title: "Effective Date",
        value: formatDate(loan.disbursementInfo?.timestamp),
      },
      {
        title: "Due Date",
        value: formatDate(loan.dueDateTimestamp),
      }
    );
    if (!!loan.salary) {
      loanInfo.push({
        title: "Pay Day",
        value: (
          <input
            className="mini fs-14"
            type="date"
            value={
              salaryDate ??
              dayjs(loan.salary.paymentDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            }
            onChange={loan.approvedBy ? null : onDateChange}
            readOnly={loan.approvedBy}
          />
        ),
      });
    }
    loanInfo.push(
      {
        title: "Days Left",
        value: 0,
      },
      ...statementButtons
    );
    loanInfo.push({
      title: "Completion Date",
      value: formatDate(loan.completedTimestamp),
    });
    if (loan.approvedBy) {
      adminInfo.push(
        {
          title: "Approved By",
          value: <span className="color-primary">{loan.approvedBy}</span>,
        },
        {
          title: "Approval Date",
          value: formatDate(loan.dateApprovedTimestamp),
        }
      );
    }
    if (loan.disburseBy) {
      adminInfo.push({
        title: "Disbursed By",
        value: (
          <div className="flex gap-8 center">
            {loan.disbursementInfo && (
              <OutlineButton mini onClick={showDisbursementModal}>
                View Details
              </OutlineButton>
            )}
            <span className="color-primary">{loan.disburseBy}</span>
          </div>
        ),
      });
    }
    if (loan.closeRequest) {
      adminInfo.push(
        {
          title: "Closed by",
          value: (
            <span className="color-primary" style={{ wordBreak: "break-all" }}>
              {loan.closedBy}
            </span>
          ),
        },
        {
          title: "Reason for Closing",
          value: <span>{loan.closeRequest?.reason}</span>,
        },
        {
          title: "Time of Close Request",
          value: (
            <span>
              {dayjs(loan.closeRequest?.requestTimestamp * 1000)
                .utc()
                .format("hh:mm")}
              &nbsp;&bull;&nbsp;
              {dayjs(loan.closeRequest?.requestTimestamp * 1000)
                .utc()
                .format("DD/MM/YYYY")}
            </span>
          ),
        }
      );
    }
    if (loan.stopInterestRequest) {
      adminInfo.push(
        {
          title: "Stop Interest Request Sent By",
          value: (
            <span className="color-primary" style={{ wordBreak: "break-all" }}>
              {loan.interestStoppedBy}
            </span>
          ),
        },
        {
          title: "Reason for Stopping Interest",
          value: <span>{loan.stopInterestRequest?.reason}</span>,
        },
        {
          title: "Time of Stop Interest Request",
          value: (
            <span>
              {dayjs(loan.stopInterestRequest?.requestTimestamp * 1000)
                .utc()
                .format("hh:mm")}
              &nbsp;&bull;&nbsp;
              {dayjs(loan.stopInterestRequest?.requestTimestamp * 1000)
                .utc()
                .format("DD/MM/YYYY")}
            </span>
          ),
        }
      );
    }
  }

  const loanInfoSections = [
    {
      name: "Loan Summary",
      values: loanInfo,
    },
    ...(loan.additionalInfo ?? []),
  ];

  const [sectionToggled, setSectionToggled] = useState(
    loanInfoSections.reduce(
      (obj, section) => ({ ...obj, [section.name]: false }),
      {}
    )
  );

  const toggleDropdown = (sectionName) => {
    setSectionToggled({
      ...sectionToggled,
      [sectionName]: !sectionToggled[sectionName],
    });
  };

  const loanInfoElem = (
    <>
      {loanInfoSections.map((section) => (
        <div className="card__box mb-12" key={section.name}>
          <div
            className="flex gap between-center"
            onClick={() => toggleDropdown(section.name)}
          >
            <span>{section.name}</span>
            <span>
              <BiCaretDown
                size={18}
                className={
                  sectionToggled[section.name]
                    ? "dropdown-icon rotate-180"
                    : "dropdown-icon"
                }
              />
            </span>
          </div>
          <div
            className={
              sectionToggled[section.name]
                ? "message__dropdown opened"
                : "message__dropdown"
            }
          >
            <div className="card__grid">
              {section.values.map((info, index) => (
                <React.Fragment key={index}>
                  <div className="flex gap-8 between-center">
                    <span className="charcoal-60">
                      {info.title ?? info.name}:
                    </span>
                    <h3 style={{ textAlign: "right" }}>
                      {info.value?.toString().startsWith("https://") ? (
                        <>
                          {info.isImage && (
                            <img
                              src={info.value}
                              alt={`${info.name} attachment`}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px",
                                display: "block",
                              }}
                            />
                          )}
                          <a href={info.value} target="_blank" rel="noreferrer">
                            {info.isImage || info.isAttachment ? 'View/Download Attachment' : info.value}
                          </a>
                        </>
                      ) : (
                        info.value
                      )}
                    </h3>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );

  const adminInfoElem = (
    <div className="card__grid">
      {adminInfo.map((info, index) => (
        <div key={index} className="flex gap-8 between-center">
          <span className="charcoal-60">{info.title}:</span>
          <h3>{info.value}</h3>
        </div>
      ))}
    </div>
  );

  return { loanTypeInfo, loanInfoElem, adminInfoElem };
};

export default LoanInfo;
