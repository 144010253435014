import React from "react";

const TableDotsIcon = () => {
  return (
    <svg
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 3C20.5 3.66304 20.7634 4.29893 21.2322 4.76777C21.7011 5.23661 22.337 5.5 23 5.5C23.663 5.5 24.2989 5.23661 24.7678 4.76777C25.2366 4.29893 25.5 3.66304 25.5 3C25.5 2.33696 25.2366 1.70107 24.7678 1.23223C24.2989 0.763393 23.663 0.5 23 0.5C22.337 0.5 21.7011 0.763393 21.2322 1.23223C20.7634 1.70107 20.5 2.33696 20.5 3ZM20.5 13C20.5 13.663 20.7634 14.2989 21.2322 14.7678C21.7011 15.2366 22.337 15.5 23 15.5C23.663 15.5 24.2989 15.2366 24.7678 14.7678C25.2366 14.2989 25.5 13.663 25.5 13C25.5 12.337 25.2366 11.7011 24.7678 11.2322C24.2989 10.7634 23.663 10.5 23 10.5C22.337 10.5 21.7011 10.7634 21.2322 11.2322C20.7634 11.7011 20.5 12.337 20.5 13ZM13 15.5C12.337 15.5 11.7011 15.2366 11.2322 14.7678C10.7634 14.2989 10.5 13.663 10.5 13C10.5 12.337 10.7634 11.7011 11.2322 11.2322C11.7011 10.7634 12.337 10.5 13 10.5C13.663 10.5 14.2989 10.7634 14.7678 11.2322C15.2366 11.7011 15.5 12.337 15.5 13C15.5 13.663 15.2366 14.2989 14.7678 14.7678C14.2989 15.2366 13.663 15.5 13 15.5ZM10.5 3C10.5 3.66304 10.7634 4.29893 11.2322 4.76777C11.7011 5.23661 12.337 5.5 13 5.5C13.663 5.5 14.2989 5.23661 14.7678 4.76777C15.2366 4.29893 15.5 3.66304 15.5 3C15.5 2.33696 15.2366 1.70107 14.7678 1.23223C14.2989 0.763393 13.663 0.5 13 0.5C12.337 0.5 11.7011 0.763393 11.2322 1.23223C10.7634 1.70107 10.5 2.33696 10.5 3ZM3 15.5C2.33696 15.5 1.70107 15.2366 1.23223 14.7678C0.763392 14.2989 0.5 13.663 0.5 13C0.5 12.337 0.763392 11.7011 1.23223 11.2322C1.70107 10.7634 2.33696 10.5 3 10.5C3.66304 10.5 4.29893 10.7634 4.76777 11.2322C5.23661 11.7011 5.5 12.337 5.5 13C5.5 13.663 5.23661 14.2989 4.76777 14.7678C4.29893 15.2366 3.66304 15.5 3 15.5ZM0.5 3C0.5 3.66304 0.763392 4.29893 1.23223 4.76777C1.70107 5.23661 2.33696 5.5 3 5.5C3.66304 5.5 4.29893 5.23661 4.76777 4.76777C5.23661 4.29893 5.5 3.66304 5.5 3C5.5 2.33696 5.23661 1.70107 4.76777 1.23223C4.29893 0.763393 3.66304 0.5 3 0.5C2.33696 0.5 1.70107 0.763393 1.23223 1.23223C0.763392 1.70107 0.5 2.33696 0.5 3Z"
        fill="#9FA4A7"
      />
    </svg>
  );
};

export default TableDotsIcon;
