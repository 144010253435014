import React, { useRef } from "react";
import { useOutsideClickAlerter } from "../hooks/onClickOutside.hook";

export const OutsideClickAlerter = ({
  handleOnClickOutside,
  children,
  ...props
}) => {
  const ref = useRef();
  useOutsideClickAlerter({ ref, handleOnClickOutside });
  return (
    <div {...props} ref={ref}>
      {children}
    </div>
  );
};

export const ButtonOutsideClickAlerter = ({
  handleOnClickOutside,
  children,
  className,
  ...props
}) => {
  const ref = useRef();
  useOutsideClickAlerter({ ref, handleOnClickOutside });
  return (
    <button {...props} ref={ref} className={className}>
      {children}
    </button>
  );
};
