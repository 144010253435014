// import LoanRestriction from "./LoanRestriction";
import SectionDropDown from "./SectionDropDown";

const CustomerApplicationForm = ({
  applicationForm,
  dispatch,
  selectOptions,
  fieldEdited,
  loanRestrictions,
  dropdownArr
}) => {
  return (
    <div className="customer-application">
      {!applicationForm || applicationForm?.length === 0 ? (
        <p style={{ padding: "18px 0" }}>No sections added yet</p>
      ) : (
        <div>
          {applicationForm.map((section, index) => (
            <SectionDropDown
              key={index}
              section={section}
              dispatch={dispatch}
              applicationForm={applicationForm}
              dropdownArr={dropdownArr}
            />
          ))}
        </div>
      )}
      {/* {selectOptions && selectOptions.length > 0 && (
        <LoanRestriction
          key={fieldEdited}
          applicationForm={applicationForm}
          loanRestrictions={loanRestrictions}
          selectOptions={selectOptions}
          dispatch={dispatch}
        />
      )} */}
    </div>
  );
};

export default CustomerApplicationForm;
