import React from "react";
import { useLocation } from "react-router-dom";
import { FiEye, FiEyeOff, FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";

import useForm from "../../../hooks/useForm.hook";
import useToggle from "../../../hooks/useToggle.hook";
import { sendPostRequest } from "../../../config/swr";
import useMessageModal from "../../../hooks/useMessageModal.hook";

const SetPasswordForm = ({ fcmToken, goToLogin }) => {
  const { trigger } = useSWRMutation("/set-password", sendPostRequest);
  const [pwdVisible, togglePasswordVisibility] = useToggle(false);
  const [repeatPwdVisible, toggleRepeatPasswordVisibility] = useToggle(false);
  const showMessageModal = useMessageModal();

  const tokenFromUrl = new URLSearchParams(useLocation().search).get("token");
  const form = useForm({
    email: "",
    password: "",
    repeatPassword: "",
    token: tokenFromUrl,
  });

  const canSubmitForm =
    form.valid() && fcmToken.ready() && !form.state.submitting;

  const submitSetForm = async (e) => {
    e?.preventDefault();

    if (!canSubmitForm) return;

    const { email, password, repeatPassword, token } = form.data;

    if (password !== repeatPassword) {
      showMessageModal({
        title: "Error",
        message: "Password is not the same as Repeat Password",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
      return;
    }

    const notificationToken = fcmToken.value();

    // TODO: Use a unique device identifier that can properly distingush this
    // device from other devices. FCM ntfn token does that though.
    const deviceID = notificationToken;

    const data = {
      email,
      password,
      token,
      deviceID,
      notificationToken,
    };
    form.submitStarted();

    try {
      await trigger(data);
      goToLogin();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <form className="fullwidth">
      {/* <div className="login-form"> */}
      <h1 className="fs-24">Set Password</h1>

      {!tokenFromUrl && (
        <div className="login-form-field mb-20">
          <label htmlFor="token">Token Code</label>
          <input
            type="text"
            name="token"
            disabled={form.state.submitting}
            {...form.fieldProps("token")}
          />
        </div>
      )}

      <div className="login-form-field mb-20">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          name="email"
          placeholder="admin@email.com"
          disabled={form.state.submitting}
          {...form.fieldProps("email")}
        />
      </div>

      <div className="form-field has-icon mb-20">
        <label htmlFor="pwd">Password</label>
        <input
          type={pwdVisible ? "visible" : "password"}
          name="pwd"
          placeholder="••••••••"
          disabled={form.state.submitting}
          {...form.fieldProps("password")}
        />
        <span id="form-field-icon" onClick={togglePasswordVisibility}>
          {pwdVisible ? (
            <FiEyeOff size={16} color={"#ABB3B7"} />
          ) : (
            <FiEye size={16} color={"#ABB3B7"} />
          )}
        </span>
      </div>

      <div className="form-field has-icon">
        <label htmlFor="pwd">Repeat Password</label>
        <input
          type={repeatPwdVisible ? "visible" : "password"}
          name="pwd"
          placeholder="••••••••"
          disabled={form.state.submitting}
          {...form.fieldProps("repeatPassword")}
        />
        <span id="form-field-icon" onClick={toggleRepeatPasswordVisibility}>
          {repeatPwdVisible ? (
            <FiEyeOff size={16} color={"#ABB3B7"} />
          ) : (
            <FiEye size={16} color={"#ABB3B7"} />
          )}
        </span>
      </div>

      <button
        className="forgot-pwd-btn fs-16 mb-32"
        type="button"
        onClick={goToLogin}
        disabled={form.state.submitting}
      >
        Go to Login
      </button>

      <button
        className="btn primary fullwidth"
        onClick={submitSetForm}
        disabled={!canSubmitForm}
      >
        {form.state.submitting ? <FiLoader /> : "Set Password"}
      </button>
    </form>
  );
};

export default SetPasswordForm;
