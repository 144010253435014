import { useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";

import { PrimaryButton } from "./Button";
import { FALLBACK_IMAGE_URL } from "../config/swr";

function AccountHolderBox({
  accountName,
  accountNumber,
  profileImageUrl,
  setSendMessageModal,
  infoList,
  className = "mb-32",
  children,
}) {
  const [imgSrc, setImgSrc] = useState(profileImageUrl ?? FALLBACK_IMAGE_URL);

  const profileInfo = (
    <>
      <img
        src={imgSrc}
        onError={() => {
          setImgSrc(FALLBACK_IMAGE_URL);
        }}
        alt="profile"
        className={`profile-img ${children ? "mb-12" : "mb-20"}`}
      />

      <h3 className="fs-20 mb-6">{accountName}</h3>
      <p className={`charcoal-60 ${children ? "mb-12" : "mb-20 center-text"}`}>
        {accountNumber}
      </p>

      <PrimaryButton
        mini={!!children}
        className={`${children ? "flex center" : "flex center fs-14"}`}
        onClick={() => setSendMessageModal(true)}
      >
        <AiOutlineMessage />
        &nbsp;Send Message
      </PrimaryButton>
    </>
  );

  const infoListElement =
    infoList && infoList.length > 0 ? (
      <div className={className}>
        {infoList.map((info, index) => (
          <div key={index} className="flex gap between-center mb-16">
            <span className="charcoal-60 fs-16">{info.title}</span>
            <h3 className="fs-16">{info.value}</h3>
          </div>
        ))}
      </div>
    ) : null;

  if (!children)
    return (
      <>
        <div className="flex col center mb-16">{profileInfo}</div>
        <hr className="mb-16" />
        {infoListElement}
      </>
    );

  return (
    <>
      <div className="flex gap equal-widths mb-16">
        <div>{profileInfo}</div>
        <div>{children}</div>
      </div>
      <hr className="mb-16" />
      {infoListElement}
    </>
  );
}

export default AccountHolderBox;
