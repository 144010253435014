import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import {
  getPermissions,
  sendPatchRequest,
  sendPostRequest,
} from "../../config/swr";
import SearchFilterTable, {
  FilterArray,
} from "../../components/SearchFilterTable";
import { PayrollPermissions } from ".";
import { CalculateNetSalary, CompareSalaries } from "./SalaryBreakdownFields";
import PayrollStaffDetailsModal from "./PayrollStaffDetailsModal";
import DataFetcher from "../../components/DataFetcher";
import useMessageModal from "../../hooks/useMessageModal.hook";
import { FiLoader } from "react-icons/fi";

const headers = [
  "S/N",
  "Name",
  "Role",
  "Net Salary",
  "Salary Account",
  "Email",
  "Phone Number",
  "Status",
  "Action", // edit button column
];

const sortOptions = {
  Name: "name",
  "Net Salary": "netSalary",
  Role: "role",
  Status: "status",
};

const PayrollStaffListPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Payroll Staff List");

  const cleanupStaffList = (data) => {
    const statusText = (isDisabled) => (isDisabled ? "Disabled" : "Active");
    const roleWithID = (roleID) =>
      roleID ? data.staffRoles.find((role) => role.id === roleID) : "";

    data.staff = (data.staff ?? [])
      .map((staff) => {
        if (staff.latestInfo && !staff.name) {
          Object.keys(staff.latestInfo).map(
            (field) => (staff[field] = staff.latestInfo[field])
          );
          staff.latestInfo.new = true;
        } else if (staff.latestInfo) {
          staff.isStatusChange =
            staff.isDisabled !== staff.latestInfo.isDisabled;

          staff.updatedInfo = {};
          Object.keys(staff.latestInfo).forEach((field) => {
            const oldValue = staff[field];
            if (oldValue !== staff.latestInfo[field]) {
              if (field === "role") {
                staff.updatedInfo[field] = roleWithID(oldValue)?.name;
              } else {
                staff.updatedInfo[field] = oldValue || "-";
              }
            }
          });

          const salaryFromStaffInfo = (staffInfo) => {
            return roleWithID(staffInfo.role) ?? staffInfo;
          };
          const oldSalary = salaryFromStaffInfo(staff) ?? {};
          const newSalary = salaryFromStaffInfo(staff.latestInfo) ?? {};
          staff.updatedInfo.salaryChanges = CompareSalaries(
            newSalary,
            oldSalary
          );
        }

        staff.status = staff.latestInfo?.new
          ? "New"
          : statusText(staff.isDisabled);

        const staffRole = roleWithID(staff.role);
        staff.roleName = staffRole?.name ?? "";
        staff.netSalary = CalculateNetSalary(
          staff.tieSalaryToRole ? staffRole : staff
        );

        const atSymbolIndex = (staff.email ?? "").indexOf("@");
        if (atSymbolIndex > 12) {
          // 9 chars + ... = max 12 chars before @ symbol
          staff.emailShort =
            staff.email.substring(0, 9) +
            "..." +
            staff.email.substring(atSymbolIndex);
        }

        return staff;
      })
      .sort((staff1, staff2) => {
        if (staff1.latestInfo?.new && !staff2.latestInfo?.new) {
          return -1; // show staff1 that is a new info before staff2
        }
        if (staff1.latestInfo && !staff2.latestInfo) {
          return -1; // show staff1 that has pending update before staff2
        }
        return 0;
      });

    return data;
  };

  return (
    <DataFetcher
      url={`/payroll/staffs`}
      buildUI={(data, mutate) => (
        <PayrollStaffListDisplay
          data={cleanupStaffList(data)}
          mutate={mutate}
        />
      )}
    />
  );
};

const PayrollStaffListDisplay = ({ data, mutate }) => {
  const permissions = getPermissions();
  const canAddPayrollStaffInfo =
    permissions[PayrollPermissions.ADD_PAYROLL_STAFF_PERM];
  const canUploadPayrollStaffCSV =
    permissions[PayrollPermissions.UPLOAD_PAYROLL_STAFF_CSV_PERM];
  const canEditPayrollStaffInfo =
    permissions[PayrollPermissions.EDIT_PAYROLL_STAFF_PERM];
  const canToggleStaffStatus =
    permissions[PayrollPermissions.TOGGLE_PAYROLL_STAFF_STATUS_PERM];
  const canReviewStaffInfoUpdate =
    permissions[PayrollPermissions.REVIEW_PAYROLL_STAFF_UPDATE_PERM];

  const [filter, setFilter] = useState({});
  const staffList = FilterArray(data.staff, filter, sortOptions);

  const [staffInfoModal, setStaffInfoModal] = useState(null);
  const closeStaffInfoModal = (success) => {
    setStaffInfoModal(null);
    if (success) mutate();
  };

  const staffToEdit = staffInfoModal?.editStaffID
    ? data.staff.find((staff) => staff.id === staffInfoModal.editStaffID)
    : null;

  const showMessageModal = useMessageModal();

  const { trigger: uploadCSVTrigger } = useSWRMutation(
    `/payroll/staff/bulk`,
    sendPostRequest
  );
  const [uploadingCSV, setUploadingCSV] = useState(false);
  const uploadCSV = async (e) => {
    e.preventDefault();
    setUploadingCSV(true);

    const data = new FormData();
    data.append("staffCsv", e.target.files[0]);

    try {
      const res = await uploadCSVTrigger(data);
      const { ok, message } = res.data ?? {};
      showMessageModal({
        title: ok ? "Success" : "Failed",
        message: message,
        isError: !ok,
        closeButtonText: "Close",
      });
      if (message.toLocaleLowerCase().includes("successful")) {
        mutate();
      }
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
    }
    setUploadingCSV(false);
  };

  const { trigger: toggleStaffStatus } = useSWRMutation(
    `/payroll/staff`,
    sendPatchRequest
  );
  const [changingStatus, setChangingStatus] = useState(false);
  const disableStaff = async (staffID, disable) => {
    setChangingStatus(true);
    try {
      const subUrl = `/${staffID}/status?disable=${disable ? "true" : "false"}`;
      const res = await toggleStaffStatus({ subUrl });
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      mutate();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
    }
    setChangingStatus(false);
  };

  const addStaffButtons = (
    <>
      {canAddPayrollStaffInfo && (
        <button
          className="mini btn outline fw-600"
          onClick={() => setStaffInfoModal(true)}
        >
          Add Staff
        </button>
      )}
      {canUploadPayrollStaffCSV && (
        <div
          id="image-picker"
          className="clickable mini btn outline fw-600"
          disabled={uploadingCSV}
        >
          <span>
            {uploadingCSV ? (
              <>
                <FiLoader /> Uploading...
              </>
            ) : (
              "Upload CSV"
            )}
          </span>
          <input
            type="file"
            disabled={uploadingCSV}
            onChange={uploadCSV}
            value=""
          />
        </div>
      )}
    </>
  );

  const asterisk = <sup>*</sup>;

  return (
    <>
      {data.staff.length === 0 ? (
        <div className="flex col gap-8 center fs-20">
          <p>No staff info yet.</p>
          {addStaffButtons}
        </div>
      ) : (
        <>
          <div style={{ marginBottom: "-30px" }}>
            {/* Reduce the unnecessary marginTop on the SearchFilterTable below */}
          </div>
          <SearchFilterTable
            headers={headers}
            sortOptions={Object.keys(sortOptions)}
            searchHint="Search for a staff"
            searchDelay={0}
            reloadTable={setFilter}
            appendWidget={addStaffButtons}
            tableClass="mini"
          >
            {staffList.map((staff, index) => (
              <tr
                key={index}
                style={
                  canReviewStaffInfoUpdate && staff.latestInfo
                    ? {
                        background: staff.latestInfo.new
                          ? "#d9f7c7"
                          : "#efecde",
                      }
                    : {}
                }
              >
                <td>{index + 1}</td>
                <td>
                  {staff.name}
                  {staff.updatedInfo?.name && asterisk}
                </td>
                <td>
                  {staff.roleName}
                  {staff.updatedInfo?.role && asterisk}
                </td>
                <td>
                  &#8358;{staff.netSalary?.toLocaleString()}
                  {staff.updatedInfo?.salaryChanges && asterisk}
                </td>
                <td>
                  {staff.salaryAccount}
                  {staff.updatedInfo?.salaryAccount && asterisk}
                </td>
                <td>
                  {staff.emailShort ?? staff.email}
                  {staff.updatedInfo?.email && asterisk}
                </td>
                <td>
                  {staff.phoneNumber}
                  {staff.updatedInfo?.phoneNumber && asterisk}
                </td>
                <td>
                  {staff.status}
                  {staff.updatedInfo?.isDisabled && asterisk}
                </td>
                <td>
                  {staff.latestInfo ? (
                    <button
                      className="mini danger btn"
                      disabled={!canReviewStaffInfoUpdate}
                      onClick={() =>
                        setStaffInfoModal({ editStaffID: staff.id })
                      }
                    >
                      {staff.isStatusChange
                        ? "Review Status Change"
                        : staff.latestInfo.new
                        ? "Review New Staff Info"
                        : "Review Updated Info"}
                    </button>
                  ) : (
                    canEditPayrollStaffInfo && (
                      <>
                        <button
                          className="mini primary btn"
                          disabled={!canEditPayrollStaffInfo}
                          onClick={() =>
                            setStaffInfoModal({ editStaffID: staff.id })
                          }
                        >
                          Edit
                        </button>
                        &nbsp;&nbsp;
                        {staff.isDisabled ? (
                          <button
                            className="mini btn primary"
                            disabled={!canToggleStaffStatus || changingStatus}
                            onClick={() => disableStaff(staff.id, false)}
                          >
                            {changingStatus ? "Enabling..." : "Enable"}
                          </button>
                        ) : (
                          <button
                            className="mini btn danger"
                            disabled={!canToggleStaffStatus || changingStatus}
                            onClick={() => disableStaff(staff.id, true)}
                          >
                            {changingStatus ? "Disabling..." : "Disable"}
                          </button>
                        )}
                      </>
                    )
                  )}
                </td>
              </tr>
            ))}
          </SearchFilterTable>
        </>
      )}

      {staffInfoModal && (
        <PayrollStaffDetailsModal
          staff={staffToEdit}
          staffRoles={data.staffRoles}
          nextApprovedPaymentDate={data.nextApprovedPaymentDate}
          dismissModal={closeStaffInfoModal}
        />
      )}
    </>
  );
};

export default PayrollStaffListPage;
