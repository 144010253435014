import React from "react";

const ExportIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 16H13V7H16L12 2L8 7H11V16Z" fill="#627178" />
      <path
        d="M5 22H19C20.103 22 21 21.103 21 20V11C21 9.897 20.103 9 19 9H15V11H19V20H5V11H9V9H5C3.897 9 3 9.897 3 11V20C3 21.103 3.897 22 5 22Z"
        fill="#627178"
      />
    </svg>
  );
};

export default ExportIcon;
