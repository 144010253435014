import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import DataFetcher from "../../../components/DataFetcher";
import SearchFilterTable, {
  FilterArray,
} from "../../../components/SearchFilterTable";
import { getPermissions } from "../../../config/swr";
import VirtualAccountDetailsModal from "./VirtualAccountDetailsModal";
import PlusBox from "../../../assets/icons/plusbox";
import CreateVirtualAccountModal from "./CreateVirtualAccountModal";

dayjs.extend(utc);

const CREATE_VIRTUAL_ACCOUNTS_PERM = "create-virtual-accounts";

const VirtualAccountsPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Virtual Accounts");

  return (
    <DataFetcher
      url={`/virtual-accounts`}
      buildUI={(data, mutate) => (
        <VirtualAccountsList data={data} mutate={mutate} />
      )}
    />
  );
};

const VirtualAccountsList = ({ data, mutate }) => {
  const sortOptions = {
    "Date Created": "created",
    "Last Updated": "lastUpdated",
    Name: "name",
    Balance: "balance",
  };
  const [filter, setFilter] = useState({ sort: "Date Created" });
  const accounts = FilterArray(data, filter, sortOptions);

  const [createVirtualAccount, setCreateVirtualAccount] = useState(false);
  const openCreateVirtualAccountModal = () => setCreateVirtualAccount(true);
  const closeCreateVirtualAccountModal = () => setCreateVirtualAccount(false);

  const [selectedAccountNumber, setSelectedAccountNumber] = useState("");
  const selectedAccount =
    selectedAccountNumber &&
    accounts.find((account) => account.accountNumber === selectedAccountNumber);

  const permissions = getPermissions();
  const canCreateVirtualAccounts = permissions[CREATE_VIRTUAL_ACCOUNTS_PERM];
  const createAccountButton = canCreateVirtualAccounts ? (
    <button
      className="fw-700 transparent btn flex center gap-4"
      onClick={openCreateVirtualAccountModal}
    >
      <PlusBox color="#627178" />
      Create Virtual Account
    </button>
  ) : null;

  const dateDisplay = (timestamp) => {
    if (!timestamp) return "-";
    return dayjs(timestamp * 1000)
      .utc()
      .format("DD/MM/YYYY hh:mm A");
  };

  return (
    <>
      <div style={{ marginBottom: "-40px" }}></div>

      <SearchFilterTable
        headers={[
          "Full Name",
          "Account Number",
          "Balance",
          "BVN",
          "NIN",
          "Date Created",
          "Last Updated",
        ]}
        sortOptions={Object.keys(sortOptions)}
        searchHint="Search for an account"
        searchDelay={0}
        reloadTable={setFilter}
        prependWidget={createAccountButton}
      >
        {accounts.map((account) => (
          <tr
            key={account.accountNumber}
            className="clickable"
            onClick={() => setSelectedAccountNumber(account.accountNumber)}
          >
            <td>{account.name}</td>
            <td>{account.accountNumber}</td>
            <td>&#8358; {account.balance?.toLocaleString()}</td>
            <td>{account.bvn}</td>
            <td>{account.nin}</td>
            <td>{dateDisplay(account.created)}</td>
            <td>{dateDisplay(account.lastUpdated)}</td>
          </tr>
        ))}
      </SearchFilterTable>

      {createVirtualAccount ? (
        <CreateVirtualAccountModal
          closeModal={closeCreateVirtualAccountModal}
        />
      ) : selectedAccount ? (
        <VirtualAccountDetailsModal
          account={selectedAccount}
          dismissModal={setSelectedAccountNumber}
          mutate={mutate}
        />
      ) : null}
    </>
  );
};

export default VirtualAccountsPage;
