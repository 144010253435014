import { useEffect, useState, useRef } from "react";
import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";
import Modal from "../../components/Modal";
import useForm from "../../hooks/useForm.hook";
import useMessageModal from "../../hooks/useMessageModal.hook";
import {
  BackButton,
  DelayedActionButton,
  OutlineButton,
} from "../../components/Button";
import CheckBox from "../../components/Checkbox";
import SalaryBreakdownFields, { ClearSalary } from "./SalaryBreakdownFields";
import {
  sendPatchRequest,
  sendPostRequest,
  sendPutRequest,
} from "../../config/swr";

export default function PayrollStaffDetailsModal({
  staff,
  staffRoles,
  nextApprovedPaymentDate,
  dismissModal,
}) {
  const hasPendingUpdate = !!staff?.latestInfo;
  const form = useForm(hasPendingUpdate ? staff.latestInfo : staff);

  const isNewInfo = hasPendingUpdate && staff.latestInfo.new;
  const isStatusChange =
    hasPendingUpdate && staff.isDisabled !== staff.latestInfo.isDisabled;
  const updatedInfo = staff?.updatedInfo ?? {};

  const modalTitle = isStatusChange
    ? "Review Staff Status Change"
    : isNewInfo
    ? "Review NEW Staff Info"
    : hasPendingUpdate
    ? "Review UPDATED Staff Info"
    : staff
    ? "Update Staff Info"
    : "Add New Staff Info";

  const selectedRoleID = form.data.role;
  const selectedRole =
    form.data.role && staffRoles.find((role) => role.id === selectedRoleID);
  const invalidRoleSelected = selectedRoleID && !selectedRole;

  // Enable tieSalaryToRole if a new role was selected. If there was no role
  // change and the current role selected is invalid, disable tieSalaryToRole.
  if (form.data.role !== form.data.lastSelectedRole) {
    form.setData({
      ...form.data,
      lastSelectedRole: form.data.role,
      tieSalaryToRole: true,
    });
  } else if (!selectedRole && form.data.tieSalaryToRole) {
    form.setData({ ...form.data, tieSalaryToRole: false });
  }

  const formValid = !!form.data.name && !!form.data.salaryAccount;

  const showMessageModal = useMessageModal();

  const [url, action] = !staff
    ? ["/payroll/staff", sendPostRequest]
    : ["/payroll/staff/" + staff.id, sendPutRequest];

  const { trigger } = useSWRMutation(url, action);

  const submitUpdate = async () => {
    if (!formValid) return;
    form.submitStarted();
    const data = { ...form.data };
    if (data.tieSalaryToRole) ClearSalary(data);

    try {
      const res = await trigger(staff ? { payload: data } : data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      dismissModal(true);
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  // Disable salary modification if the next payment date has been approved and
  // this is an existing staff update.
  const disableSalaryMod = nextApprovedPaymentDate && staff;

  return (
    <Modal rightSheet hideCancelButton handleClose={dismissModal}>
      <div className="modal-header-with-back-btn flex start center gap-8 mb-16">
        <BackButton onClick={dismissModal}>
          <h3 className="fs-20">{modalTitle}</h3>
        </BackButton>
      </div>

      <div className="fs-16" style={{ padding: "0 1rem 1rem" }}>
        <div>
          <label className="flex center space-between fs-18" htmlFor="name">
            Name
            {updatedInfo.name && (
              <small>Previously: "{updatedInfo.name}"</small>
            )}
          </label>
          <input
            id="name"
            type="text"
            className="mb-20"
            placeholder="Enter Staff Name"
            readOnly={hasPendingUpdate}
            {...form.fieldProps("name")}
          />

          <label className="flex center space-between fs-18" htmlFor="email">
            Email (optional)
            {updatedInfo.email && (
              <small>Previously: "{updatedInfo.email}"</small>
            )}
          </label>
          <input
            id="email"
            type="text"
            className="mb-20"
            placeholder="Enter email"
            readOnly={hasPendingUpdate}
            {...form.fieldProps("email")}
          />

          <label
            className="flex center space-between fs-18"
            htmlFor="phoneNumber"
          >
            Phone Number (optional)
            {updatedInfo.phoneNumber && (
              <small>Previously: "{updatedInfo.phoneNumber}"</small>
            )}
          </label>
          <input
            id="phoneNumber"
            type="text"
            className="mb-20"
            placeholder="Enter staff phone number"
            readOnly={hasPendingUpdate}
            {...form.fieldProps("phoneNumber")}
          />

          <label
            className="flex center space-between fs-18"
            htmlFor="salaryAccount"
          >
            Salary Account
            {updatedInfo.salaryAccount && (
              <small>Previously: "{updatedInfo.salaryAccount}"</small>
            )}
          </label>
          <input
            id="salaryAccount"
            type="text"
            className="mb-20"
            placeholder="Enter salary account number"
            readOnly={hasPendingUpdate}
            {...form.fieldProps("salaryAccount")}
          />

          <label className="flex center space-between fs-18" htmlFor="role">
            Role (optional)
            {updatedInfo.role && (
              <small>Previously: "{updatedInfo.role}"</small>
            )}
          </label>
          <select
            id="role"
            className="mb-20"
            style={invalidRoleSelected ? { borderColor: "red" } : {}}
            disabled={hasPendingUpdate}
            {...form.fieldProps("role")}
          >
            <option value="" disabled hidden>
              Select Staff Role
            </option>
            <option value="">-</option>
            {invalidRoleSelected && (
              <option value={form.data.role} disabled hidden>
                {form.data.role} (invalid)
              </option>
            )}
            {staffRoles.map((role, index) => (
              <option key={index} value={role.id}>
                {role.name}
              </option>
            ))}
          </select>

          <div className="flex center gap-8 fs-18 fw-600">
            <span>Salary Breakdown</span>
            {selectedRole && (
              <CheckBox
                text="Tie Salary to Role"
                color="#8ea1aa"
                disabled={hasPendingUpdate || disableSalaryMod}
                additionalStyles={{ fontSize: "0.8rem", marginBottom: "0" }}
                {...form.fieldProps("tieSalaryToRole", true)}
              />
            )}
          </div>
          <p className="tart-orange mb-8">
            {disableSalaryMod && (
              <i>
                Cannot modify salary information until after the next salary
                payments are made on {nextApprovedPaymentDate}.
              </i>
            )}
          </p>
          <div className="mb-8">
            <SalaryBreakdownFields
              form={form}
              oldValues={updatedInfo.salaryChanges}
              defaultValues={form.data.tieSalaryToRole && selectedRole}
              readOnly={
                hasPendingUpdate ||
                disableSalaryMod ||
                form.data.tieSalaryToRole
              }
              spaceBetweenFields="mb-12"
            />
          </div>

          <div className="flex center fs-18 fw-600 mb-8">Deductions</div>
          <div className="mb-32">
            <SalaryBreakdownFields
              forDeductions={true}
              form={form}
              oldValues={updatedInfo.salaryChanges}
              // defaultValues={form.data.tieSalaryToRole && selectedRole}
              readOnly={
                hasPendingUpdate || disableSalaryMod
                // form.data.tieSalaryToRole
              }
              spaceBetweenFields="mb-12"
            />
          </div>

          {isStatusChange && (
            <p className="color-orange mb-12 center-text">
              Approving this change will
              <b>{staff.latestInfo.isDisabled ? " DISABLE" : " ENABLE"}</b> this
              staff.
            </p>
          )}

          {hasPendingUpdate ? (
            <ReviewUpdateActionButtons
              staffID={staff.id}
              updatedInfo={staff.latestInfo}
              isNewInfo={isNewInfo}
              isStatusChange={isStatusChange}
              dismissModal={dismissModal}
            />
          ) : (
            <button
              className="fullwidth dark btn"
              disabled={!formValid || form.state.submitting}
              onClick={submitUpdate}
            >
              {form.state.submitting ? <FiLoader /> : "Save"}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

const ReviewUpdateActionButtons = ({
  staffID,
  updatedInfo,
  isNewInfo,
  isStatusChange,
  dismissModal,
}) => {
  const [review, setReview] = useState({
    reject: false,
    rejectReason: "",
    submitting: false,
  });

  const cancelRejectButton = useRef();
  useEffect(() => {
    cancelRejectButton.current?.scrollIntoView();
  });

  const showMessageModal = useMessageModal();

  const { trigger } = useSWRMutation(
    `/payroll/staff/${staffID}/review-pending-update`,
    sendPatchRequest
  );

  const submitReview = async () => {
    setReview({ ...review, submitting: true });
    const data = {
      update: updatedInfo,
      mandate: review.reject
        ? { reject: true, message: review.rejectReason }
        : { approve: true },
    };

    try {
      const res = await trigger({ payload: data });
      dismissModal(true);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      setReview({ ...review, submitting: false });
    }
  };

  const action = isNewInfo
    ? "New Staff Info"
    : isStatusChange
    ? "Status Change"
    : "Staff Info Update";

  return !review.reject ? (
    <div className="flex gap-8 space-between">
      <OutlineButton
        disabled={review.submitting}
        onClick={() => setReview({ reject: true, rejectReason: "" })}
        className="danger"
      >
        Reject {action}
      </OutlineButton>

      <DelayedActionButton
        className="btn outline"
        text={`Approve ${action}`}
        confirmText="Tap again to confirm"
        busyText={`Approving ${action}...`}
        isBusy={review.submitting}
        clickAction={submitReview}
      />
    </div>
  ) : (
    <>
      <label className="fs-18 center-text" htmlFor="rejectReason">
        Reason for rejecting
      </label>
      <input
        id="rejectReason"
        type="text"
        className="mb-20"
        placeholder="What's wrong with the proposed update?"
        value={review.rejectReason}
        onChange={(e) => setReview({ ...review, rejectReason: e.target.value })}
      />
      <section className="flex col">
        <button
          className="light danger btn"
          disabled={!review.rejectReason || review.submitting}
          onClick={submitReview}
        >
          {review.submitting ? <FiLoader /> : "Reject"}
        </button>
        <button
          className="transparent btn"
          ref={cancelRejectButton}
          onClick={() => setReview({})}
        >
          Cancel
        </button>
      </section>
    </>
  );
};
