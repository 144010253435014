import useSWRMutation from "swr/mutation";
import { useNavigate, useOutletContext } from "react-router-dom";

import Modal from "../../../components/Modal";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";
import { sendGetRequest } from "../../../config/swr";
import { createCSV } from "../../../utils/helpers";

const AccountsCSVPage = () => {
  const navigate = useNavigate();
  const [setPageTitle] = useOutletContext();
  setPageTitle("Export Accounts");

  const form = useForm({
    startDate: "",
    endDate: "",
  });
  const { trigger } = useSWRMutation(`/accounts/export`, sendGetRequest);
  const showMessageModal = useMessageModal();

  const { startDate, endDate } = form.data;

  const closeCsvModal = () => navigate(-1);

  const exportAccts = async () => {
    form.submitStarted();

    const str = startDate || endDate ? `?from=${startDate}&to=${endDate}` : "";

    try {
      const res = await trigger(str);
      createCSV(res.data, `accounts-export${str}`);
      form.submitSuccess();
      navigate(-1);
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred while exporting csv. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div>
      <Modal
        handleClose={closeCsvModal}
        className="modal-heading-subheading-buttons"
      >
        <h1 className="fs-24">Export Accounts</h1>
        <p className="mb-32">Select Start and End Date</p>
        <div className="flex gap between-center equal-widths mb-32">
          <div>
            <label htmlFor="startDate">Start Date</label>
            <input
              id="startDate"
              className="mini fs-14"
              type="date"
              max={new Date().toISOString().split("T")[0]}
              {...form.fieldProps("startDate")}
            />
          </div>
          <div>
            <label htmlFor="endDate">End Date</label>
            <input
              id="endDate"
              className="mini fs-14"
              type="date"
              min={startDate && new Date(startDate).toISOString().split("T")[0]}
              max={new Date().toISOString().split("T")[0]}
              {...form.fieldProps("endDate")}
            />
          </div>
        </div>
        <div className="actions">
          <button
            className="btn transparent outline fs-22"
            onClick={closeCsvModal}
          >
            Cancel
          </button>
          <button onClick={exportAccts} className="btn primary fs-22">
            Export
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AccountsCSVPage;
