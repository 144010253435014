export default function MobileAdsIcon() {
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width="56"
        height="56"
        rx="16"
        fill="#CAD7D0"
        fillOpacity="0.4"
      />
      <path
        d="M36.3277 29.24L35.5077 28.61C35.5077 28.52 35.5077 28.48 35.5077 28.39L36.3077 27.76C36.4677 27.64 36.5077 27.42 36.4077 27.25L35.5577 25.77C35.4877 25.64 35.3477 25.57 35.2077 25.57C35.1577 25.57 35.1077 25.58 35.0577 25.6L34.1077 25.98C34.0277 25.93 33.9977 25.91 33.9177 25.87L33.7677 24.86C33.7377 24.65 33.5677 24.5 33.3677 24.5H31.6577C31.4577 24.5 31.2877 24.65 31.2577 24.84L31.1177 25.85C31.0877 25.87 31.0477 25.88 31.0177 25.9C30.9877 25.92 30.9577 25.94 30.9277 25.96L29.9777 25.58C29.9277 25.56 29.8777 25.55 29.8277 25.55C29.6877 25.55 29.5577 25.62 29.4777 25.75L28.6277 27.23C28.5277 27.4 28.5677 27.62 28.7277 27.74L29.5277 28.37C29.5277 28.46 29.5277 28.5 29.5277 28.6L28.7277 29.23C28.5677 29.35 28.5277 29.57 28.6277 29.74L29.4777 31.22C29.5477 31.35 29.6877 31.42 29.8277 31.42C29.8777 31.42 29.9277 31.41 29.9777 31.39L30.9277 31.02C31.0077 31.07 31.0477 31.09 31.1277 31.13L31.2777 32.14C31.3077 32.34 31.4777 32.48 31.6777 32.48H33.3877C33.5877 32.48 33.7577 32.33 33.7877 32.14L33.9377 31.13C33.9677 31.11 34.0077 31.1 34.0377 31.08C34.0677 31.06 34.0977 31.04 34.1277 31.02L35.0777 31.4C35.1277 31.42 35.1777 31.43 35.2277 31.43C35.3677 31.43 35.4977 31.36 35.5777 31.23L36.4277 29.75C36.5277 29.58 36.4877 29.36 36.3277 29.24ZM32.5177 30C31.6877 30 31.0177 29.33 31.0177 28.5C31.0177 27.67 31.6877 27 32.5177 27C33.3477 27 34.0177 27.67 34.0177 28.5C34.0177 29.33 33.3477 30 32.5177 30ZM31.5177 34.5H21.5177V22.5H31.5177V23.5H33.5177V19.5C33.5177 18.4 32.6177 17.5 31.5177 17.5H21.5177C20.4177 17.5 19.5177 18.4 19.5177 19.5V37.5C19.5177 38.6 20.4177 39.5 21.5177 39.5H31.5177C32.6177 39.5 33.5177 38.6 33.5177 37.5V33.5H31.5177V34.5ZM21.5177 19.5H31.5177V20.5H21.5177V19.5ZM31.5177 37.5H21.5177V36.5H31.5177V37.5Z"
        fill="#1F613E"
      />
    </svg>
  );
}
