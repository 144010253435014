import dayjs from "dayjs";

import HeaderNavs from "../../../components/page-wrapper/HeaderNavs";
import ArrowLeftIcon from "../../../assets/icons/arrowleft";

const headers = ["Date", "Description", "Balance", "Amount", "Type"];

const StatementTransactions = ({ handleClose, loanStatement }) => {
  return (
    <>
      <div className="statement-analysis">
        <header className="box-shadow flex between-center">
          <div className="flex gap-12 center start">
            <span onClick={handleClose} style={{ cursor: "pointer" }}>
              <ArrowLeftIcon width={14} style={{ verticalAlign: "middle" }} />
            </span>
            <h3 className="fs-20">Statement Transactions</h3>
          </div>
          <div className="flex gap">
            <HeaderNavs />
          </div>
        </header>

        <div className="statement-analysis__content flex col gap">
          {!loanStatement.data?.transactions ||
          loanStatement.data?.transactions?.length === 0 ? (
            <h3 className="center-text">No transactions</h3>
          ) : (
            <table className="fs-16 box statement">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {loanStatement.data?.transactions.map((transaction, index) => (
                  <tr key={index} className="clickable fw-600">
                    <td>
                      {dayjs(transaction.date).utc().format("DD/MM/YYYY")}
                    </td>
                    <td>{transaction.description}</td>
                    <td>{transaction.balance}</td>
                    <td>{transaction.amount}</td>
                    <td>{transaction.type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default StatementTransactions;
