import { useState } from "react";

import DirectDeposit from "./DirectDeposit";
import InstantDebit from "./InstantDebit";

const LoanFilterOverdue = "Overdue";

function LoanRepaymentForm({
  cancel,
  loan,
  activeFilter,
  mutate,
  dismissModal,
  canAddRepayment,
}) {
  const [view, setview] = useState(1);

  const amountDue =
    loan.accruedInterest + loan.loanAmount - loan.totalRepayment;

  return (
    <div className="loan-repayment">
      <div className="tab-nav fs-16 mini gap-12 mb-12">
        <button
          className={`btn box${view === 1 ? " active" : ""}`}
          onClick={() => setview(1)}
        >
          Direct Deposit
        </button>
        {activeFilter === LoanFilterOverdue && (
          <button
            className={`btn box${view === 2 ? " active" : ""}`}
            onClick={() => setview(2)}
          >
            Instant Deposit
          </button>
        )}
      </div>

      <p className="fs-16">Loan Amount Due</p>
      <p className="fs-16 mb-12">
        &#8358;
        {amountDue?.toLocaleString()}
      </p>

      {view === 1 && (
        <DirectDeposit
          cancel={cancel}
          loan={loan}
          mutate={mutate}
          amountDue={amountDue}
          dismissModal={dismissModal}
          canAddRepayment={canAddRepayment}
        />
      )}

      {view === 2 && (
        <InstantDebit
          cancel={cancel}
          loan={loan}
          mutate={mutate}
          amountDue={amountDue}
          dismissModal={dismissModal}
          canAddRepayment={canAddRepayment}
        />
      )}
    </div>
  );
}

export default LoanRepaymentForm;
