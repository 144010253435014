import { BiCaretDown } from "react-icons/bi";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";


import { PrimaryButton, OutlineButton } from "../../../components/Button";
import { InputField } from "../../../components/Input";
import { getPermissions, sendPostRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useToggle from "../../../hooks/useToggle.hook";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const SEND_MESSAGE_KEY = "send-bulk-message";

export default function ResendBulkMessage({
  id,
  title,
  status,
  customers,
  msgTypes,
  message,
  batchStats,
  timestamp,
  lastUpdated,
  mutate,
  setInited,
  cancel,
}) {
  const form = useForm({ message });
  const [dropdownOpened, _toggleDropdown] = useToggle(false);
  const permissions = getPermissions();

  const canSendMessage = permissions[SEND_MESSAGE_KEY];

  const { trigger } = useSWRMutation("/bulk-message", sendPostRequest);

  const toggleDropdown = (e) => {
    e.preventDefault();
    _toggleDropdown();
  };

  const messageInfo = [
    {
      title: "Status",
      value: <span>{status}</span>,
    },
    {
      title: "Date",
      value: <span>{dayjs(timestamp * 1000).utc().format("D MMM YYYY")}</span>,
    },
    {
      title: "Last Update",
      value: <span>{dayjs(lastUpdated * 1000).utc().format("D MMM YYYY")}</span>,
    },
    {
      title: "Customers",
      value: <span style={{ wordBreak: "break-all" }}>{customers}</span>,
    },
    {
      title: "Message Type",
      value: <span>{msgTypes.join(", ")}</span>,
    },
  ];

  const submitForm = async () => {
    if (!canSendMessage) return;
    form.submitStarted();

    const data = new FormData();
    data.append("messageID", id);

    try {
      const res = await trigger(data);
      form.submitSuccess(res.data?.message || "Successfully submitted");
      cancel();
      mutate();
      setInited(false);
    } catch (error) {
      form.submitError(
        error?.response?.data?.errorMessage ||
          "An error occurred. Please try again"
      );
    }
  };

  return (
    <div className="chat__sendbulk">
      <div className="chat__sendbulk-receipient-inputs">
        <p>{title}</p>

        <InputField
          multiline
          rows="4"
          label="Your Message"
          placeholder="Hello, {{first name}}"
          {...form.fieldProps("message")}
          readOnly
        />

        <div className="box mb-12">
          <div className="flex gap between-center" onClick={toggleDropdown}>
            <span>{dropdownOpened ? "Hide" : "View"} Send Details</span>
            <BiCaretDown
              size={18}
              className={
                dropdownOpened ? "dropdown-icon rotate-180" : "dropdown-icon"
              }
            />
          </div>
          <div
            className={
              dropdownOpened ? "message__dropdown opened" : "message__dropdown"
            }
          >
            {messageInfo.map((info, index) => (
              <div key={index} className="flex gap equal-widths">
                <span className="charcoal-60 fs-16">{info.title}</span>
                <h3 className="fs-16">{info.value}</h3>
              </div>
            ))}

            {batchStats && (
              <>
                <p>Send History</p>
                {Object.entries(batchStats).map(([batchId, batch]) => (
                  <BatchDisplay
                    {...batch}
                    id={id}
                    batchId={batchId}
                    key={batchId}
                    canSendMessage={canSendMessage}
                  />
                ))}
              </>
            )}
          </div>
        </div>

        <div className="chat__sendbulk-receipient-buttons">
          <OutlineButton big onClick={cancel} disabled={form.state.submitting}>
            Cancel
          </OutlineButton>
          {canSendMessage && (
            <PrimaryButton
              big
              onClick={submitForm}
              disabled={form.state.submitting}
            >
              {form.state.submitting ? <FiLoader /> : "Resend"}
            </PrimaryButton>
          )}
        </div>
      </div>

      {form.state.error && (
        <div className="form-error">Error: {form.state.error}</div>
      )}
    </div>
  );
}

const BatchDisplay = ({
  id,
  batchId,
  status,
  totalRecipients,
  totalSent,
  timestamp,
  lastUpdated,
  canSendMessage,
}) => {
  const [dropdownOpened, _toggleDropdown] = useToggle(false);
  const toggleDropdown = (e) => {
    e.preventDefault();
    _toggleDropdown();
  };
  const form = useForm();

  const { trigger } = useSWRMutation("/bulk-message", sendPostRequest);

  const leftInfoList = [
    {
      title: "Status",
      value: <span>{status}</span>,
    },
    {
      title: "Date",
      value: <span>{dayjs(timestamp * 1000).utc().format("D MMM YYYY")}</span>,
    },
    {
      title: "Last Update",
      value: <span>{dayjs(lastUpdated * 1000).utc().format("D MMM YYYY")}</span>,
    },
  ];

  const rightInfoList = [
    {
      title: "Total Receipts",
      value: <span>{totalRecipients}</span>,
    },
    {
      title: "Total Sent",
      value: <span>{totalSent}</span>,
    },
  ];

  const handleResend = async () => {
    if (!canSendMessage) return;
    form.submitStarted();

    const data = new FormData();
    data.append("messageID", id);
    data.append("batchID", batchId);
    data.append("retryFailedBatch", true);

    try {
      const res = await trigger(data);
      form.submitSuccess(res.data?.message || "Successfully submitted");
    } catch (error) {
      form.submitError(
        error?.response?.data?.errorMessage ||
          "An error occurred. Please try again"
      );
    }
  };

  return (
    <div className="box mb-12">
      <div className="flex gap between-center" onClick={toggleDropdown}>
        <span>{batchId}</span>
        <BiCaretDown
          size={18}
          className={
            dropdownOpened ? "dropdown-icon rotate-180" : "dropdown-icon"
          }
        />
      </div>
      <div
        className={
          dropdownOpened ? "batch__dropdown opened" : "batch__dropdown"
        }
      >
        <div className="flex gap equal-widths">
          <div>
            {leftInfoList.map((info, index) => (
              <div key={index} className="flex gap equal-widths">
                <span className="charcoal-60 fs-16">{info.title}</span>
                <h3 className="fs-16">{info.value}</h3>
              </div>
            ))}
          </div>
          <div>
            {rightInfoList.map((info, index) => (
              <div key={index} className="flex gap equal-widths">
                <span className="charcoal-60 fs-16">{info.title}</span>
                <h3 className="fs-16">{info.value}</h3>
              </div>
            ))}
            {canSendMessage ? (
              <button
                className="btn mini primary"
                onClick={() => handleResend()}
                disabled={form.state.submitting}
              >
                {form.state.submitting ? <FiLoader /> : "Resend this batch"}
              </button>
            ) : (
              <button className="btn mini"></button>
            )}
          </div>
        </div>
        <>
          {form.state.error && (
            <div className="form-error">Error: {form.state.error}</div>
          )}

          {form.state.successMsg && (
            <div className="form-success fs-16">{form.state.successMsg}</div>
          )}
        </>
      </div>
    </div>
  );
};
