import { useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import AccountHolderBox from "../../components/AccountHolderBox";
import Modal from "../../components/Modal";
import SendMessage from "../../components/SendMessage";

dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

export default function CollectionDetailsModal({ collection, dismissModal }) {
  const [sendMessageModal, setSendMessageModal] = useState(false);

  const amountElement = (amount) => (
    <>&#8358; {Number(amount)?.toLocaleString()}</>
  );

  const hideMessageModal = () => {
    setSendMessageModal(false);
  };

  const collectionEndDate = (withdrawal, created, days) =>
    withdrawal && withdrawal > 0
      ? dayjs(withdrawal * 1000).utc().format("Do MMM YYYY")
      : dayjs(created * 1000).utc()
          .add(days, "day")
          .format("Do MMM YYYY");

  const details = [
    { title: "Collection Type", value: collection.type },
    {
      title: "Target Amount",
      value: amountElement(collection.targetAmount?.toFixed(2)),
    },
    {
      title: "Total Collected",
      value: amountElement(collection.totalAmount?.toFixed(2)),
    },
    {
      title: "Duration",
      value: `${collection.duration?.toLocaleString()} Days`,
    },
    {
      title: "Start Date",
      value: dayjs(collection.createdTimestamp * 1000).utc().format("Do MMM YYYY"),
    },
    {
      title: "End Date",
      value: collectionEndDate(
        collection.withdrawalTimestamp,
        collection.createdTimestamp,
        collection.duration
      ),
    },
    {
      title: "Interest Yield",
      value: amountElement(collection.interestYield?.toFixed(2)),
    },
    {
      title: "Net Yield",
      value: amountElement(
        (collection.totalAmount + collection.interestYield)?.toFixed(2)
      ),
    },
  ];

  if (sendMessageModal)
    return (
      <Modal
        rightSheet
        hideCancelButton
        className="account-holder-with-info-modal"
        handleClose={hideMessageModal}
      >
        <SendMessage
          accountName={collection.accountName}
          accountNumber={collection.accountNumber}
          customerID={collection.customerID}
          profileImageUrl={collection.customerProfileImageUrl}
          handleClose={hideMessageModal}
        />
      </Modal>
    );

  return (
    <Modal
      rightSheet
      className="account-holder-with-info-modal"
      handleClose={dismissModal}
    >
      <AccountHolderBox
        accountName={collection.accountName}
        accountNumber={collection.title}
        profileImageUrl={collection.customerProfileImageUrl}
        setSendMessageModal={setSendMessageModal}
        infoList={details}
        key={collection.id}
      />
    </Modal>
  );
}
