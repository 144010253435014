import { useState } from "react";
import { FiLoader } from "react-icons/fi";

import useForm from "../../hooks/useForm.hook";
import useMessageModal from "../../hooks/useMessageModal.hook";

export default function DeclineRequestForm({
  requestType,
  declineRequest,
  done,
}) {
  const [reason, setReason] = useState("");
  const form = useForm();
  const showMessageModal = useMessageModal();

  const requestTypeSm = requestType.toLowerCase();

  const onDeclineRequestClick = async () => {
    form.submitStarted();
    const { error, customerName } = await declineRequest(reason);
    if (error) {
      form.submitError(error);
    } else {
      done(true);
      showMessageModal({
        title: `${requestType} Request Declined`,
        message: `You have successully declined ${customerName}'s ${requestTypeSm} request.`,
        closeButtonText: "Close",
      });
    }
  };

  return (
    <form>
      <h1 className="fs-24">Decline {requestType} Request</h1>
      <p className="mb-28">
        Are you sure you want to decline this {requestTypeSm} request?
      </p>

      <label>Reason for declining {requestTypeSm} request</label>
      <input
        className="mb-32"
        type="text"
        placeholder={`Enter reason for declining ${requestTypeSm} request`}
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />

      <div className="actions flex equal-widths">
        <button
          className="btn primary fs-22"
          disabled={!reason || form.state.submitting}
          onClick={onDeclineRequestClick}
        >
          {form.state.submitting ? <FiLoader /> : "Confirm"}
        </button>

        <button
          className="btn transparent outline fs-22"
          type="button"
          disabled={form.state.submitting}
          onClick={done}
        >
          Cancel
        </button>
      </div>

      {form.state.error && (
        <div className="form-error">Error: {form.state.error}</div>
      )}
    </form>
  );
}
