export default function CardRequestsIcon() {
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width="56"
        height="56"
        rx="16"
        fill="#549DC6"
        fillOpacity="0.08"
      />
      <path
        d="M37.75 20.25H18.25C17.8352 20.25 17.5 20.5852 17.5 21V24.75H38.5V21C38.5 20.5852 38.1648 20.25 37.75 20.25ZM17.5 36C17.5 36.4148 17.8352 36.75 18.25 36.75H37.75C38.1648 36.75 38.5 36.4148 38.5 36V26.8125H17.5V36ZM31.0703 31.6875C31.0703 31.5844 31.1547 31.5 31.2578 31.5H35.125C35.2281 31.5 35.3125 31.5844 35.3125 31.6875V33.375C35.3125 33.4781 35.2281 33.5625 35.125 33.5625H31.2578C31.1547 33.5625 31.0703 33.4781 31.0703 33.375V31.6875Z"
        fill="#549DC6"
      />
    </svg>
  );
}
