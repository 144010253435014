import React, { useState } from "react";
import { OutsideClickAlerter } from "./OutsideClickAlerter";

function Dropdown({
  options,
  selectedOption,
  onOptionSelected,
  allowReselection,
  prompt,
  children,
  alignEnd,
  ...props
}) {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleOptionClick = (option) => {
    setDropdownVisible(false);
    if (option === selectedOption && !allowReselection) {
      return;
    }
    if (onOptionSelected) {
      onOptionSelected(option);
    }
  };

  return (
    <OutsideClickAlerter
      style={{ position: "relative" }}
      handleOnClickOutside={() => setDropdownVisible(false)}
    >
      <button onClick={() => setDropdownVisible(true)} {...props}>
        {children}
      </button>

      {dropdownVisible ? (
        <div
          className={`in-place-popup${alignEnd ? " alignEnd" : ""} box-shadow`}
        >
          {prompt}
          {options.map((option, index) => (
            <p
              key={index}
              className={`clickable${
                option === selectedOption ? " color-primary" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </p>
          ))}
        </div>
      ) : null}
    </OutsideClickAlerter>
  );
}

export default Dropdown;
