const PlusBox = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 11H15V14H13V11H10V9H13V6H15V9H18M20 4V16H8V4H20ZM20 2H8C6.9 2 6 2.9 6 4V16C6 16.5304 6.21071 17.0391 6.58579 17.4142C6.96086 17.7893 7.46957 18 8 18H20C21.11 18 22 17.11 22 16V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2ZM4 6H2V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18V20H4V6Z"
        fill={color}
      />
    </svg>
  );
};

export default PlusBox;
