import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import { BackButton, PrimaryButton } from "../../components/Button";
import CheckBox from "../../components/Checkbox";

import {
  sendGetRequest,
  sendPostRequest,
  getPermissions,
} from "../../config/swr";
import useForm from "../../hooks/useForm.hook";
import useMessageModal from "../../hooks/useMessageModal.hook";

const FIX_PAYMENT_KEY = "fix-payment-issues";

export function TxInfoForm({ onSuccess, setFetchingData }) {
  const { trigger } = useSWRMutation(`/payment/details?`, sendGetRequest);

  const form = useForm({
    accountNumber: "",
    txReference: "",
    isCardTx: false,
  });

  const showMessageModal = useMessageModal();

  const submitTxInfoForm = async (e) => {
    e.preventDefault();
    const { accountNumber, txReference, isCardTx } = form.data;
    setFetchingData(true);
    form.submitStarted();

    try {
      const res = await trigger(
        `accountNumber=${accountNumber}&txRef=${txReference}&isCardPayment=${isCardTx}`
      );
      form.submitSuccess();
      onSuccess({ ...res.data, ...form.data });
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
    setFetchingData(false);
  };

  return (
    <>
      <b className="fs-24 mb-20">Enter The Following Information</b>
      <form onSubmit={submitTxInfoForm}>
        <div className="mb-12">
          <label>Account Number</label>
          <input
            placeholder="002xxxxxxxxxxxx"
            {...form.fieldProps("accountNumber")}
          />
        </div>

        <div className="mb-12">
          <label>Reference Number</label>
          <input
            placeholder="TR02xxxxxxxxxxxxxxxxxxx"
            {...form.fieldProps("txReference")}
          />
        </div>

        <div className="mb-32">
          <CheckBox
            text="Card"
            color="#3d5059"
            {...form.fieldProps("isCardTx", true)}
          />
        </div>

        <PrimaryButton
          className="fullwidth"
          disabled={
            !form.data.accountNumber ||
            !form.data.txReference ||
            form.state.submitting
          }
        >
          {form.state.submitting ? <FiLoader /> : "Search"}
        </PrimaryButton>
      </form>
    </>
  );
}

export function TxResolutionForm({ onClose, data }) {
  const {
    accountNumber,
    txReference,
    transactionInfo: { amount },
    fixableIssues,
    customerInfo,
    isCardTx,
  } = data;
  const { trigger } = useSWRMutation("/payment/fix", sendPostRequest);
  const permissions = getPermissions();

  const canFixPayments = permissions[FIX_PAYMENT_KEY];
  const showMessageModal = useMessageModal();

  const form = useForm({
    selectedIssueIndex: -1,
    selectedIssueItemIndex: -1,
  });

  const { selectedIssueIndex, selectedIssueItemIndex } = form.data;
  const selectedIssue =
    selectedIssueIndex < 0 ? null : fixableIssues[selectedIssueIndex];

  const itemSelected =
    selectedIssue &&
    selectedIssueItemIndex >= 0 &&
    selectedIssueItemIndex < selectedIssue.items.length;

  const submitTxResolutionForm = async (e) => {
    e.preventDefault();
    if (!canFixPayments || !itemSelected) return;
    const selectedIssueItem = selectedIssue.items[selectedIssueItemIndex];

    form.submitStarted();

    const data = {
      txID: selectedIssueItem.id,
      txType: selectedIssue.type,
      transactionRef: txReference,
      customerID: customerInfo.customerID,
      cardPayment: isCardTx,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res?.data?.message || "Successfully resolved",
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
      onClose();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <>
      <BackButton onClick={onClose} className="mb-16" />

      <div className="gray-box flex col gap-8 mb-16">
        <div>
          <p>Account Number</p>
          <b>{accountNumber}</b>
        </div>
        <div>
          <p>Reference Number</p>
          <b>{txReference}</b>
        </div>
      </div>

      <b className="fs-24 mb-8">Transaction Details</b>

      <form onSubmit={submitTxResolutionForm}>
        <div className="mb-12">
          <label>Transaction Type</label>
          <select {...form.fieldProps("selectedIssueIndex")}>
            <option value="-1" disabled>
              Select Transaction Type
            </option>
            {fixableIssues.map((issue, index) => (
              <option key={index} value={index}>
                {issue.type}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-12">
          <label>Transaction in Dispute</label>
          <select {...form.fieldProps("selectedIssueItemIndex")}>
            <option value="-1" disabled>
              Select Transaction
            </option>
            {selectedIssue &&
              selectedIssue.items.map((issue, index) => (
                <option key={index} value={index}>
                  {issue.name}
                </option>
              ))}
          </select>
        </div>

        <div className="mb-32">
          <label>Transaction Amount</label>
          <input readOnly value={`N${amount?.toLocaleString()}`} />
        </div>
        
        {canFixPayments && (
          <PrimaryButton
            className="fullwidth"
            disabled={!itemSelected || form.state.submitting}
          >
            {form.state.submitting ? <FiLoader /> : "Resolve"}
          </PrimaryButton>
        )}
      </form>
    </>
  );
}
