import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="chart__tooltip flex gap-8 col">
        <p>{`${label}`}</p>
        <p>Amount - &#8358;{`${payload[0].value?.toLocaleString()}`}</p>
      </div>
    );
  }

  return null;
};

const renderLegend = () => {
  return <p className="flex center mt-12">Quarterly Cash Flow</p>;
};

const DataFormater = (number) => {
  let str = "\u20A6";
  return `${str}${number?.toLocaleString()}`;
};

const BarChartComponent = ({ chartData }) => {
  if (chartData?.length === 0) return null;

  return (
    <ResponsiveContainer width="100%" height={400} minWidth={200}>
      <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 30,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={DataFormater} />
        <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
        <Legend content={renderLegend} />
        <Bar dataKey="amount" fill="#1382BB" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
