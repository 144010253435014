const ThreeDots = ({...props}) => {
  return (
    <svg
      width="28"
      height="9"
      viewBox="0 0 28 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="4" cy="4.5" r="4" fill="#627178" />
      <circle cx="14" cy="4.5" r="4" fill="#627178" />
      <circle cx="24" cy="4.5" r="4" fill="#627178" />
    </svg>
  );
};

export default ThreeDots;
