import CheckboxIcon from "../assets/icons/checkbox";

const Radio = ({ text, name, value, checked, onChange, color }) => {
  return (
    <label
      className="checkbox clickable"
      htmlFor={value}
      style={color ? { color: color } : {}}
    >
      <input
        type="radio"
        name={name}
        id={value}
        value={value}
        onChange={onChange}
        style={{ visibility: "hidden", display: "none" }}
      />
      <div
        className="checkbox__box flex center"
        style={color ? { borderColor: color } : {}}
      >
        {checked ? <CheckboxIcon color={color} /> : null}
      </div>
      {text}
    </label>
  );
};

export default Radio;
