import { Link, useOutletContext } from "react-router-dom";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import DataFetcher from "../../components/DataFetcher";
import BarChartComponent from "./BarChart";
import PieChartComponent from "./PieChart";
import AccountUpgradeIcon from "./icons/accountupgradeicon";
import AddressUpdateIcon from "./icons/addressupdateicon";
import CardRequestsIcon from "./icons/cardrequestsicon";
import MobileAdsIcon from "./icons/mobileadsicon";
import CustomerIcon from "./icons/customer";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const OverviewPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Overview");

  return (
    <DataFetcher
      url={`/summary`}
      buildUI={(data) => <OverviewPageDisplay data={data} />}
    />
  );
};

const OverviewPageDisplay = ({ data }) => {
  const {
    totalAds,
    maxAds,
    totalCustomers,
    totalPendingCardRequests,
    totalPendingAccountUpgradeRequests,
    totalPendingAddressUpgradeRequests,
    totalLoans,
    totalLoanAmount,
    totalPaydayLoanAmount,
    totalAssetLoanAmount,
    totalRetailLoanAmount,
    loanQuarterlyCashFlow,
    targetCollections,
  } = data;

  const { active, withdrawn } = targetCollections;

  const collectionsColor = ["#F9B492", "#F57E43"];

  const collectionsChartData = Object.entries(targetCollections).map(
    ([name, { totalAmount }], index) => ({
      name,
      value: totalAmount,
      color: collectionsColor[index],
    })
  );

  const loansChartData = [
    {
      name: "Payday Loans",
      value: totalPaydayLoanAmount,
      color: "#671C73",
    },
    {
      name: "Asset Loans",
      value: totalAssetLoanAmount,
      color: "#D39BDB",
    },
    {
      name: "Retail Loans",
      value: totalRetailLoanAmount,
      color: "#F8D1FF",
    },
  ];

  const barChartData = loanQuarterlyCashFlow.map(
    ({ endTimestamp, totalLoanAmount }) => ({
      name: dayjs(endTimestamp * 1000)
        .utc()
        .format("MMM YYYY"),
      amount: totalLoanAmount,
    })
  );

  const barChartTitle = (arr) => {
    const firstElem = arr[0];
    const lastElem = arr[arr.length - 1];
    return `Chart for ${dayjs(firstElem.endTimestamp * 1000)
      .utc()
      .format("MMM YYYY")} - ${dayjs(lastElem.endTimestamp * 1000)
      .utc()
      .format("MMM YYYY")}`;
  };

  return (
    <>
      <div className="overview-summaries">
        <Link className="box" to={`/mobile-ads`}>
          <div>
            <h3 className="charcoal-60 fs-16">Mobile Ads</h3>
            <h1 className="fs-20">{`${totalAds}/${maxAds}`}</h1>
          </div>
          <MobileAdsIcon />
        </Link>
        <Link className="box" to={`/customers/cards`}>
          <div>
            <h3 className="charcoal-60 fs-16">Pending ATM Card Requests</h3>
            <h1 className="fs-20">
              {totalPendingCardRequests?.toLocaleString()}
            </h1>
          </div>
          <CardRequestsIcon />
        </Link>
        <Link className="box" to={`/customers/account-upgrade`}>
          <div>
            <h3 className="charcoal-60 fs-16">
              Pending Account Upgrade Request
            </h3>
            <h1 className="fs-20">
              {totalPendingAccountUpgradeRequests?.toLocaleString()}
            </h1>
          </div>
          <AccountUpgradeIcon />
        </Link>
        <Link className="box" to={`/customers/address-update`}>
          <div>
            <h3 className="charcoal-60 fs-16">
              Pending Address Update Request
            </h3>
            <h1 className="fs-20">
              {totalPendingAddressUpgradeRequests?.toLocaleString()}
            </h1>
          </div>
          <AddressUpdateIcon />
        </Link>
        <div className="box">
          <div>
            <h3 className="charcoal-60 fs-16">Total Mobile Users</h3>
            <h1 className="fs-20">{totalCustomers?.toLocaleString()}</h1>
          </div>
          <CustomerIcon />
        </div>
      </div>

      <div className="overview-charts">
        <div className="box">
          <div className="header">
            <h1 className="fs-20">Loan Bar Chart</h1>
            <h3 className="fs-14 charcoal-60">
              {loanQuarterlyCashFlow?.length > 0 &&
                barChartTitle(loanQuarterlyCashFlow)}
            </h3>
          </div>
          <BarChartComponent chartData={barChartData} />
        </div>

        <div>
          <div className="box">
            <div className="header with-amount">
              <div>
                <h3 className="fs-20">Loans</h3>
                <h3 className="fs-16 charcoal-60">
                  Total: {totalLoans?.toLocaleString()}
                </h3>
              </div>
              <div>
                <p>&#8358;{totalLoanAmount?.toLocaleString()}</p>
                <Link to={"/loans/payday-loans"}>View all</Link>
              </div>
            </div>
            <div className="flex gap-20 center equal-widths">
              <div>
                <PieChartComponent chartData={loansChartData} />
              </div>
              <div className="flex col gap-8">
                {loansChartData.map(({ name, value, color }) => (
                  <div className="flex gap-8 start center" key={name}>
                    <div
                      className="piedata-rounded"
                      style={{ backgroundColor: color }}
                    ></div>
                    <div>
                      <p className="fs-18 fw-600">{name}</p>
                      <p className="piedata-value">
                        &#8358;{value?.toLocaleString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="box">
            <div className="header with-amount">
              <div>
                <h3 className="fs-20">Collections</h3>
                <h3 className="fs-16 charcoal-60">
                  Total: {(active?.count + withdrawn?.count)?.toLocaleString()}
                </h3>
              </div>
              <div>
                <p>
                  &#8358;
                  {(
                    active?.totalAmount + withdrawn?.totalAmount
                  )?.toLocaleString()}
                </p>
                <Link to={"/collections"}>View all</Link>
              </div>
            </div>
            <div className="flex gap-20 center equal-widths">
              <div>
                <PieChartComponent chartData={collectionsChartData} />
              </div>
              <div className="flex col gap-8">
                {collectionsChartData.map(({ name, value, color }) => (
                  <div className="flex gap-8 start center" key={name}>
                    <div
                      className="piedata-rounded"
                      style={{ backgroundColor: color }}
                    ></div>
                    <div>
                      <p className="fs-18 fw-600 capitalize">{name}</p>
                      <p className="piedata-value">
                        &#8358;{value?.toLocaleString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewPage;
