import React from "react";

const ResetPasswordForm = ({ onCanceled }) => {
  return (
    <div className="login-form">
      <h1 className="fs-24">Password Reset</h1>

      <div className="login-form-field mb-32">
        <label htmlFor="code">Reset Code</label>
        <input type="password" name="code" placeholder="••••••••" />
      </div>

      <button className="btn primary fullwidth">Reset Password</button>

      <button className="btn transparent fullwidth fs-16" onClick={onCanceled}>
        Cancel
      </button>
    </div>
  );
};

export default ResetPasswordForm;
