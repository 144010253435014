import useForm from "../../../hooks/useForm.hook";

export default function AccountTierForm({ tierData, onSubmit }) {
  const form = useForm(tierData);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!form.valid()) return;

    const tierData = {
      ...form.data,
      withdrawalLimit: +form.data.withdrawalLimit,
    };
    onSubmit(tierData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="displayName">Display Name</label>
      <input
        id="displayName"
        type="text"
        className="mb-20"
        placeholder="Enter Display Name"
        {...form.fieldProps("displayName")}
      />
      
      <label htmlFor="tier">Account Tier</label>
      <input
        id="tier"
        type="text"
        className="mb-20"
        placeholder="Enter Account Tier"
        {...form.fieldProps("tier")}
      />

      <label htmlFor="limit">Daily Withdrawal Limit</label>
      <input
        id="limit"
        type="number"
        className="mb-20"
        placeholder="Enter Amount"
        {...form.fieldProps("withdrawalLimit")}
      />

      <button
        className="fullwidth btn primary light"
        disabled={!form.data.tier || !form.data.withdrawalLimit}
      >
        {tierData ? "Update" : "Add"}
      </button>
    </form>
  );
}
