import { useEffect, useState, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import SearchFilterTable, {
  FilterArray,
} from "../../components/SearchFilterTable";
import DataFetcher from "../../components/DataFetcher";
import {
  CalculateNetSalary,
  CalculateSalaryDeductions,
  mainSalaryBreakdown,
} from "./SalaryBreakdownFields";
import useMessageModal from "../../hooks/useMessageModal.hook";
import { getPermissions, sendPatchRequest } from "../../config/swr";
import { DelayedActionButton } from "../../components/Button";
import { PayrollPermissions } from ".";
import { monthNames } from "./PayrollHistoryPage";

export default function PayrollPaymentReviewPage() {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Upcoming Payroll Payment");

  const preparePaymentsArray = (data) => {
    const staffToPay = data.staffList
      .filter((staff) => !!staff.name && !staff.isDisabled) // !staff.name = new staff info
      .map((staff) => {
        return {
          ...staff,
          totalPayment: CalculateNetSalary(staff),
          totalDeductions: CalculateSalaryDeductions(staff),
        };
      })
      .sort((staff1, staff2) => {
        if (staff1.name > staff2.name) return 1;
        return -1;
      });

    const totalPayment = staffToPay.reduce(
      (total, staff) => total + staff.totalPayment,
      0
    );

    const staffCountWithPendingUpdate = data.staffList.filter(
      (staff) => staff.latestInfo
    ).length;

    return {
      ...data,
      staffToPay,
      totalPayment,
      staffCountWithPendingUpdate,
    };
  };

  return (
    <DataFetcher
      url="/payroll/upcoming-payment"
      buildUI={(data) => (
        <UpcomingPaymentsDisplay data={preparePaymentsArray(data)} />
      )}
    />
  );
}

function UpcomingPaymentsDisplay({ data }) {
  const sortOptions = {
    Name: "name",
    "Net Salary": "netSalary",
    Deductions: "deductions",
    "Other Allowances": "otherAllowances",
  };

  const [filter, setFilter] = useState({});
  const staffToPay = FilterArray(data.staffToPay, filter, sortOptions);
  const nextPaymentDate = new Date(data.paymentTimestamp * 1000).toDateString(
    "ddd, dd mmm, yyyy"
  );

  const [review, setReview] = useState({
    reject: false,
    message: "",
    submitting: false,
  });

  const cancelRejectButton = useRef();
  useEffect(() => {
    cancelRejectButton.current?.scrollIntoView();
  });

  const showMessageModal = useMessageModal();

  const { trigger } = useSWRMutation(
    `/payroll/upcoming-payment`,
    sendPatchRequest
  );

  const submitReview = async (approve) => {
    const reject = !approve;
    const payload = {
      reviewedInfo: data,
      mandate: { reject: reject, approve: !reject, message: review.message },
    };

    setReview({ ...review, reject, submitting: true });

    try {
      const res = await trigger({ payload });
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      setReview({ ...review, submitting: false });
    }
  };

  const permissions = getPermissions();
  const canReviewUpcomingPayment =
    permissions[PayrollPermissions.REVIEW_PAYROLL_UPCOMING_PAYMENT_PERM];

  const approved = data.reviewedStatus === "Approved";
  const waitingForReview = data.reviewedStatus === "Waiting for review";
  const approvalStatus = waitingForReview
    ? "PENDING APPROVAL!!! Scroll down to approve/reject"
    : data.reviewedStatus;

  return (
    <>
      <div style={{ marginBottom: "-10px" }}>
        <p>
          <b>Payment For:</b>
          {` ${monthNames[data.paymentMonth.month - 1]} ${
            data.paymentMonth.year
          }`}
        </p>
        <p>
          <b>Payment Date:</b> {nextPaymentDate}
          &nbsp;
          <span className={approved ? "color-primary" : "tart-orange"}>
            ({approvalStatus})
          </span>
        </p>
      </div>

      <SearchFilterTable
        headers={[
          "S/N",
          "Name",
          ...Object.values(mainSalaryBreakdown),
          "Other Allowances",
          "MD Allowance",
          "Total Payment",
          "Total Ded.",
        ]}
        sortOptions={Object.keys(sortOptions)}
        searchHint="Search for a payment"
        searchDelay={0}
        reloadTable={setFilter}
        tableClass="mini mb-32"
      >
        {staffToPay.map((staff, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{staff.name}</td>
            {Object.keys(mainSalaryBreakdown).map((k) => (
              <td key={k}>{staff[k]?.toLocaleString()}</td>
            ))}
            <td>{staff.otherAllowance?.toLocaleString()}</td>
            <td>{staff.mdAllowance?.toLocaleString()}</td>
            <td>{staff.totalPayment?.toLocaleString()}</td>
            <td>{staff.totalDeductions?.toLocaleString()}</td>
          </tr>
        ))}
      </SearchFilterTable>

      <div className="flex col gap-4 mb-20">
        <b>Summary</b>
        <p>
          A total of<b> N{data.totalPayment.toLocaleString()}</b> will be paid
          to {data.staffToPay.length} staff on {nextPaymentDate}.
          {data.staffCountWithPendingUpdate > 0 && (
            <>
              <br />
              <i>
                {data.staffCountWithPendingUpdate} staff have pending
                information updates that are not reflected in the table above!
              </i>
            </>
          )}
        </p>
      </div>

      {canReviewUpcomingPayment && !approved && (
        <div className="flex col gap-8">
          <b>Approve / Reject</b>

          <input
            id="message"
            type="text"
            className="mini"
            placeholder="Additional message (optional)"
            value={review.message}
            onChange={(e) => setReview({ ...review, message: e.target.value })}
          />

          <div className="flex gap-8 start">
            <DelayedActionButton
              className="mini outline btn"
              text="APPROVE"
              confirmText="Tap again to approve"
              busyText="Approving..."
              disabled={review.submitting}
              isBusy={!review.reject && review.submitting}
              clickAction={() => submitReview(true)}
            />

            <DelayedActionButton
              className="mini danger btn"
              text="REJECT"
              confirmText="Tap again to reject"
              busyText="Rejecting..."
              disabled={review.submitting}
              isBusy={review.reject && review.submitting}
              clickAction={() => submitReview(false)}
            />
          </div>
        </div>
      )}
    </>
  );
}
