import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import useSWRMutation from "swr/mutation";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { sendPatchRequest } from "../../../config/swr";
import useMessageModal from "../../../hooks/useMessageModal.hook";
import Modal from "../../../components/Modal";
import { FALLBACK_IMAGE_URL } from "../../../config/swr";
import useToggle from "../../../hooks/useToggle.hook";
import { FilterArray } from "../../../components/SearchFilterTable";
import { FiCheck, FiLoader } from "react-icons/fi";

dayjs.extend(utc);

export default function VirtualAccountDetailsModal({
  account,
  dismissModal,
  mutate,
}) {
  const [editAcctName, setEditAcctName] = useState(null);
  const [showTxs, toggleShowTxs] = useToggle(false);
  const [txSearchQuery, setTxSearchQuery] = useState("");

  const showMessageModal = useMessageModal();
  const { trigger: updateAccountName, isMutating: isUpdatingName } =
    useSWRMutation("/virtual-account", sendPatchRequest);

  const handleChangeName = async (e) => {
    e.preventDefault();
    if (isUpdatingName) return;

    try {
      const res = await updateAccountName({
        payload: {
          accountNumber: account.accountNumber,
          accountName: editAcctName,
        },
      });
      showMessageModal({
        title: "Success",
        message: res.data?.message ?? "Account name update successful",
      });
      mutate();
      setEditAcctName(null);
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
    }
  };

  const accountDetails = {
    "Email Address": account?.email || account?.bvnInfo?.Email,
    "Phone Number": account?.phone || account?.bvnInfo?.PhoneNumber,
    Address: account?.address,
    BVN: account?.bvn,
    NIN: account?.nin,
    "Account No": account?.accountNumber,
  };

  const transactions = FilterArray(account?.transactions ?? [], {
    search: txSearchQuery,
  });

  return (
    <Modal
      rightSheet
      width="400px"
      fullScreen={showTxs}
      hideCancelButton
      handleClose={dismissModal}
    >
      <div className="flex" style={{ height: "100vh", textAlign: "left" }}>
        <div style={{ width: showTxs ? "400px" : "100%" }}>
          <div
            className="mb-20"
            style={{ margin: "1rem 1.5rem", textAlign: "right" }}
          >
            <button
              style={showTxs ? { visibility: "hidden" } : {}}
              type="button"
              onClick={dismissModal}
              aria-label="close modal"
            >
              <AiOutlineClose size="24px" />
            </button>
          </div>

          <div className="flex col center mb-16">
            <img
              src={FALLBACK_IMAGE_URL}
              alt="profile"
              className="profile-img mb-20"
            />

            {editAcctName === null ? (
              <div className="flex center gap-8 mb-12">
                <h3 className="fs-20">{account?.name}</h3>
                <span
                  className="fs-12 color-orange clickable"
                  onClick={() => setEditAcctName(account?.name)}
                >
                  (edit)
                </span>
              </div>
            ) : (
              <form
                className="fullwidth flex gap-8 mb-12"
                style={{ padding: "0 1.5rem", textAlign: "right" }}
                onSubmit={handleChangeName}
              >
                <label
                  htmlFor="accountName"
                  className="fs-16"
                  style={{ marginBottom: 0, alignSelf: "center" }}
                >
                  New name:
                </label>
                <input
                  id="accountName"
                  className="mini flex-1 center-text fs-16"
                  type="name"
                  value={editAcctName}
                  onChange={(e) => setEditAcctName(e.target.value)}
                />
                <button
                  className="mini primary btn flex center fs-14"
                  type="submit"
                  disabled={isUpdatingName || editAcctName === account?.name}
                >
                  {isUpdatingName ? <FiLoader /> : <FiCheck />}
                </button>
                <button
                  className="tart-orange flex center clickable"
                  disabled={isUpdatingName}
                  onClick={isUpdatingName ? null : () => setEditAcctName(null)}
                >
                  <AiOutlineClose />
                </button>
              </form>
            )}

            <p className="flex gap-4 center color-primary fs-14">
              <span>Account Bal:</span>
              <span className="fs-20 fw-600">
                &#8358;{account.balance?.toLocaleString()}
              </span>
            </p>

            <p className="flex gap-4 center charcoal-60 fs-12 mb-6">
              Total Received: &#8358;{account.grossBalance?.toLocaleString()}
            </p>
          </div>

          <hr className="mb-16" />

          <div
            className="flex col space-between gap-12"
            style={{ padding: "1.5rem" }}
          >
            {Object.entries(accountDetails).map(([label, value]) => (
              <div key={label} className="flex gap between-center mb-8">
                <span className="charcoal-60 fs-18">{label}</span>
                <h3 className="fs-18">{value}</h3>
              </div>
            ))}

            <button
              className="mini fullwidth btn outline"
              onClick={toggleShowTxs}
            >
              {showTxs
                ? "Hide transaction history"
                : "View transaction history"}
            </button>
          </div>
        </div>

        {showTxs ? (
          <div
            className="flex col"
            style={{
              flex: 1,
              borderLeft: "1px solid #D7D7D8",
              padding: "1rem 1.5rem",
            }}
          >
            <div className="flex space-between center mb-20">
              <h3 className="fs-20">Transaction History</h3>

              <button
                type="button"
                onClick={dismissModal}
                aria-label="close modal"
              >
                <AiOutlineClose size="24px" />
              </button>
            </div>

            <input
              type="text"
              placeholder="Search by amount, remark, beneficiary name"
              className="mb-32"
              value={txSearchQuery}
              onChange={(e) => setTxSearchQuery(e.target.value)}
            />

            <div style={{ overflow: "auto" }}>
              {transactions.map((tx) => (
                <div
                  key={tx.settlementId}
                  className="flex space-between center gap-20"
                  style={{
                    color: "#A2A1A1",
                    border: "1px solid #D7D7D8",
                    padding: "12px 24px",
                  }}
                >
                  <div className="flex col gap-4">
                    <p className="fs-14 fw-600">From: {tx.sourceAccountName}</p>
                    <p className="fs-16 fw-600" style={{ color: "#606062" }}>
                      {tx.tranRemarks}
                      <br />
                      {tx.sessionId}
                    </p>
                    <p className="fs-14">
                      {dayjs(tx.tranDateTime)
                        .utc()
                        .format("dddd, MMMM DD, YYYY")}
                    </p>
                  </div>
                  <div>
                    <p className="color-primary fs-16 fw-600">
                      &#8358;{tx.amount?.toLocaleString()}
                    </p>
                    <p className="fs-14">
                      {dayjs(tx.tranDateTime).utc().format("hh:mm:ss")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
