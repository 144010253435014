import { useState } from "react";

import { DelayedActionButton } from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import SuccessfulBatchModal from "./SuccessfulBatchModal";
import SpreadsheetIcon from "../../../../assets/icons/spreadsheeticon";
import { BASE_URL, getToken } from "../../../../config/swr";
import useMessageModal from "../../../../hooks/useMessageModal.hook";

const BatchDetailsModal = ({ batch, dismissModal }) => {
  const [batchModal, setBatchModal] = useState(false);
  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const showMessageModal = useMessageModal();

  const openBatchModal = () => setBatchModal(true);
  const closeBatchModal = () => setBatchModal(false);

  const downloadFailedCSV = async () => {
    if (!batch.failedReports || batch.failedReports.length === 0) {
      return;
    }

    setDownloadingCSV(true);

    let filename = `${batch.id}_failures.csv`;
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + getToken());
    try {
      const response = await fetch(
        `https://${BASE_URL}/accounts/batches/${filename}`,
        {
          headers,
        }
      );
      if (response.status === 200) {
        const csvBlob = await response.blob();
        let csvUrl = window.URL.createObjectURL(csvBlob);

        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        anchor.href = csvUrl;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(csvUrl);
        anchor.remove();
      } else {
        console.log(response);
        showMessageModal({
          title: "Error",
          message: "Download CSV failed",
          isError: true,
          closeButtonText: "Close",
        });
      }
    } catch (error) {
      console.error(error);
      showMessageModal({
        title: "Error",
        message: error?.response?.data?.errorMessage || "Download CSV failed",
        isError: true,
        closeButtonText: "Close",
      });
    }
    setDownloadingCSV(false);
  };

  const batchInfo = [
    {
      title: "Batch Code",
      value: batch.id,
    },
    {
      title: "Total No. of Batch Accounts",
      value: batch.totalEntries,
    },
    {
      title: "No of Successful Accounts",
      value: (
        <div className="flex gap-8 center">
          {batch.successfulReports?.length > 0 && (
            <button
              className="btn primary light fs-14 mini fw-600"
              onClick={openBatchModal}
              disabled={
                !batch.successfulReports ||
                batch.successfulReports?.length === 0
              }
            >
              View
            </button>
          )}
          <span>{batch.successfulReports?.length ?? 0}</span>
        </div>
      ),
    },
    {
      title: "No of Failed Accounts",
      value: (
        <div className="flex gap-8 center">
          {batch.failedReports?.length > 0 && (
            <DelayedActionButton
              className="btn primary light fs-14 mini fw-600"
              text="Export as CSV"
              busyText="Exporting..."
              isBusy={downloadingCSV}
              clickAction={downloadFailedCSV}
              disabled={
                !batch.failedReports || batch.failedReports?.length === 0
              }
            />
          )}
          <span>{batch.failedReports?.length ?? 0}</span>
        </div>
      ),
    },
  ];

  if (batchModal) {
    return (
      <Modal fullScreen handleClose={closeBatchModal}>
        <SuccessfulBatchModal handleClose={closeBatchModal} batch={batch} />
      </Modal>
    );
  }

  return (
    <Modal
      rightSheet
      className="account-holder-with-info-modal"
      handleClose={dismissModal}
    >
      <div className="flex col center mb-32">
        <div className="mb-20">
          <SpreadsheetIcon />
        </div>
        <h3 className="fs-20 mb-6">{batch.id}</h3>
        <p className={`charcoal-60 $mb-20`}>
          {batch.successfulReports?.length ?? 0} Successful Accounts
        </p>
      </div>
      <hr className="mb-32" />
      <div className="flex gap-20 col mb-32 fs-16">
        {batchInfo.map((info, index) => (
          <div key={index} className="flex gap-8 between-center">
            <span className="charcoal-60">{info.title}</span>
            <h3>{info.value}</h3>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default BatchDetailsModal;
