import React from "react";

export const mainSalaryBreakdown = {
  basicSalary: "Basic Salary",
  housing: "Housing",
  transport: "Transport",
  allowance: "Allowance",
};

const breakdown = {
  ...mainSalaryBreakdown,
  otherAllowance: "Other Allowance",
  mdAllowance: "MD Allowance",
};

const deductions = {
  payeDed: "Paye Ded",
  pensionDed: "Pension Ded",
  otherDed: "Other Ded",
  loanDed: "Loan Ded",
};

export function CalculateSalaryDeductions(salary) {
  return Object.keys(deductions).reduce(
    (sum, deduction) => sum + Number(salary[deduction] ?? 0),
    0
  );
}

export function CalculateNetSalary(salary) {
  return Object.keys(breakdown).reduce(
    (sum, salaryCategory) => sum + Number(salary[salaryCategory] ?? 0),
    0
  );
}

export function ClearSalary(obj) {
  Object.keys(breakdown).forEach((field) => delete obj[field]);
}

export function CompareSalaries(newSalary, oldSalary) {
  const changes = {};
  let changed = false;
  Object.keys(breakdown).forEach((field) => {
    const oldValue = oldSalary[field];
    if (oldValue !== newSalary[field]) {
      changes[field] = oldValue || "0";
      changed = true;
    }
  });
  if (!changed) {
    return;
  }
  return changes;
}

export default function SalaryBreakdownFields({
  form,
  oldValues = {},
  defaultValues = {},
  readOnly,
  spaceBetweenFields,
  forDeductions,
}) {
  const formFieldProps = (field) => {
    if (readOnly) return { value: form.data[field] || "" };
    return form.fieldProps(field);
  };

  const obj = forDeductions ? deductions : breakdown;
  return Object.keys(obj).map((field) => (
    <React.Fragment key={field}>
      <label className="flex center space-between fs-18" htmlFor={field}>
        {obj[field]}
        {oldValues[field] && <small>Previously: {oldValues[field]}</small>}
      </label>
      <input
        id={field}
        type="number"
        step=".01"
        min="0"
        readOnly={readOnly}
        onWheel={(e) => e.target.blur()}
        className={spaceBetweenFields ?? "mb-20"}
        placeholder={defaultValues[field] ?? "0.00"}
        {...formFieldProps(field)}
      />
    </React.Fragment>
  ));
}
