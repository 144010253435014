import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";

import DataFetcher from "../../components/DataFetcher";
import SearchFilterTable from "../../components/SearchFilterTable";
import CollectionDetailsModal from "./CollectionDetailsModal";
import { getPermissions } from "../../config/swr";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const EXPORT_COLLECTIONS_KEY = "export-collections";

const CollectionsPage = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Target Collections");

  return (
    <DataFetcher
      url={`/collections/summary`}
      buildUI={(data) => <CollectionsPageWithSummary data={data} />}
    />
  );
};

const CollectionsPageWithSummary = ({ data }) => {
  const [activeStatus, setActiveStatus] = useState("active");
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");

  const reloadCollections = ({ sort, search }) => {
    setSearch(search);
    setSort(sort);
  };

  return (
    <div className="collections">
      <nav className="tab-nav mb-32">
        {Object.keys(data).map((status, index) => (
          <button
            key={index}
            onClick={() => setActiveStatus(status)}
            className={"box fs-20" + (activeStatus === status ? " active" : "")}
          >
            <span className="fs-16" style={{ textTransform: "capitalize" }}>
              {status} Collections ({data[status].count.toLocaleString()})
            </span>
            &#8358;{data[status].totalAmount.toLocaleString()}
          </button>
        ))}
      </nav>
      <DataFetcher
        url={`/collections?status=${activeStatus}&sort=${sort}&search=${search}`}
        buildUI={(data) => (
          <CollectionsTable
            activeStatus={activeStatus}
            collections={data}
            reloadCollections={reloadCollections}
          />
        )}
      />
    </div>
  );
};

const CollectionsTable = ({ collections, reloadCollections, activeStatus }) => {
  const permissions = getPermissions();

  const canExportCollections = permissions[EXPORT_COLLECTIONS_KEY];
  const headers = [
    "Account Name",
    "Title",
    "Target Amount",
    "Duration/Package",
    "Start Date",
  ];

  const sortOptions = [
    "Date",
    "Amount",
    "Account Name",
    "Name",
    "Package",
    "Interest",
    "Total Collected",
  ];

  const [selectedCollectionIndex, setSelectedCollectionIndex] = useState(-1);
  const selectedCollection =
    selectedCollectionIndex >= 0 && collections[selectedCollectionIndex];

  return (
    <>
      {!collections || collections.length === 0 ? (
        <div className="flex center box fs-20">
          There is currently no {activeStatus} collections
        </div>
      ) : (
        <SearchFilterTable
          headers={headers}
          sortOptions={sortOptions}
          reloadTable={reloadCollections}
          csvUrl={canExportCollections && "collections/export"}
        >
          {collections.map((collection, index) => (
            <tr
              key={index}
              className="clickable"
              onClick={() => setSelectedCollectionIndex(index)}
            >
              <td>{collection.accountName}</td>
              <td>{collection.title}</td>
              <td>&#8358;{collection.targetAmount?.toLocaleString()}</td>
              <td>{collection.duration?.toLocaleString()} Days</td>
              <td>
                {dayjs(collection.createdTimestamp * 1000).utc().format(
                  "Do MMM YYYY"
                )}
              </td>
            </tr>
          ))}
        </SearchFilterTable>
      )}

      {selectedCollection && (
        <CollectionDetailsModal
          collection={selectedCollection}
          dismissModal={() => setSelectedCollectionIndex(-1)}
        />
      )}
    </>
  );
};

export default CollectionsPage;
