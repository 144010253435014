import { useEffect } from "react";
import useSWR from "swr";

function DataFetcher({ url, buildUI, loadingUI, onLoadingComplete }) {
  const { data, error, isLoading, mutate } = useSWR(url);

  useEffect(() => {
    if (!isLoading && onLoadingComplete) onLoadingComplete();
  });

  if (isLoading) return loadingUI ?? <>Loading...</>;

  if (error)
    return (
      <>
        {error.response?.data?.errorMessage?.includes(
          "invalid token in authorization header"
        )
          ? "Login required to access resources"
          : error.response?.data?.errorMessage ||
            error.response?.data ||
            "An error occurred. Please try again"}
      </>
    );

  return buildUI(data, mutate);
}

export default DataFetcher;
