import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";

import Checkbox from "../../../components/Checkbox";
import DataFetcher from "../../../components/DataFetcher";
import Modal from "../../../components/Modal";
import MutableListView from "../../../components/MutableListView";
import AccountTierForm from "./AccountTierForm";
import AccountProductForm from "./AccountProductForm";
import UploadIcon from "../../../assets/icons/upload";
import { getPermissions, sendPostRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";
import ChatTranscriptRecipientForm from "./ChatTranscriptRecipientForm";

const UPDATE_GENERAL_SETTINGS_KEY = "update-general-settings";

const GeneralSettings = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("General Settings");

  return (
    <DataFetcher
      url={`/settings/general`}
      buildUI={(data, mutate) => <GeneralDisplay data={data} mutate={mutate} />}
    />
  );
};

const GeneralDisplay = ({ data, mutate }) => {
  const form = useForm(data);
  const permissions = getPermissions();

  const canUpdateSettings = permissions[UPDATE_GENERAL_SETTINGS_KEY];

  const { trigger } = useSWRMutation("/settings/general", sendPostRequest);
  const { trigger: stampTrigger } = useSWRMutation(
    "/settings/general/statementstamp",
    sendPostRequest
  );

  const {
    regularFewchoreAccountProductCode,
    accountOfficerCode,
    nibssCode,
    institutionCode,
    faqUrl,
    termsConditionsUrl,
    statementStampImageUrl,
  } = form.data;

  const buttonDisabled =
    !regularFewchoreAccountProductCode ||
    !accountOfficerCode ||
    !nibssCode ||
    !institutionCode ||
    !faqUrl ||
    !termsConditionsUrl;

  const showMessageModal = useMessageModal();
  const [stampData, setStampData] = useState(null);
  const saveStampButtonDisabled = !stampData?.["statement-stamp"];

  const closeStampModal = () => {
    setStampData(null);
  };

  const openStampModal = () => {
    setStampData({
      imageUrl: `${statementStampImageUrl}?${new Date().getTime()}`,
    });
  };

  const onStampFormDataChange = (e) => {
    const { files, name } = e.target;
    setStampData({
      ...stampData,
      [name]: files[0] && files[0],
      imageUrl: files[0] && URL.createObjectURL(files[0]),
    });
  };

  const submitStampForm = async (event) => {
    event.preventDefault();
    if (saveStampButtonDisabled) return;

    form.submitStarted();

    const data = new FormData(event.target);

    try {
      await stampTrigger(data);
      form.submitSuccess();
      closeStampModal();
      mutate();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  const [addEditTier, setAddEditTier] = useState(null);
  const closeTierFormModal = () => setAddEditTier(null);

  const [addEditRecipients, setAddEditRecipients] = useState(null);
  const closeRecipientsFormModal = () => setAddEditRecipients(null);

  const [addEditProduct, setAddEditProduct] = useState(null);
  const closeProductFormModal = () => setAddEditProduct(null);

  const editTier = (index) => setAddEditTier({ id: index });
  const editRecipients = (index) => setAddEditRecipients({ id: index });
  const editProduct = (index) => setAddEditProduct({ id: index });

  const deleteTier = (index, onSuccess) => {
    form.setData({
      ...form.data,
      accountTiers: [
        ...form.data.accountTiers.slice(0, index),
        ...form.data.accountTiers.slice(index + 1),
      ],
    });
    onSuccess();
  };

  const onTierFormSubmit = (tierData) => {
    const tiers = form.data.accountTiers?.slice() ?? [];
    if (addEditTier.id >= 0) {
      tiers[addEditTier.id] = tierData;
    } else {
      tiers.push(tierData);
    }

    form.setData({
      ...form.data,
      accountTiers: tiers,
    });
    closeTierFormModal();
  };

  const deleteRecipients = (index, onSuccess) => {
    form.setData({
      ...form.data,
      chatTranscriptRecipients: [
        ...form.data.chatTranscriptRecipients.slice(0, index),
        ...form.data.chatTranscriptRecipients.slice(index + 1),
      ],
    });
    onSuccess();
  };

  const onRecipientsFormSubmit = (recipientData) => {
    const recipients = form.data.chatTranscriptRecipients?.slice() ?? [];
    if (addEditRecipients.id >= 0) {
      recipients[addEditRecipients.id] = recipientData;
    } else {
      recipients.push(recipientData);
    }

    form.setData({
      ...form.data,
      chatTranscriptRecipients: recipients,
    });
    closeRecipientsFormModal();
  };

  const deleteProduct = (index, onSuccess) => {
    form.setData({
      ...form.data,
      accountProducts: [
        ...form.data.accountProducts.slice(0, index),
        ...form.data.accountProducts.slice(index + 1),
      ],
    });
    onSuccess();
  };

  const onProductFormSubmit = (productData) => {
    const products = form.data.accountProducts?.slice() ?? [];
    if (addEditProduct.id >= 0) {
      products[addEditProduct.id] = productData;
    } else {
      products.push(productData);
    }

    form.setData({
      ...form.data,
      accountProducts: products,
    });
    closeProductFormModal();
  };

  const dateStringToTimestamp = (date, isStartOfDay) => {
    if (!date) return 0;
    const dt = new Date(date);
    if (isStartOfDay) {
      dt.setHours(0, 0, 0, 0);
    } else {
      dt.setHours(23, 59, 59, 999);
    }
    return parseInt(dt.getTime() / 1000);
  };

  const saveGeneralSettings = async () => {
    if (!canUpdateSettings) return;
    form.submitStarted();
    const data = {
      ...form.data,
      billPaymentFee: +form.data.billPaymentFee,
      dataPurchaseFee: +form.data.dataPurchaseFee,
      airtimePurchaseFee: +form.data.airtimePurchaseFee,
      fundsTransferBatchMax: +form.data.fundsTransferBatchMax,
      cashbackStartTimestamp: dateStringToTimestamp(
        form.data.cashbackStartTimestamp,
        true
      ),
      cashbackEndTimestamp: dateStringToTimestamp(
        form.data.cashbackEndTimestamp,
        false
      ),
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <div className="settings">
      <div className="flex start gap equal-widths mb-32">
        <div>
          <h2 className="fs-20 mb-16">Account Product Codes</h2>
          <div className="box mb-20">
            <label htmlFor="regularFewchoreAccountProductCode">
              Regular Fewchore Account
            </label>
            <input
              id="regularFewchoreAccountProductCode"
              type="text"
              className="mb-16"
              placeholder="Enter Regular Fewchore Account Code here"
              {...form.fieldProps("regularFewchoreAccountProductCode")}
            />

            <label htmlFor="accountOfficerCode">Account Officer Code</label>
            <input
              id="accountOfficerCode"
              type="text"
              className="mb-16"
              placeholder="Enter Account Officer Code"
              {...form.fieldProps("accountOfficerCode")}
            />

            <label htmlFor="nibssCode">NIBSS Code</label>
            <input
              id="nibssCode"
              type="text"
              className="mb-32"
              placeholder="Enter NIBSS Code here"
              {...form.fieldProps("nibssCode")}
            />

            <label htmlFor="institutionCode">Institution Code</label>
            <input
              id="institutionCode"
              type="text"
              className="mb-32"
              placeholder="Enter Institution Code here"
              {...form.fieldProps("institutionCode")}
            />

            <label htmlFor="billPaymentLedgerCode">
              Bill Payment Ledger Code
            </label>
            <input
              id="billPaymentLedgerCode"
              type="text"
              className="mb-32"
              placeholder="Enter Ledger Code here"
              {...form.fieldProps("billPaymentLedgerCode")}
            />

            <label htmlFor="billPaymentRefundLedgerCode">
              Bill Payment Refund Ledger Code
            </label>
            <input
              id="billPaymentRefundLedgerCode"
              type="text"
              className="mb-32"
              placeholder="Enter Refund Ledger Code here"
              {...form.fieldProps("billPaymentRefundLedgerCode")}
            />

            <label htmlFor="billPaymentFee">Bill Payment Fee</label>
            <input
              id="billPaymentFee"
              type="number"
              className="mb-32"
              placeholder="Enter Bill Payment Fee here"
              {...form.fieldProps("billPaymentFee")}
            />

            <label htmlFor="airtimePurchaseFee">Airtime Purchase Fee</label>
            <input
              id="airtimePurchaseFee"
              type="number"
              className="mb-32"
              placeholder="Enter Airtime Purchase Fee here"
              {...form.fieldProps("airtimePurchaseFee")}
            />

            <label htmlFor="dataPurchaseFee">Data Purchase Fee</label>
            <input
              id="dataPurchaseFee"
              type="number"
              className="mb-32"
              placeholder="Enter Data Purchase Fee here"
              {...form.fieldProps("dataPurchaseFee")}
            />

            <label htmlFor="fundsTransferBatchMax">
              Maximum Batch Funds Transfer
            </label>
            <input
              id="fundsTransferBatchMax"
              type="number"
              className="mb-32"
              placeholder="Enter Maximum Batch Funds Transfer here"
              {...form.fieldProps("fundsTransferBatchMax")}
            />

            <label htmlFor="faqUrl">FAQ Url</label>
            <input
              id="faqUrl"
              type="text"
              className="mb-32"
              placeholder="Enter FAQ Url here"
              {...form.fieldProps("faqUrl")}
            />

            <label htmlFor="termsConditionsUrl">Terms and Conditions Url</label>
            <input
              id="termsConditionsUrl"
              type="text"
              className="mb-32"
              placeholder="Enter Terms and Conditions Url here"
              {...form.fieldProps("termsConditionsUrl")}
            />

            <div className="flex gap between-center">
              <label>Bank Statement Stamp</label>
              <button className="btn mini outline" onClick={openStampModal}>
                Update
              </button>
            </div>
          </div>

          <h2 className="fs-20 mb-16">Other Account Products</h2>
          <div className="box">
            <div className="mb-28">
              {
                <MutableListView
                  dataList={form.data.accountProducts}
                  renderItem={(item, editButton, deleteButton) => (
                    <div
                      key={item.code}
                      className="flex gap center info-row-with-actions"
                    >
                      <div className="text">
                        <h3 className="fs-20">
                          {item.displayName}
                          {item.showInApp ? (
                            <span className="btn primary light fs-14">APP</span>
                          ) : null}
                        </h3>
                        <p className="charcoal-60">{item.code}</p>
                      </div>

                      <div className="actions">
                        {editButton}
                        {deleteButton}
                      </div>
                    </div>
                  )}
                  editItem={editProduct}
                  deleteItem={deleteProduct}
                />
              }
            </div>

            <button
              onClick={() => setAddEditProduct({})}
              className="fullwidth btn primary light"
            >
              Add Product
            </button>

            {addEditProduct && (
              <Modal handleClose={closeProductFormModal} className="box">
                <AccountProductForm
                  productData={
                    addEditProduct.id >= 0
                      ? form.data.accountProducts[addEditProduct.id]
                      : null
                  }
                  onSubmit={onProductFormSubmit}
                />
              </Modal>
            )}
          </div>
        </div>

        <div>
          <h2 className="fs-20 mb-16">Cashback Promo</h2>
          <div className="box mb-20">
            <div>
              <label htmlFor="cashbackStartTimestamp">Start Date</label>
              <input
                id="cashbackStartTimestamp"
                type="date"
                className="mb-16"
                {...form.timestampFieldProps("cashbackStartTimestamp")}
              />

              <label htmlFor="cashbackEndTimestamp">End Date</label>
              <input
                id="cashbackEndTimestamp"
                type="date"
                className="mb-16"
                {...form.timestampFieldProps("cashbackEndTimestamp")}
              />

              <label htmlFor="adminCashbackPercentage">
                Admin Cashback Percentage
              </label>
              <input
                id="adminCashbackPercentage"
                type="number"
                className="mb-16"
                min="0"
                {...form.fieldProps("adminCashbackPercentage")}
              />

              <label htmlFor="customerCashbackPercentage">
                Customer Cashback Percentage
              </label>
              <input
                id="customerCashbackPercentage"
                type="number"
                className="mb-16"
                min="0"
                {...form.fieldProps("customerCashbackPercentage")}
              />

              <label htmlFor="cashbackAccount">Cashback Account</label>
              <input
                id="cashbackAccount"
                type="text"
                className="mb-16"
                {...form.fieldProps("cashbackAccount")}
              />

              <Checkbox
                text="Disable Cashback Spending"
                color="#3d5059"
                additionalStyles={{
                  paddingLeft: 0,
                  paddingBottom: 0,
                  background: "none",
                }}
                {...form.fieldProps("disableCashbackSpending", true)}
              />
            </div>
          </div>

          <h2 className="fs-20 mb-16">Account Tiers</h2>
          <div className="box mb-20">
            <div className="mb-28">
              {
                <MutableListView
                  dataList={form.data.accountTiers}
                  renderItem={(item, editButton, deleteButton) => (
                    <div
                      key={item.tier}
                      className="flex gap center info-row-with-actions"
                    >
                      <div className="text">
                        <h3 className="fs-20">{item.displayName}</h3>
                        <p className="charcoal-60">
                          N {Number(item.withdrawalLimit).toLocaleString()}
                        </p>
                      </div>

                      <div className="actions">
                        {editButton}
                        {deleteButton}
                      </div>
                    </div>
                  )}
                  editItem={editTier}
                  deleteItem={deleteTier}
                />
              }
            </div>

            <button
              onClick={() => setAddEditTier({})}
              className="fullwidth btn primary light"
            >
              Add Tier
            </button>

            {addEditTier && (
              <Modal handleClose={closeTierFormModal} className="box">
                <AccountTierForm
                  tierData={
                    addEditTier.id >= 0
                      ? form.data.accountTiers[addEditTier.id]
                      : null
                  }
                  onSubmit={onTierFormSubmit}
                />
              </Modal>
            )}
          </div>

          <h2 className="fs-20 mb-16">Chat Transcript Recipients</h2>
          <div className="box mb-20">
            <div className="mb-28">
              {
                <MutableListView
                  dataList={form.data.chatTranscriptRecipients}
                  renderItem={(item, editButton, deleteButton) => (
                    <div
                      key={item.email}
                      className="flex gap center info-row-with-actions"
                    >
                      <div className="text">
                        <h3 className="fs-20">{item.displayName}</h3>
                        <p className="charcoal-60">{item.email}</p>
                      </div>

                      <div className="actions">
                        {editButton}
                        {deleteButton}
                      </div>
                    </div>
                  )}
                  editItem={editRecipients}
                  deleteItem={deleteRecipients}
                />
              }
            </div>

            <button
              onClick={() => setAddEditRecipients({})}
              className="fullwidth btn primary light"
            >
              Add Chat Transcript Recipient
            </button>

            {addEditRecipients && (
              <Modal handleClose={closeRecipientsFormModal} className="box">
                <ChatTranscriptRecipientForm
                  recipientData={
                    addEditRecipients.id >= 0
                      ? form.data.chatTranscriptRecipient[addEditRecipients.id]
                      : null
                  }
                  onSubmit={onRecipientsFormSubmit}
                />
              </Modal>
            )}
          </div>
        </div>
      </div>

      {canUpdateSettings && (
        <button
          className="fullwidth dark primary btn"
          onClick={saveGeneralSettings}
          disabled={form.state.submitting || buttonDisabled}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}

      {stampData && (
        <Modal
          handleClose={closeStampModal}
          className="mobile-ads__modalcontent"
        >
          <p className="fs-24 center-text">Stamp Editor</p>
          <form onSubmit={submitStampForm}>
            <div className="mobile-ads__modalcontent-top flex center">
              <label
                className="mobile-ads__modalcontent-top-selectimage"
                htmlFor="select-image"
              >
                <p className="fs-20">Select Image</p>
                <p className="fs-16">Min. Res.: 360px * 150px</p>
                <p className="fs-18">(.jpg, .png, .jpeg)</p>
                <UploadIcon />
                <input
                  type="file"
                  hidden
                  id="select-image"
                  name="statement-stamp"
                  onChange={onStampFormDataChange}
                />
              </label>
            </div>

            <div className="mobile-ads__modalcontent-bottom">
              <p className="fs-16">Preview</p>
              <div className="mobile-ads__modalcontent-bottom-previewpane">
                {stampData?.imageUrl && (
                  <img
                    src={stampData?.imageUrl}
                    className="mobile-ads__modalcontent-bottom-previewpane-image"
                    alt="ad-img"
                  />
                )}
              </div>
              <button
                className="mobile-ads__modalcontent-bottom-save fs-22"
                disabled={saveStampButtonDisabled || form.state.submitting}
              >
                {form.state.submitting ? <FiLoader /> : " Save"}
              </button>
              <button
                className="mobile-ads__modalcontent-bottom-cancel fs-18"
                type="button"
                onClick={closeStampModal}
                disabled={form.state.submitting}
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default GeneralSettings;
