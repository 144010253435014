import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { FiLoader } from "react-icons/fi";

import useForm from "../../../../hooks/useForm.hook";
import useMessageModal from "../../../../hooks/useMessageModal.hook";
import { getPermissions, sendPostRequest } from "../../../../config/swr";

const UPDATE_PAYDAY_LOAN_SETTINGS_KEY = "update-payday-loan-settings";

const PaydayLoanTab = ({ paydayLoanSettings, mutate }) => {
  const permissions = getPermissions();

  const canUpdateSettings = permissions[UPDATE_PAYDAY_LOAN_SETTINGS_KEY];
  const [formData, setFormData] = useState(paydayLoanSettings);
  const { trigger } = useSWRMutation("/settings/loan/payday", sendPostRequest);
  const form = useForm();
  const showMessageModal = useMessageModal();

  const onChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "dailyInterestRate" ||
      name === "maximumDurationDays" ||
      name === "maximumFirstTimeCustomerLoanAmount" ||
      name === "maxReturningCustomerLoanAmount"
    ) {
      setFormData({ ...formData, [name]: +value });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    if (!canUpdateSettings) return;
    e.preventDefault();

    form.submitStarted();
    const data = {
      ...formData,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
      mutate()
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          error?.message ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <form className="payday-loan" onSubmit={handleSubmit}>
      <div className="flex start equal-widths mb-28" style={{ gap: "48px" }}>
        <div className="box group-item">
          <label htmlFor="">Name</label>
          <input
            type="text"
            className="mb-16"
            name="name"
            value={formData.name}
            onChange={onChange}
          />

          <label htmlFor="">Summary</label>
          <textarea
            className="mb-16"
            name="summary"
            value={formData.summary}
            onChange={onChange}
          ></textarea>

          <label htmlFor="">Daily Interest Rate (%)</label>
          <input
            type="number"
            name="dailyInterestRate"
            value={formData.dailyInterestRate}
            onChange={onChange}
          />
        </div>

        <div className="box group-item">
          <label htmlFor="">Maximum Duration (months)</label>
          <input
            type="number"
            min="1"
            className="mb-16"
            name="maximumDurationDays"
            value={formData.maximumDurationDays}
            onChange={onChange}
          />

          <label htmlFor="">Disbursement Ledger Code</label>
          <input
            type="text"
            className="mb-16"
            name="disbursementLedgerCode"
            value={formData.disbursementLedgerCode}
            onChange={onChange}
          />

          <label htmlFor="">Repayment Ledger Code</label>
          <input
            type="text"
            className="mb-16"
            name="repaymentLedgerCode"
            value={formData.repaymentLedgerCode}
            onChange={onChange}
          />

          <label htmlFor="">First time borrowers limit</label>
          <input
            type="text"
            name="maximumFirstTimeCustomerLoanAmount"
            className="mb-16"
            value={formData.maximumFirstTimeCustomerLoanAmount}
            onChange={onChange}
          />

          <label htmlFor="">Returning borrowers limit</label>
          <input
            type="text"
            name="maxReturningCustomerLoanAmount"
            value={formData.maxReturningCustomerLoanAmount}
            onChange={onChange}
          />
        </div>
      </div>

      {canUpdateSettings && (
        <button
          className="fullwidth dark primary btn"
          onClick={handleSubmit}
          disabled={form.state.submitting}
        >
          {form.state.submitting ? <FiLoader /> : "Save"}
        </button>
      )}
    </form>
  );
};

export default PaydayLoanTab;
