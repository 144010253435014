import React from "react";
import { BiCaretDown } from "react-icons/bi";

import useToggle from "../../../hooks/useToggle.hook";

const GuarantorInfoDisplay = ({
  guarantorInfo,
}) => {
  const [dropdownOpened, toggleDropdown] = useToggle(false);

  const infoList = [
    {
      key: "name",
      title: "Name",
    },
    {
      key: "bvn",
      title: "BVN",
    },
    {
      key: "phoneNumber",
      title: "Phone Number",
    },
    {
      key: "employer",
      title: "Employer",
    },
    {
      key: "employerEmailAddress",
      title: "Employer Email Address",
    },
    {
      key: "employerAddress",
      title: "Employer Address",
    },
    {
      key: "customerRelativeName",
      title: "Relative Name",
    },
    {
      key: "customerRelativePhoneNumber",
      title: "Relative Phone Number",
    },
    {
      key: "customerRelativeAddress",
      title: "Relative Address",
    },
  ];

  const guarantorInfoList = dropdownOpened && guarantorInfo
    ? infoList.map((titleObj) => ({
        title: titleObj.title,
        value: guarantorInfo[titleObj.key] ? (
          <span>{guarantorInfo[titleObj.key]}</span>
        ) : null,
      }))
    : [];

  return (
    <div className="card__box mb-20">
      <div className="flex gap between-center" onClick={toggleDropdown}>
        <span>Guarantor Info</span>
        <span>
          <BiCaretDown
            size={18}
            className={
              dropdownOpened ? "dropdown-icon rotate-180" : "dropdown-icon"
            }
          />
        </span>
      </div>
      <div
        className={
          dropdownOpened ? "batch__dropdown opened" : "batch__dropdown"
        }
      >
        <div className="card__grid">
          {guarantorInfoList.map((info, index) => (
            <div key={index} className="flex gap equal-widths">
              <span className="charcoal-60 fs-18">{info.title}:</span>
              <h3 className="fs-18">{info.value}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuarantorInfoDisplay;
