export const CHANGE_PARTNER = "CHANGE_PARTNER";
export const CHANGE_RESTRICTION_FIELD = "CHANGE_RESTRICTION_FIELD";
export const CHANGE_RESTRICTIONS = "CHANGE_RESTRICTIONS";
export const ADD_SECTION = "ADD_SECTION";
export const EDIT_SECTION_TITLE = "EDIT_SECTION_TITLE";
export const REMOVE_SECTION = "REMOVE_SECTION";
export const ADD_FIELD = "ADD_FIELD";
export const EDIT_FIELD = "EDIT_FIELD";
export const REMOVE_FIELD = "REMOVE_FIELD";
export const ADD_TENURE = "ADD_TENURE";
export const EDIT_TENURE = "EDIT_TENURE";
export const REMOVE_TENURE = "REMOVE_TENURE";
export const ADD_DROPDOWN = "ADD_DROPDOWN";
export const EDIT_DROPDOWN = "EDIT_DROPDOWN";
export const REMOVE_DROPDOWN = "REMOVE_DROPDOWN";

export const partnerReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_PARTNER:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case CHANGE_RESTRICTION_FIELD:
      return {
        ...state,
        loanRestrictions: {
          ...state.loanRestrictions,
          restrictionFormFieldID: action.payload.value,
          restrictions: action.payload.restrictions,
        },
      };
    case CHANGE_RESTRICTIONS:
      return {
        ...state,
        loanRestrictions: {
          ...state.loanRestrictions,
          restrictions: {
            ...state.loanRestrictions.restrictions,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_SECTION: {
      const newArr = state.applicationForm?.slice() ?? [];
      return {
        ...state,
        applicationForm: [...newArr, { name: action.payload, fields: [] }],
        fieldEdited:
          typeof state.fieldEdited === "undefined" ? 0 : state.fieldEdited + 1,
      };
    }
    case EDIT_SECTION_TITLE: {
      const index = state.applicationForm.findIndex(
        (item) => item.name === action.payload.oldName
      );
      const newArr = state.applicationForm?.slice() ?? [];
      newArr[index] = {
        ...newArr[index],
        name: action.payload.name,
      };
      return {
        ...state,
        applicationForm: newArr,
        fieldEdited:
          typeof state.fieldEdited === "undefined" ? 0 : state.fieldEdited + 1,
      };
    }
    case REMOVE_SECTION: {
      const index = state.applicationForm.findIndex(
        (item) => item.name === action.payload.name
      );
      const newArr = state.applicationForm?.slice() ?? [];
      return {
        ...state,
        applicationForm: [
          ...newArr.slice(0, index),
          ...newArr.slice(index + 1),
        ],
        fieldEdited:
          typeof state.fieldEdited === "undefined" ? 0 : state.fieldEdited + 1,
      };
    }
    case ADD_FIELD: {
      const index = state.applicationForm.findIndex(
        (item) => item.name === action.payload.sectionName
      );
      const newArr = state.applicationForm?.slice() ?? [];

      const section = newArr[index];
      newArr[index] = {
        ...newArr[index],
        fields: [...section.fields, action.payload.item],
      };

      return {
        ...state,
        applicationForm: newArr,
        fieldEdited:
          typeof state.fieldEdited === "undefined" ? 0 : state.fieldEdited + 1,
      };
    }
    case EDIT_FIELD: {
      //get section
      const sectionIndex = state.applicationForm.findIndex(
        (item) => item.name === action.payload.sectionName
      );
      const newArr = state.applicationForm?.slice() ?? [];

      //get field
      const fieldIndex = newArr[sectionIndex].fields.findIndex(
        (item) => item.name === action.payload.fieldName
      );

      //change field
      newArr[sectionIndex].fields[fieldIndex] = action.payload.item;

      return {
        ...state,
        applicationForm: newArr,
        fieldEdited:
          typeof state.fieldEdited === "undefined" ? 0 : state.fieldEdited + 1,
      };
    }
    case REMOVE_FIELD: {
      //get section
      const sectionIndex = state.applicationForm.findIndex(
        (item) => item.name === action.payload.sectionName
      );
      const newArr = state.applicationForm?.slice() ?? [];

      //get field
      const fieldIndex = newArr[sectionIndex].fields.findIndex(
        (item) => item.name === action.payload.fieldName
      );

      // delete field
      const newFields = [
        ...newArr[sectionIndex].fields.slice(0, fieldIndex),
        ...newArr[sectionIndex].fields.slice(fieldIndex + 1),
      ];

      newArr[sectionIndex].fields = newFields;

      return {
        ...state,
        applicationForm: newArr,
        fieldEdited:
          typeof state.fieldEdited === "undefined" ? 0 : state.fieldEdited + 1,
      };
    }
    case ADD_TENURE: {
      const newArr = state.loanPackages?.slice() ?? [];
      return {
        ...state,
        loanPackages: [...newArr, action.payload],
      };
    }
    case EDIT_TENURE: {
      const newArr = state.loanPackages?.slice() ?? [];
      newArr[action.payload.index] = action.payload.item;
      return {
        ...state,
        loanPackages: newArr,
      };
    }
    case REMOVE_TENURE: {
      const index = action.payload.index;
      const newArr = state.loanPackages?.slice() ?? [];
      return {
        ...state,
        loanPackages: [...newArr.slice(0, index), ...newArr.slice(index + 1)],
      };
    }
    case ADD_DROPDOWN: {
      const newArr = state.dropdownArr?.slice() ?? [];

      return {
        ...state,
        dropdownArr: [...newArr, action.payload.item],
      };
    }
    case EDIT_DROPDOWN: {
      const index = state.dropdownArr.findIndex(
        (item) => item.restrictionFormFieldID === action.payload.name
      );

      const newArr = state.dropdownArr?.slice() ?? [];
      
      if (index === -1)
        return {
          ...state,
          dropdownArr: [...newArr, action.payload.item],
        };

      newArr[index] = action.payload.item;

      return {
        ...state,
        dropdownArr: newArr,
        fieldEdited:
          typeof state.fieldEdited === "undefined" ? 0 : state.fieldEdited + 1,
      };
    }
    case REMOVE_DROPDOWN: {
      const index = state.dropdownArr.findIndex(
        (item) => item.restrictionFormFieldID === action.payload.name
      );

      const newArr = state.dropdownArr?.slice() ?? [];
      return {
        ...state,
        dropdownArr: [...newArr.slice(0, index), ...newArr.slice(index + 1)],
        fieldEdited:
          typeof state.fieldEdited === "undefined" ? 0 : state.fieldEdited + 1,
      };
    }
    default:
      return state;
  }
};
