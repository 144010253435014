import useForm from "../../../hooks/useForm.hook";

export default function ChatTranscriptRecipientForm ({ recipientData, onSubmit }) {
  const form = useForm(recipientData);
  
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!form.valid()) return;

    const recipientData = {
      ...form.data,
    };
    onSubmit(recipientData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="displayName">Display Name</label>
      <input
        id="displayName"
        type="text"
        className="mb-20"
        placeholder="Enter Display Name"
        {...form.fieldProps("displayName")}
      />
      
      <label htmlFor="email">Email</label>
      <input
        id="email"
        type="email"
        className="mb-20"
        placeholder="Enter Email"
        {...form.fieldProps("email")}
      />

      <button
        className="fullwidth btn primary light"
        disabled={!form.data.displayName || !form.data.email}
      >
        {recipientData ? "Update" : "Add"}
      </button>
    </form>
  );


}
