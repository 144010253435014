import React, { useState } from "react";
import useSWRMutation from "swr/mutation";

import AccountHolderBox from "../../../components/AccountHolderBox";
import { BackButton, DelayedActionButton } from "../../../components/Button";
import Modal from "../../../components/Modal";
import {
  UploadedDocumentPreview,
  UploadedDocumentSummary,
} from "../../../components/uploaded-document-widgets";
import DeclineRequestForm from "../DeclineRequestForm";
import useShowRequestApprovalMessage from "../useShowRequestApprovalMessage";
import { getPermissions, sendPatchRequest } from "../../../config/swr";
import SendMessage from "../../../components/SendMessage";

const ADDRESS_UPDATE_KEY = "update-address-update-request";

export default function AddressUpdateDetailsModal({
  req,
  dismissModal,
  mutate,
}) {
  const permissions = getPermissions();

  const canUpdateAddress = permissions[ADDRESS_UPDATE_KEY];
  const { trigger } = useSWRMutation(`/address-update`, sendPatchRequest);
  const [document, setDocument] = useState({
    url: req.addressProofFilePath,
    name: "Proof of Address",
  });
  const [sendMessageModal, setSendMessageModal] = useState(false);

  const hideMessageModal = () => {
    setSendMessageModal(false);
  };

  const [previewDoc, setPreviewDoc] = useState(false);
  const closeDocumentPreview = ({ accepted, rejected, rejectReason }) => {
    if (!accepted && !rejected) {
      setPreviewDoc(false);
      return;
    }

    setDocument({
      ...document,
      status: accepted ? "accepted" : "rejected",
      rejectReason: rejectReason,
    });
    setPreviewDoc(false);
  };

  const [approving, setApproving] = useState(false);
  const showRequestApprovalMessage = useShowRequestApprovalMessage();
  const approveRequest = async () => {
    if (!canUpdateAddress) return;

    setApproving(true);
    try {
      await trigger({
        payload: {
          customerID: req.customerID,
          notifyCustomer: false,
          Approve: true,
          proofOffAddressFileFeedback: {
            Approve: true,
            Message: "",
          },
        },
      });
      showRequestApprovalMessage({
        requestType: "Address Update",
        customerName: req.customerName,
        error: null,
      });
      mutate();
      dismissModal();
    } catch (error) {
      showRequestApprovalMessage({
        requestType: "Address Update",
        customerName: req.customerName,
        error:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
      });
    }
    setApproving(false);
  };

  const [declining, setDeclining] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const declineRequest = async (message) => {
    if (!canUpdateAddress) return;

    setDeclining(true);

    try {
      await trigger({
        payload: {
          customerID: req.customerID,
          notifyCustomer: false,
          Reject: true,
          Message: message,
          proofOffAddressFileFeedback: {
            Reject: true,
            Message: document.rejectReason ?? message,
          },
        },
      });
      mutate();
      dismissModal();
      return { error: null, customerName: req.customerName };
    } catch (error) {
      return {
        error:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
      };
    } finally {
      setDeclining(false);
    }
  };

  const oldNewAddressElements = (
    <>
      <h3>Old Address</h3>
      <p className="mb-16">{req.currentAddress}</p>
      <h3>New Address</h3>
      <p>{req.address}</p>
    </>
  );

  if (sendMessageModal)
    return (
      <Modal
        rightSheet
        hideCancelButton
        className="account-holder-with-info-modal"
        handleClose={hideMessageModal}
      >
        <SendMessage
          accountName={req.customerName}
          accountNumber={
            req.accountNumbers &&
            (req.accountNumbers.length > 1
              ? req.accountNumbers.join(", ")
              : req.accountNumbers[0])
          }
          customerID={req.customerID}
          profileImageUrl={req.customerProfileImageUrl}
          handleClose={hideMessageModal}
        />
      </Modal>
    );

  return (
    <Modal rightSheet hideCancelButton handleClose={dismissModal}>
      <div className="modal-header-with-back-btn flex start center gap-8 mb-16">
        {previewDoc ? (
          <BackButton onClick={closeDocumentPreview}>
            <h3 className="fs-20">Uploaded Document</h3>
          </BackButton>
        ) : (
          <BackButton onClick={dismissModal}>
            <h3 className="fs-20">Address Update</h3>
          </BackButton>
        )}
      </div>

      {previewDoc ? (
        <UploadedDocumentPreview
          document={document}
          closePreview={closeDocumentPreview}
          hasPermissions={canUpdateAddress}
        >
          <div className="fs-16" style={{ padding: "1rem 0" }}>
            {oldNewAddressElements}
          </div>
        </UploadedDocumentPreview>
      ) : (
        <>
          <AccountHolderBox
            accountName={req.customerName}
            accountNumber={
              req.accountNumbers &&
              (req.accountNumbers.length > 1
                ? req.accountNumbers.join(", ")
                : req.accountNumbers[0])
            }
            profileImageUrl={req.customerProfileImageUrl}
            setSendMessageModal={setSendMessageModal}
          />

          <div
            className="fs-16"
            style={{
              padding: "0 1rem 1rem",
              borderBottom: "0.5px solid #d7d7d8",
            }}
          >
            {oldNewAddressElements}
          </div>

          <UploadedDocumentSummary
            document={document}
            timestamp={req.timestamp}
            onClick={() => setPreviewDoc(true)}
          />

          {canUpdateAddress && (
            <div
              style={{ padding: "3rem 1rem" }}
              className="flex gap equal-widths fs-16"
            >
              <button
                onClick={() => setShowDeclineModal(true)}
                disabled={showDeclineModal || approving}
                className="btn outline danger"
              >
                Decline
              </button>

              <DelayedActionButton
                className="btn outline"
                text="Approve"
                confirmText="Confirm Approval"
                disabled={
                  showDeclineModal ||
                  approving ||
                  document.status !== "accepted"
                }
                isBusy={approving}
                clickAction={approveRequest}
              />
            </div>
          )}
        </>
      )}

      {showDeclineModal && (
        <Modal
          className="modal-heading-subheading-buttons"
          handleClose={declining ? null : () => setShowDeclineModal(false)}
        >
          <DeclineRequestForm
            requestType="Address Update"
            declineRequest={declineRequest}
            done={() => setShowDeclineModal(false)}
          />
        </Modal>
      )}
    </Modal>
  );
}
