const CustomerIcon = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="56" rx="16" fill="#FC3833" fillOpacity="0.08" />
      <path
        d="M27.9994 4.6665C15.1193 4.6665 4.66602 15.1198 4.66602 27.9998C4.66602 40.8798 15.1193 51.3332 27.9994 51.3332H46.666C49.2327 51.3332 51.3327 49.2332 51.3327 46.6665V27.9998C51.3327 15.1198 40.8794 4.6665 27.9994 4.6665ZM27.9994 46.6665C21.256 46.6665 15.3293 43.0732 12.0627 37.7065L18.946 30.8232L26.6227 37.3332L37.3327 26.6465V30.3332H41.9994V18.6665H30.3327V23.3332H34.0194L26.366 30.9865L18.666 24.4998L10.056 33.1332C9.58935 31.4998 9.33268 29.7732 9.33268 27.9998C9.33268 17.7098 17.7094 9.33317 27.9994 9.33317C38.2894 9.33317 46.666 17.7098 46.666 27.9998C46.666 38.2898 38.2894 46.6665 27.9994 46.6665ZM45.4994 47.8332C44.216 47.8332 43.166 46.7832 43.166 45.4998C43.166 44.2165 44.216 43.1665 45.4994 43.1665C46.7827 43.1665 47.8327 44.2165 47.8327 45.4998C47.8327 46.7832 46.7827 47.8332 45.4994 47.8332Z"
        fill="#FC3833"
      />
    </svg>
  );
};

export default CustomerIcon;
