import React, { useEffect, useRef, useState } from "react";
import { FiLoader } from "react-icons/fi";

import ArrowLeftIcon from "../assets/icons/arrowleft";

export const PrimaryButton = (props) => {
  return Button("btn primary", props);
};

export const OutlineButton = (props) => {
  return Button("btn outline", props);
};

export const BackButton = ({ children, ...props }) => {
  return Button("back btn", {
    ...props,
    children: (
      <>
        <ArrowLeftIcon width="16" /> {children ?? "Back"}
      </>
    ),
  });
};

export const Button = (defaultClass, props) => {
  const { className, big, mini, children, ...otherProps } = props;

  let _className = className ? `${defaultClass} ${className}` : defaultClass;
  if (big) _className += " big";
  else if (mini) _className += " mini";

  return (
    <button className={_className} {...otherProps}>
      {children}
    </button>
  );
};

export const DelayedActionButton = ({
  className,
  disabled,
  text,
  confirmText,
  busyText,
  isBusy,
  clickAction,
}) => {
  const actionReqTimeoutRef = useRef(null);
  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(actionReqTimeoutRef.current);
  }, []);

  const [actionRequested, setActionRequested] = useState(false);
  const cancelActionRequest = () => setActionRequested(false);

  const handleClick = async (e) => {
    e.stopPropagation();
    clearTimeout(actionReqTimeoutRef.current);

    // execute action if a request was previously queued.
    if (actionRequested) {
      clickAction();
      setActionRequested(false);
      return;
    }

    // queue the action request and auto-cancel after 3s.
    setActionRequested(true);
    actionReqTimeoutRef.current = setTimeout(cancelActionRequest, 3000);
  };

  return (
    <button
      disabled={disabled ?? isBusy}
      className={className}
      onClick={handleClick}
    >
      {isBusy
        ? busyText ?? <FiLoader />
        : actionRequested
        ? confirmText ?? "Confirm"
        : text ?? "Click Me"}
    </button>
  );
};
