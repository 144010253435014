import { useState } from "react";

import { DelayedActionButton } from "../../../components/Button";
import { FALLBACK_IMAGE_URL } from "../../../config/swr";

const AdminInfo = ({
  customer,
  chatRoom,
  connected,
  closingChatRoom,
  closeChatRoomClick,
}) => {
  const [imgSrc, setImgSrc] = useState(customer.imageUrl ?? FALLBACK_IMAGE_URL);

  return (
    <div className="flex start center gap-8 mb-12">
      <img
        className="profile-img"
        src={imgSrc}
        onError={() => {
          setImgSrc(FALLBACK_IMAGE_URL);
        }}
        alt="profile"
      />
      <h3>{customer.firstName}</h3>
      <div className="chat-popup-msg box-shadow fs-16">
        {!connected && <span>Reconnecting...</span>}
      </div>
      {!chatRoom.waitingForAdmin && (
        <DelayedActionButton
          className="mini btn outline danger"
          disabled={!connected}
          isBusy={closingChatRoom}
          clickAction={closeChatRoomClick}
          text={
            chatRoom.otherRespondingAdmin
              ? "UNASSIGN " + chatRoom.otherRespondingAdmin
              : "CLOSE CHAT ROOM"
          }
        />
      )}
    </div>
  );
};

export default AdminInfo;
