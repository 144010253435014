import { useState, useEffect, useRef } from "react";
import useSWR from "swr";
import { Link, Outlet } from "react-router-dom";
import { RiArrowRightLine } from "react-icons/ri";

import HeaderNavs from "./HeaderNavs";
import { RenderSideBarLinks } from "./SidebarLinks";
import Modal from "../Modal";
import logo from "../../assets/logo_sidebar.png";
import ArrowLeftIcon from "../../assets/icons/arrowleft";
import { getPermissions, useLogout } from "../../config/swr";

let timeNow = new Date().getHours();
const greeting =
  timeNow >= 5 && timeNow < 12
    ? " Good Morning"
    : timeNow >= 12 && timeNow < 18
    ? " Good Afternoon"
    : "Good evening";

const VIEW_SUMMARY_KEY = "dashboard-summary";

const PageWrapper = () => {
  const permissions = getPermissions();

  const { data } = useSWR(permissions[VIEW_SUMMARY_KEY] ? `/summary` : null);
  const isRunned = useRef(false);

  const defaultPageTitle = (
    <div className="pagetitle-fadeOut">
      {greeting}
      <p className="fs-18">Welcome back, Admin</p>
    </div>
  );
  const [pageTitle, setPageTitle] = useState(defaultPageTitle);
  const usePageTitle = (title, link) => {
    useEffect(() => {
      let element = title;
      if (link) {
        element = (
          <div className="flex gap-12 center start">
            <Link to={link}>
              <ArrowLeftIcon width={16} style={{ verticalAlign: "middle" }} />
            </Link>
            <span>{title}</span>
          </div>
        );
      }

      const titleElem = <div className="pagetitle-fadeIn">{element}</div>;
      let timer1;
      if (!isRunned.current) {
        setPageTitle(defaultPageTitle);
        timer1 = setTimeout(
          () => setPageTitle(titleElem ?? defaultPageTitle),
          2000
        );
        isRunned.current = true;
      } else {
        setPageTitle(titleElem);
      }

      return () => {
        clearTimeout(timer1);
      };
    }, [link, title]);
  };

  const [logoutModalOpen, setShowLogoutModal] = useState(false);
  const openLogoutModal = () => setShowLogoutModal(true);
  const closeLogoutModal = () => setShowLogoutModal(false);
  const logout = useLogout();

  return (
    <>
      <div className="sidebar">
        <div className="sidebar__logo">
          <Link to={"/overview"}>
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        <div className="fs-16 flex col gap" style={{ minHeight: "75vh" }}>
          <div>{RenderSideBarLinks()}</div>

          <div>
            <div className="sidebar__link logout" onClick={openLogoutModal}>
              <span>Log Out</span>
              <RiArrowRightLine size={18} />
            </div>

            <div className="flex center capitalize">
              <span className="sidebar__link">{data?.name}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="page-header-content">
        <header className="box-shadow">
          <h1 className="fs-24">{pageTitle}</h1>
          <div className="header-nav">
            <input className="search fs-18" type="text" placeholder="Search" />
            <HeaderNavs />
          </div>
        </header>

        <div className="page-content">
          <Outlet context={[usePageTitle]} />
        </div>
      </div>

      {logoutModalOpen && (
        <Modal
          handleClose={closeLogoutModal}
          className="modal-heading-subheading-buttons"
        >
          <h1 className="fs-24">Confirm Log Out</h1>
          <p className="mb-32">Are you sure you want to log out?</p>
          <div className="actions">
            <button className="btn primary fs-22" onClick={closeLogoutModal}>
              No
            </button>
            <button onClick={logout} className="btn transparent outline fs-22">
              Yes, Log Out
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PageWrapper;
