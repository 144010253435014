import React, { useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import { ReadyState } from "react-use-websocket";

import ChatIcon from "../../../assets/icons/chat";
import { setToken } from "../../../config/swr";
import MessageWindow from "./MessageWindow";
import useChatWebSocket, { chatMessageTypes } from "./useChatWebSocket.hook";

export default function ChatAndSupport({
  displayChatRooms,
  displayNewChatRoom,
  displayNewMessage,
  selectedChatRoom,
}) {
  const [ready, setReady] = useState(false);
  const [chatRoomsAvailable, setChatRoomsAvailable] = useState(false);

  const [tempMessages, addTempMessage, removeTempMsg, clearTempMessages] =
    useTempMessages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearTempMessages(), [selectedChatRoom]); // clear temp messages if selectedConversation changes

  const [readyState, wsSendMessage] = useChatWebSocket({
    [chatMessageTypes.ready]: (chatRooms) => {
      displayChatRooms(chatRooms);
      setChatRoomsAvailable(chatRooms && chatRooms.length > 0);
      setReady(true);
    },
    [chatMessageTypes.activeChatRooms]: (chatRooms) => {
      displayChatRooms(chatRooms);
      setChatRoomsAvailable(chatRooms && chatRooms.length > 0);
    },
    [chatMessageTypes.reloadActiveChatRooms]: () => {
      wsSendMessage(chatMessageTypes.fetchActiveChatRooms);
      // server will respond with a chatMessageTypes.activeChatRooms msg.
    },
    [chatMessageTypes.newChatRoom]: displayNewChatRoom,
    [chatMessageTypes.newMessage]: (msg) => {
      if (msg.tempID) {
        removeTempMsg(msg.tempID);
      }
      displayNewMessage(msg);
    },
    [chatMessageTypes.tokenUpdated]: setToken,
  });

  const joinChatRoom = () => {
    wsSendMessage(chatMessageTypes.joinChatRoom, {
      customerID: selectedChatRoom.createdBy.id,
      roomID: selectedChatRoom.id,
    });
  };

  const sendChatMessage = (msgText) => {
    const tempMsgID = addTempMessage(msgText);
    wsSendMessage(chatMessageTypes.sendMessage, {
      customerID: selectedChatRoom.createdBy.id,
      roomID: selectedChatRoom.id,
      tempID: tempMsgID,
      message: msgText,
    });
  };

  const closeChatRoom = () => {
    wsSendMessage(chatMessageTypes.closeChatRoom, {
      customerID: selectedChatRoom.createdBy.id,
      roomID: selectedChatRoom.id,
    });
  };

  return selectedChatRoom ? (
    <MessageWindow
      connected={readyState === ReadyState.OPEN}
      chatRoom={selectedChatRoom}
      joinChatRoom={joinChatRoom}
      closeChatRoom={closeChatRoom}
      sendMessage={sendChatMessage}
      tempMessages={tempMessages}
    />
  ) : ready ? (
    <div className="chat__conversation flex center">
      <div>
        <ChatIcon />
        <p className="fs-20">
          {chatRoomsAvailable
            ? "Select a conversation from the left"
            : "No active customer chat/support request"}
        </p>
      </div>
    </div>
  ) : (
    <div className="flex center">
      <FiLoader />
    </div>
  );
}

function useTempMessages() {
  const [tempMessages, setTempMessages] = useState({});

  const addTempMessage = (msgText) => {
    const id = `${new Date().getTime()}`; // TODO: use random id generator.
    setTempMessages({
      ...tempMessages,
      [id]: {
        id,
        text: msgText,
        createdAt: new Date().getTime(),
        status: "sending",
      },
    });
    return id;
  };

  const removeTempMsg = (tempMsgID) => {
    const msgs = { ...tempMessages }; // make a copy of tempMessages
    delete msgs[tempMsgID];
    setTempMessages(msgs);
  };

  const clearTempMessages = () => {
    setTempMessages({});
  };

  return [tempMessages, addTempMessage, removeTempMsg, clearTempMessages];
}
