import { useState } from "react";
import { useOutletContext } from "react-router";
import { BiCaretDown } from "react-icons/bi";

import ValidateCustomerInfoForm from "./form";
import useToggle from "../../../hooks/useToggle.hook";

import { FALLBACK_IMAGE_URL } from "../../../config/swr";

export default function ValidateCustomerInfoPage() {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Validate Customer Info");
  const [dropdownOpened, toggleDropdown] = useToggle(false);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);

  const dataDisplayClass = fetchingData
    ? "data-display fetching"
    : customerInfo
    ? "data-display"
    : "data-display empty-blocks";

  const customerDetails = customerInfo
    ? [
        { title: "Email", value: customerInfo.customerInfo?.email },
        { title: "Phone Number", value: customerInfo.customerInfo?.phoneNumber },
        { title: "BVN", value: customerInfo.customerInfo?.bvn },
        { title: "Date of Birth", value: customerInfo.customerInfo?.dateOfBirth },
      ]
    : Array(6).fill({});

  return (
    <>
      <div className="validate-info-content">
        <div className="box form-container">
          <ValidateCustomerInfoForm
            setFetchingData={setFetchingData}
            onSuccess={(customerInfo) => setCustomerInfo(customerInfo)}
          />
        </div>

        <div className={`box ${dataDisplayClass}`}>
          <div className="flex center">
            <CustomerImage url={customerInfo?.customerInfo?.url} />
          </div>

          {customerInfo?.customerInfo && (
            <div
              className={`flex col ${customerInfo ? "gap-8" : "gap-4"} mb-20 center `}
            >
              <div className="flex">
                <h3 className="fs-20 mb-6">{customerInfo?.customerInfo?.lastName}</h3>
                &nbsp;
                <h3 className="fs-20 mb-6">{customerInfo?.customerInfo?.firstName}</h3>
              </div>
            </div>
          )}

          {/* Skeleton placeholder */}

          {!customerInfo && (
            <div className="flex col gap-8 mb-20">
              <div className="info-row flex mb-4 center">
                <div></div>
              </div>
            </div>
          )}

          <hr className="mb-20" />

          <div className={`flex col ${customerInfo ? "gap-8" : "gap-4"} mb-20`}>
            {customerDetails.map((info, index) => (
              <div key={index} className="info-row flex mb-4">
                <div>{info?.title}</div>
                <div>{info?.value}</div>
              </div>
            ))}

            {customerInfo?.customerInfo?.accounts &&
              customerInfo?.customerInfo?.accounts?.length > 0 && (
                <div className="card__box mb-12">
                  <div
                    className="flex gap between-center"
                    onClick={toggleDropdown}
                  >
                    <span>Accounts</span>
                    <span>
                      <BiCaretDown
                        size={18}
                        className={
                          dropdownOpened
                            ? "dropdown-icon rotate-180"
                            : "dropdown-icon"
                        }
                      />
                    </span>
                  </div>
                  <div
                    className={
                      dropdownOpened
                        ? "message__dropdown opened"
                        : "message__dropdown"
                    }
                  >
                    <div className="card__grid">
                      {customerInfo?.customerInfo?.accounts?.map(
                        ({ accountNumber, status }, i) => (
                          <div key={i} className="flex gap-8 between-center">
                            <span className="charcoal-60">
                              {`Account Number ${i + 1}`}:
                            </span>
                            <h3>
                              {`${accountNumber} `}
                              <span className="charcoal-60">({status})</span>
                            </h3>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

const CustomerImage = ({ url }) => {
  const [imgSrc, setImgSrc] = useState(url ?? FALLBACK_IMAGE_URL);

  return (
    <img
      src={imgSrc}
      onError={() => {
        setImgSrc(FALLBACK_IMAGE_URL);
      }}
      alt="customer dp"
      className={`profile-img mb-12`}
    />
  );
};
