import { useNavigate } from "react-router-dom";

import { OutsideClickAlerter } from "../../../../components/OutsideClickAlerter";
import ThreeDots from "../../../../assets/icons/threedots";

import { getPermissions } from "../../../../config/swr";

const VIEW_ADMIN_KEY = "view-loan-partner-admins";

const headers = [
  "Name",
  "Pending Loans",
  "Active Loans",
  "Completed Loans",
  "",
];

const PartnerTableList = ({
  partners,
  activeId,
  handleOpen,
  handleClose,
  handleEdit,
  handleDelete,
}) => {
  const navigate = useNavigate();
  const permissions = getPermissions();

  const canViewAdmin = permissions[VIEW_ADMIN_KEY];

  const handleClick = (e, id) => {
    e.stopPropagation();
    navigate(`/loans/loan-partners/${id}`);
  };

  partners = partners.map((partner) => {
    const loanSummaries = partner.loanSummaries.reduce(
      (obj, summary) => ({ ...obj, [summary.status]: summary.count }),
      {}
    );
    return {
      ...partner,
      pending: loanSummaries["Pending Approval"],
      active:
        loanSummaries["Active"] +
        loanSummaries["Due"] +
        loanSummaries["Overdue"],
      completed: loanSummaries["Completed"],
    };
  });

  return (
    <>
      {!partners || partners.length === 0 ? (
        <p className="box">No partners added yet</p>
      ) : (
        <table className="fs-20 box">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {partners.map((partner) => (
              <tr
                key={partner.id}
                className="clickable fw-600 loan-partner__row"
                onClick={(e) => handleClick(e, partner.id)}
              >
                <td>{partner.name}</td>
                <td>{partner.pending?.toLocaleString()}</td>
                <td>{partner.active?.toLocaleString()}</td>
                <td>{partner.completed?.toLocaleString()}</td>
                <td onClick={(e) => handleOpen(e, partner.id)}>
                  <ThreeDots style={{ height: "6px" }} />
                  {activeId === partner.id && (
                    <OutsideClickAlerter
                      className="box flex col start gap-8 loan-partner__options"
                      handleOnClickOutside={() => handleClose()}
                    >
                      <p onClick={(e) => handleEdit(e, partner.id)}>
                        Edit loan partner
                      </p>
                      <p onClick={(e) => handleDelete(e, partner.id)}>
                        Delete loan partner
                      </p>
                      {canViewAdmin && (
                        <p
                          onClick={() =>
                            navigate(`/loans/loan-partners/${partner.id}/admin`)
                          }
                        >
                          Manage Admins
                        </p>
                      )}
                    </OutsideClickAlerter>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default PartnerTableList;
