import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { FiEye, FiEyeOff, FiLoader } from "react-icons/fi";
import useSWRMutation from "swr/mutation";

import { OutlineButton } from "../../../components/Button";
import { sendPostRequest } from "../../../config/swr";
import useForm from "../../../hooks/useForm.hook";
import useToggle from "../../../hooks/useToggle.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";

const SecuritySettings = () => {
  const { trigger } = useSWRMutation("/change-password", sendPostRequest);
  const [setPageTitle] = useOutletContext();
  setPageTitle("Security Settings");

  const [pwdError, setPwdError] = useState("");
  const [currPwdVisible, toggleCurrPasswordVisibility] = useToggle(false);
  const [newPwdVisible, toggleNewPasswordVisibility] = useToggle(false);
  const [confPwdVisible, toggleConfPasswordVisibility] = useToggle(false);
  const form = useForm();
  const showMessageModal = useMessageModal();

  const [formData, setFormData] = useState({
    curpwd: "",
    newpwd: "",
    confpwd: "",
  });

  const buttonDisabled =
    !formData.curpwd ||
    !formData.newpwd ||
    !formData.confpwd ||
    form.state.submitting;

  const onChange = (e) => {
    setPwdError("");
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPwdError("");

    if (buttonDisabled) return;

    if (formData.newpwd !== formData.confpwd)
      return setPwdError("Confirm password does not match new password.");

    form.submitStarted();

    const data = {
      oldPassword: formData.curpwd,
      newPassword: formData.newpwd,
    };

    try {
      const res = await trigger(data);
      showMessageModal({
        title: "Success",
        message: res.data?.message,
        isError: false,
        closeButtonText: "Close",
      });
      form.submitSuccess();
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  return (
    <form className="settings security-form" onSubmit={handleSubmit}>
      <div>
        <h3 className="fs-20">Security</h3>
        <h2 className="fs-16 mb-32">Change Password</h2>

        <label htmlFor="curpwd">Enter Current Password</label>
        <div className="input-container mb-16">
          <input
            type={currPwdVisible ? "visible" : "password"}
            name="curpwd"
            placeholder="••••••••"
            value={formData.curpwd}
            onChange={onChange}
            disabled={form.state.submitting}
          />
          <span id="form-field-icon" onClick={toggleCurrPasswordVisibility}>
            {currPwdVisible ? (
              <FiEyeOff size={16} color={"#ABB3B7"} />
            ) : (
              <FiEye size={16} color={"#ABB3B7"} />
            )}
          </span>
        </div>

        <label htmlFor="newpwd">Enter New Password</label>
        <div className="input-container mb-16">
          <input
            type={newPwdVisible ? "visible" : "password"}
            name="newpwd"
            placeholder="••••••••"
            value={formData.newpwd}
            onChange={onChange}
            disabled={form.state.submitting}
          />
          <span id="form-field-icon" onClick={toggleNewPasswordVisibility}>
            {newPwdVisible ? (
              <FiEyeOff size={16} color={"#ABB3B7"} />
            ) : (
              <FiEye size={16} color={"#ABB3B7"} />
            )}
          </span>
        </div>

        <label htmlFor="confpwd">Confirm New Password</label>
        <div className="mb-32">
          <div className="input-container">
            <input
              type={confPwdVisible ? "visible" : "password"}
              name="confpwd"
              placeholder="••••••••"
              value={formData.confpwd}
              onChange={onChange}
              disabled={form.state.submitting}
            />
            <span id="form-field-icon" onClick={toggleConfPasswordVisibility}>
              {confPwdVisible ? (
                <FiEyeOff size={16} color={"#ABB3B7"} />
              ) : (
                <FiEye size={16} color={"#ABB3B7"} />
              )}
            </span>
          </div>
          {pwdError && <div className="form-error">{pwdError}</div>}
        </div>

        <OutlineButton
          className="fullwidth"
          style={{ borderWidth: "1px" }}
          disabled={buttonDisabled}
        >
          {form.state.submitting ? <FiLoader /> : <>Save Password</>}
        </OutlineButton>
      </div>
    </form>
  );
};

export default SecuritySettings;
