import React, { useState } from "react";

import logo from "../../assets/logo_with_name.png";
import useFCMToken from "../../hooks/useFCMToken.hook";
import LoginForm from "./forms/LoginForm";
import LoginOTPForm from "./forms/LoginOTPForm";
import ForgotPasswordForm from "./forms/ForgotPasswordForm";
import ResetPasswordForm from "./forms/ResetPasswordForm";
import SetPasswordForm from "./forms/SetPasswordForm";

export const LoginFormID = 1,
  LoginOTPFormID = 2,
  ForgotPasswordFormID = 3,
  ResetPasswordFormID = 4,
  SetPasswordFormID = 5;

const LoginPage = ({ initialFormID }) => {
  const fcmToken = useFCMToken();
  const [pageState, setPageState] = useState({
    formID: initialFormID ?? LoginFormID,
  });
  const changeForm = (formID, data) => setPageState({ formID, data });
  const backToLogin = () => changeForm(LoginFormID);

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-row">
          <div className="login-col left">
            <img className="logo" src={logo} alt="logo" />
          </div>
          <div className="login-col right">
            {pageState.formID === LoginFormID && (
              <LoginForm
                fcmToken={fcmToken}
                loginSuccess={(data) => changeForm(LoginOTPFormID, data)}
                onForgotPasswordClick={() => changeForm(ForgotPasswordFormID)}
              />
            )}

            {pageState.formID === LoginOTPFormID && (
              <LoginOTPForm {...pageState.data} onCanceled={backToLogin} />
            )}

            {pageState.formID === ForgotPasswordFormID && (
              <ForgotPasswordForm
                fcmToken={fcmToken}
                onSuccess={() => changeForm(LoginFormID)}
                onCanceled={backToLogin}
              />
            )}

            {pageState.formID === ResetPasswordFormID && (
              <ResetPasswordForm onCanceled={backToLogin} />
            )}

            {pageState.formID === SetPasswordFormID && (
              <SetPasswordForm fcmToken={fcmToken} goToLogin={backToLogin} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
