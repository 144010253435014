import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft, FiLoader } from "react-icons/fi";
import VerificationInput from "react-verification-input";
import useSWRMutation from "swr/mutation";

import useForm from "../../../hooks/useForm.hook";
import useMessageModal from "../../../hooks/useMessageModal.hook";
import { sendPostRequest, setPermissions, setToken } from "../../../config/swr";

const LoginOTPForm = ({ email, deviceID, notificationToken, onCanceled }) => {
  const { trigger } = useSWRMutation("/validate-login-otp", sendPostRequest);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const form = useForm();
  const showMessageModal = useMessageModal();

  const validateOTP = async () => {
    if (otp.length < 6 || form.state.submitting) return;

    form.submitStarted();

    const data = {
      email,
      deviceID,
      notificationToken,
      otp: otp,
    };

    try {
      const res = await trigger(data);
      setToken(res?.data?.authToken);
      setPermissions(res?.data?.permissions);
      form.submitSuccess();
      if (res?.data?.permissions?.["dashboard-summary"]) {
        navigate("/overview");
        return;
      }
      navigate("/loans");
    } catch (error) {
      showMessageModal({
        title: "Error",
        message:
          error?.response?.data?.errorMessage ||
          "An error occurred. Please try again",
        isError: true,
        closeButtonText: "Close",
      });
      form.submitError();
    }
  };

  const buttonDisabled = otp.length < 6 || form.state.submitting;

  return (
    <form className="fullwidth login-otp">
      <FiArrowLeft
        id="btn-back"
        size={24}
        color={"#3D5059"}
        onClick={onCanceled}
        style={{ cursor: "pointer" }}
      />

      <h1 className="fs-24">Login Authentication</h1>
      <p className="fs-16 mb-32">
        Enter the verification code that was sent to {email}
      </p>

      <div className="login-form-field otp mb-32">
        <label htmlFor="email">Authentication Code</label>
        <div className="otp-box">
          <VerificationInput
            placeholder="x"
            classNames={{
              container: "otp-input-container",
              character: "otp-input-character",
              characterInactive: "otp-input-character--inactive",
              characterSelected: "otp-input-character--selected",
            }}
            autoFocus={!form.state.submitting}
            onChange={(e) => setOtp(e)}
            passwordMode
          />
          {form.state.submitting && <div className="blockade"></div>}
        </div>
      </div>

      <button
        className="btn primary fullwidth"
        onClick={validateOTP}
        disabled={buttonDisabled}
      >
        {form.state.submitting ? <FiLoader /> : <>Confirm</>}
      </button>

      <button className="btn transparent fullwidth fs-16" onClick={onCanceled}>
        Cancel
      </button>
    </form>
  );
};

export default LoginOTPForm;
