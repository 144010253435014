import { useState } from "react";
import { useOutletContext } from "react-router-dom";

import DataFetcher from "../../../components/DataFetcher";
import FlexiInvestmentSettings from "./tabs/FlexiInvestment";
import StandardInvestmentSettings from "./tabs/StandardInvestment";
import StockInvestmentSettings from "./tabs/StockInvestment";

const InvestmentsSettings = () => {
  const [setPageTitle] = useOutletContext();
  setPageTitle("Investments Settings");

  return (
    <DataFetcher
      url={`/settings/investments`}
      buildUI={(data) => <InvestmentDisplay data={data} />}
    />
  );
};

const InvestmentDisplay = ({ data }) => {
  const { flexi, standard, stock } = data;
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="settings">
      <div className="tab-nav mb-32">
        <button
          className={`btn box${activeTab === 1 ? " active" : ""}`}
          onClick={() => setActiveTab(1)}
        >
          Flexi Investment
        </button>
        <button
          className={`btn box${activeTab === 2 ? " active" : ""}`}
          onClick={() => setActiveTab(2)}
        >
          Standard Investment
        </button>
        <button
          className={`btn box${activeTab === 3 ? " active" : ""}`}
          onClick={() => setActiveTab(3)}
        >
          Stock Investment
        </button>
      </div>

      <div className="tabs mb-32">
        <h2 className="fs-20 mb-16">Edit investment Package</h2>
        {activeTab === 1 && <FlexiInvestmentSettings flexi={flexi} />}
        {activeTab === 2 && <StandardInvestmentSettings standard={standard} />}
        {activeTab === 3 && <StockInvestmentSettings stock={stock} />}
      </div>
    </div>
  );
};

export default InvestmentsSettings;
